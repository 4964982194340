var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var moment = require("moment") ;
var FieldGeneric = require("aetna-views/fields/generic") ;
var str = require("aetna-string") ;

var model = "aetna.stats.invoices" ;
var moduleName = "statsInvoices" ;

function StatsInvoicesGridView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"period",
			"partner",
			"invoice_count",
			"total_untaxed",
			"total_tax",
			"total_amount",
			"total_residual",
			"total_paid"
    	],
    	gridOptions : {
    		show : {
    			selectColumn : false,
    			toolbarReload  : false
    		}
    	},
    	summary : {
	    	colsToSum : ["invoice_count", "total_untaxed", 
	    	"total_tax", "total_amount", "total_residual", "total_paid"]
    	}
	}) ;
	
	
	this.staticHTML = null;
	this.staticCSS = null;
	
	_self.fields.fields["aetna.stats.invoices~~grid"].colsCustom = [
		{field : "partner", hidden : true}
	] ;
	
	
	
	var filtersLinks = ["filterPeriod", "filterPartner"] ;
	
	_self.once("initDone", function(){
		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey){
					filtersLinks.forEach(function(otherId){
						if(id !== otherId){
							_self.$EL[otherId].removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
		
		_self.dateStart = new FieldGeneric(t, "date_start", "date", t("accountingVAT.from")) ;
		_self.dateEnd = new FieldGeneric(t, "date_end", "date", t("accountingVAT.to")) ;
		
		_self.dateStart.init(_self.EL.dateStart);
		_self.dateEnd.init(_self.EL.dateEnd);
		
		_self.dateStart.load(moment().subtract(1, 'month').startOf('year').toDate()) ;
		_self.dateEnd.load(moment().subtract(1, 'month').endOf('month').toDate()) ;
		
		_self.dateStart.addEventListener("change", function(){
			_self.emit("changeFilter", {filter : _self.getFilter()}) ;
		});
		_self.dateEnd.addEventListener("change", function(){
			_self.emit("changeFilter", {filter : _self.getFilter()}) ;
		});
		
	}) ;
	
	_self.on("changeFilter", function(ev){
		
		var hasFilter = false ;
			
		if(ev.data.filter.period){
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.showColumn('period');
			hasFilter = true ;
		}else{
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.hideColumn('period');
		}
		if(ev.data.filter.partner){
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.showColumn('partner');
			hasFilter = true ;
		}else{
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.hideColumn('partner');
		}
		
		if(!hasFilter){
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.showColumn('period');
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.showColumn('partner');
		}
		
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh() ;
		_self.resizeTable() ;
	}) ;
	
	
	var _load = this.load;
	this.load = function(records){
		_load(records) ;
	} ;
	
	this.getFilter = function(){
		var filter = {} ;
		
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;	
			if($a.hasClass("active")){
				filter[str.unCapitalize(id.replace(/^filter/, ""))] = true ;
			}
		});
		
		filter.from = _self.dateStart?_self.dateStart.val():moment().subtract(1, 'month').startOf('month').toDate() ;
		filter.to = _self.dateEnd?_self.dateEnd.val():moment().subtract(1, 'month').endOf('month').toDate() ;
		
		
		return filter ;
	} ;
	
	
	this.resizeTable = function(){
		
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		_self.EL.gridRow.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields["aetna.stats.invoices~~grid"].render() ;
	} ;
}

module.exports =StatsInvoicesGridView;
