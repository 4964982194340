var BaseView = require("aetna-views/BaseView.js") ;
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;

function ConfirmActionView(serverUrl){
    BaseView.call(this, t, "doc", "confirmAction") ;
	
	var _self = this ;
   
   	var fields = new FieldsEMA(t, serverUrl) ;
   	
   	
   	fields.fields["mail.mail~body_html"].editorHeight = $(window).height() - 470 ;
	   
	this._initElements = function(callback){
		
        fields.init(_self, function(){
            callback() ;
        }) ;
		
		_self.EL.cancel.addEventListener("click", _self.closePopup) ;
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
	};
	
	this.load = function(msg, mail){
	    _self.EL.msg.innerHTML = msg ;
	    _self.mail = mail ;
	    if(mail){
		    fields.load(mail) ;
	    }else{
	        _self.EL.btCustomMail.style.display = "none" ;
	    }
	} ;
	
	
	this.onValidate = function(){
	    var values = {} ;
	    if(_self.mail){
    		var fieldInError = false ;
    		fields.usedFields.forEach(function(f){
    			f.clearError();
    			if(!f.isReadOnly() && f.isRequired() && f.isEmpty()){
    				f.error() ;	
    				fieldInError = true ;
    			}
    			if(!f.isEmpty() && !f.isValid()){
    				f.error(t("invalidFormat")) ;
    				fieldInError = true ;
    			}
    		}) ;
    		
    		values = fields.values()["mail.mail"];
    		
    		if(fieldInError){
    			return _self.error(t("mail.thereIsErrorPleaseCorrect")) ;
    		}
	    }
		
		_self.emit("validate", values) ;
	} ;
		
	
}

module.exports = ConfirmActionView;
