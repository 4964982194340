/* global BootstrapDialog */
var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var BUS = require("aetna-events").EventSource.BUS;
var DistantFile = require("../../api/DistantFile") ;
var uuid = require("uuid") ;
var loki = require("../../api/loki") ;
var PopupPdfView = require("./popupPdf") ;
var PopupUpload = require("aetna-views/views/popupUpload/popupUpload") ;

var LibsLoader = require("aetna-views/LibsLoader") ;
LibsLoader.load([
	"animate.css/animate.min.css"
]) ;

function DocGridView(serverUrl){
    BaseView.call(this, t, "doc", "gridDocs") ;
	
	var _self = this ;
	
	this.initialVisibility = "visible" ;
   
   	this.fields = new FieldsEMA(t, serverUrl) ;
   	
   	this.colsToShow = [
			"AetnaExt",
			"AetnaRef",
			"project_name",
			"name",
			"AetnaSubtitle",
			"create_date",
			"create_uid",
			"write_date",
			"write_uid",
			"user_name",
			"AetnaRestrictGroup_ids",
			"AetnaWorkflow_name",
			"status_label",
			"AetnaRev"
		] ;
		
	this.multiSelect = true	 ;
	this.offlineMode = false ;
	this.idGrid = uuid.v4() ;
	
	this.expanded = {} ;
	this.lastExpanded ;
	   
	   
	this._initElements = function(callback){
		var context = require("aetna-views/context").context;
		var storage = require("aetna-storage").storage ;

		_self.workflowsById = storage(context().name).get("workflowsById") ;

		_self.stagesByName = storage(context().name).get("stagesByName") ;
		
		_self.user = storage(context().name).get("user") ;
		//_self.schemas = storage(context().name).get("schemas") ;
		_self.schemas = JSON.parse(localStorage.getItem(serverUrl+"/schemas")).schemas ;
		
		
		_self.fields.fields["project.task~~grid"].colsToShow = _self.colsToShow ;
		_self.fields.fields["project.task~~grid"].colsCustom = [] ;
		
		var indexSubtitle = _self.colsToShow.indexOf("AetnaSubtitle") ;
		var customFields = [];
		_self.schemas.MAIN.tables.some(function(table){
			if(table.tableName === "project.task") {
				table.columns.forEach(function(col){
					if(col.name.indexOf("x_") === 0){
						customFields.push(col) ;
						
						_self.fields.fields["project.task~~grid"].colsToShow.splice(indexSubtitle+1,0,col.name) ;
						_self.fields.fields["project.task~~grid"].colsCustom.push({field : col.name, caption : col.desc, size :"150px"}) ;
					}
				}) ;
				return true ;
			}
		}) ;
		
		if(_self.colsToShow.indexOf("AetnaExt") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "AetnaExt",  size :"20px", searchable : true, sortable : true, render : function(record){
					var icon = "file-o" ;
					if(record.AetnaExt){
						switch(record.AetnaExt.toLowerCase()){
							case "docx":
							case "doc":
							icon = "file-word-o text-primary" ;
							break;
							case "xlsx":
							case "xls":
							icon = "file-excel-o text-success" ;
							break;
							case "pptx":
							case "ppt":
							icon = "file-powerpoint-o text-danger" ;
							break;
							case "pdf":
							icon = "file-pdf-o text-default" ;
							break;
						}
					}
					return '<i class="fa fa-'+icon+'" title="'+record.AetnaExt+'"></i>' ;
				}}) ;
		}
		
		if(_self.colsToShow.indexOf("AetnaRestrictGroup_ids") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "AetnaRestrictGroup_ids",  size :"100px", searchable : false, sortable : false, hidden : true,  render : function(record){
					var groups = [] ;
					record.AetnaRestrictGroup_ids.forEach(function(gid){
						var g = loki.daos["hr.aetna.group"].getById(gid) ;
						if(g){
							groups.push(g.name) ;
						}
					}) ;
					return groups.join(", ") ;
				}}) ;
		}
		
		if(_self.colsToShow.indexOf("project_name") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "project_name", caption: t("fields.project.task.project_id"), size :"150px", searchable : true, sortable : true}) ;
		}
		
		if(_self.colsToShow.indexOf("user_name") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "user_name", caption: t("fields.project.task.user_name"), size :"150px", searchable : true, sortable : true}) ;
		}
		
		if(_self.colsToShow.indexOf("create_date") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "create_date", caption: t("fields.project.task.create_date"), size :"120px", searchable : true, sortable : true}) ;
		}
		
		if(_self.colsToShow.indexOf("write_date") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "write_date", caption: t("fields.project.task.write_date"), size :"120px", searchable : true, sortable : true}) ;
		}
	
		if(_self.colsToShow.indexOf("create_uid") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "create_name", hidden : true, searchable : true, sortable : true}) ;
		}
		if(_self.colsToShow.indexOf("write_uid") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "write_name", hidden : true, searchable : true, sortable : true}) ;
		}
		
		if(_self.colsToShow.indexOf("AetnaWorkflow_name") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "AetnaWorkflow_name", caption: t("fields.project.task.AetnaWorkflow_name"),size :"150px", searchable : true, sortable : true}) ;
		}
		
		if(_self.colsToShow.indexOf("AetnaRef") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "AetnaRef", caption: t("fields.project.task.AetnaRef"), size :"200px", searchable : true, sortable : true,
					render : function(record){
						if(record.AetnaRef){
							return record.AetnaRef ;
						}else {
							return "<i>&nbsp;&nbsp;&nbsp;("+t("doc.tempRef")+")</i>" ;
						}
					}
				}) ;
		}
		if(_self.colsToShow.indexOf("AetnaRev") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push(
				{field : "AetnaRev", caption: t("fields.project.task.AetnaRev"), size :"40px", searchable : true, sortable : true}) ;
		}
		
		if(_self.colsToShow.indexOf("status_label") !== -1){
			_self.fields.fields["project.task~~grid"].colsCustom.push({
				caption   : t("fields.project.task.stage_id"),
				field     : "status_label",
				size      : "70px",
				searchable : true, 
				sortable : true,
				// render	  : function(record){
				// 	return 
				// }
			}) ;
		}
		
		if(_self.offlineMode){
			_self.fields.fields["project.task~~grid"].colsCustom.push({
				caption   : "",
				field     : "open",
				size      : "120px",
				render	  : function(record){
					return 	'<button class="btn btn-primary doc_gridDocs__openDoc"><i class="fa fa-folder-open"></i> '+t("doc.openDocNoSoft")+'</button>' ;
				}
			}) ;
		}


		
		var toolbar ={
            items: [
            	{ type: 'break' , id:'breakCreate'},
            	{ type: 'html',  id: 'CREATE', html : '<button class="btn btn-sm btn-primary" data-action="CREATE"><i class="fa fa-plus-square"></i> '+t("doc.newDoc")+'</button>'},
            	{ type: 'break' , id:'breakAction', hidden: true},
            	{ type: 'html',  id: 'SUBMIT', html : '<button class="btn btn-sm btn-primary" data-action="SUBMIT"><i class="fa fa-arrow-circle-right"></i> '+t("doc.action.SUBMIT")+'</button>', hidden: true},
            	{ type: 'html',  id: 'PUBLISH', html : '<button class="btn btn-sm btn-success" data-action="PUBLISH"><i class="fa fa-check-circle-o"></i> '+t("doc.action.PUBLISH")+'</button>', hidden: true},
            	{ type: 'html',  id: 'REWORK', html : '<button class="btn btn-sm btn-warning" data-action="REWORK"><i class="fa fa-retweet"></i> '+t("doc.action.REWORK")+'</button>', hidden: true},
            	{ type: 'html',  id: 'DELETE', html : '<button class="btn btn-sm btn-danger" data-action="DELETE"><i class="fa fa-trash"></i> '+t("doc.action.DELETE")+'</button>', hidden: true},
            	{ type: 'html',  id: 'SHARE', html : '<button class="btn btn-sm btn-primary doc_gridDocs__shareToolbar" data-action="SHARE"><i class="fa fa-share-alt"></i> '+t("doc.share")+'</button>', hidden: true},
            	{ type: 'spacer' },
                { type: 'check',  id: 'selectMulti', caption: t("doc.selectMulti"), icon: 'fa fa-square-o', checked: false },
            	{ type: 'button',  id: 'xls', icon: 'fa fa-file-excel-o' },
            	{ type: 'html',  id: 'count', html : '<span class="badge grid-count">42</span>'},
            ],
            onClick: function (event) {
            	if (event.target == 'xls') {
                	_self.exportXls() ;
                } else if (event.target == "w2ui-reload") {
                	_self.onReload(event) ;
                }else if (event.target == 'selectMulti') {
                	var checked = !event.item.checked ; //event fired before the change !
                	_self.setSelectMulti(checked) ;
                	if(checked){
                		event.item.icon = "fa fa-check-square-o" ;
                		
                		
                	}else{
                		event.item.icon = "fa fa-square-o" ;
                		this.hide('noAction');
                	}
                	this.show('selectMulti');
                }
            }
        } ;
        if(!_self.multiSelect || _self.offlineMode){
        	toolbar = null;
        }

		
		_self.fields.fields["project.task~~grid"].options = {
			name : _self.idGrid,
			show: {
	            header         : false,
	            toolbar     : true,
	            footer        : false,
	            lineNumbers    : false,
	            selectColumn: !_self.multiSelect && !_self.offlineMode,
	            expandColumn: !_self.offlineMode
	        },
	        toolbar: toolbar,
	        multiSelect : false,
	        reorderColumns: true,
		    sortData: [
		        { field: 'create_date', direction: 'desc' }
		    ],
	        onExpand: function (event) {
				$(".tooltip").remove(); //remove all tooltip to avoid remaining tooltip
				
				var $recordDiv = _self.$EL.grid.find(".w2ui-grid-records") ;
            	//var heightRecord = $recordDiv.height() ;
            	
	            // var height = heightRecord*0.75 ;
	            // if(height<100){
	            // 	height = 100 ;
	            // }
	            var height = 100 ;
				var lineNumber = parseInt($("#grid_gridDocMain_rec_"+event.recid).attr("line"), 10) ;
				
				var initialScroll = $recordDiv.scrollTop() ;
				var newScroll = initialScroll ;
				
				//prevent grid auto scroll
				_self.fields.fields["project.task~~grid"].grid.grid.last.scrollTop = 0;
				_self.fields.fields["project.task~~grid"].grid.grid.last.scrollLeft = 0;
				
				$(".w2ui-expanded2>div").css({height: 0, overflow: 'hidden'}) ;
				
				$(".w2ui-expanded").each(function(i, el){ 
					var recidToCollapse = el.getAttribute("recid"); 
					if(recidToCollapse !==  event.recid){
						_self.fields.fields["project.task~~grid"].grid.grid.collapse(recidToCollapse) ;
					}
				}) ;
        		Object.keys(_self.expanded).forEach(function(recid){
					if(recid !== event.recid && _self.fields.fields["project.task~~grid"].grid.grid.get(recid)){
						
						var expandedLineNumber = parseInt($("#grid_gridDocMain_rec_"+recid).attr("line"), 10) ;
						if(expandedLineNumber < lineNumber){
							newScroll = initialScroll - height ;
						}
						
						//_self.fields.fields["project.task~~grid"].grid.grid.collapse(recid) ;	
					}
				}) ;
				
				$recordDiv.scrollTop(newScroll) ;
				
				
				
				var $boxExpanded = $('#'+event.box_id) ;
				
				
				event.onComplete = function(){
						
		         	var record = _self.fields.fields["project.task~~grid"].grid.get(event.recid) ;
		         	
		         	
		         	
		         	var $row = $('<div class="row doc_gridDocs__expandContainer"></div>') ;
		         	$boxExpanded.append($row) ;
		            
		            $boxExpanded.css({ 'height': height, 'opacity' : 1 });
		            
		            
		            
		            
		            var $divButtons = $('<div class="doc_gridDocs__buttons"></div>') ;
		            $row.append($divButtons);
		            
		            var $divPdf = $('<div class="doc_gridDocs__expandBtn doc_gridDocs__btPdf"></div>') ;
		            var $btPdf = $('<button class="btn btn-default" data-toggle="tooltip"  data-placement="bottom" title="'+t("doc.genPdf")+'"><i class="fa fa-file-pdf-o"></i> <span>'+t("doc.genPdf")+'</span></button>') ;
		            $divPdf.append($btPdf);
		            $divButtons.append($divPdf);	
		            
		            if(["docx", "xlsx", "pptx","doc", "xls", "ppt",  "pdf"].indexOf(record.AetnaExt.toLowerCase()) === -1){
		            	$divPdf.css("display", "none") ;
		            }
		            
		            
		            $btPdf.on("click", function(){
		            	var popup = new PopupPdfView(serverUrl) ;
		            	popup.openInPopup({
							size: BootstrapDialog.SIZE_VERY_WIDE, 
							title: '<i class="fa fa-file-pdf-o"></i>'
						}, function(){
							popup.load(record) ;
						});
		            }) ;
		            
		            if(record.AetnaWorkflow_id){ 
		            	var $divWorkflow = $('<div  class="doc_gridDocs__expandBtn doc_gridDocs__workflowBts"></div>') ;
		            	var possibleActions = _self.getPossibleActions(record) ;
			            ["SUBMIT", "PUBLISH", "REWORK",  "DELETE"].forEach(function(action){
			            	var style="info" ;
			            	var icon = "" ;
							switch(action){
								case "SUBMIT":
									style = "primary" ;
									icon = "fa-arrow-circle-right" ;
									break;
								case "PUBLISH":
									style = "success" ;
									icon = "fa-check-circle-o" ;
									break;
								case "REWORK":
									style = "warning" ;
									icon = "fa-retweet" ;
									break;
								case "DELETE":
									style = "danger" ;
									icon = "fa-trash" ;
									break;
							}
							var disabled = possibleActions.indexOf(action) === -1 ? ' disabled="disabled"':'' ;
							var $bt = $('<button class="btn btn-'+style+'" data-action="'+action+'" '+disabled+'  data-toggle="tooltip"  data-placement="bottom" title="'+t("doc.action."+action)+'"><i class="fa '+icon+'"></i> <span>'+t("doc.action."+action)+'</span></button>') ;
							$bt.appendTo($divWorkflow) ;
							$bt.on("click", function(){
								_self.applyAction(record, action) ;
							}) ;
			            }) ;
						
						// var margin = height-72;//128;
						//var width = $( _self.container ).width() - $viewer.width() - 235 ;
						
						var $divBts = $('<div  class="doc_gridDocs__expandBtn doc_gridDocs__actionBts"></div>') ;
						
						
						var $btNotifs = $('<button class="btn btn-primary doc_gridDocs__openNotifs" data-toggle="tooltip" data-placement="top" title="'+t("doc.openNotifs")+'"><i class="fa fa-list"></i> <span>'+t("doc.openNotifs")+'</span></button>') ;
						$btNotifs.on("click", function(){
							_self.openNotifs(record) ;
						}) ;
						$btNotifs.appendTo($divBts) ;
						
						var officeSoftware ;
						if(record.AetnaExt){
							switch(record.AetnaExt.toLowerCase()){
								case "docx":
								officeSoftware = "Word" ;
								break;
								case "xlsx":
								officeSoftware = "Excel" ;
								break;
								case "pptx":
								officeSoftware = "PowerPoint" ;
								break;
							}
						}
						
						if(officeSoftware || window.isDesktop){
							var labelOpen = t("doc.openDoc", {soft : officeSoftware}) ;
							if(!officeSoftware){
								labelOpen = t("doc.openDocNoSoft") ;
							}
							var $bt = $('<button class="btn btn-primary doc_gridDocs__openDoc" data-toggle="tooltip" data-placement="top" title="'+labelOpen+'"><i class="fa fa-folder-open"></i> <span>'+
							labelOpen+'</span></button>') ;
							$bt.on("click", function(){
								_self.openDoc(record) ;
							}) ;
							$bt.appendTo($divBts) ;
						}else{
							var $btDown = $('<button class="btn btn-primary doc_gridDocs__openDoc" data-toggle="tooltip" data-placement="top" title="'+t("doc.downloadDoc")+'"><i class="fa fa-download"></i> <span>'+t("doc.downloadDoc")+'</span></button>') ;
							$btDown.on("click", function(){
								_self.downloadDoc(record) ;
							}) ;
							$btDown.appendTo($divBts) ;
						}
						
						var $btDetails = $('<button class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="'+t("doc.openDetails")+'"><i class="fa fa-info-circle"></i> <span>'+t("doc.openDetails")+'</span></button>') ;
						$btDetails.on("click", function(){
							_self.openDetails(record) ;
						}) ;
						$btDetails.appendTo($divBts) ;
						
						if(record.stage_name === "PUBLISHED" && record.AetnaRev_last === record.AetnaRev){
							var $btRev = $('<button class="btn btn-primary doc_gridDocs__createRev" data-toggle="tooltip" data-placement="top" title="'+t("doc.createRev")+'"><i class="fa fa-plus-circle"></i> <span>'+t("doc.createRev")+'</span></button>') ;
							$btRev.on("click", function(){
								_self.createRev(record) ;
							}) ;
							$btRev.appendTo($divBts) ;
						}
						
						var $btCreateFrom = $('<button class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="'+t("doc.createFromThisDoc")+'"><i class="fa fa-files-o"></i> <span>'+t("doc.createFromThisDoc")+'</span></button>') ;
		            	
		            	if(DistantFile.isFileAvailable("project.task", record.id)){
							$btCreateFrom.on("click", function(){
								_self.onCreateFrom(record) ;
							}) ;
		            	}else{
		            		$btCreateFrom.addClass("disabled") ;
		            	}
						$btCreateFrom.appendTo($divBts) ;
						
						
						var $btShare = $('<button class="btn btn-primary doc_gridDocs__share" data-toggle="tooltip" data-placement="top" title="'+t("doc.share")+'"><i class="fa fa-share-alt"></i> <span>'+t("doc.share")+'</span></button>') ;
						$btShare.on("click", function(){
							_self.onShare(record) ;
						}) ;
						$btShare.appendTo($divBts) ;
						
						if(record.stage_name !== "PUBLISHED" && record.stage_name !== "DELETED" && record.user_id === _self.user.id){
							var $btUpload = $('<button class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="'+t("doc.replaceDoc")+'"><i class="fa fa-upload"></i> <span>'+t("doc.replaceDoc")+'</span></button>') ;
							$btUpload.on("click", function(){
								_self.onUpload(record) ;
							}) ;
							$btUpload.appendTo($divBts) ;
						}
						
						
						if(record.create_uid !== _self.user.id && record.AetnaFollowers_ids.indexOf(_self.user.partner_id) === -1){
							var $btFollow = $('<button class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="'+t("doc.follow")+'"><i class="fa fa-eye"></i> <span>'+t("doc.follow")+'</span></button>') ;
							$btFollow.on("click", function(){
								_self.onFollow(record) ;
							}) ;
							$btFollow.appendTo($divBts) ;
						}else if(record.create_uid !== _self.user.id){
							var $btUnfollow = $('<button class="btn btn-primary" data-toggle="tooltip" data-placement="top" title="'+t("doc.unfollow")+'"><i class="fa fa-eye-slash"></i> <span>'+t("doc.unfollow")+'</span></button>') ;
							$btUnfollow.on("click", function(){
								_self.onUnfollow(record) ;
							}) ;
							$btUnfollow.appendTo($divBts) ;
						}
						
						$divButtons.append($divWorkflow);
						$divButtons.append($divBts);
	
		            }
		            
		            $boxExpanded.find('[data-toggle="tooltip"]').tooltip({container : "body", viewport : $boxExpanded}) ;
				} ;
				
				
	        },
	        
	        onCollapse : function(event){
	        	$(".tooltip").remove(); //remove all tooltip to avoid remaining tooltip
	        	delete _self.expanded[event.recid] ;
	        },
	        onDblClick : function(ev){
	        	if(!_self.offlineMode){
	        		_self.fields.fields["project.task~~grid"].grid.grid.toggle(ev.recid) ;
	        	}
	        },
	        onClick : function(ev){
	        	if(ev.column){
		        	if(_self.fields.fields["project.task~~grid"].grid.grid.columns[ev.column].field === "open"){
			         	var record = _self.fields.fields["project.task~~grid"].grid.get(ev.recid) ;
		        		_self.openDoc(record) ;
		        	}
	        	}
	        }
	    } ;
	    
	    if(_self.offlineMode){
	    	delete _self.fields.fields["project.task~~grid"].options["onExpand"];
	    	delete _self.fields.fields["project.task~~grid"].options["onCollapse"];
	    	delete _self.fields.fields["project.task~~grid"].options["onDblClick"];
	    }
		
		_self.fields.init(_self, function(){
			_self.fields.fields["project.task~~grid"].addEventListener("selectionChanged", _self.onSelect) ;
					
			_self.fields.fields["project.task~~grid"].grid.grid.toolbar.on("refresh", _self.onToolbarRefresh) ;
			
            callback() ;
        }) ;
	};
	
	this.setSelectMulti = function(isMulti){
		_self.isMulti = isMulti ;
		_self.fields.fields["project.task~~grid"].grid.grid.show.selectColumn = isMulti ;
		_self.fields.fields["project.task~~grid"].grid.grid.multiSelect = isMulti ;
		_self.fields.fields["project.task~~grid"].grid.grid.refresh() ;
		_self.fields.fields["project.task~~grid"].grid.grid.selectNone() ;
		_self.showActions([]) ;
	} ;
	
	this.getPossibleActions = function(record){
		var possibleActions = [] ;
		if(record.AetnaWorkflow_id){ 
        	var w = _self.workflowsById[record.AetnaWorkflow_id] ;
			
			if(record.user_id === _self.user.id){
				w.AetnaCycles_ids.forEach(function(c){
					if(c.AetnaStage_name === record.stage_name){
						possibleActions.push(c.AetnaAction) ;
					}
				}) ;
			}
		}
		return possibleActions ;
	} ;
	
	this.getSelectedRecords = function(){
		return _self.fields.fields["project.task~~grid"].getSelectedRecords() ;
	} ;
	
	this.load = function(docs, resetExpanded){
		docs.forEach(function(d){
			d.recid = d.id ;
			d.status_label = _self.stagesByName[d.stage_name]?_self.stagesByName[d.stage_name].description : d.stage_name ;
			
			if(!DistantFile.isFileAvailable("project.task", d.id)){
				d.style = "background-color: #D5D5D5; color: #999;";
			}else{
				delete d.style ;
			}
		}) ;
		_self.fields.fields["project.task~~grid"].load(docs) ;
		
		
		
		if(resetExpanded){
			_self.expanded = {} ;
		}else{
			Object.keys(_self.expanded).forEach(function(recid){
				if(_self.fields.fields["project.task~~grid"].grid.grid.get(recid)){
					_self.fields.fields["project.task~~grid"].grid.grid.expand(recid) ;	
				}
			}) ;	
		}
		
		_self.showActions([]) ;
		
	} ;
	
	_self.onToolbarRefresh = function(ev){
		if(ev.target === "SUBMIT" || ev.target === "PUBLISH" || ev.target === "REWORK" || ev.target === "DELETE"){
			var $box = $(this.box);
			ev.onComplete = function(){
				var $btn = $box.find(".btn[data-action="+ev.target+"]");
				$btn.off() ;
				$btn.on("click", _self.actionMultiClicked) ;
				if(_self.fields.fields["project.task~~grid"].grid.grid.toolbar.get(ev.target).disabled){
					$btn.attr("disabled", "disabled") ;
				}else{
					$btn.removeAttr("disabled") ;
				}
			} ;
			
		}
		if(ev.target === "CREATE"){
			ev.onComplete = function(){
				var $box = $(this.box);
				var $btn = $box.find(".btn[data-action="+ev.target+"]");
				$btn.off() ;
				$btn.on("click", _self.onCreate) ;
			} ;
			
		}
		if(ev.target === "SHARE"){
			ev.onComplete = function(){
				var $box = $(this.box);
				var $btn = $box.find(".btn[data-action="+ev.target+"]");
				$btn.off() ;
				$btn.on("click", _self.onShareMulti) ;
			} ;
			
		}
		if(ev.target === "count"){
			ev.onComplete = function(){
				var $box = $(this.box);
				var total = _self.fields.fields["project.task~~grid"].grid.grid.total ;
				$box.find(".grid-count").html(total);
			} ;
			
		}
	} ;
	
	this.showActions = function(actionsToShow){
		
		["SUBMIT", "PUBLISH", "REWORK", "DELETE"].forEach(function(action){
			_self.fields.fields["project.task~~grid"].grid.grid.toolbar.show(action) ;
			if(actionsToShow.indexOf(action) !== -1){
				_self.fields.fields["project.task~~grid"].grid.grid.toolbar.enable(action) ;
			}else{
				_self.fields.fields["project.task~~grid"].grid.grid.toolbar.disable(action) ;
			}
		});
		_self.fields.fields["project.task~~grid"].grid.grid.toolbar.show("SHARE") ;
			
		if(_self.getSelectedRecords().length === 0){
			_self.fields.fields["project.task~~grid"].grid.grid.toolbar.hide("breakAction") ;
			["SUBMIT", "REWORK", "PUBLISH", "DELETE"].forEach(function(action){
				_self.fields.fields["project.task~~grid"].grid.grid.toolbar.hide(action) ;
			}) ;
			_self.fields.fields["project.task~~grid"].grid.grid.toolbar.hide("SHARE") ;
		}else{
			_self.fields.fields["project.task~~grid"].grid.grid.toolbar.show("breakAction") ;
		}
	} ;
	
	
	this.getDisplayedRecords = function(){
		var displayedRecords = _self.fields.fields["project.task~~grid"].grid.grid.records ;
      	
      	if(_self.fields.fields["project.task~~grid"].grid.grid.last.search || 
      		_self.fields.fields["project.task~~grid"].grid.grid.last.multi){
      		displayedRecords = [] ;
      		_self.fields.fields["project.task~~grid"].grid.grid.last.searchIds.forEach(function(i){
      			displayedRecords.push(_self.fields.fields["project.task~~grid"].grid.grid.records[i]) ;
      		}) ;
      	}
      	return displayedRecords ;
	} ;
	
	this.refreshRow = function(record){
		_self.fields.fields["project.task~~grid"].grid.grid.refreshRow(record.recid) ;
	} ;
	
	this.exportXls = function(){
        var recordsToPrint = [] ;
      
      	//_self.fields.fields[_self.options.model+"~~grid"].grid.grid.
      
      	var displayedRecords = _self.fields.fields["project.task~~grid"].grid.grid.records ;
      	
      	if(_self.fields.fields["project.task~~grid"].grid.grid.last.search || 
      		_self.fields.fields["project.task~~grid"].grid.grid.last.multi){
      		displayedRecords = [] ;
      		_self.fields.fields["project.task~~grid"].grid.grid.last.searchIds.forEach(function(i){
      			displayedRecords.push(_self.fields.fields["project.task~~grid"].grid.grid.records[i]) ;
      		}) ;
      	}
      
        displayedRecords.concat(
        	_self.fields.fields["project.task~~grid"].grid.grid.summary).forEach(function(record, lineNumber){
        		
            var recid = record.recid ;
            var line = {} ;
            var rIndex = _self.fields.fields["project.task~~grid"].grid.grid.get(recid, true) ;
            
            
            _self.fields.fields["project.task~~grid"].columns.forEach(function(c, i){
            	
                var value = $(_self.fields.fields["project.task~~grid"].grid.grid.getCellHTML(rIndex, i, record.summary)).text() ;

                line[c.field] = value;
            }) ;
            recordsToPrint.push(line) ;
        }) ;

        _self.emit("exportXls", {
            columns : _self.fields.fields["project.task~~grid"].columns,
            lines : recordsToPrint
        }) ;
    } ;
	
	this.openDoc = function(doc){
		_self.emit("openDoc", doc) ;
	} ;

	this.downloadDoc = function(doc){
		_self.emit("downloadDoc", doc) ;
	} ;
	
	this.openNotifs = function(doc){
		_self.emit("openNotifs", doc) ;
	} ;
	this.openDetails = function(doc){
		_self.emit("openDocDetails", doc) ;
	} ;

	this.applyAction = function(doc, action){
		_self.emit("applyAction", {doc : doc, action : action}) ;		
	} ;
	
	this.actionMultiClicked = function(event){
    	_self.emit("actionMultiClicked", {action : event.target.getAttribute("data-action"), records : _self.getSelectedRecords()}) ;
	} ;

	this.onSelect = function(event, records){
		_self.emit("select", {records : records}) ;
	} ;
	
	this.createRev = function(doc){
		_self.emit("createRev", doc) ;
	} ;
	
	this.onCreate = function(doc){
		_self.emit("createDoc", doc) ;
	} ;
	
	this.onCreateFrom = function(doc){
		_self.emit("createDocFrom", doc) ;
	} ;
	
	this.onShare = function(doc){
		_self.emit("share", doc) ;
	} ;
	
	this.onUpload = function(doc){
		var viewUpload = new PopupUpload() ;
		viewUpload.openInPopup({
			title: " "
		}, function(){
			viewUpload.uploadFile.accept = "."+doc.AetnaExt ;
		}) ;
		viewUpload.on("validate", function(ev){
			if(!new RegExp(doc.AetnaExt.toLowerCase()+"$").test(ev.data.name.toLowerCase())){
				return _self.error(t("doc.wrongType")) ;
			}
			_self.emit("uploadFile", {id : doc.id, file: ev.data}) ;
			viewUpload.closePopup() ;
		}) ;
	} ;
	
	this.onShareMulti = function(doc){
		_self.emit("share",  _self.getSelectedRecords()) ;
	} ;

	
	this.onFollow = function(doc){
		_self.emit("follow", doc) ;
	} ;
	
	this.onUnfollow = function(doc){
		_self.emit("unfollow", doc) ;
	} ;
	
	this.render = function(){
		_self.ensureInit(function(){
			_self.fields.fields["project.task~~grid"].render() ; 
		}) ;
	} ;
	
	this.onReload = function(ev){
		_self.emit("reload", {forceRefresh : ev.originalEvent.ctrlKey}) ;
	} ;

}

module.exports = DocGridView;
