var FieldCollection = require("aetna-views/fields/FieldCollection") ;
var context = require("aetna-views/context").context ;
var storage = require("aetna-storage").storage(context().name);
var loki = require("../../api/loki") ;
var str = require("aetna-string") ;
var moment = require("moment");

function FieldsEMA(t, baseUrl){
	var _self = this ;

	FieldCollection.call(this, t, baseUrl);
	
	this.cacheHandler = loki ;
	
	var schemas = JSON.parse(localStorage.getItem(baseUrl+"/schemas")).schemas ;
	var stagesByName = storage.get("stagesByName") ;
	var user = storage.get("user") ;


	
	this.addSchemaFields(schemas) ;
	
	this.tableDefs["project.task.type"].colDefs["AetnaActionIcon_Icon"] = 
			{"name" : "AetnaActionIcon_Icon", "type" : "varchar", "inputType" :"iconPicker"} ;
    
    this.tableDefs["project.task.type"].colDefs["AetnaActionIcon_Color"] = 
			{"name" : "AetnaActionIcon_Color", "type" : "varchar", "inputType" :"bsColorPicker"} ;
    
    this.tableDefs["project.task.type"].colDefs["AetnaSendEmail"] = 
			{"name" : "AetnaSendEmail", "type" : "boolean"} ;
	                
	this.fields["project.task~project_id"].minimumInputLength = 0 ;
	this.fields["project.task~project_id"].ajaxFormat = function(params){
		 return {
			q: '{{{q}}}', 
//			page: params.page,
			baseSearch : [
				{ field : "AetnaIsWorkflow", operator : "is", value : false}
			]
		};
	} ;
	
	
	var possibleTypes = ["integer", "float", "char", "date", "boolean", "selection"] ;
	_self.fields["ir.model.fields~ttype"].filter = function(a){ return possibleTypes.indexOf(a) !== -1; } ;
	_self.fields["ir.model.fields~ttype"].sort = function(a, b){ return possibleTypes.indexOf(a) - possibleTypes.indexOf(b); } ;

	var possibleStates = ["open", "close"] ;
	_self.fields["project.project~state"].filter = function(a){ return possibleStates.indexOf(a) !== -1; } ;
	_self.fields["project.project~state"].sort = function(a, b){ return possibleStates.indexOf(a) - possibleStates.indexOf(b); } ;

	_self.fields["project.task~project_id"].sort = function(a, b){ 
		var libA = _self.fields["project.task~project_id"]._items[a] ;
		var libB = _self.fields["project.task~project_id"]._items[b] ;
		if(libA > libB){
			return 1;
		}else if(libA === libB){
			return 0;
		}else{
			return -1;
		}
	} ;
	
	
	
	
	_self.fields["project.project~members"].sort = function(a, b){ 
		var libA = _self.fields["project.project~members"]._items[a] ;
		var libB = _self.fields["project.project~members"]._items[b] ;
		if(libA > libB){
			return 1;
		}else if(libA === libB){
			return 0;
		}else{
			return -1;
		}
	} ;
	
	_self.fields["hr.aetna.group~member_ids"].sort = function(a, b){ 
		var libA = _self.fields["hr.aetna.group~member_ids"]._items[a] ;
		var libB = _self.fields["hr.aetna.group~member_ids"]._items[b] ;
		if(libA > libB){
			return 1;
		}else if(libA === libB){
			return 0;
		}else{
			return -1;
		}
	} ;
	
	_self.fields["project.task~user_id"].sort = function(a, b){ 
		var libA = _self.fields["project.task~user_id"]._items[a] ;
		var libB = _self.fields["project.task~user_id"]._items[b] ;
		if(libA > libB){
			return 1;
		}else if(libA === libB){
			return 0;
		}else{
			return -1;
		}
	} ;
	
	_self.fields["project.aetnatasknotification~AetnaUser_id"].sort = function(a, b){ 
		var libA = _self.fields["project.aetnatasknotification~AetnaUser_id"]._items[a] ;
		var libB = _self.fields["project.aetnatasknotification~AetnaUser_id"]._items[b] ;
		if(libA > libB){
			return 1;
		}else if(libA === libB){
			return 0;
		}else{
			return -1;
		}
	} ;
	
	_self.fields["account.invoice~AetnaContract_id"].filter = function(a){ 
		var partner = null;
		if(_self.fields["account.invoice~partner_id"].initDone){
			partner = _self.fields["account.invoice~partner_id"].val();
		}
		if(!partner){ return false; }
		
		var ctr = loki.daos["aetna.contract.contract"].getById(a) ;
		
		if(ctr && ctr.partner_id == partner){
			return true ;
		}else{
			return false ;
		}
	} ;
	
	_self.fields["account.invoice~project_id"].filter = function(a){ 
		var partner = null;
		if(_self.fields["account.invoice~partner_id"].initDone){
			partner = _self.fields["account.invoice~partner_id"].val();
		}
		if(!partner){ return false; }
		
		var ctr = loki.daos["project.project"].getById(a) ;
		
		if(ctr && ctr.partner_id == partner){
			return true ;
		}else{
			return false ;
		}
	} ;
	
	var myGroups = null;
	_self.fields["project.task~AetnaRestrictGroup_ids"].filter = function(a){ 
		if(!myGroups){
			myGroups = loki.daos["hr.aetna.group"].listAll().filter(function(group){
			 	return group.member_ids.indexOf(user.id) !== -1 ;
			}).map(function(group){ return ""+group.id ;});
		}
		return myGroups.indexOf(a) !== -1; } ;
	_self.fields["project.task~AetnaRestrictGroup_ids"].sort = function(a, b){ 
		var libA = _self.fields["project.task~AetnaRestrictGroup_ids"]._items[a] ;
		var libB = _self.fields["project.task~AetnaRestrictGroup_ids"]._items[b] ;
		if(libA > libB){
			return 1;
		}else if(libA === libB){
			return 0;
		}else{
			return -1;
		}
	} ;
	
	this._initElement = function(){
		
			
		
		if(_self.fields["document.aetna.template~category_id"]._items){
			var parentsCate = loki.daos["document.aetna.category"].search({parent_id : false}) ;
		
			var items = {} ;
			var order = {} ;
			addCate(items, order, parentsCate, 0) ;
			
			
			_self.fields["document.aetna.template~category_id"].sort = function(a, b){
				return order[a] > order[b] ? 1 : order[a] < order[b] ? -1 : 0 ;
			} ;
			
			_self.fields["document.aetna.template~category_id"].items = items ;
			
		}
		
		if(_self.fields["account.invoice~partner_id"].initDone && _self.fields["account.invoice~AetnaContract_id"].initDone){
			_self.fields["account.invoice~partner_id"].addEventListener("change", function(){
				_self.fields["account.invoice~AetnaContract_id"].refresh() ;
			}) ;
			var _load=_self.fields["account.invoice~partner_id"].load ;
			_self.fields["account.invoice~partner_id"].load = function(val){
				_load(val) ;
				_self.fields["account.invoice~AetnaContract_id"].refresh() ;
			} ;
		}
		
		if(_self.fields["account.invoice~partner_id"].initDone && _self.fields["account.invoice~project_id"].initDone){
			_self.fields["account.invoice~partner_id"].addEventListener("change", function(){
				_self.fields["account.invoice~project_id"].refresh() ;
			}) ;
			var _loadPartner=_self.fields["account.invoice~partner_id"].load ;
			_self.fields["account.invoice~partner_id"].load = function(val){
				_loadPartner(val) ;
				_self.fields["account.invoice~project_id"].refresh() ;
			} ;
		}
		
		
		var updateDueDate = function(){
			
			var settings = loki.daos["account.config.aetna.settings"].searchFirst({}) ;
			
			var dueType = settings.AetnaDueType ;
			var dueDelay = settings.AetnaDueDelay ;
			if(!dueType){
				dueType = "immediate" ;
			}
			if(!dueDelay){
				dueDelay = 0 ;
			}
			
			var partnerId = null;
			if(_self.fields["account.invoice~partner_id"].initDone ){
				partnerId = _self.fields["account.invoice~partner_id"].val() ;
				if(partnerId){
					var partner = loki.daos["res.partner"].getById(partnerId) ;
					if(partner.AetnaDueType){
						dueType = partner.AetnaDueType ;
						dueDelay = partner.AetnaDueDelay ;
					}
				}
			}
			if(!dueType){
				dueType = "immediate" ;
			}
			if(!dueDelay){
				dueDelay = 0 ;
			}
			var startDate = null ;
			if(_self.fields["account.invoice~date_invoice"].initDone){
				startDate = _self.fields["account.invoice~date_invoice"].val() ;
			}
			if(!startDate){ startDate = new Date(); }
			var oldValue = _self.fields["account.invoice~date_due"].val() ;
			
			switch(dueType){
				case "immediate" : 
					_self.fields["account.invoice~date_due"].load(startDate) ;
					break;
				case "delay" : 
					_self.fields["account.invoice~date_due"].load(moment(startDate).add(dueDelay, 'days').toDate()) ;
					break;
				case "delayEndOfMonth" : 
					_self.fields["account.invoice~date_due"].load(moment(startDate).add(dueDelay, 'days').endOf("month").toDate()) ;
					break;
			}
			
			var newValue = _self.fields["account.invoice~date_due"].val() ;
			if(!moment(oldValue).isSame(moment(newValue), "day")){
				var $dueContainer = $(_self.fields["account.invoice~date_due"].container) ;
				$dueContainer.addClass("animated") ;
				$dueContainer.addClass("flash") ;
				
				$dueContainer.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$dueContainer.removeClass("animated") ;
					$dueContainer.removeClass("flash") ;
				});	
			}
			
		} ;
		if(_self.fields["account.invoice~partner_id"].initDone && _self.fields["account.invoice~date_due"].initDone){
			_self.fields["account.invoice~partner_id"].addEventListener("change",updateDueDate) ;
		}
		if(_self.fields["account.invoice~date_invoice"].initDone && _self.fields["account.invoice~date_due"].initDone){
			_self.fields["account.invoice~date_invoice"].addEventListener("change",updateDueDate) ;
		}
		
	} ;
	
	var addCate = function(items, order, cates, level){
		var indent = "" ;
		for(var i=0; i<level; i++){ indent += "&nbsp;&nbsp;&nbsp;&nbsp;" ; }
		cates.forEach(function(c, i){
			items[c.id] = indent+c.name ;
			order[c.id] = str.leftPad(Object.keys(order).length, 5, "0") ;
			var children = loki.daos["document.aetna.category"].search({parent_id : c.id}) ;
			addCate(items, order, children, level+1) ;
		}) ;
	} ;

	
}

module.exports = FieldsEMA ;