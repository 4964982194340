var HrGroupViews = require("../views/hrGroup/hrGroupViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

function HrGroupController() {
	var _self = this ;


	
	BaseController.call(this, {
		model : "hr.aetna.group",
		// modelsToSync : ["project.project", "res.users"],
		socketChangeEvent : 'hrGroupChanged',
		name : "hrGroup",
		gridClass : HrGroupViews.Grid,
		formClass : HrGroupViews.Form,
		uniqueCols : ["name"]
	}) ;
	
    
    this.checkCanDelete = function(data, callback){
    	var hrGroup = data.recordToDelete ;
    	
    	var task = loki.daos["project.task"].searchFirst(
    		{AetnaRestrictGroup_ids : {operator : "contains", value : hrGroup.id}}) ;
    	if(task){
    		_self.viewForm.error(t("hrGroup.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
}

module.exports = new HrGroupController();
