var t = require("aetna-views/locales/tr").t ;
var BaseView = require("aetna-views/BaseView") ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var moment = require("moment") ;
var str = require("aetna-string") ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var numbro = require("numbro");
var numbroLangs = [require("numbro/languages/fr-FR.js")] ;
var Handlebars = require("handlebars") ;

numbroLangs.forEach(function(l){
	numbro.culture(l.langLocaleCode, l);
}) ;

function ScheduleGenView(serverUrl, schemas){
	var _self = this ;
	
	BaseView.call(this, t,"contract", "scheduleGen") ;
	
   	this.fields = new FieldsEMA(t, serverUrl)  ;

	this.fields.fields["aetna.contract.invoice.schedule~date_schedule"].label = t("contract.scheduleFrom") ;
	this.fields.fields["aetna.contract.invoice.schedule~date_done"].label = t("contract.scheduleTo") ;
	
	_self.productsById = {} ;
	
	this._initElements = function(callback){
		_self.templateProducts = new TemplateFields({ newLineOnChange : false });
		_self.templateSchedule = new TemplateWidget(_self.EL.schedules_ids);
		
        _self.templateProducts.on("render", _self.renderProducts) ;
        _self.templateProducts.on("initField", _self.onLineFieldInit) ;
        
        
        
		_self.fields.init(_self, function(){
			_self.templateProducts.init(_self.EL.products, _self.fields) ;
			_self.fields.fields["aetna.contract.invoice.schedule~date_done"].addEventListener("change", _self.computeSchedule) ;
			_self.fields.fields["aetna.contract.invoice.schedule~date_schedule"].addEventListener("change", _self.computeSchedule) ;
			_self.fields.fields["aetna.contract.invoice.schedule~name"].addEventListener("keyup", _self.computeSchedule) ;
            callback() ;
        }) ;
        
        _self.EL.cancel.emit() ;
        _self.EL.generate.addEventListener("click", _self.onGenerate) ;
	} ;
	
	this.load = function(contract){
		_self.contract = contract ;
		
		
		_self.templateProducts.load(contract) ;
		
		
		_self.fields.load({
			name : t("contract.scheduleDefaultName"),
			date_schedule : moment().endOf("month").toDate(),
			date_done : moment().endOf("year").toDate()
		}) ;
		
		_self.computeSchedule() ;
	} ;
	
	this.onLineFieldInit = function(ev){
		if(ev.data.name === "aetna.contract.product~price_unit"){
			ev.data.field.addEventListener("change", function(){
				_self.computeSchedule() ;
			}) ;
		}
	} ;
	
	this.renderProducts = function(ev){
		var template = ev.data.template ;
		if(template.EL_LISTS.productLine){
			template.EL_LISTS.productLine.forEach(function(el){
				el.addEventListener("click", function(ev){
					if(ev.target.tagName !== "INPUT"){
						$(el).toggleClass("active") ;
						$(el).find("input").prop("checked", $(el).hasClass("active")) ;
						_self.computeSchedule() ;
					}
				}) ;
			}) ;
		}
		if(template.EL_LISTS.productCheck){
			template.EL_LISTS.productCheck.forEach(function(el){
				el.addEventListener("click", function(){
					if(el.checked){
						$(el.parentElement).removeClass("active") ;
					}else{
						$(el.parentElement).addClass("active") ;
					}
					_self.computeSchedule() ;
				}) ;
			}) ;
		}
	} ;
	
	this.computeSchedule = function(){
		var values = _self.fields.values()["aetna.contract.invoice.schedule"] ;
		var productsValues = _self.templateProducts.val() ;
		var products = [] ;
		var productsByPeriodicity = {} ;
		$(_self.container).find("input[data-product]").each(function(i, checkbox){
			if(checkbox.checked){
				productsValues.product_ids.some(function(p){
					if(p.id == checkbox.getAttribute("data-product")){
						products.push(p) ;
						if(!productsByPeriodicity[p.invoice_period]){
							productsByPeriodicity[p.invoice_period] = [] ;
						}
						productsByPeriodicity[p.invoice_period].push(p) ;
						return true ;
					}
				}) ;
			}
		}) ;
		
		var template = Handlebars.compile(values.name.replace(/{/g, "{{").replace(/}/g, "}}"));
		
		
		var schedulesByDate = {} ;
		
		if(values.date_schedule && values.date_done){
			Object.keys(productsByPeriodicity).forEach(function(period){
				var date = moment(values.date_schedule)	 ;
				var dateEnd = moment(values.date_done) ;
				while(date.isBefore(dateEnd)){
					var strDate = date.format("L") ;
					
					var schedule = schedulesByDate[strDate] ;
					
					if(!schedule){
						
						schedule = {} ;
						schedule.contract_id = _self.contract.id;
						schedule.date_schedule = new Date(date.toDate().getTime()) ;
						schedule.strDate = date.format("L") ;
						schedule.state = 'todo';
						schedule.lines = [];
						schedulesByDate[strDate] = schedule ;
					}
					
					productsByPeriodicity[period].forEach(function(product){
						var prodDef = _self.productsById[product.product_id] ;
						var line = {
							product_id : product.product_id,
							name : prodDef.name,
							price_unit : product.price_unit,
							invoice_line_tax_id : prodDef.taxes_id,
							quantity: 1
						} ;
						if(product.qty_to_input){
							line.quantity = 0 ;
						}
						
						line.price_subtotal = line.quantity * line.price_unit ;
						
						schedule.lines.push(line) ;
					}) ;
					
					if(period === "month"){
						date.add(1, "months") ;
					}else if(period === "quarter"){
						date.add(3, "months") ;
					}else if(period === "semester"){
						date.add(6, "months") ;
					}else if(period === "year"){
						date.add(1, "years") ;
					}
				}
			}) ;
		}
		
		var lang = storage(context().name).get("lang");
		numbro.language(lang);
		
		var schedules = [];
		Object.keys(schedulesByDate).sort().forEach(function(dt, i){
			var schedule = schedulesByDate[dt] ;
			
			
			
			var total = 0;
			var hasVariable = false ;
			var productsNames = [] ;
			var strTotal ;
			schedule.lines.some(function(l){
				productsNames.push(l.name) ;
				if(l.price_subtotal === 0){
					hasVariable = true ; 
					return true ;
				}
				total += l.price_subtotal  ;
			}) ;
			
			if(hasVariable){
				if(total === 0){
					strTotal = t("contract.variable") ; 
				}else{
					strTotal = numbro(total).format("0,0.00") +" + "+t("contract.variable") ; 
				}
			}else{
				strTotal = numbro(total).format("0,0.00"); 
			}
			
			schedule.amount_untaxed = total;
			schedule.strTotal = strTotal ;
			schedule.strProducts = productsNames.join(", ") ;
			
			var data = {
				N : numbro(i+1).format("0o"),
				product : schedule.strProducts,
				customer : _self.contract.customer_name,
				year : moment(schedule.date_schedule).format("YYYY"),
				month : moment(schedule.date_schedule).format("MM")
			} ;
			
			try{
				schedule.name = template(data);	
			}catch(e){
				console.log("template error", e) ;
			}
			
			
			schedules.push(schedule) ;
		}) ;
		
		_self.schedules = schedules ;
		
		_self.templateSchedule.render({schedules_ids : schedules}) ;
		
	} ;
	
	this.onGenerate = function(){
		_self.schedules.forEach(function(schedule){
			delete schedule.strTotal ;
			delete schedule.strProducts ;
		}) ;
		_self.emit("generate", {schedules : _self.schedules}) ;
	} ;
}

module.exports = ScheduleGenView;
