var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var loki = require("../../api/loki") ;
var storage = require("aetna-storage").storage ;
var context = require("aetna-views/context").context;

function CustomerGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : "customer",
    	model : "res.partner", 
    	colsToShow : [
    		"ref",
			"name",
			"zip",
			"city",
			"phone"
    	],
	}) ;
}

function CustomerFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : "customer",
    	model : "res.partner",
    	newRecord : {
    		invoices : []
    	}
	}) ;
	
	var _initElements = this._initElements ;
	
	this._initElements=function(callback){
		var len = _self.accountSettings.AccountNumberLength || 6;
		
		if(_self.accountSettings.AetnaExportType === "cegid"){
			_self.fields.fields["res.partner~accountParent"].type = "mask:41A{0,"+(len-2)+"}" ;
			_self.fields.fields["res.partner~accountCustomer"].type = "mask:A{0,"+len+"}" ;
			_self.EL.accountParent.style.display = "block" ;
		}else{
			_self.EL.accountParent.style.display = "none" ;
			_self.fields.fields["res.partner~accountCustomer"].type = "mask:41A{0,"+(len-2)+"}" ;
		}
		
		
		
		_initElements(callback) ;
	} ;
	
	_self.on("initDone", function(){
		_self.gridInvoiceView = new CustomerInvoiceGridView(serverUrl, schemas) ;
		
		_self.gridInvoiceView.init(_self.EL.gridInvoices) ;
		
		_self.gridInvoiceView.on("exportXls", function(ev){
			ev.data.title = t("customer.invoicesOf", {customer : _self.item.name}) ;
			_self.emit("exportXls", ev.data) ;
		}) ;
		
		$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
		  	if(e.target.getAttribute("aria-controls") === _self.EL.invoices.id){
				_self.gridInvoiceView.refresh() ;		
		  	}
		}) ;
		
		if(_self.accountSettings.AetnaExportType === "cegid"){
			_self.EL.accountParent.style.display = "block" ;
		}else{
			_self.EL.accountParent.style.display = "none" ;
		}
		
		
	}) ;
	
	_self.on("modify", function(){
		_self.$EL.tabCustomer.tab('show') ;
	}) ;
	
	this.checkInput = function(){
		var value = _self.getValues() ;
		
		_self.fields.clearError() ;
		
		if(value.recordToSave.AetnaDueType === "delay" || value.recordToSave.AetnaDueType === "delayEndOfMonth" ){
			if(!value.recordToSave.AetnaDueDelay){
				_self.fields.fields["res.partner~AetnaDueDelay"].error() ;
				return t("customer.pleaseInputDelay") ;
			}
		}
		
		var user = storage(context().name).get("user-full") ;
		
		if(user.moduleFacto && value.recordToSave.accountCustomer){
			var account = loki.daos["account.account"].searchFirst({code : value.recordToSave.accountCustomer});
			if(account){
				var partners = loki.daos["res.partner"].search({property_account_receivable : account.id});
				partners = partners.filter(function(p){
					return p.id !== _self.item.id ;
				}) ;
				if(partners.length > 0){
					return t("customer.alreadyUsedAccount", {number : value.recordToSave.accountCustomer, otherPartner : partners.map(function(p){
						return p.ref+ " "+p.name ;
					}).join(", ")}) ;
				}
			}
		}
	} ;
	
	var _load = this.load ;
	this.load = function(item){
		_load(item) ;
		
		_self.gridInvoiceView.ensureInit(function(){
			_self.gridInvoiceView.load(item.invoices) ;
		}) ;
		
		_self.EL.invoiceCount.innerHTML = item.invoices.length ;
	} ;
}

function CustomerInvoiceGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : "customer",
    	model : "account.invoice", 
    	colsToShow : [
    		"AetnaRef",
			"name",
			"date_invoice",
			"date_due",
			"partner_id",
			"amount_untaxed",
			"amount_total",
			"residual",
			"AetnaLastRemindNumber",
			"AetnaLastRemindDate",
			"state"
    	],
    	gridOptions : {
    		show : {
    			selectColumn : false,
    			toolbarReload  : false
    		}
    	},
    	summary : {
	    	colsToSum : ["amount_untaxed", "amount_total", "residual"]
    	}
	}) ;
	
	this.initialVisibility = "visible" ;
	
	
}

module.exports.Grid = CustomerGridView;
module.exports.Form = CustomerFormView;
