var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;

var model = "account.journal" ;
var moduleName = "payType" ;

function PayTypeGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"code",
			"name",
			"type"
    	],
	}) ;
}

function PayTypeFormView(serverUrl, schemas){
	var _self = this ;

	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		active : true
    	}
	}) ;
	
	var possibleTypes = ["cash", "bank"] ;
	_self.fields.fields["account.journal~type"].filter = function(a){ 
		return possibleTypes.indexOf(a) !== -1; 
	} ;
	
	var _initElements = this._initElements ;
	
	this._initElements=function(callback){
		var len = _self.accountSettings.AccountNumberLength || 6;
		_self.fields.fields["account.journal~accountNumber"].type = "mask:519{0,"+(len-2)+"}" ;
		
		_initElements(callback) ;
	} ;
	
}

module.exports.Grid = PayTypeGridView;
module.exports.Form = PayTypeFormView;
