/* global BootstrapDialog */
var context = require("aetna-views/context").context;
var LoginView = require("../views/login/login");
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;
var storage = require("aetna-storage").storage ;
var BaseGui = require("aetna-views/BaseGui") ;
var loki = require("../api/loki") ;
var ChangePassView = require("../views/login/changePass") ;
var UserPrefView = require("../views/login/userPref") ;
var userController = require("./userController") ;

function LoginController() {
    var _self = this;

	
	var initialized =false ;

    this.init = function (callback) {
    	if(initialized){ return callback(); }
    	
    	initialized = true ;
    	
		_self.gui = new BaseGui() ;
				
		

		//on global loaded event, show the main view (as it is our first view)
		//(ON in uppercase means global broacast event)
        BUS.on("loaded", function(){
	    	_self.viewLogin = new LoginView(cli().serverUrl);
			
	        _self.viewLogin.init(function () {
				cli("auth").info(function(err, infos){
					if(err){
						return BUS.emit("notLogged", err) ;
					}
					BUS.emit("logged", infos) ;
				}) ;
			});
			
		});
		
		BUS.on("notLogged", _self.notLogged) ;
		BUS.on("logoutDone", _self.notLogged) ;
		BUS.on("logged", _self.logged) ;
		BUS.on("goOffline", _self.offlineMode) ;
		BUS.on("loginRequest", _self.loginRequest) ;
		BUS.on("logoutRequest", _self.logoutRequest) ;
		BUS.on("changePassRequest", _self.changePassRequest) ;
		BUS.on("userPrefRequest", _self.userPrefRequest) ;
		
		BUS.on(["loaded", "online"], function(){
		    if( cli().addErrorHandler){
		    	cli().addErrorHandler(_self.onAjaxError) ;
		    }
		}) ;
		
		
		callback();
    };
    
    this.onAjaxError = function(err){
    	if(err.code === "NO_SESSION"){
    		if(_self.viewLogin.container.style.display !== "block"){
    			BUS.emit("logoutDone") ;
    		}
    		return t("login.noSessionError") ;
    	}else if(err.xhr && err.xhr.responseJSON && err.xhr.responseJSON.data && err.xhr.responseJSON.data.message){
    		return err.xhr.responseJSON.data.message ;
    	}else if(err.code !== undefined){
    		var trKey = "main.ajaxError."+err.code ;
    		var msg = t(trKey) ;
    		if(msg !== trKey){
    			return msg ;
    		}
    	}else if(err.error === "Bad Gateway"){
			return t( "main.ajaxError.badGateway") ;
    	}else if(err.xhr && err.xhr.readyState === 0){
    		return t("main.ajaxError.ENOTFOUND") ;
    	}
    } ;
	
	this.notLogged = function(){
		document.getElementById("waiter_load").style.display = "none" ;
		_self.viewLogin.show() ;
	} ;
	
	this.offlineMode = function(){
		if(_self.viewLogin){
			_self.viewLogin.hide() ;
		}
	} ;
	
	
	this.logged = function(ev){
		_self.viewLogin.hide() ;
		document.getElementById("waiter_load").style.display = "block" ;
		
		loki.sync(["project.project", "project.task.type", "project.aetnaworkflowcycle", "res.users", "ir.model.data"], function(err){
			if(err){ return _self.viewLogin.error(err) ; }
			
			var user = JSON.parse(JSON.stringify(loki.daos["res.users"].getById(ev.data.id))) ; //clone object to avoid affect loki cache
			user.dbName = ev.data.dbName ;
			user.sessionId = ev.data.sessionId ;
			storage(context().name).set("user", user) ;
			
			storage(context().name).set("user-full", userController.mapUser(user)) ;
			
			var workflows = loki.daos["project.project"].search({AetnaIsWorkflow : true}) ;
			
			workflows = JSON.parse(JSON.stringify(workflows)) ; //clone object to avoid affect loki cache

			var workflowsById = {} ;
			
			workflows.forEach(function(w){
				w.AetnaCycles_ids.forEach(function(id, i){
					if(id.id){ id = id.id; }
					w.AetnaCycles_ids[i] = loki.daos["project.aetnaworkflowcycle"].getById(id) ;
				}) ;
				workflowsById[w.id] = w ;
			}) ;
			
			storage(context().name).set("workflows", workflows) ;
			
			storage(context().name).set("workflowsById", workflowsById) ;
			
			var stages = loki.daos["project.task.type"].listAll() ;
		
				
			var stagesByName = {} ;
			stages.forEach(function(s){
				stagesByName[s.name] = s ;
			}) ;
			
			storage(context().name).set("stagesByName", stagesByName) ;
			// storage(context().name).set("schemas", cli().schemas);
			
			//loki.syncAll(function(){
			if(window.isDesktop){
				BUS.emit("doDesktopInit", {callback : function(){
					BUS.emit("loggedAndInitialized") ;	
				}}) ;	
			}else{
				BUS.emit("loggedAndInitialized") ;	
			}
			//}) ;
		}) ;
	} ;
	
	this.loginRequest = function(ev){
		_self.gui.startWait() ;
		cli("auth").login(ev.data.login, ev.data.password, function(err, user){
			_self.gui.endWait() ;
			if (err) {
                return _self.gui.error(t("login.failed"));
            }
			storage(context().name).set("user", user) ;
			BUS.emit("logged", user);
		});
	} ;
	
	this.logoutRequest = function(ev){
		_self.gui.startWait() ;
		cli("auth").logout(function(err, user){
			_self.gui.endWait() ;
			if (err) {
                return _self.gui.error(t("login.logoutFailed"));
            }
			BUS.emit("logoutDone", storage(context().name).get("user"));
			
			//keep user for offline mode
			//storage(context().name).remove("user") ;
		}) ;
	} ;
	
	this.changePassRequest = function(){
		var viewChange = new ChangePassView(cli().serverUrl) ;
		viewChange.openInPopup({
			size: BootstrapDialog.SIZE_WIDE, 
			title: t("login.changePassword") 
		}) ;
		viewChange.on("validate", function(ev){
			cli("auth").changePassword(ev.data.passwordOld, ev.data.password, function(err, result){
				_self.gui.endWait() ;
				if (err) {
	                return _self.gui.error(err);
	            }
	            if(result === "WRONG_PASS"){
	            	return _self.gui.error(t("login.wrongPass"));
	            }
				viewChange.closePopup() ;
			}) ;
		}) ;
	}  ;
	
	this.userPrefRequest = function(){
		var user = storage(context().name).get("user") ;
		loki.sync("hr.employee", function(){
			var employee = loki.daos["hr.employee"].searchFirst({user_id : user.id}) ;
			var viewPref = new UserPrefView(cli().serverUrl) ;
			viewPref.openInPopup({
				size: BootstrapDialog.SIZE_WIDE, 
				title: t("login.userPref") 
			}, function(){
				viewPref.load(employee) ;
			}) ;
			viewPref.on("validate", function(ev){
				ev.data.id = employee.id ;
				_self.gui.startWait() ;
				cli("hr.employee").update(ev.data, function(err, result){
					_self.gui.endWait() ;
					if (err) {
		                return _self.gui.error(err);
		            }
					viewPref.closePopup() ;
				}) ;
			}) ;
		}) ;
	}  ;
}

module.exports = new LoginController();
