var t = require("aetna-views/locales/tr").t ;
var loki = require("../loki") ;
var OfflineSynchro = require("./OfflineSynchro") ;

function OfflineCrudCli(tableName) {
    
    this.tableName = tableName ;
    var _self = this ;
    
        
    this.listAll = function(fields, callback){
        if(typeof(fields) === "function"){
            callback = fields ;
            fields = null;
        }
        var results;
        try{
            results = loki.daos[_self.tableName].listAll() ;
        }catch(err){
            return callback(err) ;
        }
        return callback(null, results) ;
    };
	
	this.search = function(search, limit, fields, callback){
		if(typeof(limit) === "function"){
			callback = limit;
			limit = null ;
			fields = null ;
		}else if(Array.isArray(limit) && typeof(fields) === "function"){
			callback = fields;
			fields = limit ;
			limit = null ;
		}else if(typeof(fields) === "function"){
            callback = fields ;
            fields = null;
        }
        
        var results;
        try{
            results = loki.daos[_self.tableName].search(search, limit) ;
        }catch(err){
            return callback(err) ;
        }
        return callback(null, results) ;
    };
    
    this.searchAndCount = function(search, fields, offset, limit, orderBy, callback){
		var results;
        try{
            results = loki.daos[_self.tableName].search(search, limit) ;
        }catch(err){
            return callback(err) ;
        }
        return callback(null, {
            length : results.length,
            records : results
        }) ;
    };
    
    this.searchNewer = function(fields, maxWriteDate, idsToExclude, callback){
        return callback(null, []) ;
    };
    
    this.searchGridW2ui = function(search, callback){
        return callback(null, []) ;
    };
    
    this.searchGridW2uiXls = function(search, filename, fields, headers){
        throw t("main.offlineNotAvailable") ;
    };
    
    this.searchFirst = function(search, callback){
        var results;
        try{
            results = loki.daos[_self.tableName].searchFirst(search) ;
        }catch(err){
            return callback(err) ;
        }
        return callback(null, results) ;
    };
	
	this.getByPk = function(pk, callback){
	    var results;
        try{
            results = loki.daos[_self.tableName].getByPk(pk) ;
        }catch(err){
            return callback(err) ;
        }
        return callback(null, results) ;
    };

    this.create = function(obj, callback){
        OfflineSynchro.insert(_self.tableName, obj) ;
        callback(null, obj) ;
    };

    this.update = function(obj, callback){
        OfflineSynchro.update(_self.tableName, obj) ;
        callback(null, obj) ;
    };
    
    this.save = function(obj, callback){
        OfflineSynchro.update(_self.tableName, obj) ;
        callback(null, obj) ;
    };
	
    this.saveAll = function(objects, callback){
        objects.forEach(function(obj){
            OfflineSynchro.update(_self.tableName, obj) ;
        }) ;
        callback(null, objects) ;
    };

    this.delete = function(obj, callback){
        OfflineSynchro.delete(_self.tableName, obj) ;
        callback(null, obj) ;
    };
    
    this.execWorkflow = function(id, signal, callback){
        return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.callAction = function(ids, action, params, callback){
        if(typeof(params) === "function"){
            callback = params ;
            params = {};
        }
        return callback(t("main.offlineNotAvailable")) ;
    } ;
    
}

module.exports = OfflineCrudCli ;