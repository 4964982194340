var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineAccountMoveLine(){
	OfflineCrudCli.call(this, "account.move.line");
	
	    
	this.doExport = function(ids, callback){
 	     return callback(t("main.offlineNotAvailable")) ;
    };
    
}

module.exports = OfflineAccountMoveLine ;