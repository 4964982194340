var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var OfflineView = require("../views/doc/offline");
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;
var BaseGui = require("aetna-views/BaseGui") ;

function OfflineController() {
    var _self = this;
	
	var isOffline = false;
	

	var initialized = false ;
    this.init = function (callback) {
    	if(initialized){ return callback(); }
    	
    	initialized = true ;
		
		_self.gui = new BaseGui() ;
		
		BUS.on("goOffline", _self.goOffline) ;
		BUS.on("backOnline", _self.backOnline) ;
		
		callback() ;

    };
	
	this.goOffline = function(ev){
		if(!isOffline){
			isOffline = true ;
			_self.offlineView = new OfflineView(cli().serverUrl, cli().schemas) ;
			_self.offlineView.init(function(){
				_self.offlineView.show() ;
				_self.offlineView.load(ev.data.localTasks) ;
			}) ;
		}
	} ;
	
	this.backOnline = function(){
		if(isOffline){
			isOffline = false ;
			_self.offlineView.hide() ;
		}
	} ;
	
}

module.exports = new OfflineController();
