var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var PdfView = require("aetna-views/views/pdf/pdf") ;
var DistantFile = require("../../api/DistantFile") ;


function PopupPdfView(serverUrl){
    BaseView.call(this, t, "doc", "popupPdf") ;
	
	var _self = this ;
   
	this._initElements = function(callback){
		_self.pdfView = new PdfView(t) ;
		
		_self.pdfView.init(_self.EL.pdf, function(){
			callback() ;
		}) ;
		
		_self.pdfView.on("viewerLoaded", function(){
			_self.pdfView.openSidebar("thumbs") ;
			if(window.isDesktop){
				_self.pdfView.EL.viewer.contentWindow.PDFView.download = function(){ 
					var $input = $('<input type = "file" nwworkingdir="C:\\"  nwsaveas="'+window.myDocsDirectory+'\\'+_self.filename+'" />'); 
					$input.on("change", function(){ 
						_self.pdfView.startWait() ;
						DistantFile.copyFile("project.task", _self.url.replace(/^file:\/\//, ""), this.value, function(err){
							if(err){
								return _self.pdfView.endWaitError(err) ;
							}
							 _self.pdfView.endWait() ;
						}) ;
					}); 
					$("body").append($input); $input.click();
				} ;
			}
		});
	};
	
	this.load = function(doc){
		_self.doc= doc ;
		
		if(DistantFile.isFileAvailable("project.task", doc.id)){
			_self.pdfView.startWait() ;
			DistantFile.getPdfUrl("project.task", doc.id, function(err, url){
				_self.pdfView.endWait() ;
				if(err){
					//not available
					return ;//_self.pdfView.error(err) ;
				}
				_self.pdfView.showPdf(url, doc.AetnaRef+".pdf") ;
				_self.url = url;
				_self.filename = doc.AetnaRef+".pdf";
			}) ;
		}else{
			_self.EL.pdf.style.display = 'none' ;
			_self.EL.nopdf.style.display = 'block' ;
		}
	} ;
}

module.exports = PopupPdfView;
