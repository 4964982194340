var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var BUS = require("aetna-events").EventSource.BUS;

var LibsLoader = require("aetna-views/LibsLoader") ;
LibsLoader.load([
	"animate.css/animate.min.css"
]) ;

function NotifsListView(serverUrl){
    BaseView.call(this, t, "doc", "notifs") ;
	
	var _self = this ;
   
   	this.fields = new FieldsEMA(t, serverUrl) ;
	   
	   
	this._initElements = function(callback){
		_self.fields.fields["aetna.modif.track~~grid"].colsToShow = [
			"date", "user_id", "label", "body"
		];
		
		_self.fields.fields["aetna.modif.track~~grid"].colsCustom = [
			{field : "body", caption: "", size :"300px", searchable : true, sortable : false}
		] ;
		
		
		_self.fields.init(_self, function(){
            callback() ;
        }) ;
		
		
	};
	
	this.load = function(notifs){
		_self.fields.fields["aetna.modif.track~~grid"].load(notifs) ;
	} ;
	


}

module.exports = NotifsListView;
