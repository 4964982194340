var ProjectCustomFieldViews = require("../views/projectCustomField/projectCustomFieldViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var BaseGui = require("aetna-views/BaseGui") ;
var cli = require("aetna-ajax/Cli").cli ;
var context = require("aetna-views/context").context ;
var storage = require("aetna-storage").storage(context().name);


function ProjectCustomFieldController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "ir.model.fields",
		modelsToSync: ["ir.model.fields","ir.model"],
		socketChangeEvent : 'modelFieldChanged',
		name : "projectCustomField",
		uniqueCols : ["name"] ,
		gridClass : ProjectCustomFieldViews.Grid,
		formClass : ProjectCustomFieldViews.Form
	}) ;
	
	this.gui = new BaseGui() ;

	
    
    this.mapRecord = function(f){
		var clone = JSON.parse(JSON.stringify(f)) ;
		clone.name  = f.name.substring(2) ; //remove x_
		if(f.selection){
			clone.selection = [] ;
			f.selection.split("),(").forEach(function(v){
                v = v.replace(/^\[\('/g, "") ;
                v = v.replace(/^\('/g, "") ;
                v = v.replace(/'\)$/g, "") ;
                v = v.replace(/'$/g, "") ;
                v = v.replace(/^'/g, "") ;
                v = v.replace(/'\)\]$/g, "") ;
                v = v.replace(/\\'/g, "'") ;
                var keyAndValue = v.split("','") ;
                clone.selection.push(keyAndValue[1]) ;
            }) ;
		}
		return clone ;
	} ; 
    
    this.listAll = function(){
    	return loki.daos["ir.model.fields"].search({model : "project.task", name : /^x_.*/})
    		.map(_self.mapRecord) ;
    } ;
    
    var _doSync = this.doSync ;
    this.doSync = function(forceRefresh, callback){
    	if(!callback){
    		callback = forceRefresh ;
    	}
    	_doSync(true, callback) ;
	} ;
    
    var _initForm = this.initForm ;
    this.initForm= function(callback){
    	_initForm(function(){
    		_self.viewForm.options.newRecord.model_id = loki.daos["ir.model"].searchFirst({model : "project.task"}).id ;
    		callback() ;
    	}) ;
    } ;
    
    this.loadRecord = function(record){
		_self.viewForm.load(_self.mapRecord(record)) ;
	} ;
	
	this.refreshSchema = function(){
		_self.gui.startWait() ;
		cli().init(function(err){
			if(err){ return _self.gui.endWaitError(err) ; }
			// storage.set("schemas", cli().schemas) ;
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.on("modified", this.refreshSchema) ;
	this.on("created", this.refreshSchema) ;
	this.on("deleted", this.refreshSchema) ;

}

module.exports = new ProjectCustomFieldController();
