var BaseView = require("aetna-views/BaseView.js") ;
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var pkg = require("../../../package.json") ;

function LoginView(serverUrl){
    BaseView.call(this, t, "login", "login") ;
	
	var _self = this ;
   
   	var fields = new FieldsEMA(t, serverUrl) ;
	   
	this._initElements = function(callback){
		_self.EL.signIn.addEventListener("click", _self.onSignIn) ;	
		
		_self.EL.login.addEventListener("keydown", function(ev){
			if(ev.keyCode === 13){
				_self.onSignIn() ;
			}
		});
		_self.EL.password.addEventListener("keydown", function(ev){
			if(ev.keyCode === 13){
				_self.onSignIn() ;
			}
		});
		//localeChooser.init(_self.EL.lang) ;
		
		_self.EL.logo.src = serverUrl+"/api/company/logo" ;
		
		fields.fields["res.users~login"].type = "text" ;
		
        fields.init(_self, function(){
            callback() ;
        }) ;
		
	};
	
	
	
	this.onShow = function(){
		fields.clear() ;
		fields.fields["res.users~login"].focus() ;	
		document.title = "ADS PRO "+pkg.humanVersion ;
	} ;

	this.onSignIn = function(){
		fields.clearError() ;
		
		var requiredFields =["res.users~login", "res.users~password"] ;
			
		var hasError = false ;
		requiredFields.forEach(function(fieldName){
			var f = fields.fields[fieldName] ;
            if(f.isEmpty()){
                hasError = true ;
            }else if(!f.isValid()){
                hasError = true ;
            }
        });
		
		if(hasError){
           return _self.error(t("login.pleaseInputLoginAndPass"));
        }
		
		var values = fields.values() ; 
		
		BUS.emit("loginRequest", values["res.users"]) ;
	} ;


}

module.exports = LoginView;
