var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseView = require("aetna-views/BaseView.js") ;
var UploadField = require("aetna-views/fields/upload");
var moduleName = "accountingImport" ;


function AccoutingImportGridView(serverUrl, schemas){
	var _self = this ;
	BaseView.call(this, t, moduleName, moduleName+"Grid") ;
	
	this._initElements = function(){
		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;

		_self.uploadFile = new UploadField(t) ;
		_self.uploadFile.filedrag = _self.EL.drop ;
		_self.uploadFile.accept = ".tra" ;
		_self.uploadFile.init(_self.EL.uploadFile) ;


		_self.EL.doImport.addEventListener("click", _self.doImport) ;
		
	} ;
	
	this.load = function(records){} ;
	
	
	
	this.doImport = function(){
		if(!_self.uploadFile.getFile()){
			return _self.error(t("doc.accountingImport")) ;
		}
		_self.emit("doImport", {type : "cegid", file : _self.uploadFile.getFile()}) ;
	} ;
	
	
	
}

module.exports = AccoutingImportGridView;