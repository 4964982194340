var storage = require("aetna-storage").storage ;
var context = require("aetna-views/context").context;
var OfflineCrudCli= require("./OfflineCrudCli") ;
var OfflineAuth = require("./impl/OfflineAuth") ;
var OfflineProjectTask = require("./impl/OfflineProjectTask") ;
var OfflineResUsers = require("./impl/OfflineResUsers") ;
var OfflineDocumentAetnaTemplate = require("./impl/OfflineDocumentAetnaTemplate") ;
var OfflineAccountInvoiceAetnaTemplate = require("./impl/OfflineAccountInvoiceAetnaTemplate") ;
var OfflineAccounRefundAetnaTemplate = require("./impl/OfflineAccountRefundAetnaTemplate") ;
var OfflineAccountInvoice = require("./impl/OfflineAccountInvoice") ;
var OfflineAccountInvoiceAetnaRemindSettings = require("./impl/OfflineAccountInvoiceAetnaRemindSettings") ;
var OfflineAccountInvoiceAetnaRemind = require("./impl/OfflineAccountInvoiceAetnaRemind") ;
var OfflineAetnaContractTemplate = require("./impl/OfflineAetnaContractTemplate") ;
var OfflineAetnaContractContract = require("./impl/OfflineAetnaContractContract") ;
var OfflineAccountInvoiceAetnaTvaExport = require("./impl/OfflineAccountInvoiceAetnaTvaExport");
var OfflineAccountMoveLine = require("./impl/OfflineAccountMoveLine");
var OfflineSynchro = require("./impl/OfflineSynchro");

function OfflineCli(serverUrl) {
    
    var _self = this ;
    
    this.serverUrl = serverUrl ;
    
   // this.schemas= storage(context().name).get("schemas") ;
    this.schemas = JSON.parse(localStorage.getItem(serverUrl+"/schemas")).schemas ;
    
    this.apis = {} ;
    
    this.apis.auth = new OfflineAuth() ;
	
	this.apis["project.task"] = new OfflineProjectTask() ;
	this.apis["res.users"] = new OfflineResUsers() ;
	this.apis["document.aetna.template"] = new OfflineDocumentAetnaTemplate() ;
	this.apis["account.invoice"] = new OfflineAccountInvoice() ;
	this.apis["account.invoice.aetna.template"] = new OfflineAccountInvoiceAetnaTemplate() ;
	this.apis["account.refund.aetna.template"] = new OfflineAccounRefundAetnaTemplate() ;
	this.apis["account.invoice.aetna.remind.settings"] = new OfflineAccountInvoiceAetnaRemindSettings() ;
	this.apis["account.invoice.aetna.remind"] = new OfflineAccountInvoiceAetnaRemind() ;
	this.apis["account.invoice.aetna.tvaexport"] = new OfflineAccountInvoiceAetnaTvaExport() ;
	this.apis["account.move.line"] = new OfflineAccountMoveLine() ;
	this.apis["aetna.contract.template"] = new OfflineAetnaContractTemplate() ;
	this.apis["aetna.contract.contract"] = new OfflineAetnaContractContract() ;
	this.apis["synchro"] = new OfflineSynchro() ;
    
    
    this.getApi = function(apiName){
        if(!_self.apis[apiName]){
            _self.apis[apiName] = new OfflineCrudCli(apiName);
        }
        return  _self.apis[apiName] ;
    } ;
    
}

module.exports = OfflineCli ;