var TemplateTypeViews = require("../views/templateType/templateTypeViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;

function TemplateTypeController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "document.aetna.category",
		socketChangeEvent : 'documentCategoryChanged',
		name : "templateType",
		gridClass : TemplateTypeViews.Grid,
		formClass : TemplateTypeViews.Form
	}) ;
    
    this.checkCanDelete = function(data, callback){
    	var templateType = data.recordToDelete ;
    	
    	var templates = loki.daos["document.aetna.template"].search({category_id : templateType.id}) ;
    	if(templates.length>0){
    		_self.viewForm.error(t("templateType.cantDeleteUsedInTemplate", {count : templates.length})) ;
			return callback(false) ;
    	}
    	
    	var children = loki.daos["document.aetna.category"].search({parent_id : templateType.id}) ;
    	if(children.length>0){
    		_self.viewForm.error(t("templateType.cantDeleteUsedAsParent", {count : children.length})) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
}

module.exports = new TemplateTypeController();
