var MainView = require("../views/main/main");
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var cliFactory = require("aetna-ajax/Cli") ;
var cli = cliFactory.cli ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var loki = require("../api/loki") ;
var userController = require("./userController") ;

function MainController() {
    var _self = this;

	var initialized =false ;
    this.init = function (callback) {
    	if(initialized){ return callback(); }
    	
    	initialized = true ;
    	
		// create the main view
		_self.viewMain = new MainView(cli().serverUrl);
		
		// init the main view (will load HTML/CSS and view JS init)
        _self.viewMain.init(function () {
			callback();
		});
		
		BUS.on("notLogged", _self.notLogged) ;
		BUS.on("logoutDone", _self.notLogged) ;
		BUS.on("loggedAndInitialized", _self.logged) ;
		BUS.on("backOnline", _self.logged) ;
		BUS.on("goOffline", _self.goOffline) ;
		BUS.on("offline", _self.onOffline) ;
		BUS.on("online", _self.onOnline) ;
		BUS.on("openScreen", _self.onOpenScreen) ;
		
		window.addEventListener("blur", _self.onBlur) ;
		
		_self.viewMain.on("logout", function(){ BUS.emit("logoutRequest") }) ;
		_self.viewMain.on("changePass", function(){ BUS.emit("changePassRequest") }) ;
		_self.viewMain.on("userPref", function(){ BUS.emit("userPrefRequest") }) ;
		_self.viewMain.on("customers", function(){ BUS.emit("openScreen", 			{screen : "customer", module : "ref"}) }) ;
		_self.viewMain.on("company", function(){ BUS.emit("openScreen", 		 	{screen : "company", module : "ref"}) }) ;
		_self.viewMain.on("project", function(){ BUS.emit("openScreen",  			{screen : "project", module : "ref"}) }) ;
		_self.viewMain.on("product", function(){ BUS.emit("openScreen",  			{screen : "product", module : "ref"}) }) ;
		_self.viewMain.on("tax", function(){ BUS.emit("openScreen",  				{screen : "tax", module : "ref"}) }) ;
		_self.viewMain.on("payType", function(){ BUS.emit("openScreen",  			{screen : "payType", module : "ref"}) }) ;
		_self.viewMain.on("projectSettings", function(){ BUS.emit("openScreen",  	{screen : "projectSettings", module : "settings"}) }) ;
		_self.viewMain.on("user", function(){ BUS.emit("openScreen", 				{screen : "user", module : "settings"}) }) ;
		_self.viewMain.on("hrGroup", function(){ BUS.emit("openScreen", 			{screen : "hrGroup", module : "settings"}) }) ;
		_self.viewMain.on("hrDepartment", function(){ BUS.emit("openScreen", 			{screen : "hrDepartment", module : "settings"}) }) ;
		_self.viewMain.on("dico", function(){ BUS.emit("openScreen", "dico") }) ;
		_self.viewMain.on("template", function(){ BUS.emit("openScreen", 			{screen : "template", module : "settings"}) }) ;
		_self.viewMain.on("invoiceTemplate", function(){ BUS.emit("openScreen", 	{screen : "invoiceTemplate", module : "settings"}) }) ;
		_self.viewMain.on("refundTemplate", function(){ BUS.emit("openScreen", 		{screen : "refundTemplate", module : "settings"}) }) ;
		_self.viewMain.on("remindSettings", function(){ BUS.emit("openScreen", 		{screen : "remindSettings", module : "settings"}) }) ;
		_self.viewMain.on("invoiceSettings", function(){ BUS.emit("openScreen", 	{screen : "invoiceSettings", module : "settings"}) }) ;
		_self.viewMain.on("fiscalYear", function(){ BUS.emit("openScreen", 			{screen : "fiscalYear", module : "settings"}) }) ;
		_self.viewMain.on("templateType", function(){ BUS.emit("openScreen", 		{screen : "templateType", module : "settings"}) }) ;
		_self.viewMain.on("taskStage", function(){ BUS.emit("openScreen", 			{screen : "taskStage", module : "settings"}) }) ;
		_self.viewMain.on("workflow", function(){ BUS.emit("openScreen",			{screen :  "workflow", module : "settings"}) }) ;
		_self.viewMain.on("projectCustomField", function(){ BUS.emit("openScreen", 	{screen : "projectCustomField", module : "settings"}) }) ;
		_self.viewMain.on("facto", function(){ BUS.emit("openScreen",				{screen :  "facto", module : "facto"}) }) ;
		_self.viewMain.on("refund", function(){ BUS.emit("openScreen",				{screen :  "refund", module : "facto"}) }) ;
		_self.viewMain.on("contract", function(){ BUS.emit("openScreen", "contract") }) ;
		_self.viewMain.on("contractTemplate", function(){ BUS.emit("openScreen", 	{screen : "contractTemplate", module : "settings"}) }) ;
		_self.viewMain.on("accountingVat", function(){ BUS.emit("openScreen", 		{screen : "accountingVAT", module : "accounting"}) }) ;
		_self.viewMain.on("accountingExport", function(){ BUS.emit("openScreen", 	{screen : "accountingExport", module : "accounting"}) }) ;
		_self.viewMain.on("accountingImport", function(){ BUS.emit("openScreen", 	{screen : "accountingImport", module : "accounting"}) }) ;
		_self.viewMain.on("statsInvoices", function(){ BUS.emit("openScreen", 		{screen : "statsInvoices", module : "stats"}) }) ;
		_self.viewMain.on("purchase", function(){ BUS.emit("openScreen", 			{screen : "purchase", module : "purchase"}) }) ;
		_self.viewMain.on("sale", function(){ BUS.emit("openScreen", 				{screen : "sale", module : "sale"}) }) ;
		_self.viewMain.on("saleOrderTemplate", function(){ BUS.emit("openScreen", 	{screen : "saleOrderTemplate", module : "settings"}) }) ;
		_self.viewMain.on("saleSettings", function(){ BUS.emit("openScreen", 		{screen : "saleSettings", module : "settings"}) }) ;
		_self.viewMain.on("dmsInvoice", function(){ BUS.emit("openScreen", 			{screen : "dmsInvoice", module : "dms"}) }) ;

    };
	
	this.logged = function(){
		_self.viewMain.serverUrl= cli().serverUrl  ;
		userController.doSync(false, function(err){
			if(err){ return _self.viewMain.error(err) ; }
			var user = userController.mapUser(loki.daos['res.users'].searchFirst({
				login : storage(context().name).get("user").login
			})) ;
			_self.viewMain.setUser(user) ;
			_self.viewMain.show() ;
			
			//remove the waiter
			document.getElementById("waiter_load").style.display = "none" ;
			
			var defaultScreen = null ;
			if(user.moduleDico){
				defaultScreen = "dico" ;
			}else if(user.moduleFacto){
				defaultScreen = "facto" ;	
			}else if(user.moduleContract){
				defaultScreen = "contract" ;	
			}else if(user.modulePurchase){
				defaultScreen = "purchase" ;	
			}else if(user.dms){
				defaultScreen = "dms" ;	
			}
			
			if(user.moduleDico && window.location.hash && window.location.hash.indexOf("#openDoc=") === 0){
				defaultScreen = "dico" ;
			}
			
			if(defaultScreen){
				BUS.emit("openScreen", defaultScreen) ;
			}
		}) ;
	} ;
	
	this.goOffline = function(){
		_self.viewMain.show() ;
	} ;
	
	this.notLogged = function(){
		_self.viewMain.hide() ;
	} ;
	
	this.onOffline = function(){
		_self.viewMain.setOffline(true) ;
	};
	
	this.onOnline = function(){
		_self.viewMain.setOffline(false) ;
	};
	
	this.onOpenScreen = function(){
		$(".tooltip").remove(); //remove all tooltip to avoid remaining tooltip on screen change
	} ;

	this.onBlur = function(){
		$(".tooltip").remove(); //remove all tooltip to avoid remaining tooltip on screen change
	} ;
}

module.exports = new MainController();
