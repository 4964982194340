var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var loki = require("../../api/loki") ;
var str = require("aetna-string") ;

var model = "account.invoice" ;
var moduleName = "facto" ;

function InvoiceGridView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"AetnaRef",
			"partner_id",
			"name",
			"date_invoice",
			"date_due",
			"partner_id",
			"amount_untaxed",
			"amount_total",
			"residual",
			"AetnaLastRemindNumber",
			"AetnaLastRemindDate",
			"RemindToDo",
			"state",
			"action"
    	],
	}) ;
	
	
	this.staticHTML = null;
	this.staticCSS = null;
	
	_self.fields.fields["account.invoice~~grid"].colsCustom = [
		{field : "AetnaLastRemindNumber", hidden: true },
		{field : "AetnaLastRemindDate", hidden: true },
		{field : "RemindToDo",  caption: t("facto.remindToDo"), size :"50px", hidden: true, render : function(record){
			var remindToDo = (record.AetnaLastRemindNumber||0) ;
			
			if(_self.customersInvoices && _self.customersInvoices[record.partner_id]){
				_self.customersInvoices[record.partner_id].forEach(function(invoice){
					remindToDo = Math.max(remindToDo, (invoice.AetnaLastRemindNumber||0)) ;
				}) ;
			}
			remindToDo = remindToDo + 1 ;
			var options = [] ;
			_self.allReminds.forEach(function(r){
				var selected = "" ;
				if(r.sequence === remindToDo) {
					selected = 'selected = "selected"';
				}
				options.push('<option value="'+r.sequence+'" '+selected+'>'+r.sequence+"</option>");
			}) ;
			
			
			return '<select id="remindToDo_'+record.recid+'" style="width: 40px">'+options.join("\n")+"</select>" ;
		} },

		{field : "state", caption : t("fields.account.invoice.state"), size :"150px", render: function(record){
			var state = loki.daos["account.invoice"].getExtendedState(record) ; 
			
			var color = "info" ;
			if(state === "waiting" || state === "remindWaiting"){
				color = "primary" ;
			}else if(state === "toRemind" || state === "notPaid" ){
				color = "danger" ;
			}else if(state === "cancel"){
				color = "warning" ;
			}else if(state === "paid"){
				color = "success" ;
			}
			
			return '<div class="bg-'+color+'" style="height: 37px;line-height: 31px;">'+
			t("fields.values.account.invoice.state."+state)+'</div>' ;
		}},
		
		{field : "action", caption : " ", size :"250px", render: function(record){
			var state = loki.daos["account.invoice"].getExtendedState(record) ; 

			if(state === "draft"){
				return '<button class="btn btn-sm btn-primary" data-action="validate">'+
				'<i class="fa fa-check-circle"></i> '+t("facto.validate")+'</button>&nbsp;'+
				
				'<button class="btn btn-sm btn-danger" data-action="cancel">'+
				'<i class="fa fa-trash"></i> '+t("facto.cancel")+'</button>';
			}else if(state === "waiting" || state === "toRemind"){
				'<button class="btn btn-sm btn-danger" data-action="refund">'+
				'<i class="fa fa-undo"></i> '+t("facto.refundInvoice")+'</button>';
			}else{
				return "" ;
			}
		}}
	] ;
	
	
	
	var filtersLinks = ["filterDraft", "filterWaiting", "filterToRemind", "filterRemindWaiting", "filterPaid", "filterNotPaid", "filterCancel"] ;
	
	_self.once("initDone", function(){
		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey){
					filtersLinks.forEach(function(otherId){
						if(id !== otherId){
							_self.$EL[otherId].removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
		
		_self.EL.detailsFilters.addEventListener("click", _self.toggleDetailsFilter);

		_self.fields.fields[_self.options.model+"~~grid"].addEventListener("click", _self.onTableClick) ;
		_self.fields.fields[_self.options.model+"~~grid"].addEventListener("selectionChanged", _self.onTableSelect) ;
		
		_self.EL.inputPay.addEventListener("click", _self.openInputPay) ;
		_self.EL.validatePay.addEventListener("click", _self.validatePay) ;
		_self.EL.closePay.addEventListener("click", _self.closePay) ;
		_self.fields.fields["account.voucher~amount"].addEventListener("keyup", _self.amountKeyUp) ;
		_self.fields.fields["account.voucher~partner_id"].addEventListener("change", _self.customerChanged) ;
		
		_self.EL.validateAndPrint.addEventListener("click", _self.validateAndPrint);
		_self.EL.genRemind.addEventListener("click", _self.genRemind);
		
		
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.on("select", _self.onSelect) ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.onExpand = _self.onExpand ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.onColumnResize = _self.onColumnResize ;
	}) ;
	
	_self.on("changeFilter", function(ev){
		var showMulti = false ;
		var filterCount = 0;
		_self.allFiltersRelatedToRemind = true ;
		_self.isFilterRemind = ev.data.filter.toRemind &&  Object.keys(ev.data.filter).length === 1;
		Object.keys(ev.data.filter).forEach(function(k){
			if(ev.data.filter[k]){
				filterCount++ ;
				if(k !== "toRemind" && k !== "remindWaiting" && k !== "notPaid"){
					_self.allFiltersRelatedToRemind = false ;
				}
			} 
		}) ;
		if(filterCount === 0){
			_self.allFiltersRelatedToRemind = false ;
		}
			
		if(filterCount === 1 && ev.data.filter.draft){
			_self.EL.divValidateMultiple.style.display = "block" ;
			_self.$EL.pointerValidate.addClass("animated") ;
			_self.$EL.pointerValidate.addClass("bounce") ;
			
			_self.$EL.pointerValidate.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
				_self.$EL.pointerValidate.removeClass("animated") ;
				_self.$EL.pointerValidate.removeClass("bounce") ;
			});	
			showMulti = true ;
		}else{
			_self.EL.divValidateMultiple.style.display = "none" ;
		}
		
		if(filterCount === 1 && ev.data.filter.toRemind){
			_self.EL.divRemindMultiple.style.display = "block" ;
			_self.$EL.pointerRemind.addClass("animated") ;
			_self.$EL.pointerRemind.addClass("bounce") ;
			
			_self.$EL.pointerRemind.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
				_self.$EL.pointerRemind.removeClass("animated") ;
				_self.$EL.pointerRemind.removeClass("bounce") ;
			});	
			showMulti = true ;
		}else{
			_self.EL.divRemindMultiple.style.display = "none" ;
		}
		
		if(_self.allFiltersRelatedToRemind){
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.showColumn('AetnaLastRemindNumber', 'AetnaLastRemindDate');
		}else{
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.hideColumn('AetnaLastRemindNumber', 'AetnaLastRemindDate');
		}
		
		if(_self.isFilterRemind){
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.showColumn('RemindToDo');
		}else{
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.hideColumn('RemindToDo');
		}
		
		
		
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.selectColumn = showMulti ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.multiSelect = showMulti ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh() ;
		_self.resizeTable() ;
	}) ;
	
	
	var _load = this.load;
	this.load = function(records){
		var filteredRecords = records ;
		_self.customersInvoices = {} ;
		if(_self.isFilterRemind){
			filteredRecords = records.filter(function(record){
				if(!_self.customersInvoices[record.partner_id]){
					_self.customersInvoices[record.partner_id] = [] ;
					
					return true ;
				}else{
					_self.customersInvoices[record.partner_id].push(record) ;
					return false ;
				}
			}) ;
			
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.expandColumn = true ;
		}else{
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.expandColumn = false ;
		}
		_load(filteredRecords) ;
		filteredRecords.forEach(function(record){
			if(_self.customersInvoices[record.partner_id] && _self.customersInvoices[record.partner_id].length > 0){
				_self.fields.fields[_self.options.model+"~~grid"].grid.grid.expand(record.recid) ;
			}
		}) ;
		
	} ;
	
	this.onSelect = function(event){
		setTimeout(function(){
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.records.forEach(function(record){
				if(_self.customersInvoices[record.partner_id] && _self.customersInvoices[record.partner_id].length > 0){
					_self.fields.fields[_self.options.model+"~~grid"].grid.grid.expand(record.recid) ;
				}
			}) ;
		}, 300) ;
	} ;
	
	this.onColumnResize = function(event){
		event.onComplete = function(){
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.records.forEach(function(record){
				if (w2ui.hasOwnProperty('subgrid-' + record.recid)){
					console.log("resize subgrid") ;
					w2ui['subgrid-' + record.recid].columns.forEach(function(col, i){
						var sizeCalculated = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.columns[i].sizeCalculated ;
						console.log("size ?", col.size, sizeCalculated) ;
						col.size = sizeCalculated;
					}) ;
					w2ui['subgrid-' + record.recid].refresh() ;
				}
			}) ;
		} ;
	} ;
	
	this.onExpand = function(event){
        var record = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.get(event.recid) ;

		if (w2ui.hasOwnProperty('subgrid-' + event.recid)){
			w2ui['subgrid-' + event.recid].destroy();
		}
		
		var columns = [] ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.columns.forEach(function(col){
			if(col.field !== "action" && col.field !== "RemindToDo"){
				var colCopy = JSON.parse(JSON.stringify(col)) ;
				colCopy.size = col.sizeCalculated ;
				colCopy.render = col.render ;
				columns.push(colCopy) ;
			}else if(col.field === "RemindToDo"){
				columns.push({
					field : "RemindToDo", 
					render : function(record){ return "" ;}, 
					size : col.sizeCalculated
					
				}) ;
			}
		}) ;
		
        $('#'+ event.box_id).css({ margin: '0px', padding: '0px', width: '100%' })
        	.animate({ height: '105px' }, 100);
        setTimeout(function () {
            $('#'+ event.box_id).w2grid({
                name: 'subgrid-' + event.recid, 
                show: { columnHeaders: false },
                fixedBody: true,
                columns:  columns,
                records: _self.customersInvoices[record.partner_id]
            });
            w2ui['subgrid-' + event.recid].resize();
        }, 300);
	} ;
	
	this.getFilter = function(){
		var filter = {} ;
		
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;	
			if($a.hasClass("active")){
				filter[str.unCapitalize(id.replace(/^filter/, ""))] = true ;
			}
		});
		
		return filter ;
	} ;
	
	this.toggleDetailsFilter = function(){
		_self.$EL.filters.toggleClass("list-group-horizontal") ;
		if(_self.$EL.filters.hasClass("list-group-horizontal")){
			_self.EL.detailsFilters.innerHTML = t("facto.viewDetails") ;
		}else{
			_self.EL.detailsFilters.innerHTML = t("facto.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	
	this.resizeTable = function(){
		
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		if(_self.$EL.inputPayRow.is(":visible")){
			headerHeight += _self.$EL.inputPayRow.height() ;
		}

		_self.EL.gridRow.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields["account.invoice~~grid"].render() ;
	} ;
	
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		filtersLinks.forEach(function(id){
			var $span = _self.$EL[id].find(".badge") ;
			var count = counts[str.unCapitalize(id.replace(/^filter/, ""))] || 0 ;
			if($span.html() != count){// != because compare string and integer
				$span.html(count) ;
				$span.addClass("animated") ;
				$span.addClass("flash") ;
				
				$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$span.removeClass("animated") ;
					$span.removeClass("flash") ;
				});	
			}
		}) ;
	} ;
	
	this.onTableClick = function(ev){
		if(!ev.originalEvent || !ev.originalEvent.target){ return; }
		
		console.log(ev.originalEvent.target) ;
		
		if(ev.originalEvent.target.tagName === "SELECT"){
			ev.preventDefault() ;
		}
		
		var action = ev.originalEvent.target.getAttribute("data-action") ;
		if(action === "validate"){
			_self.confirm(t("facto.areYouSureValidate"), function(yes){
				if(yes){
					_self.emit("validate", _self.fields.fields["account.invoice~~grid"].getRecord(ev.recid)) ;
				}
			});
		}else if(action === "cancel"){
			_self.confirm(t("facto.areYouSureCancel"), function(yes){
				if(yes){
					_self.emit("cancel", _self.fields.fields["account.invoice~~grid"].getRecord(ev.recid)) ;
				}
			});
		}else if(action === "refund"){
			_self.confirm(t("facto.areYouSureRefund"), function(yes){
				if(yes){
					_self.emit("refund", _self.fields.fields["account.invoice~~grid"].getRecord(ev.recid)) ;
				}
			});
		}
	} ;
	
	this.validateAndPrint = function(){
		 var selection = _self.fields.fields["account.invoice~~grid"].getSelectedRecords()  ;
		 if(selection.length === 0){
		 	return _self.error(t("facto.pleaseSelectAnInvoice")) ;
		 }
		 _self.confirm(t("facto.areYouSureValidateMulti", {count: selection.length}), function(yes){
			if(yes){
				_self.emit("validate", selection) ;
			}
		});
		 
	} ;
	
	this.genRemind = function(){
		 var selection = _self.fields.fields["account.invoice~~grid"].getSelectedRecords()  ;
		 if(selection.length === 0){
		 	return _self.error(t("facto.pleaseSelectAnInvoice")) ;
		 }
		 selection = JSON.parse(JSON.stringify(selection)) ;
		 selection.forEach(function(record){
		 	record.remindToDo = parseInt(document.getElementById('remindToDo_'+record.recid).value,10) ;
		 	record.otherInvoicesToRemind = _self.customersInvoices[record.partner_id];
		 }) ;
		 _self.confirm(t("facto.areYouSureRemindMulti", {count: selection.length}), function(yes){
			if(yes){
				_self.emit("genRemind", selection) ;
			}
		});
		 
	} ;
	
	this.onTableSelect = function(ev, selectedRows){
		if(_self.isInputPay){
			_self.onSelectInvoiceToPay(selectedRows) ;
		}
	} ;
	
	this.validatePay = function(){
		var hasError = false ;
		_self.fields.fields["account.voucher~amount"].clearError() ;
		_self.fields.fields["account.voucher~date"].clearError() ;
		_self.fields.fields["account.voucher~journal_id"].clearError() ;
		
		if(_self.fields.fields["account.voucher~amount"].val() === 0){
			_self.fields.fields["account.voucher~amount"].error() ;
			hasError = true ;
		}
		if(_self.fields.fields["account.voucher~date"].isEmpty()){
			_self.fields.fields["account.voucher~date"].error() ;
			hasError = true ;
		}
		if(_self.fields.fields["account.voucher~journal_id"].isEmpty()){
			_self.fields.fields["account.voucher~journal_id"].error() ;
			hasError = true ;
		}
		
		if(hasError){
			return _self.error(t("facto.thereIsErrorPleaseCorrect")) ;
		}
		
		var amount = _self.fields.fields["account.voucher~amount"].val() ;
		var journal = _self.fields.fields["account.voucher~journal_id"].val() ;
		var date = _self.fields.fields["account.voucher~date"].val() ;
		var invoices = [] ;
		if(_self.fields.fields[_self.options.model+"~~grid"].getRecords().length === 1){
			invoices = _self.fields.fields[_self.options.model+"~~grid"].getRecords() ;
		}else{
			invoices = _self.fields.fields[_self.options.model+"~~grid"].getSelectedRecords() ;
		}
		
		if(!invoices || invoices.length === 0){
			return _self.error(t("facto.pleaseChooseInvoice")) ;
		}
		
		_self.confirm(t("facto.areYouAddPay"), function(yes){
			_self.emit("validatePay", {
				date: date,
				journal : journal,
				amount : amount,
				invoices : invoices
			});
		})	;
	} ;
	
	
	
	this.openInputPay= function(){
		_self.isInputPay = true ;
		_self.EL.rowControls.style.display = "none" ;
		_self.EL.inputPayRow.style.display = "block" ;
		_self.$EL.inputPayRow.addClass("animated") ;
		_self.$EL.inputPayRow.addClass("slideInRight") ;
		
		_self.$EL.inputPayRow.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
			_self.$EL.inputPayRow.removeClass("animated") ;
			_self.$EL.inputPayRow.removeClass("slideInRight") ;
			_self.fields.fields["account.voucher~journal_id"].focus() ;
		});	
		
		_self.fields.fields["account.voucher~date"].load(new Date()) ;
		_self.fields.fields["account.voucher~journal_id"].clear() ;
		_self.fields.fields["account.voucher~amount"].clear() ;
		_self.fields.fields["account.voucher~partner_id"].clear() ;
		
		_self.load([]) ;
		
		
		
		_self.EL.validatePay.setAttribute("disabled", "disabled") ;
		_self.EL.infoUniqueResult.style.display = "none" ;
		_self.EL.infoNoResult.style.display = "none" ;
		_self.EL.infoManyResults.style.display = "none" ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.selectColumn = false ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.multiSelect = false ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh() ;
		_self.resizeTable() ;
		
		$(_self.container).addClass("inputPay") ;
	} ;
	
	this.nextInputPay = function(){
		_self.fields.fields["account.voucher~amount"].clear() ;
		_self.fields.fields["account.voucher~partner_id"].clear() ;
		_self.fields.fields["account.voucher~amount"].focus() ;
		_self.EL.validatePay.setAttribute("disabled", "disabled") ;
		_self.EL.infoUniqueResult.style.display = "none" ;
		_self.EL.infoNoResult.style.display = "none" ;
		_self.EL.infoManyResults.style.display = "none" ;
		_self.load([]) ;
	} ;
	
	var _lastAmountVal = 0 ;
	this.amountKeyUp = function(event){
		if(event.keyCode === 13){
			_self.validatePay() ;
			return;
		}
		var amount = _self.fields.fields["account.voucher~amount"].val() ;
		var customer = _self.fields.fields["account.voucher~partner_id"].val() ;
		
		if(amount !== _lastAmountVal){
			_lastAmountVal = amount ;
			_self.emit("searchForPay", {amount : amount, customer : customer}) ;
		}
	} ;
	
	this.customerChanged = function(){
		var customer = _self.fields.fields["account.voucher~partner_id"].val() ;
		_self.emit("searchForPay", {customer : customer}) ;
	} ;
	
	this.onSelectInvoiceToPay = function(selectedRows){
		if(selectedRows.length > 0){
			_self.EL.validatePay.removeAttribute("disabled") ;
		}else{
			_self.EL.validatePay.setAttribute("disabled", "disabled") ;
		}
	} ;
	
	this.loadForPay = function(invoices){
		_self.load(invoices) ;
		
		_self.EL.infoUniqueResult.style.display = "none" ;
		_self.EL.infoNoResult.style.display = "none" ;
		_self.EL.infoManyResults.style.display = "none" ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.selectColumn = false ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.multiSelect = false ;
		_self.EL.validatePay.setAttribute("disabled", "disabled") ;
			
		if(invoices.length === 0){
			_self.EL.infoNoResult.style.display = "block" ;
		}else if(invoices.length === 1){
			_self.EL.validatePay.removeAttribute("disabled") ;
			_self.EL.infoUniqueResult.style.display = "block" ;
			
			_self.$EL.pointerValidatePay.addClass("animated") ;
			_self.$EL.pointerValidatePay.addClass("flash") ;
			
			_self.$EL.pointerValidatePay.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
				_self.$EL.pointerValidatePay.removeClass("animated") ;
				_self.$EL.pointerValidatePay.removeClass("flash") ;
			});	
		}else if(invoices.length > 1){
			_self.EL.infoManyResults.style.display = "block" ;
			
			_self.$EL.pointerValidatePayMulti.addClass("animated") ;
			_self.$EL.pointerValidatePayMulti.addClass("flash") ;
			
			_self.$EL.pointerValidatePayMulti.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
				_self.$EL.pointerValidatePayMulti.removeClass("animated") ;
				_self.$EL.pointerValidatePayMulti.removeClass("flash") ;
			}) ;
			
			_self.$EL.pointerSelectPayMulti.addClass("animated") ;
			_self.$EL.pointerSelectPayMulti.addClass("flash") ;
			
			_self.$EL.pointerSelectPayMulti.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
				_self.$EL.pointerSelectPayMulti.removeClass("animated") ;
				_self.$EL.pointerSelectPayMulti.removeClass("flash") ;
			}) ;
			
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.selectColumn = true ;
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.multiSelect = true ;
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.selectNone() ;
		}
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh() ;
		_self.resizeTable() ;
	} ;
	
	this.closePay = function(){
		_self.isInputPay = false ;
		_self.EL.rowControls.style.display = "block" ;
		_self.EL.inputPayRow.style.display = "none" ;

		_self.$EL.rowControls.addClass("animated") ;
		_self.$EL.rowControls.addClass("slideInLeft") ;
		
		_self.$EL.rowControls.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
			_self.$EL.rowControls.removeClass("animated") ;
			_self.$EL.rowControls.removeClass("slideInLeft") ;
		});	
		

		$(_self.container).removeClass("inputPay") ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.selectColumn = false ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.multiSelect = false ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh() ;
		_self.resizeTable() ;
		
		_self.emit("changeFilter", {filter : _self.getFilter()}) ;
	} ;
	
}

module.exports = InvoiceGridView;
