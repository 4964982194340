var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function AetnaContractTemplate(serverUrl){
	CrudCli.call(this, serverUrl, "aetna.contract.template");
	
	this.create = function(template, uploadFile, callback, callbackProgress){
        ajax(serverUrl + "/api/aetna.contract.template/create", "POST",
		{
		    template : template, 
		    uploadFile: uploadFile
		}, callback, callbackProgress, true);
    };
    
    this.update = function(template, uploadFile, callback, callbackProgress){
        ajax(serverUrl + "/api/aetna.contract.template/update", "POST",
		{
		    template : template, 
		    uploadFile: uploadFile
		}, callback, callbackProgress, true);
    };
    
	
	this.getWebDavDoc = function(id, callback){
        ajax(serverUrl + "/api/aetna.contract.template/getWebDavDoc", {id : id},callback);
    };
}

module.exports = AetnaContractTemplate ;