var OfflineCrudCli = require("../OfflineCrudCli") ;
var OfflineSynchro = require("../OfflineSynchro") ;
var loki = require("../../loki") ;
var t = require("aetna-views/locales/tr").t ;

var uuid = require("uuid") ;
var moment = require("moment") ;
var BUS = require("aetna-events").EventSource.BUS;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var ProjectTaskRef = require("../../utils/ProjectTaskRef") ;


function OfflineProjectTask(){
	var _self = this ;
	OfflineCrudCli.call(this, "project.task");
	
	
	this.getAllRevs = function(task){
		var parents = [] ;
		if(task.AetnaParentRev_id){
			if(Array.isArray(task.AetnaParentRev_id)){ task.AetnaParentRev_id = task.AetnaParentRev_id[0]; }
			parents.push(task.AetnaParentRev_id) ;
			var parent = loki.daos["project.task"].getByPk(parseInt(task.AetnaParentRev_id, 10)) ;
			var parentsRev = _self.getAllRevs(parent) ;
			parentsRev.forEach(function(p){
				parents.push(parentsRev) ;
			}) ;
		}
		return parents ;
	} ;
	
	this.create = function(task, notifications, template, existingDoc, uploadFile, callback, callbackProgress){
		if(!window.isDesktop){
			return callback(t("main.offlineNotAvailable")) ;
		}
		
		var user = storage(context().name).get("user") ;
		
		task.user_id = user.id ;
		task.user_name = user.name ;
		task.create_uid = user.id;
		task.date_last_stage_update = new Date();
		task.user_last_stage_update = user.id;
		task.create_date = new Date() ;
		task.AetnaFollowers_ids = [] ;
		task.project_name = loki.daos["project.project"].getById(task.project_id).name ;
		task.AetnaWorkflow_name = loki.daos["project.project"].getById(task.AetnaWorkflow_id).name ;
		task.stage_name = loki.daos["project.task.type"].getById(task.stage_id).name ;
		task.AetnaRev = 0 ;
		
		var savedTask = JSON.parse(JSON.stringify(task)) ;
		
		var projectSettings=null;
		var settings = loki.daos["project.config.settings"].listAll();
		projectSettings = settings[0] ;
		
		var employee = loki.daos["hr.employee"].searchFirst({user_id : parseInt(savedTask.user_id, 10)}) ;
		savedTask.employee = employee ;
		
		savedTask.user = user ;
							
		var project = loki.daos["project.project"].searchFirst({id : parseInt(savedTask.project_id, 10)}) ;
		savedTask.project = project ;
		
		if(project.partner_id){
			var customer = loki.daos["res.partner"].searchFirst({id : parseInt(project.partner_id, 10)}) ;
			savedTask.customer = customer;
		}else{
			savedTask.customer = {} ;
		}
		
		var filename = "" ;
		if(template){
			var attachTemplate = loki.daos["ir.attachment"].searchFirst({res_model: "document.aetna.template", res_id : parseInt(template.id, 10)}) ;
			filename = attachTemplate.name ;
		}else if(existingDoc){
			var attachExisting = loki.daos["ir.attachment"].searchFirst({res_model: "project.task", res_id : existingDoc}) ;
			filename = attachExisting.name ;
		}else if(uploadFile){
			filename = uploadFile.name ;
		}
		
		
		var ref = task.AetnaRef ;
		if(!ref){
			ref = ProjectTaskRef.computeRef(savedTask);
		}
		var extname = "";
		if(filename.indexOf(".") !== -1){
			extname = filename.substring(filename.lastIndexOf(".")) ;
		}
		filename = ref.replace(/[^a-zA-Z0-9_\- ]/g, "_")+extname ;
								
		task.id = uuid.v4() ;
		task.AetnaRef =  "" ;
		task.AetnaExt =  extname.substring(1) ;
		
		task.notifications = notifications ;

	   
		
		var localeMoment = moment();
		localeMoment.locale(savedTask.user.lang.substring(0,2).toLowerCase()) ;
				
		var properties = {} ;
		properties.creator = savedTask.employee.otherid ;
		properties.author = savedTask.employee.otherid ;
		properties.title = savedTask.AetnaSubtitle ;
		properties.keywords = savedTask.name ;
		properties.subject = savedTask.customer.name ;
		properties.category = localeMoment.format("L") ;
		properties.FileName = ref ;
		properties.description = t("doc.tempRef") ;
		properties.comments = t("doc.tempRef") ;
		
		
		var data = {} ;
		Object.keys(savedTask).forEach(function(k){
			var destName =k ;
			if(k.indexOf("x_")===0){
				destName =k.substring(2) ;
			}
			data[destName] = savedTask[k] ;
		}) ;
		data.creator = savedTask.employee.otherid ;
		data.title = savedTask.name ;
		data.subtitle = savedTask.AetnaSubtitle ;
		data.customer = savedTask.customer.name ;
		data.project = savedTask.project.name ;
		data.creation_date = localeMoment.format("L") ;
		data.reference = "{reference}";
		
		BUS.emit("requestDocCreate", {
			model : "project.task",
			templateTable : "document.aetna.template", 
			record : task, 
			template : template, 
			existingDoc : existingDoc, 
			uploadFile: uploadFile, 
			wordProperties : properties, 
			dataTemplate : data, 
			filename: filename, 
			callback : function(err){
				if(err){
					return callback(err) ;
				}
				
				
				OfflineSynchro.insert("project.task", task) ;
	    
			    OfflineSynchro.insert("aetna.modif.track", {
					res_model : "project.task",
					res_id : task.id,
					date : new Date(),
					label : "doc.docCreated",
					user_id : user.id,
					user_name : user.name
				}) ;
			    
			    OfflineSynchro.insert("ir.attachment", {
			    	res_model: "project.task",
			    	res_id : task.id,
			    	name : filename,
			    	write_uid : user.id
			    }) ;
			    
			    notifications.forEach(function(n){
			    	n.AetnaTask_id = task.id ;
			    	n.AetnaStage_name = loki.daos["project.task.type"].getById(n.AetnaStage_id).name ;
			    	OfflineSynchro.insert("project.aetnatasknotification", n) ;
			    }) ;
			    
			   
			    var parents = _self.getAllRevs(task) ;
				
				parents.forEach(function(parentId){
					OfflineSynchro.update("project.task", {id : parentId, AetnaRev_last : task.AetnaRev }) ;
				}) ;
				
				callback(null, savedTask) ;
			}
		}) ;
    };
    
	this.update = function(task, notifications, callback){
		task.AetnaNotification_ids = notifications ;
		OfflineSynchro.update("project.task", task) ;
		
	    return callback(null, task) ;
    };
	
	
	this.applyAction = function(taskId, action,subject, body, callback){
		var task = loki.daos["project.task"].getByPk(taskId) ;
		
		var actionCycle = loki.daos["project.task"].getActionCycle(taskId, action) ;
		
		if(!actionCycle){
			console.log("can't find workflow cycle for action "+action+" on workflow ", task.AetnaWorkflow_id) ;
			return callback("Invalid workflow action");
		}
		
		var cycle = actionCycle.cycle;
		var user_id = actionCycle.user_id;
		
		var userBefore = loki.daos["res.users"].searchFirst({id : task.user_id}) ;
		var userAfter = loki.daos["res.users"].searchFirst({id : user_id}) ;
		var statusBefore = task.stage_name ;
			
		var updateTask = {
			id : taskId,
			stage_id : cycle.AetnaStageAfter_id,
			stage_name : cycle.AetnaStageAfter_name,
			user_id : user_id,
			user_name :userAfter.name
		} ;
				
		OfflineSynchro.update("project.task", updateTask) ;
		
		var track = OfflineSynchro.insert("aetna.modif.track", {
			res_model : "project.task",
			res_id : taskId,
			date : new Date(),
			label : "doc.statusChanged",
			user_name : userBefore.name,
			user_id : userBefore.id
		}) ;
		
		var userBeforeName= userBefore?userBefore.name:"";
		if(userAfter.name !== userBeforeName){
			OfflineSynchro.insert("aetna.modif.track.detail", {
				track_id : track.id,
				field_name : "fields.project.task.user_id",
				value_before : userBefore?userBefore.name:"",
				value_after : userAfter.name
			}) ;
		}
		
		OfflineSynchro.insert("aetna.modif.track.detail", {
			track_id : track.id,
			field_name : "fields.project.task.stage_id",
			value_before : statusBefore,
			value_after : cycle.AetnaStageAfter_name
		}) ;
		
		var savedTask = loki.daos["project.task"].getById(taskId) ;
		
						
		//var stageDef = loki.daos["project.task.type"].getByPk(updateTask.stage_id) ;
		
			
		//be carefull, must use search on "name" and not on "source" because of some cryptic right management in odoo translations
		/*var translations = loki.daos["ir.translation"].search({name : {operator : "in", value: 
		["project_task_aetna_follower_mail_subject","project_task_aetna_follower_mail_body"]}},
		["source", "lang", "value"]);
					
		var tr = {} ;
		translations.forEach(function(t){
			if(!tr[t.source]){
				tr[t.source] = {} ;
			}
			tr[t.source][t.lang] = t.value ;
		}) ;
					
		
		var data = {
			title : task.name,
			subtitle : task.AetnaSubtitle,
			status : stageDef.description,
			rev : task.AetnaRev,
			reference : task.AetnaRef,
			project : task.project_id[1],
			link : window.location.href+"#openDoc="+task.id
		} ;
		Object.keys(task).forEach(function(key){
			if(key.indexOf("x_") === 0){
				data[key.substring(2)] = task[key] ;
			}
		});*/
			
		// task.AetnaFollowers_ids.forEach(function(followerId, i){
		// 	var userFollow = loki.daos["res.users"].searchFirst({partner_id : followerId});
		// 	if(userFollow && userFollow.login){
		// 		var subject = tr["project_task_aetna_follower_mail_subject"][userFollow.lang] ;
		// 		if(!subject){
		// 			//not available in user lang, fallback to english
		// 			subject = tr["project_task_aetna_follower_mail_subject"]["en_US"] ;
		// 		}
				
		// 		var body = tr["project_task_aetna_follower_mail_body"][userFollow.lang] ;
		// 		if(!body){
		// 			//not available in user lang, fallback to english
		// 			body = tr["project_task_aetna_follower_mail_body"]["en_US"] ;
		// 		}
				
		// 		subject = mustache.render(subject, data) ;
		// 		body =  mustache.render(body, data) ;

		// 		// _self.sendMail(req, 'contact@aetna-softwares.com', userFollow.login, userFollow.id,
		// 		// "project.task", taskId ,subject, body, body) ;
		// 	}else{
		// 		console.log("No email for ",  followerId) ;
		// 	}
		// });
					
		// cycle.AetnaNotification_ids.forEach(function(cycleNotif){
		// 	notifs.some(function(n){
		// 		if(n.AetnaStage_id[0] === cycleNotif){
		// 			var employee = loki.daos["hr.employee"].searchFirst({user_id : n.AetnaUser_id[0]}) ;
		// 			if(employee && employee.work_email){
							
						
		// 				var subject = stageDef.AetnaNotificationSubject ;
		// 				var body = stageDef.AetnaNotificationEmail ;
						
		// 				if(!subject){
		// 					subject = "Document notification" ;
		// 				}
						
		// 				if(!body){
		// 					body = "Notification for document" ;
		// 				}
						
		// 				subject = mustache.render(subject, data) ;
		// 				body =  mustache.render(body, data) ;

		// 				// _self.sendMail(req, 'contact@aetna-softwares.com', employee.work_email, n.AetnaUser_id[0],
		// 				// "project.task", taskId ,subject, body, body) ;
		// 			}else{
		// 				console.log("No email for ",  n.AetnaUser_id) ;
		// 			}
		// 		}
		// 	});
		// }) ;
		
		callback(null, savedTask) ;
    };
    
   
	
	this.listInProgress = function(login, status, callback){
		if(typeof(status) === "function"){
			callback = status;
			status = null;
		}
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.getByPksWithDoc = function(ids, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.countUserDocByStatus = function(login, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.getWebDavDoc = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.shareDoc = function(id, callback){
		return callback(t("main.offlineNotAvailable")) ;
    };

	this.unshareDoc = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.addFollower = function(taskId, userId, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
	this.removeFollower = function(taskId, userId, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.downloadDoc = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
}

module.exports = OfflineProjectTask ;