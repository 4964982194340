var FiscalYearViews = require("../views/fiscalYear/fiscalYearViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var moment = require("moment") ;

function FiscalYearController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.fiscalyear",
		modelsToSync : ["account.fiscalyear","account.period"],
		socketChangeEvent : 'fiscalyearChanged',
		name : "fiscalYear",
		gridClass : FiscalYearViews.Grid,
		formClass : FiscalYearViews.Form,
		forceRefreshOnModif : true
	}) ;
    
	this.loadRecord = function(record){
		record.periods = loki.daos["account.period"].search({fiscalyear_id : record.id, special : false}, "id") ;
		
		record.periods.forEach(function(p){
			var move = loki.daos["account.move.line"].searchFirst({period_id : p.id}) ;
			p.hasMove = !!move ;
		}) ;
		
		record.periodsSpecial = loki.daos["account.period"].search({fiscalyear_id : record.id, special : true}, "id") ;
		
		_self.viewForm.load(record) ;
	} ;
	
	
	this.processValues = function(data, callback){
		var allPeriods = data.recordToSave.periods ;
		if(data.originalRecord && data.originalRecord.periodsSpecial){
			allPeriods = data.originalRecord.periodsSpecial.concat(data.recordToSave.periods) ;
		}
		
		data.recordToSave.period_ids = allPeriods ;
		
		data.recordToSave.period_ids.forEach(function(p){
			delete p.company_id ;
			p.code = p.name ;
			p.date_start = moment(p.date_start).format("YYYY-MM-DD")
			p.date_stop = moment(p.date_stop).format("YYYY-MM-DD")
		}) ;

		data.recordToSave.date_start = moment(data.recordToSave.date_start).format("YYYY-MM-DD")
		data.recordToSave.date_stop = moment(data.recordToSave.date_stop).format("YYYY-MM-DD")
		
		delete data.recordToSave.periods ;
		
		return callback() ;
	} ;
	
}

module.exports = new FiscalYearController();
