var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var moment = require("moment") ;
var filesize = require('filesize');

function ConflictView(serverUrl){
    BaseView.call(this, t, "desktop", "conflict") ;
	
	var _self = this ;

	this.load = function(localTask, serverTask, lastKnownfileMd5, lastKnowDate){
		_self.EL.AetnaRef.innerHTML = serverTask.model.AetnaRef; 
		_self.EL.name.innerHTML = serverTask.model.name; 
		_self.EL.AetnaSubtitle.innerHTML = serverTask.model.AetnaSubtitle; 
		
		_self.EL.localFileSize.innerHTML = filesize(localTask.doc.LocalSize); 
		_self.EL.localFileMd5.innerHTML = localTask.doc.LocalMd5sum ;
		_self.EL.localFileDateModif.innerHTML = moment(localTask.doc.write_date||localTask.model.write_date).format("L LT") ;
		
		_self.EL.serverFileSize.innerHTML = filesize(serverTask.doc.AetnaSize) ;
		_self.EL.serverFileMd5.innerHTML = serverTask.doc.AetnaMd5sum ;
		_self.EL.serverFileDateModif.innerHTML = moment(serverTask.doc.write_date||serverTask.model.write_date).format("L LT") ;
		_self.EL.serverFileUserModif.innerHTML = serverTask.model.write_name ;
		_self.EL.lastKnownfileMd5.innerHTML = lastKnownfileMd5 || "-";
		_self.EL.lastKnowDate.innerHTML = lastKnowDate?moment(lastKnowDate).format("L LT"):"-";
		
		_self.EL.openLocal.emit("openLocal") ;
		_self.EL.openServer.emit("openServer") ;
		
		_self.EL.useLocal.emit("useLocal") ;
		_self.EL.useServer.emit("useServer") ;
	} ;

}

module.exports = ConflictView;
