var PayTypeViews = require("../views/payType/payTypeViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

function PayTypeController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.journal",
		modelsToSync : ["account.journal", "account.account", 
			"account.config.aetna.settings"],
		socketChangeEvent : 'payTypeChanged',
		name : "payType",
		gridClass : PayTypeViews.Grid,
		formClass : PayTypeViews.Form,
		uniqueCols : ["code"]
	}) ;
	
	this.listAll = function(){
		return loki.daos[_self.options.model].search({type : {operator : "in", value: ["bank", "cash"]}}) ;
	};
	
	var _loadRecord = this.loadRecord ;
	this.loadRecord = function(record){
		var rec = JSON.parse(JSON.stringify(record)) ;
		
		rec.accountNumber = null;
		if(rec.default_credit_account_id){
			rec.accountNumber = loki.daos["account.account"].getById(rec.default_credit_account_id) ;
		}
		if(rec.accountNumber){
			rec.accountNumber = rec.accountNumber.code ;
		}
		
		_loadRecord(rec) ;
	} ;
	
	var _newFormInstance = this.newFormInstance ;
	this.newFormInstance = function(){
		var form = _newFormInstance() ;
		form.accountSettings = loki.daos["account.config.aetna.settings"].searchFirst({}) ;
		return form ;
	} ;
	
    this.checkCanDelete = function(data, callback){
    	var journal = data.recordToDelete ;
    	
    	var line = loki.daos["account.voucher"].searchFirst({journal_id : journal.id}) ;
    	if(line){
    		_self.viewForm.error(t("payType.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(callback) ;
	} ;
}

module.exports = new PayTypeController();
