var AccountingImportGrid = require("../views/accountingImport/accountingImportGrid");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;
var moment = require("moment") ;

function AccountingImportController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.move.line",
		socketChangeEvent : 'accountChanged',
		name : "accountingImport",
		gridClass : AccountingImportGrid,
		formClass : null
	}) ;
	
	this.onGridLoad = function(){
		_self.viewList.on("doImport", _self.doImport) ;
	} ;
	
	this.listAll = function(){
		return [] ;
	} ;
	
	this.doImport = function(ev){
		_self.viewList.startWait() ;
		cli("account.import").import(ev.data.type, ev.data.file, function(err){
			if(err){
				return _self.viewList.endWaitError(err) ;
			}
			_self.viewList.endWait() ;
			_self.viewList.info(t("accountingImport.importFinished")) ;
		}) ;
	} ;
	
}

module.exports = new AccountingImportController();
