var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var PdfView = require("aetna-views/views/pdf/pdf") ;
var userUtils = require("../../utils/userUtils") ;
var moment = require("moment") ;

var model = "account.invoice" ;
var moduleName = "refund" ;

function RefundFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		state : "draft",
    		type : "out_refund",
    		date_invoice : new Date(),
    		invoice_line : [{}]
    	},
    	firstField : "account.invoice~name"
	}) ;
	
	this.customHeaderButtons = '<div class="refund_refundForm__actionForm">'+
	'<button class="btn btn-primary btn-sm pull-left" id="validateAction">'+
	'<i class="fa fa-check-circle"></i> <span data-i18n="refund.validate"></button>'+
	
	'<button class="btn btn-danger btn-sm pull-left" id="cancelAction">'+
	'<i class="fa fa-trash"></i> <span data-i18n="refund.cancel"></button>'+
	
	'<button class="btn btn-primary btn-sm pull-left" id="printAction">'+
	'<i class="fa fa-print"></i> <span data-i18n="refund.print"></button>'+
	
	'</div>' ;
	
	this.fields.fields["account.invoice~date_invoice"].label = t("refund.refund_date") ;
	this.fields.fields["account.invoice~residual"].label = t("refund.residual_touse") ;
	
	this.on("initDone", function(){
		
		Object.keys(_self.fields.fields["account.invoice~state"]._items).forEach(function(state){
			_self.fields.fields["account.invoice~state"]._items[state] = t("refund.state."+state) ;
		}) ;
		
		_self.fields.fields["account.invoice~state"].refresh() ;
		
		_self.templateLines = new TemplateFields();
		
		_self.templateLines.init(_self.EL.lines, _self.fields) ;

		_self.templateLines.on("initField", _self.onLineFieldInit) ;
		_self.templateLines.on("lineChange", _self.onLineChange) ;
		_self.EL.validateAction.addEventListener("click", _self.onValidateInvoice) ;
		_self.EL.cancelAction.addEventListener("click", _self.onCancelInvoice) ;
		_self.EL.printAction.addEventListener("click", _self.onPrintInvoice) ;
		
		
		var hasRightContract = userUtils.hasRight("moduleContract") ;
		if(hasRightContract){
			_self.EL.contractContainer.style.display = "block" ;
		}else{
			_self.EL.contractContainer.style.display = "none" ;
		}
		
		_self.pdfView = new PdfView(t) ;
		_self.pdfView.init(_self.EL.pdfMain) ;
	}) ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		
		if(_self.isCreate){
		}else if(_self.isModif){
		}else {
			if(_self.item.state !== "draft"){
				_self.EL.modify.style.display = "none" ;
				_self.EL.validateAction.style.display = "none" ;
				_self.EL.cancelAction.style.display = "none" ;
				if(_self.item.state !== "cancel"){
					_self.EL.tabsPdf.style.display = "block" ;
					_self.EL.residual.style.display = "block" ;
					_self.EL.printAction.style.display = "block" ;
				}else{
					_self.EL.tabsPdf.style.display = "none" ;
					_self.EL.residual.style.display = "none" ;
					_self.EL.printAction.style.display = "none" ;
				}
			}else{
				_self.EL.modify.style.display = "block" ;
				_self.EL.tabsPdf.style.display = "none" ;
				_self.EL.residual.style.display = "none" ;
				_self.EL.validateAction.style.display = "block" ;
				_self.EL.cancelAction.style.display = "block" ;
				_self.EL.printAction.style.display = "none" ;
			}
		}
		
		_self.EL.delete.style.display = "none" ;
	} ;
	
	var _setReadOnly = this.setReadOnly ;
	this.setReadOnly= function(readOnly){
		_setReadOnly(readOnly) ;
		_self.templateLines.setReadOnly(readOnly) ;
	} ;
	
	this.onLineFieldInit = function(ev){
		if(ev.data.name === "account.invoice.line~product_id"){
			ev.data.field.addEventListener("change", function(){
				var line = ev.data.def.data ;
				var productId = ev.data.field.val() ;
				if(productId){
					var product = _self.productsById[productId] ;
					if(product){
						if(!line.name){
							line.name = product.name ;
						}
						line.price_unit = product.lst_price ;
						line.invoice_line_tax_id = product.taxes_id ;
						
						_self.templateLines.refreshLine(ev.data.def) ;
						
						if(!line.price_subtotal){
							_self.templateLines.focus(ev.data.def, "price_subtotal") ;
						}
					}
				}
			}) ;
		}
	} ;
	
	this.onLineChange = function(ev){
		var line = ev.data.line ;
		line.quantity = 1 ;
		
		if(ev.data.col === "price_subtotal"){
			line.price_unit = line.price_subtotal ;
		}else{
			line.price_unit = 0 ;
		}
		_self.templateLines.refreshLine(ev.data.def) ;
		
		var lines = _self.templateLines.val() ;
		
		var total_untaxed = 0;
		var total_tax = 0;
		var total_total = 0;
		var total_tax_product = 0;
		var total_tax_service = 0;
		
		lines.invoice_line.forEach(function(line){
			if(line.price_subtotal){
				total_untaxed += line.price_subtotal ;
				
				if(line.product_id){
					var product = _self.productsById[line.product_id] ;
					var lineTaxes = 0 ;
					if(product.taxes_id){
						product.taxes_id.forEach(function(taxId){
							var tax = _self.taxesById[taxId] ;
							lineTaxes += line.price_subtotal * tax.amount ;
						}) ;
					}
					
					total_tax += lineTaxes ;
					if(product.type === "service"){
						total_tax_service += lineTaxes ;
					}else{
						total_tax_product += lineTaxes ;
					}
				}
			}
		}) ;
		
		total_total = total_untaxed + total_tax ;
		
		_self.fields.fields["account.invoice~amount_untaxed"].load(total_untaxed) ;
		_self.fields.fields["account.invoice~amount_tax"] .load(total_tax) ;
		_self.fields.fields["account.invoice~amount_total"].load(total_total) ;
		_self.fields.fields["account.invoice~AetnaTaxProductTotal"].load(total_tax_product) ;
		_self.fields.fields["account.invoice~AetnaTaxServiceTotal"].load(total_tax_service) ;
	} ;
	
	
	var normalLoad = this.load ;
	this.load = function(item){
		normalLoad(item) ;
		
		_self.templateLines.load(item) ;
		
		if(item.id){
			_self.pdfView.showPdf(serverUrl+"/api/account.invoice/downloadPdf?id="+item.id) ;
		}
	} ;
	
	var _getValues = this.getValues;
	this.getValues = function(){
		var values = _getValues() ;
		
		var lines = _self.templateLines.val().invoice_line ;
		values.recordToSave.invoice_line = lines.slice(0,lines.length-1) ;
		
		return values ;
	} ;
	
	var _checkInput = this.checkInput ;
	this.checkInput = function(){
		var error = _checkInput() ;
		if(error){ return error ;}
		
		var values = _self.getValues() ;
		
		if(values.recordToSave.invoice_line.length === 0){
			return t("refund.pleaseAddLine") ;
		}
		
		if(moment(values.recordToSave.date_invoice).isBefore(moment(_self.lastInvoiceDate), 'day')){
			return t("refund.dateMustBeAfter", {lastInvoiceDate : moment(_self.lastInvoiceDate).format("L")}) ;
		}
	} ;
	
	this.onValidateInvoice = function(){
		_self.confirm(t("refund.areYouSureValidate"), function(yes){
			if(yes){
				_self.emit("validate", _self.item) ;
			}
		});
	} ;
	
	this.onCancelInvoice = function(){
		_self.confirm(t("refund.areYouSureCancel"), function(yes){
			if(yes){
				_self.emit("cancel", _self.item) ;
			}
		});
	} ;
	
	this.onPrintInvoice = function(){
		window.location.href = serverUrl+"/api/account.invoice/downloadPdf?id="+_self.item.id ;
	} ;
	
}

module.exports = RefundFormView;
