var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");

var moduleName = "user" ;
var model = "res.users" ;

function UserGridView(serverUrl){
	var _self = this ;
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
    		"name",
			"login",
			"otherid",
			"department_name",
			"AetnaActive"
    	],
	}) ;

	
	
	
	_self.fields.fields["res.users~~grid"].colsCustom = [
		{field : "otherid", caption : t("fields.hr.employee.otherid"), 
			size :"150px", searchable : true, sortable : true},
		{field : "department_name", caption : t("fields.hr.employee.department_id"), 
			size :"200px", searchable : true, sortable : true},
		{field : "AetnaActive", caption : t("fields.hr.employee.AetnaActive"), 
			size :"150px", searchable : true, sortable : true}
		] ;
}

function UserFormView(serverUrl){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord: {
    		user: { extservers: [{}] },
    		employee : {AetnaActive : true, AetnaReceiveNotification : true},
    		
    	}
	}) ;
	
	_self.once("initDone", function(){
		_self.templateExtservers = new TemplateFields();
		_self.templateExtservers.init(_self.EL.extservers, _self.fields) ;

		
		_self.fields.fields["res.users~moduleSettingsTemplatesType"].addEventListener("change", _self.onTemplatesTypeChange) ;
		_self.fields.fields["res.users~moduleSettingsWorkflowStep"].addEventListener("change", _self.onWorkflowStepChange) ;
		_self.fields.fields["res.users~moduleSettingsTemplates"].addEventListener("change", _self.onDicoSubModuleChange) ;
		_self.fields.fields["res.users~moduleSettingsWorkflow"].addEventListener("change", _self.onDicoSubModuleChange) ;
		_self.fields.fields["res.users~moduleSettingsCustomFields"].addEventListener("change", _self.onDicoSubModuleChange) ;
		_self.fields.fields["res.users~moduleDicoAssign"].addEventListener("change", _self.onDicoSubModuleChange) ;
		_self.fields.fields["res.users~moduleSettingsReference"].addEventListener("change", _self.onDicoSubModuleChange) ;
		_self.fields.fields["res.users~moduleSettingsContract"].addEventListener("change", _self.onContractSubModuleChange) ;
		_self.fields.fields["res.users~moduleAccountingVAT"].addEventListener("change", _self.onAccountingSubModuleChange) ;
		_self.fields.fields["res.users~moduleAccountingExport"].addEventListener("change", _self.onAccountingSubModuleChange) ;
		_self.fields.fields["res.users~moduleAccountingImport"].addEventListener("change", _self.onAccountingSubModuleChange) ;
		_self.fields.fields["res.users~moduleDms"].addEventListener("change", _self.onDmsSubModuleChange) ;
		_self.fields.fields["res.users~moduleSaleSettings"].addEventListener("change", _self.onSaleSubModuleChange) ;
	}) ;
	
	var _setReadOnly = this.setReadOnly ;
	this.setReadOnly= function(readOnly){
		_setReadOnly(readOnly) ;
		_self.templateExtservers.setReadOnly(readOnly) ;
	} ;
	
	var _onModify = this.onModify;
	this.onModify = function(){
		if(!_self.user.extservers || _self.user.extservers.length === 0){
			_self.user.extservers = [{}] ;
			_self.templateExtservers.load(_self.user) ;
		}
		_onModify() ;
	};
	
	this.onTemplatesTypeChange= function(){
		if(_self.fields.fields["res.users~moduleSettingsTemplatesType"].val()){
			_self.fields.fields["res.users~moduleSettingsTemplates"].setReadOnly(true) ;
			if(!_self.fields.fields["res.users~moduleSettingsTemplates"].val()){
				_self.fields.fields["res.users~moduleSettingsTemplates"].load(true) ;
			}
		}else{
			_self.fields.fields["res.users~moduleSettingsTemplates"].setReadOnly(false) ;
		}
		
	} ;
	
	this.onDicoSubModuleChange= function(){
		if(["moduleSettingsTemplates", "moduleSettingsWorkflow", 
			"moduleSettingsCustomFields", "moduleDicoAssign",
			"moduleSettingsReference"].some(function(s){
			return _self.fields.fields["res.users~"+s].val() ;
		})){
			_self.fields.fields["res.users~moduleDico"].setReadOnly(true) ;
			if(!_self.fields.fields["res.users~moduleDico"].val()){
				_self.fields.fields["res.users~moduleDico"].load(true) ;
			}
		}else{
			_self.fields.fields["res.users~moduleDico"].setReadOnly(false) ;
		}
	} ;
	
	this.onWorkflowStepChange= function(){
		if(_self.fields.fields["res.users~moduleSettingsWorkflowStep"].val()){
			_self.fields.fields["res.users~moduleSettingsWorkflow"].setReadOnly(true) ;
			if(!_self.fields.fields["res.users~moduleSettingsWorkflow"].val()){
				_self.fields.fields["res.users~moduleSettingsWorkflow"].load(true) ;
			}
		}else{
			_self.fields.fields["res.users~moduleSettingsWorkflow"].setReadOnly(false) ;
		}
	} ;

	this.onContractSubModuleChange= function(){
		if(_self.fields.fields["res.users~moduleSettingsContract"].val()){
			_self.fields.fields["res.users~moduleContract"].setReadOnly(true) ;
			if(!_self.fields.fields["res.users~moduleContract"].val()){
				_self.fields.fields["res.users~moduleContract"].load(true) ;
			}
		}else{
			_self.fields.fields["res.users~moduleContract"].setReadOnly(false) ;
		}
	} ;
	
	this.onAccountingSubModuleChange= function(){
		if(_self.fields.fields["res.users~moduleAccountingVAT"].val() || 
			_self.fields.fields["res.users~moduleAccountingExport"].val() ||
			_self.fields.fields["res.users~moduleAccountingImport"].val()
			){
			_self.fields.fields["res.users~moduleAccounting"].setReadOnly(true) ;
			if(!_self.fields.fields["res.users~moduleAccounting"].val()){
				_self.fields.fields["res.users~moduleAccounting"].load(true) ;
			}
		}else{
			_self.fields.fields["res.users~moduleAccounting"].setReadOnly(false) ;
		}
	} ;
	
	this.onDmsSubModuleChange= function(){
		if(_self.fields.fields["res.users~moduleDmsInvoice"].val()){
			_self.fields.fields["res.users~moduleDms"].setReadOnly(true) ;
			if(!_self.fields.fields["res.users~moduleDms"].val()){
				_self.fields.fields["res.users~moduleDms"].load(true) ;
			}
		}else{
			_self.fields.fields["res.users~moduleDms"].setReadOnly(false) ;
		}
	} ;
	
	this.onSaleSubModuleChange= function(){
		if(_self.fields.fields["res.users~moduleSaleSettings"].val()){
			_self.fields.fields["res.users~moduleSale"].setReadOnly(true) ;
			if(!_self.fields.fields["res.users~moduleSale"].val()){
				_self.fields.fields["res.users~moduleSale"].load(true) ;
			}
		}else{
			_self.fields.fields["res.users~moduleSale"].setReadOnly(false) ;
		}
	} ;
	
	this.setAccessibleGroups = function(accessibleGroups){
		Object.keys(_self.EL).forEach(function(fieldId){
			if(fieldId.indexOf("group_") === 0 ){
				if(accessibleGroups.indexOf(fieldId.substring(fieldId.indexOf("_")+1)) === -1){
					_self.EL[fieldId].style.display = "none" ;
				}
			}
		}) ;
	} ;
	
	var normalLoad = this.load ;
	this.load = function(item){
		
		_self.user = item.user ;
		if(_self.user.id){
			_self.user.password = "********" ;
		}
		
		normalLoad(item.user) ;
		
		_self.templateExtservers.load(item.user) ;
		
		_self.item = item ;
		
		_self.employee = item.employee ;
		
		_self.fields.load({
			otherid : (_self.employee.otherid||""),
			department_id : _self.employee.department_id,
			AetnaActive : (!!_self.employee.AetnaActive),
			AetnaRestrictOnProject : (!!_self.employee.AetnaRestrictOnProject),
			AetnaReceiveNotification : (!!_self.employee.AetnaReceiveNotification)
		}) ;
	} ;
	
	
	this.getValues = function(){
		var allValues = _self.fields.values() ;
		_self.recordToSave = allValues[_self.options.model+""] ;
		if(_self.recordToSave){
			var extservers = _self.templateExtservers.val().extservers ;
			_self.recordToSave.extservers = extservers.slice(0,extservers.length-1) ;
		
			
			
			_self.recordToSave.id = _self[_self.options.module].id ;
		}
		return {
			recordToSave : _self.recordToSave , 
			allValues : allValues,
			isCreate : _self.isCreate,
			isModif : _self.isModif
		} ;
	} ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		$(_self.container).find("fieldset").each(function(i, fs){
			var hasDiv = $(fs).find("div.form-horizontal").filter(function(i, div){
				return div.style.display !== "none" ;
			}).length > 0 ;
			if(!hasDiv){
				fs.style.display = "none" ;
			}
		}) ;
		
		if(_self.isModif){
			//_self.fields.fields["res.users~login"].setReadOnly(true) ;
			
			_self.onTemplatesTypeChange();
			_self.onDicoSubModuleChange();
			_self.onWorkflowStepChange();
			_self.onContractSubModuleChange();
			_self.onAccountingSubModuleChange();
		}
	} ;
	
	this.fields.fields["res.users~password"].postLabel = t("user.passwordHelp") ;
	this.fields.fields["res.users~login"].postLabel = t("user.loginHelp") ;
	
	
	this.fields.fields["res.users~password"].isValid = function(){
		var pass = _self.fields.fields["res.users~password"].val() ;
		if(pass === "********"){ return true ; }
		if(pass.length<8){
			return false ;	
		}
		if(!/[A-Za-a]/.test(pass)){
			return false ;	
		}
		if(!/[0-9]/.test(pass)){
			return false ;	
		}
		return true ;
	} ;
}

module.exports.Grid = UserGridView;
module.exports.Form = UserFormView;
