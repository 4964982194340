var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");

var model = "project.task.type" ;
var moduleName = "taskStage" ;

function TaskStageGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"name",
			"description"
    	],
	}) ;
}

function TaskStageFormView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model
	}) ;
	
	this.once("initDone", function(){
		_self.fields.fields["project.task.type~AetnaSendEmail"].addEventListener("change", _self.onSendMailChange) ;
		
        _self.templateCustomFields = new TemplateWidget(_self.EL.keywords) ;

		_self.customFields = [];
		schemas.MAIN.tables.some(function(table){
			if(table.tableName === "project.task") {
				table.columns.forEach(function(col){
					if(col.name.indexOf("x_") === 0){
						_self.customFields.push({
							code : "{{"+col.name.substring(2)+"}}",
							label : col.desc
						}) ;
					}
				}) ;
				return true ;
			}
		}) ;
		_self.templateCustomFields.render({fields : _self.customFields}) ;
	}) ;
	
	var normalLoad = this.load ;
	this.load = function(stage){
		stage.AetnaSendEmail = !!stage.AetnaNotificationSubject ;
		normalLoad(stage) ;
		if(stage.name === "PUBLISHED" || stage.name === "DRAFT" || stage.name === "DELETED" ){
			//we should never be able to delete these stages
			_self.EL.delete.disabled = true ;
		}else{
			_self.EL.delete.disabled = false ;
		}
	} ;
	
	
	this.onSendMailChange = function(){
		if(_self.fields.fields["project.task.type~AetnaSendEmail"].val()){
			_self.fields.fields["project.task.type~AetnaNotificationSubject"].setReadOnly(false) ;
			_self.fields.fields["project.task.type~AetnaNotificationEmail"].setReadOnly(false) ;
			_self.fields.fields["project.task.type~AetnaNotificationSubject"].setRequired(true) ;
			_self.fields.fields["project.task.type~AetnaNotificationEmail"].setRequired(true) ;
		}else{
			_self.fields.fields["project.task.type~AetnaNotificationSubject"].load("") ;
			_self.fields.fields["project.task.type~AetnaNotificationEmail"].load("") ;
			_self.fields.fields["project.task.type~AetnaNotificationSubject"].setReadOnly(true) ;
			_self.fields.fields["project.task.type~AetnaNotificationEmail"].setReadOnly(true) ;
			_self.fields.fields["project.task.type~AetnaNotificationSubject"].setRequired(false) ;
			_self.fields.fields["project.task.type~AetnaNotificationEmail"].setRequired(false) ;
		}
	} ;

	var _createNew = this.createNew ;
	this.createNew = function(){
		_createNew() ;
		_self.onSendMailChange() ;
	} ;
	
	var _onModify = this.onModify ;
	this.onModify = function(){
		_onModify() ;
		_self.onSendMailChange() ;
		
		if(_self.item.name === "PUBLISHED" || _self.item.name === "DRAFT" || _self.item.name === "DELETED" ){
			//we should never be able to modify these codes
			_self.fields.fields["project.task.type~name"].setReadOnly(true) ;
		}
	} ;

	var _getValues = this.getValues ;
	this.getValues = function(){
		var values = _getValues() ;
		
		values.recordToSave.AetnaActionIcon = values.recordToSave.AetnaActionIcon_Icon + " text-" + values.recordToSave.AetnaActionIcon_Color ;
		delete values.recordToSave.AetnaActionIcon_Icon;
		delete values.recordToSave.AetnaActionIcon_Color;
		delete values.recordToSave.AetnaSendEmail;
		
		return values ;
	} ;
}

module.exports.Grid = TaskStageGridView;
module.exports.Form = TaskStageFormView;
