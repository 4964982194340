var loki = require("lokijs") ;
var LokiIndexedAdapter = require("lokijs/src/loki-indexed-adapter.js") ;
var cliFactory = require("aetna-ajax/Cli") ;
var cli = cliFactory.cli ;
var execAlone = require("aetna-func/func").execAlone ;
var async = require("async");
var LokiDao = require("./LokiDao") ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var EventSource = require("aetna-events").EventSource ;
var moment = require("moment") ;
var EventBus = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;

// CONFIG
var LokiProjectTask = require("./dao/LokiProjectTask") ;
var LokiAetnaContractContract = require("./dao/LokiAetnaContractContract") ;
var LokiAetnaContractTemplate = require("./dao/LokiAetnaContractTemplate") ;
var LokiDocumentAetnaTemplate = require("./dao/LokiDocumentAetnaTemplate") ;
var LokiAccountInvoiceAetnaTemplate = require("./dao/LokiAccountInvoiceAetnaTemplate") ;
var LokiAccountRefundAetnaTemplate = require("./dao/LokiAccountRefundAetnaTemplate") ;
var LokiAccountInvoice = require("./dao/LokiAccountInvoice") ;
var LokiAccountInvoiceAetnaExportVAT = require("./dao/LokiAccountInvoiceAetnaExportVAT") ;
var LokiAccountMoveLine = require("./dao/LokiAccountMoveLine") ;

var dbName = "ADSPRO-DICO" ;
var collectionsDefinitions = {} ;

collectionsDefinitions["res.company"] = {} ;
collectionsDefinitions["ir.model"] = {search : {model : {operator : "in", value : ["project.task"]}}} ;
collectionsDefinitions["ir.model.data"] = {search : {model : {operator : "in", value : ["res.groups", "account.analytic.journal"]}}} ;
collectionsDefinitions["ir.model.fields"] = {search : {model : {operator : "in", value : ["project.task"]}}} ;
collectionsDefinitions["aetna.modif.track"] = {} ;
collectionsDefinitions["aetna.modif.track.detail"] = {} ;
//collectionsDefinitions["mail.message"] = {search : {model : {operator : "in", value : ["project.task"]}}} ;
collectionsDefinitions["ir.cron"] = {} ;
collectionsDefinitions["ir.sequence"] = {} ;
collectionsDefinitions["res.users"] = {
    fields : ['active', 'company_id', 'company_ids', 'display_name', 'email', 
                            'groups_id', 'lang', 'login', 'login_date', 'name', 'notify_email', 'partner_id'], 
    search : {active : {operator : "in", value : [true, false]}, login : {operator: "not in", value : ["admin", "portaltemplate", "public"]}}
} ;
collectionsDefinitions["res.partner"] = { fields : ["active", "country_id", "create_date", "create_uid", "customer", 
            "date", "email", "employee", "fax", "function", "id", "is_company", "lang", "mobile", "name", 
            "notify_email", "phone", "ref", "state_id", "street", "street2", "title", "type", "tz", "tz_offset", 
            "user_id", "website", "write_date", "write_uid", "zip", "vat", "vat_subjected", "property_account_receivable",
            "AetnaDueType", "AetnaDueDelay"],
            customMapper : function(item){
                Object.keys(item).forEach(function(k){
                    if(["property_account_receivable", "property_account_payable"].indexOf(k) !== -1){
                        var val = item[k] ;
                        var nameKey = k+"_name" ; //example : parent_name
                        if(Array.isArray(val) && val.length === 2){
                            item[k] = val[0] ;
                            item[nameKey] = val[1] ;
                        }else{
                            //the "name" was previously set by the "id" is now empty
                            item[nameKey] = "" ;
                        }
                    }
                }) ;
            	return item ;
            }
} ;
collectionsDefinitions["hr.employee"] = {} ;
collectionsDefinitions["hr.department"] = {} ;
collectionsDefinitions["hr.aetna.group"] = {} ;
collectionsDefinitions["hr.aetna.extserver"] = {alwaysRefresh: true} ;
collectionsDefinitions["project.project"] = { fields : ["AetnaCode", "AetnaCycles_ids", "AetnaIsWorkflow", 
            "AetnaPublicStatus", "AetnaStartStage_id", "active", "code", "create_date", "create_uid", "date", "date_start", 
            "id", "manager_id", "members", "name", "partner_id", "state", "user_id", "write_date", "write_uid"]} ;
collectionsDefinitions["project.config.settings"] = { fields : ["AetnaDocumentReference"]} ;
collectionsDefinitions["document.aetna.category"] = {} ;
collectionsDefinitions["document.aetna.template"] = {} ;
collectionsDefinitions["project.aetnaworkflowcycle"] = {} ;
collectionsDefinitions["project.task.type"] = {} ;
collectionsDefinitions["project.task"] = { indices: ['user_id', 'stage_id'], fields : [
            'AetnaExt', 'AetnaFollowers_ids', 'AetnaNotification_ids', 'AetnaParentRev_id', 'AetnaRef', 'AetnaRev', 
            'AetnaRev_last', 'AetnaSeq', 'AetnaSeqProject', 'AetnaSubtitle', 'AetnaWorkflow_id', 'active', 'create_date', 'create_uid', 
            'id', 'name', 'project_id', 'stage_id', 'user_id', 'write_date', 'write_uid', 
            'date_last_stage_update', 'user_last_stage_update', 'AetnaRestrictGroup_ids'
            ]} ;
collectionsDefinitions["workflow"] = {} ;
collectionsDefinitions["workflow.activity"] = {} ;
collectionsDefinitions["workflow.transition"] = {} ;
collectionsDefinitions["aetna.dms.workflow.field"] = {} ;
collectionsDefinitions["aetna.dms.workflow.act.field"] = {} ;
collectionsDefinitions["aetna.dms.workflow.function"] = {} ;
collectionsDefinitions["aetna.dms.scan.invoice"] = {} ;
collectionsDefinitions["aetna.dms.scan.invoice.line"] = {} ;

EventBus.on(["loaded", "online"], function(){
    if( cli().schemas){
        cli().schemas.MAIN.tables.some(function(table){
    		if(table.tableName === "project.task") {
    			table.columns.forEach(function(col){
    				if(col.name.indexOf("x_") === 0){
    				    if(collectionsDefinitions["project.task"].fields.indexOf(col.name)===-1){
    					    collectionsDefinitions["project.task"].fields.push(col.name) ;
    				    }
    				}
    			}) ;
    			return true ;
    		}
    	}) ;
    }
}) ;
collectionsDefinitions["ir.attachment"] = { indices: ['res_id'], fields : ['res_model', "res_id", "name", "AetnaMd5sum", 
        "AetnaSize", "write_date", "write_uid", "AetnaPublicId", "AetnaConvertWeb"] } ;
collectionsDefinitions["project.aetnatasknotification"] = {  } ;
collectionsDefinitions["account.tax"] = { fields : 
    ["active", "amount", "applicable_type", "description", 
    "name", "tax_sign", "type", "type_tax_use", "account_collected_id", 
    "account_paid_id"]} ;
collectionsDefinitions["account.journal"] = { fields : ["code", "name", "default_credit_account_id", "default_debit_account_id", "type", "sequence_id"]} ;
collectionsDefinitions["account.voucher"] = {} ;
collectionsDefinitions["account.config.aetna.settings"] = {} ;
collectionsDefinitions["account.fiscalyear"] = {} ;
collectionsDefinitions["account.period"] = {} ;
collectionsDefinitions["account.invoice"] = {} ;
collectionsDefinitions["account.invoice.line"] = {} ;
collectionsDefinitions["account.invoice.aetna.remind"] = {} ;
collectionsDefinitions["account.invoice.aetna.remind.settings"] = {} ;
collectionsDefinitions["account.invoice.aetna.tvaexport"] = {} ;
collectionsDefinitions["account.move.line"] = { 
    fields : ["account_id", "period_id", "partner_id", "journal_id", 
    "name", "invoice", "date", "credit", "debit", 
    "AetnaDateExportAccount"],
    customMapper : function(item){
    Object.keys(item).forEach(function(k){
        if(k === "invoice"){ //example : parent_id
            var val = item[k] ;
            var nameKey = k+"_name" ; //example : parent_name
            if(Array.isArray(val) && val.length === 2){
                item[k] = val[0] ;
                item[nameKey] = val[1] ;
            }else{
                //the "name" was previously set by the "id" is now empty
                item[nameKey] = "" ;
            }
        }
    }) ;
	return item ;
}} ;
collectionsDefinitions["account.account.type"] = {} ;
collectionsDefinitions["account.account"] = { search : 
    {type : {operator : "in", value : ["receivable", "liquidity", "payable", "consolidation", "other", "view"]}},
    fields : ["code", "name", "type", "user_type", "parent_id"],
    customMapper : function(item){
        Object.keys(item).forEach(function(k){
            if(k === "user_type"){ //example : parent_id
                var val = item[k] ;
                var nameKey = k+"_name" ; //example : parent_name
                if(Array.isArray(val) && val.length === 2){
                    item[k] = val[0] ;
                    item[nameKey] = val[1] ;
                }else{
                    //the "name" was previously set by the "id" is now empty
                    item[nameKey] = "" ;
                }
            }
        }) ;
    	return item ;
    }
} ;
collectionsDefinitions["account.invoice.aetna.template"] = {} ;
collectionsDefinitions["account.refund.aetna.template"] = {} ;
collectionsDefinitions["sale.order.line"] = {} ;
collectionsDefinitions["sale.order"] = {} ;
collectionsDefinitions["res.currency"] = {} ;
collectionsDefinitions["product.product"] = {fields : ['active', 'code', 'name', 'description', 'default_code', 'lst_price', 'type', 'taxes_id', 'uom_id', 'product_tmpl_id']} ;
collectionsDefinitions["product.template"] = {
     customMapper : function(item){
        Object.keys(item).forEach(function(k){
            if(["property_account_income", "property_account_expense"].indexOf(k) !== -1){
                var val = item[k] ;
                var nameKey = k+"_name" ; //example : parent_name
                if(Array.isArray(val) && val.length === 2){
                    item[k] = val[0] ;
                    item[nameKey] = val[1] ;
                }else{
                    //the "name" was previously set by the "id" is now empty
                    item[nameKey] = "" ;
                }
            }
        }) ;
    	return item ;
    }
} ;
collectionsDefinitions["product.category"] = {} ;
collectionsDefinitions["aetna.contract.contract"] = {} ;
collectionsDefinitions["aetna.contract.product"] = {} ;
collectionsDefinitions["aetna.contract.template"] = {} ;
collectionsDefinitions["aetna.contract.invoice.schedule"] = {} ;
collectionsDefinitions["aetna.contract.invoice.schedule.line"] = {} ;
collectionsDefinitions["sale.order"] = {} ;
collectionsDefinitions["sale.order.line"] = {} ;
collectionsDefinitions["sale.order.aetna.template"] = {} ;
collectionsDefinitions["sale.config.aetna.settings"] = {} ;

var customDao = {
    "project.task" : LokiProjectTask ,
    "document.aetna.template" : LokiDocumentAetnaTemplate ,
    "account.invoice.aetna.template" : LokiAccountInvoiceAetnaTemplate ,
    "account.refund.aetna.template" : LokiAccountRefundAetnaTemplate ,
    "account.invoice.aetna.tvaexport" : LokiAccountInvoiceAetnaExportVAT ,
    "account.move.line" : LokiAccountMoveLine ,
    "aetna.contract.template" : LokiAetnaContractTemplate ,
    "aetna.contract.contract" : LokiAetnaContractContract,
    "account.invoice" : LokiAccountInvoice,
    "" : LokiAccountInvoice
} ;

var itemSearchs = {} ;

itemSearchs["account.invoice~partner_id"] = {customer : true} ;
itemSearchs["account.invoice~AetnaPaymentType_id"] = {type : {operator : "in", value :  ["bank", "cash"]} } ;
itemSearchs["project.project~partner_id"] = {customer : true} ;
itemSearchs["aetna.contract.contract~partner_id"] = {customer : true} ;
itemSearchs["aetna.contract.contract~payment_type_id"] = {type : {operator : "in", value :  ["bank", "cash"]} } ;
itemSearchs["project.project~members"] = {id : {operator : "<>", value : 1}} ;
itemSearchs["project.task~project_id"] = {AetnaIsWorkflow : false} ;
itemSearchs["project.task~AetnaWorkflow_id"] = {AetnaIsWorkflow : true} ;
itemSearchs["project.aetnaworkflowcycle~AetnaStage_id"] = {name : /^(?!.*(PUBLISHED|DELETED)).*$/} ;
itemSearchs["account.voucher~journal_id"] = {type : {operator : "in", value :  ["bank", "cash"]} } ;
itemSearchs["account.voucher~partner_id"] = {customer : true} ;
itemSearchs["account.account~user_type"] = {code : {operator : "in", value :  ["income", "bank", "payable", "receivable", "cash", "expense", "view"]} } ;
itemSearchs["sale.order~partner_id"] = {customer : true} ;

var itemLabel = {} ;


itemLabel["project.task~stage_id"] = function(stage){
    return stage.description ;
} ;

itemLabel["project.task~project_id"] = function(project){
    return project.AetnaCode+" - "+project.name ;
} ;
itemLabel["project.project~partner_id"] = function(partner){
    if(partner.ref){
        return partner.ref+" - "+partner.name ;
    }
    return partner.name ;
} ;
itemLabel["project.project~user_id"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["hr.aetna.group~member_ids"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["project.project~members"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["project.task~user_id"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["project.task~create_uid"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["project.task~write_uid"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["project.task~user_last_stage_update"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["project.aetnatasknotification~AetnaUser_id"] = function(user){
    var employee = lokiObject.daos["hr.employee"].searchFirst({user_id : user.id}) ;
    var str = user.name ;
    if(employee && employee.otherid){
        str = employee.otherid + " - "+str ;
    }
    return str ;
} ;
itemLabel["account.invoice~partner_id"] = function(partner){
    if(partner.ref){
        return partner.ref+" - "+partner.name ;
    }
    return partner.name ;
} ;
itemLabel["account.invoice~project_id"] = function(project){
    return project.AetnaCode+" - "+project.name ;
} ;
itemLabel["account.invoice.line~product_id"] = function(product){
    return "["+product.default_code+"] "+product.name ;
} ;

var formatAccount = function(account){
    return "["+account.code+"] "+account.name ;
}

itemLabel["account.journal~default_credit_account_id"] = formatAccount
itemLabel["account.journal~default_debit_account_id"] = formatAccount
itemLabel["res.partner~property_account_receivable"] = formatAccount
itemLabel["product.template~property_account_expense"] = formatAccount
itemLabel["product.template~property_account_income"] = formatAccount
itemLabel["account.account~parent_id"] = formatAccount

itemLabel["account.move.line~journal_id"] = function(journal){
    if(journal.type === "sale_refund"){
        return t("facto.refund") ;
    }
    return journal.name ;
} ;

itemLabel["account.account~user_type"] = function(type){
   return t("fields.values.account.account.user_type."+type.code)  ;
} ;


// END CONFIG

// CREATE THE DB
var idbAdapter = new LokiIndexedAdapter(context().name+'-'+dbName);
var db = new loki(context().name+'-'+dbName, { 
    autoload: true,
    autoloadCallback : loadHandler,
    autosave: true, 
    autosaveInterval: 10000,
    adapter: idbAdapter
}) ;

var collections = {} ;
var syncer = {} ;
var forceClear = false ;

var initDone = false ;
var eventBus = {} ; EventSource.call(eventBus) ;

/**
 * Handle the database structure init
 */
function loadHandler() {
    // if database did not exist it will be empty so I will intitialize here
    Object.keys(collectionsDefinitions).forEach(function(collName){
        var coll = db.getCollection(collName);
        if (coll === null) {
            var options = { 
            	unique: ['id']
            } ;
            Object.keys(collectionsDefinitions[collName]).forEach(function(k){
                options[k] = collectionsDefinitions[collName][k] ;
            }) ;
            if(!options.indices){
                options.indices = [] ;
            }
            options.indices.push("write_date") ;
            coll = db.addCollection(collName, options);
        }
        collections[collName] = coll ;
    }) ;
    
    collections.sync_date = db.getCollection("sync_date");
    
    if(!collections.sync_date ){
        collections.sync_date = db.addCollection("sync_date");
    }
    
    initDone = true ;
    eventBus.emit("initDone") ;
}

var ensureInit = function(callback){
	if(initDone){
		callback() ;
	}else{
		eventBus.once("initDone", callback) ;
	}
} ;

/**
 * odoo give object like this : 
 * {
 *   user_id : [12, "Name of user"]    
 * }
 * 
 * we want to transform it to something like this : 
 * {
 *   user_id : 12
 *   user_name : "Name of user"
 * }
 * 
 */
function mapOdoo(item){
    Object.keys(item).forEach(function(k){
        if(/_[u]{0,1}id$/.test(k)){ //example : parent_id
            var val = item[k] ;
            var nameKey = k.replace(/_[u]{0,1}id$/, "_name") ; //example : parent_name
            if(Array.isArray(val) && val.length === 2 && typeof(val[1]) === "string"){
                item[k] = val[0] ;
                item[nameKey] = val[1] ;
            }else if(!val){
                //the "name" was previously set by the "id" is now empty
                item[nameKey] = "" ;
            }
        }
    }) ;
	return item ;
}

var serverDelay = null;
function getServerTime(callback){
    
    if(!serverDelay){
        if(cliFactory.getContext() === "offline"){
            return callback(null,  moment.utc().format("YYYY-MM-DD HH:mm:ss")) ;
        }
        cli().getTime(function(err, serverTime){
            if(err){ return callback(err); }
            //this is pessimistic on purpose. We consider that the time we received time is now
            //so our reference is late by the time of network transfer of the response
            //this is serve our purpose because we want to sync since last time but still 
            //want to have records that may be created exactly at the same time than last sync
            //so we want to be late a little bit
            serverDelay = moment.utc(serverTime).toDate().getTime() - moment.utc().toDate().getTime() ;
            
            callback(null, serverTime) ;
        }) ;
    }else{
        var serverTime = moment.utc(moment.utc().toDate().getTime() + serverDelay) ;
        callback(null, serverTime.format("YYYY-MM-DD HH:mm:ss")) ;
    }
}

function insertItems(collName, allItems){
    var items = allItems.map(mapOdoo) ;
    if(collectionsDefinitions[collName].customMapper){
        items = items.map(collectionsDefinitions[collName].customMapper) ;
    }
    
	collections[collName].insert(items) ;
}

var syncLoki = execAlone(function syncLoki(collNames, forceRefresh, callback){
    ensureInit(function(){
        getServerTime(function(err, serverTime){
            if(err){ return callback(err); }
            
            var currentUser = storage(context().name).get("user") ;
            
            var searches = [] ;
            var fetchAllColls = [] ;
            collNames.forEach(function(collName){
                var fields  = collectionsDefinitions[collName].fields ;
                if(fields){
                    if(fields.indexOf("write_date") === -1){
                        fields.push("write_date") ;
                    }
                }
                var search = collectionsDefinitions[collName].search ;
                
                //get last sync time
                var lastSync = null;
                
                
                if(!forceClear && !forceRefresh && !collectionsDefinitions[collName].alwaysRefresh){
                    lastSync = collections.sync_date.findOne({model : collName}) ;
                }
                
                if(lastSync && lastSync.user !== currentUser.id){
                    //user change since last sync, force refresh
                    collections.sync_date.remove(lastSync) ;
                    lastSync = null;
                }
                
            	if(!lastSync){
            		//empty, get all records
            		searches.push({
            		    model : collName,
            		    search : search||{},
            		    fields : fields,
            		    orderBy : "id"
            		}) ;
            		fetchAllColls.push(collName) ;
            	} else {
            	    // get only new or modified records
            	    
                    var searchNewer = {
                        //write date same or higher than previous sync time
                        write_date : {operator : ">=", value : lastSync.date}
                    } ;
                    if(search) {
                        Object.keys(search).forEach(function(k){
                            searchNewer[k] = search[k] ;
                        }) ;
                    }
                    
                    searches.push({
            		    model : collName,
            		    search : searchNewer,
            		    fields : fields,
            		    orderBy : "id"
            		}) ;
                    
            	}
            }) ;
            
    		cli("synchro").search(searches, function(err, itemsByColl){
    		    if(err){ return callback(err) ;}
    		    
    		    Object.keys(itemsByColl).forEach(function(collName){
                    var coll = collections[collName] ;

    		        var newItems = itemsByColl[collName] ;
    		        
    		        if(fetchAllColls.indexOf(collName) !== -1){
    		            //is a fetch all, rewrite all collection
    		            coll.removeWhere(function(){return true}) ;
    		    
    		            insertItems(collName, newItems) ;
    		            
        		        collections.sync_date.insert({
                    	    model : collName,
                    	    user : currentUser.id,
                    	    date : moment(serverTime).subtract(10, "seconds").format("YYYY-MM-DD HH:mm:ss")
                    	}) ;
    		        }else{
    		            var items = newItems.map(mapOdoo) ;
            		    if(collectionsDefinitions[collName].customMapper){
            		        items = items.map(collectionsDefinitions[collName].customMapper) ;
            		    }
            			
            			items.forEach(function(item){
            				var existingRecord = coll.findOne({id : item.id});
            				if (existingRecord) {
            				    Object.keys(item).forEach(function(k){
            				        existingRecord[k] = item[k] ;
            				    }) ;
            				    coll.update(existingRecord);
            				} else {
            				    coll.insert(item);
            				}
            			}) ;
            			
            			var lastSync = collections.sync_date.findOne({model : collName}) ;
            			lastSync.date = moment(serverTime).subtract(10, "seconds").format("YYYY-MM-DD HH:mm:ss") ;
            			lastSync.user = currentUser.id ;
    		        }
    		    }) ;
    		    callback() ;
    		}) ;
        }) ;
    }) ;
}) ;

function sync(collNames, forceRefresh, callback){
    if(typeof(forceRefresh) === "function"){
        callback = forceRefresh ;
        forceRefresh = false ;
    }
    if(!Array.isArray(collNames)){
        collNames = [collNames] ;
    }
    syncLoki(collNames, forceRefresh, function(err){
        //never send technical error to user, just notify a synchro error
        if(err){ 
            console.log("loki synchro error", err) ;
            EventBus.emit("synchroError"); 
        }
        callback() ;
    }) ;
} 

function syncAll(forceRefresh, callback){
    if(typeof(forceRefresh) === "function"){
        callback = forceRefresh ;
        forceRefresh = false ;
    }
    forceClear =forceRefresh ;
    sync(Object.keys(collectionsDefinitions), callback) ;
}


var lokiObject = {} ;
lokiObject.collections = collections ;
lokiObject.sync = sync ;
lokiObject.syncAll = syncAll ;
lokiObject.daos = {} ;

Object.keys(collectionsDefinitions).forEach(function(collName){
    if(customDao[collName]){
        lokiObject.daos[collName] = new customDao[collName](lokiObject) ;
    }else{
        lokiObject.daos[collName] = new LokiDao(lokiObject, collName) ;
    }
}) ;

lokiObject.getItems = function(otherTable, model, code){
   if(!lokiObject.daos[otherTable]) { return null; }
   var search =  itemSearchs[model+"~"+code]||{} ;
   var records = lokiObject.daos[otherTable].search(search) ;
   var items = {} ;
   records.forEach(function(r){
       var value = r.name ;
       if(itemLabel[model+"~"+code]){
           value = itemLabel[model+"~"+code](r) ;
       }
       items[r.id] = value ;
   }) ;
   return items ;
} ;

module.exports = lokiObject ;
