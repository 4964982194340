var CustomerViews = require("../views/customer/customerViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;

function CustomerController() {
	var _self = this ;
	BaseController.call(this, {
		model : "res.partner",
		modelsToSync : ["res.partner", "account.account", 
			"account.config.aetna.settings"],
		socketChangeEvent : 'customerChanged',
		name : "customer",
		defaultValues : {
			customer : true 
		},
		gridClass : CustomerViews.Grid,
		formClass : CustomerViews.Form
	}) ;
    
	this.listAll = function(){
		return loki.daos["res.partner"].search({customer : true}) ;
	};
	
	
	var _loadRecord = this.loadRecord ;
	this.loadRecord = function(record){
		var rec = JSON.parse(JSON.stringify(record)) ;
		
		rec.invoices = loki.daos["account.invoice"].search({partner_id : rec.id}) ;
		
		rec.accountCustomer = null;
		if(rec.property_account_receivable){
			rec.accountCustomer = loki.daos["account.account"].getById(rec.property_account_receivable) ;
			if(rec.accountCustomer && rec.accountCustomer.parent_id){
				rec.accountParent = loki.daos["account.account"].getById(rec.accountCustomer.parent_id) ;	
			}
		}
		if(rec.accountCustomer){
			rec.accountCustomer = rec.accountCustomer.code ;
		}
		if(rec.accountParent){
			rec.accountParent = rec.accountParent.code ;
		}
		
		_loadRecord(rec) ;
	} ;
	
	this.checkCanDelete = function(data, callback){
    	var customer = data.recordToDelete ;
    	
    	
    	var task = loki.daos["project.task"].searchFirst({partner_id : customer.id}) ;
    	if(task){
    		_self.viewForm.error(t("customer.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
    	var project = loki.daos["project.project"].searchFirst({partner_id : customer.id}) ;
    	if(project){
    		_self.viewForm.error(t("customer.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
    	var invoice = loki.daos["account.invoice"].searchFirst({partner_id : customer.id}) ;
    	if(invoice){
    		_self.viewForm.error(t("customer.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
    	var contract = loki.daos["aetna.contract.contract"].searchFirst({partner_id : customer.id}) ;
    	if(contract){
    		_self.viewForm.error(t("customer.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
    	
		return callback(true) ;
	} ;
	
	var _newFormInstance = this.newFormInstance ;
	this.newFormInstance = function(){
		var form = _newFormInstance() ;
		form.accountSettings = loki.daos["account.config.aetna.settings"].searchFirst({}) ;
		return form ;
	} ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(function(){
			_self.viewForm.on("exportXls", _self.exportXls) ;	
			callback() ;
		}) ;
	} ;
	
}

module.exports = new CustomerController();
