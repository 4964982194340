var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineDocumentAetnaTemplate(){
	OfflineCrudCli.call(this, "document.aetna.template");
	
	this.create = function(template, uploadFile, callback, callbackProgress){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.update = function(template, uploadFile, callback, callbackProgress){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
	this.getWebDavDoc = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
}

module.exports = OfflineDocumentAetnaTemplate ;