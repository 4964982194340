var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var UploadField = require("aetna-views/fields/upload");
var PdfView = require("aetna-views/views/pdf/pdf") ;

var moduleName = "invoiceTemplate" ;
var model = "account.invoice.aetna.template" ;

function TemplateGridView(serverUrl){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
    		"name"
    	],
	}) ;
}

function TemplateFormView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model
	}) ;
	
	_self.pdfView = new PdfView(t) ;
	
	this.once("initDone", function(){
		_self.uploadFile = new UploadField(t) ;
		_self.uploadFile.accept = ".docx" ;
		_self.uploadFile.filedrag = _self.EL.drop ;
		_self.uploadFile.init(_self.EL.uploadFile) ;
		
		_self.pdfView.init(_self.EL.pdf) ;
		
		_self.EL.openWord.addEventListener("click", _self.openWord) ;
	}) ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		_self.EL.returnToList.style.display = "none" ;
		_self.EL.delete.style.display = "none" ;
		//_self.EL.cancel.style.display = "none" ;
	} ;
	
	this.on("changeEditionMode", function(){
		_self.pdfView.ensureInit(function(){
			if(_self.invoiceTemplate.id){
				_self.pdfView.showPdf(serverUrl+"/api/account.invoice.aetna.template/downloadPdf?id="+_self.invoiceTemplate.id) ;		
			}
		}) ;
		
		var officeSoftware ;
		if(_self.invoiceTemplate.extension){
			switch(_self.invoiceTemplate.extension.toLowerCase()){
				case "docx":
				officeSoftware = "Word" ;
				break;
				case "xlsx":
				officeSoftware = "Excel" ;
				break;
				case "pptx":
				officeSoftware = "PowerPoint" ;
				break;
			}
		}
		
		if(officeSoftware || window.isDesktop){
			var labelOpen = t("doc.openDoc", {soft : officeSoftware}) ;
			if(!officeSoftware){
				labelOpen = t("doc.openDocNoSoft") ;
			}
			_self.EL.openWord.style.display = "block" ;
			_self.EL.openWordLabel.innerHTML = t(labelOpen, {soft : officeSoftware}) ;
		}else{
			_self.EL.openWord.style.display = "none" ;
		}
		
		if(_self.isCreate){
			_self.EL.uploadFileRow.style.display = "block" ;
			_self.$EL.uploadFileLabel.addClass("required") ;
			_self.EL.openWordRow.style.display = "none" ;
			_self.EL.pdf.style.display = "none" ;
		}else if(_self.isModif){
			_self.EL.uploadFileRow.style.display = "block" ;
			_self.$EL.uploadFileLabel.removeClass("required") ;
			_self.EL.openWordRow.style.display = "none" ;
			_self.EL.pdf.style.display = "none" ;
		}else{
			_self.EL.uploadFileRow.style.display = "none" ;
			_self.EL.openWordRow.style.display = "block" ;
			_self.EL.pdf.style.display = "block" ;
		}
	}) ;
	
	this.openWord = function(){
		if(window.isDesktop) {
			BUS.emit("openTemplate", _self.invoiceTemplate) ;
		}else{
			_self.emit("openTemplateWeb", _self.invoiceTemplate) ;
		}
	} ;
	
	
	var _getValues = this.getValues ;
	
	this.getValues = function(){
		var values = _getValues() ;
		values.uploadFile = _self.uploadFile.getFile();
		return values ;
	} ;
	
	var _checkInput = this.checkInput ;
	
	this.checkInput = function(){
		if(_self.isCreate){
			if(! _self.uploadFile.getFile()){
				return t("invoiceTemplate.pleaseChooseFile") ;
			}
		}
		
		return _checkInput() ; 
	} ;
	
	this.onPdfStart = function(invoiceTemplate){
		if(_self.invoiceTemplate && invoiceTemplate && invoiceTemplate.id == _self.invoiceTemplate.id){ // /!\ compare string and number
			_self.pdfView.startWait() ;
		}
	} ;
	
	this.onPdfFinished = function(invoiceTemplate){
		if(_self.invoiceTemplate && invoiceTemplate && invoiceTemplate.id == _self.invoiceTemplate.id){ // /!\ compare string and number
			_self.pdfView.showPdf(serverUrl+"/api/account.invoice.aetna.template/downloadPdf?id="+invoiceTemplate.id) ;
			_self.pdfView.endWait() ;
		}
	} ;
	
}

module.exports.Grid = TemplateGridView;
module.exports.Form = TemplateFormView;
