var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;

var model = "account.account" ;
var moduleName = "account" ;

function AccountGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"code",
			"name",
			"type"
    	],
	}) ;
}

function AccountFormView(serverUrl, schemas){
	var _self = this ;

	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		active : true
    	}
	}) ;
	
	var possibleTypes = ["receivable", "liquidity", "payable", "consolidation", "other", "view"] ;
	_self.fields.fields["account.account~type"].filter = function(a){ 
		return possibleTypes.indexOf(a) !== -1; 
	} ;

}

module.exports.Grid = AccountGridView;
module.exports.Form = AccountFormView;
