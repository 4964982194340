var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var str = require("aetna-string") ;
var loki = require("../../api/loki") ;

var model = "aetna.contract.contract" ;
var moduleName = "contract" ;

function ContractGridView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"name",
			"partner_id",
			"date_start",
			"date_end",
			"state",
			"action"
    	],
	}) ;
	
	this.staticHTML = null;
	this.staticCSS = null;
	
	_self.fields.fields["aetna.contract.contract~~grid"].colsCustom = [
		{field : "state", caption : t("fields.aetna.contract.contract.state"), size :"150px", render: function(record){
			var state = record.state ; 
			
			var color = "default" ;
			if(state === "open"){
				color = "info" ;
			}else if( state === "cancel" ){
				color = "danger" ;
			}else if(state === "closed"){
				color = "warning" ;
			}
			
			return '<div class="bg-'+color+'" style="height: 37px;line-height: 31px;">'+
			t("fields.values.aetna.contract.contract.state."+state)+'</div>' ;
		}},
		
		{field : "action", caption : " ", size :"400px", render: function(record){
			var state = loki.daos["aetna.contract.contract"].getExtendedState(record) ; 

			var buttons = "" ;

			if(state === "draft"){
				buttons = '<button class="btn btn-sm btn-primary" data-action="validate">'+
				'<i class="fa fa-check-circle"></i> '+t("contract.validate")+'</button>&nbsp;'+
				
				'<button class="btn btn-sm btn-danger" data-action="delete">'+
				'<i class="fa fa-trash"></i> '+t("contract.delete")+'</button>';
			}else if(state === "scheduleRenew"){
				buttons = '<button class="btn btn-sm btn-primary" data-action="scheduleRenew">'+
				'<i class="fa fa-refresh"></i> '+t("contract.scheduleRenew")+'</button>&nbsp;';
			}else if(state === "toInvoice"){
				buttons = '<button class="btn btn-sm btn-primary" data-action="createInvoice">'+
				'<i class="fa fa-cogs"></i> '+t("contract.createInvoice")+'</button>&nbsp;';
			}
			
			if(record.state === "open"){
				buttons += '<button class="btn btn-sm btn-warning" data-action="close">'+
				'<i class="fa fa-ban"></i> '+t("contract.close")+'</button>&nbsp;';
				
				buttons += '<button class="btn btn-sm btn-danger" data-action="cancel">'+
				'<i class="fa fa-trash"></i> '+t("contract.cancel")+'</button>&nbsp;';
			}
			return buttons ;
		}}
	] ;
	
	
	
	var filtersLinks = ["filterDraft", "filterOpen", "filterToInvoice", "filterScheduleRenew", "filterClosed", "filterCancel"] ;
	
	_self.once("initDone", function(){
		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey){
					filtersLinks.forEach(function(otherId){
						if(id !== otherId){
							_self.$EL[otherId].removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
		
		_self.EL.detailsFilters.addEventListener("click", _self.toggleDetailsFilter);
		_self.EL.createInvoice.addEventListener("click", _self.createInvoice);

		_self.fields.fields[_self.options.model+"~~grid"].addEventListener("click", _self.onTableClick) ;
	}) ;
	
	this.getFilter = function(){
		var filter = {} ;
		
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;	
			if($a.hasClass("active")){
				filter[str.unCapitalize(id.replace(/^filter/, ""))] = true ;
			}
		});
		
		return filter ;
	} ;
	
	_self.on("changeFilter", function(ev){
		var showMulti = false ;
		var filterCount = 0;
		Object.keys(ev.data.filter).forEach(function(k){
			if(ev.data.filter[k]){
				filterCount++ ;
			} 
		}) ;
			
		if(filterCount === 1 && ev.data.filter.toInvoice){
			_self.EL.divCreateInvoice.style.display = "block" ;
			_self.$EL.pointerCreateInvoice.addClass("animated") ;
			_self.$EL.pointerCreateInvoice.addClass("bounce") ;
			
			_self.$EL.pointerCreateInvoice.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
				_self.$EL.pointerCreateInvoice.removeClass("animated") ;
				_self.$EL.pointerCreateInvoice.removeClass("bounce") ;
			});	
			showMulti = true ;
		}else{
			_self.EL.divCreateInvoice.style.display = "none" ;
		}
		
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.selectColumn = showMulti ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.multiSelect = showMulti ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh() ;
		_self.resizeTable() ;
	}) ;
	
	this.toggleDetailsFilter = function(){
		_self.$EL.filters.toggleClass("list-group-horizontal") ;
		if(_self.$EL.filters.hasClass("list-group-horizontal")){
			_self.EL.detailsFilters.innerHTML = t("facto.viewDetails") ;
		}else{
			_self.EL.detailsFilters.innerHTML = t("facto.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	
	this.resizeTable = function(){
		
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		_self.EL.gridRow.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields["aetna.contract.contract~~grid"].render() ;
	} ;
	
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		filtersLinks.forEach(function(id){
			var $span = _self.$EL[id].find(".badge") ;
			var count = counts[str.unCapitalize(id.replace(/^filter/, ""))] || 0 ;
			if($span.html() != count){// != because compare string and integer
				$span.html(count) ;
				$span.addClass("animated") ;
				$span.addClass("flash") ;
				
				$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$span.removeClass("animated") ;
					$span.removeClass("flash") ;
				});	
			}
		}) ;
	} ;
	
	this.onTableClick = function(ev){
		var action = ev.originalEvent.target.getAttribute("data-action") ;
		if(action === "validate"){
			_self.confirm(t("contract.areYouSureValidate"), function(yes){
				if(yes){
					_self.emit("validate", _self.fields.fields["aetna.contract.contract~~grid"].getRecord(ev.recid)) ;
				}
			});
		}else if(action === "delete"){
			_self.confirm(t("contract.areYouSureDelete"), function(yes){
				if(yes){
					_self.emit("delete", _self.fields.fields["aetna.contract.contract~~grid"].getRecord(ev.recid)) ;
				}
			});
		}else if(action === "cancel"){
			_self.confirm(t("contract.areYouSureCancel"), function(yes){
				if(yes){
					_self.emit("cancel", _self.fields.fields["aetna.contract.contract~~grid"].getRecord(ev.recid)) ;
				}
			});
		}else if(action === "scheduleRenew"){
			_self.emit("genSchedule", { contract : _self.fields.fields["aetna.contract.contract~~grid"].getRecord(ev.recid)}) ;
		}else if(action === "createInvoice"){
			_self.emit("createInvoice", [_self.fields.fields["aetna.contract.contract~~grid"].getRecord(ev.recid)]) ;
		}else if(action === "close"){
			_self.emit("close", _self.fields.fields["aetna.contract.contract~~grid"].getRecord(ev.recid)) ;
		}
	} ;
	
	this.createInvoice = function(){
		var selection = _self.fields.fields["aetna.contract.contract~~grid"].getSelectedRecords()  ;
		if(selection.length === 0){
			return _self.error(t("contract.pleaseSelectAContract")) ;
		}
		_self.emit("createInvoice", selection) ;
	} ;
}

module.exports = ContractGridView;
