var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var DocGridView= require("./gridDocs") ;


function OfflineView(serverUrl, schemas){
    BaseView.call(this, t, "doc", "offline") ;
	
	var _self = this ;
   
	this._initElements = function(callback){
		_self.grid = new DocGridView(serverUrl, schemas) ;
		_self.grid.idGrid = "gridDocOffline" ;
		_self.grid.offlineMode = true ;
		_self.grid.init(_self.EL.grid, callback) ;
		
		_self.grid.on("openDoc", _self.openDoc) ;
		
     //   _self.fields.fields["project.task~~grid"].onClick = function (event) {
     //           var target = event.originalEvent.target ;

     //           if(target && target.tagName === "BUTTON"){
     //               var record = _self.fields.fields["project.task~~grid"].grid.get(event.recid) ;
					// if(target.className.indexOf("doc_list__openDoc") !== -1){
					// 		
					// }
					
     //           }

     //       }
		  
	};
	
	this.load = function(docs){
		_self.grid.load(docs, true) ;
	} ;
	
	this.openDoc = function(ev){
		BUS.emit("openDoc", ev.data) ;	
	} ;


}

module.exports = OfflineView;
