var loki = require("../api/loki") ;
var userController = require("../controllers/userController") ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

module.exports.hasRight = function(right){
    var user = userController.mapUser(loki.daos['res.users'].searchFirst({
		login : storage(context().name).get("user").login
	})) ;
	return !!user[right] ;
} ;
