var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;

var model = "res.company" ;
var moduleName = "company" ;

function CompanyGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"code",
			"name",
			"type"
    	],
	}) ;
}

function CompanyFormView(serverUrl, schemas){
	var _self = this ;

	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model
	}) ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		_self.EL.returnToList.style.display = "none" ;
		_self.EL.delete.style.display = "none" ;
	} ;

}

module.exports.Grid = CompanyGridView;
module.exports.Form = CompanyFormView;
