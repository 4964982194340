var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var Clipboard= require("clipboard") ;

function ShareView(serverUrl, schemas){
    BaseView.call(this, t, "doc", "share") ;
	
	var _self = this ;
   
	this._initElements = function(){
		_self.templateLinks = new TemplateWidget(_self.EL.links) ;
        _self.templateLinks.on("render", _self.renderLinks) ;
		
		_self.EL.unshare.emit() ;
		_self.EL.emailLinkMulti.addEventListener("click", _self.onEmailLinkMulti);
		_self.EL.emailFileMulti.addEventListener("click", _self.onEmailFileMulti);
		if(!window.isDesktop){
			_self.EL.emailFileMulti.style.display = "none" ;
		}
	};
	
	this.load = function(linksByTask){
		_self.templateLinks.render({ tasks : linksByTask}) ;
	} ;
	
	this.renderLinks = function(){
		if(_self.templateLinks.LISTS.copy){
			_self.templateLinks.LISTS.copy.forEach(function(itemCopy, i){
				new Clipboard("#"+itemCopy.el.id, {
				    text: function(trigger) {
				        return itemCopy.data.url;
				    }
				});
	        }) ;
		}
		if(_self.templateLinks.LISTS.emailLink){
			_self.templateLinks.LISTS.emailLink.forEach(function(itemMailLink, i){
				itemMailLink.el.addEventListener("click", function(){
					
					
					var body = encodeURIComponent(t("doc.mailLinkBody", {url : itemMailLink.data.url})) ;
					var subject = encodeURIComponent(t("doc.mailLinkSubject")) ;
					
					if(itemMailLink.data.webConvert){
						_self.confirm(t("doc.convertedByServerAreYouSure"), function(yes){
							if(yes){
								if(!window.isDesktop){
									window.location='mailto:?subject='+subject+'&body='+body ;
								}else{
									_self.emit("sendMailLink", itemMailLink.data) ;
								}
							}
						}) ;
					}else{
						if(!window.isDesktop){
							window.location='mailto:?subject='+subject+'&body='+body ;
						}else{
							_self.emit("sendMailLink", itemMailLink.data) ;
						}
					}
				
				}) ;
	        }) ;
		}
		if(_self.templateLinks.LISTS.emailFile){
			_self.templateLinks.LISTS.emailFile.forEach(function(itemMailFile, i){
				if(!window.isDesktop){
					itemMailFile.el.style.display = "none" ;
				}else{
					itemMailFile.el.addEventListener("click", function(){
						if(itemMailFile.data.webConvert){
							_self.confirm(t("doc.convertedByServerAreYouSure"), function(yes){
								if(yes){
									_self.emit("sendMailAttach", itemMailFile.data) ;
								}
							}) ;
						}else{
							_self.emit("sendMailAttach", itemMailFile.data) ;
						}
					}) ;
				}
	        }) ;
		}
		_self.$EL.links.find('[data-toggle="tooltip"]').tooltip({container : "body"}) ;

	} ;
	
	this.getSelection = function(){
		var selection = [] ;
		if(_self.templateLinks.LISTS.checkLink){
			_self.templateLinks.LISTS.checkLink.forEach(function(itemCheckLink, i){
				if(itemCheckLink.el.checked){
					selection.push(itemCheckLink.data) ;
				}
	        }) ;
		}
		return selection ;
	} ;
	
	this.onEmailLinkMulti = function(){
		var selection = _self.getSelection() ;
		
		
		
		if(selection.length === 0){
			return _self.error(t("doc.pleaseSelectADoc")) ;
		}
		var urls = selection.map(function(s){ return s.url ;}).join("\r\n") ;
		
		var bodyText = t("doc.mailLinkBody", {url : urls});
		if(selection.length > 1 ){
			bodyText = t("doc.mailLinkBodyMany", {url : urls});
		}
		
		var subjectText = t("doc.mailLinkSubject");
		if(selection.length > 1 ){
			subjectText = t("doc.mailLinkSubjectMany");
		}
		
		var body = encodeURIComponent(bodyText) ;
		var subject = encodeURIComponent(subjectText) ;
		
		var webConvert = selection.some(function(s){ return s.webConvert ;}) ;

		if(webConvert){
			_self.confirm(t("doc.convertedByServerAreYouSure"), function(yes){
				if(yes){
					if(!window.isDesktop){
						window.location='mailto:?subject='+subject+'&body='+body ;
					}else{
						_self.emit("sendMailLink", selection) ;
					}
				}
			}) ;
		}else{
			if(!window.isDesktop){
				window.location='mailto:?subject='+subject+'&body='+body ;
			}else{
				_self.emit("sendMailLink", selection) ;
			}
		}
	} ;
	
	this.onEmailFileMulti = function(){
		var selection = _self.getSelection() ;
		if(selection.length === 0){
			return _self.error(t("doc.pleaseSelectADoc")) ;
		}
		
		var webConvert = selection.some(function(s){ return s.webConvert ;}) ;

		if(webConvert){
			_self.confirm(t("doc.convertedByServerAreYouSure"), function(yes){
				if(yes){
					_self.emit("sendMailAttach", selection) ;
				}
			}) ;
		}else{
			_self.emit("sendMailAttach", selection) ;
		}
		
		
	} ;
}

module.exports = ShareView;
