var ProductViews = require("../views/product/productViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

function ProductController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "product.template",
		modelsToSync : ["product.template", "account.config.aetna.settings", "account.account"],
		socketChangeEvent : 'productChanged',
		name : "product",
		gridClass : ProductViews.Grid,
		formClass : ProductViews.Form,
		uniqueCols : ["default_code"]
	}) ;
	
    this.checkCanDelete = function(data, callback){
    	var productTemplate = data.recordToDelete ;
    	
    	var linkProducts = loki.daos["product.product"].search({product_tmpl_id : productTemplate.id}) ;
    	
    	var productsIds = linkProducts.map(function(p){ return p.id ;});
    	
    	if(productsIds.length>0){
	    	var line = loki.daos["account.invoice.line"].searchFirst({product_id : {operator : "in", value : productsIds}}) ;
	    	if(line){
	    		_self.viewForm.error(t("project.cantDeleteUsed")) ;
				return callback(false) ;
	    	}
    	}
    	
		return callback(true) ;
	} ;
	
	
	var _loadRecord = this.loadRecord ;
	this.loadRecord = function(record){
		var rec = JSON.parse(JSON.stringify(record)) ;
		
		rec.accountExpenseNumber = null;
		if(rec.property_account_expense){
			rec.accountExpenseNumber = loki.daos["account.account"].getById(rec.property_account_expense) ;
		}
		if(rec.accountExpenseNumber){
			rec.accountExpenseNumber = rec.accountExpenseNumber.code ;
		}
		
		rec.accountIncomeNumber = null;
		if(rec.property_account_income){
			rec.accountIncomeNumber = loki.daos["account.account"].getById(rec.property_account_income) ;
		}
		if(rec.accountIncomeNumber){
			rec.accountIncomeNumber = rec.accountIncomeNumber.code ;
		}
		
		_loadRecord(rec) ;
	} ;
	
	var _newFormInstance = this.newFormInstance ;
	this.newFormInstance = function(){
		var form = _newFormInstance() ;
		form.accountSettings = loki.daos["account.config.aetna.settings"].searchFirst({}) ;
		return form ;
	} ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(callback) ;
		
		var allSalesTaxes = loki.daos["account.tax"].search({type_tax_use : "sale"}) ;
		
		var allSalesTaxesByIds = {} ;
		allSalesTaxes.forEach(function(tax){
			allSalesTaxesByIds[tax.id] = tax ;
		}) ;
		
		_self.viewForm.fields.fields["product.template~taxes_id"].filter = function(a){ 
			return allSalesTaxesByIds[a]; 
		} ;
	} ;
}

module.exports = new ProductController();
