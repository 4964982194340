var TaxViews = require("../views/tax/taxViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;

function TaxController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.tax",
		modelsToSync : ["account.tax", "account.account", 
			"account.config.aetna.settings"],
		socketChangeEvent : 'taxChanged',
		name : "tax",
		gridClass : TaxViews.Grid,
		formClass : TaxViews.Form,
		uniqueCols : ["description"]
	}) ;
	
	var _loadRecord = this.loadRecord ;
	this.loadRecord = function(record){
		var rec = JSON.parse(JSON.stringify(record)) ;
		
		rec.accountNumber = null;
		if(rec.account_collected_id){
			rec.accountNumber = loki.daos["account.account"].getById(rec.account_collected_id) ;
		}
		if(rec.accountNumber){
			rec.accountNumber = rec.accountNumber.code ;
		}
		
		rec.amount = rec.amount * 100 ;
		
		_loadRecord(rec) ;
	} ;
	
	var _newFormInstance = this.newFormInstance ;
	this.newFormInstance = function(){
		var form = _newFormInstance() ;
		form.accountSettings = loki.daos["account.config.aetna.settings"].searchFirst({}) ;
		return form ;
	} ;
	
    this.checkCanDelete = function(data, callback){
    	var tax = data.recordToDelete ;
    	
    	var line = loki.daos["account.invoice.line"].searchFirst({
    			invoice_line_tax_id : {operator : "contains", value : tax.id }}) ;
    	if(line){
    		_self.viewForm.error(t("tax.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
	
	this.processValues = function(data, callback){
		data.recordToSave.amount = data.recordToSave.amount / 100 ;
		callback() ;
	} ;
}

module.exports = new TaxController();
