var TemplateViews = require("../views/saleOrderTemplate/templateViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;
var t = require("aetna-views/locales/tr").t ;
var DistantFile = require("../api/DistantFile") ;

function saleOrderTemplateController() {
	var _self = this ;
	BaseController.call(this, {
		model : "sale.order.aetna.template",
		socketChangeEvent : 'templateOrderChanged',
		name : "saleOrderTemplate",
		gridClass : TemplateViews.Grid,
		formClass : TemplateViews.Form
	}) ;
	
	var gui = new BaseGui() ;
	_self.viewList = gui ;
	_self.viewList.hide = function(){} ;
	_self.viewList.show = function(){} ;
	
	BUS.on("loaded", function(){
		_self.socket.on('saleOrderTemplate-pdfStart', _self.onPdfStart);
		_self.socket.on('saleOrderTemplate-pdfFinished', _self.onPdfFinished);
	}) ;
	
	
	this.openGrid = function(){
		//no grid
		_self.doSync(function(err){
			if(err){ return gui.error(err); }
			var records = _self.listAll() ;
					
			if(records.length === 0){
				_self.createNew() ;
			}else{
				_self.openRecord({data: {id : records[0].id}}) ;
			}
		});
	} ;
	
	
	this.onPdfStart = function(doc){
		if(_self.viewForm){
			_self.viewForm.onPdfStart(doc) ;
		}
	} ;
	
	this.onPdfFinished = function(doc){
		if(_self.viewForm){
			_self.viewForm.onPdfFinished(doc) ;
		}
	} ;
	
	this.doSync = function(forceRefresh, callback){
		loki.sync([
			"sale.order.aetna.template", 
			"ir.attachment"
		], forceRefresh, callback) ;
	} ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(function(){
			_self.viewForm.on("openTemplateWeb", _self.openTemplateWeb) ;
			
			callback() ;
		}) ;
	} ;
	
	this.onSave = function(ev){
		_self.viewForm.startWait();
		
		if(!ev.data.recordToSave){
			ev.data.recordToSave = {} ;
		}
		if(!ev.data.recordToSave.name){
			ev.data.recordToSave.name = t("saleOrderTemplate.defaultTemplate") ;
		}
		
		if(ev.data.isCreate){
			cli(_self.options.model).create(ev.data.recordToSave,  ev.data.uploadFile, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				_self.doSync(function(){
					_self.viewForm.load(savedRecord) ;
					_self.viewForm.endWait() ;	
				}) ;
			}) ;
		}else{
			cli(_self.options.model).update( ev.data.recordToSave, 
				ev.data.uploadFile, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				
				_self.doSync(function(){
					_self.viewForm.load(savedRecord) ;
					_self.viewForm.endWait() ;
				}) ;
			}) ;
		}	
	} ;
	
	
	this.openTemplateWeb = function(ev){
		gui.startWait() ;
		
		DistantFile.openDistantFile("sale.order.aetna.template", ev.data.id, function(err){
			if(err){ return gui.endWaitError(err) ;}
			gui.endWait() ;
		}) ;
	} ;
}

module.exports = new saleOrderTemplateController();
