var ajax = require("aetna-ajax/ajax.js").ajax;

function Synchro(serverUrl){
	this.syncRecord = function(entry, callback){
         ajax(serverUrl + "/api/synchro/syncRecord", entry, callback);
    };
    
    this.search = function(searches, callback){
         ajax(serverUrl + "/api/synchro/search", {searches : searches}, callback, 120000);
    };
   
}

module.exports = Synchro ;