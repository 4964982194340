var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineAccountInvoice(serverUrl){
	OfflineCrudCli.call(this, "account.invoice");
	
    
	this.validate = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.validateMulti = function(ids, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.createRemindMulti = function(ids, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.cancel = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.cancelMulti = function(ids, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
}

module.exports = OfflineAccountInvoice ;