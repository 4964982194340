var loki = require("../../loki") ;
var t = require("aetna-views/locales/tr").t ;

function Synchro(serverUrl){
	this.syncRecord = function(entry, callback){
         return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.search = function(searches, callback){
        var allResults = {} ;
        
        searches.forEach(function(k){
            try{
                allResults[k.model] = loki.daos[k.model].search(k.search, k.limit) ;
            }catch(err){
                return callback(err) ;
            }
            
            
        }) ;
        return callback(null, allResults) ;
    };
   
}

module.exports = Synchro ;