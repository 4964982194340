var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;


var model = "document.aetna.category" ;
var moduleName = "templateType" ;

function TemplateTypeGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"name",
			"parent_id"
    	],
	}) ;
}

function TemplateTypeFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model
	}) ;


	var _checkInput = this.checkInput ;
	this.checkInput = function(){
		var error = _checkInput() ;
		if(error){ return error; }

		var parent_id = _self.fields.fields["document.aetna.category~parent_id"].val()
		if(_self.item.id && parent_id && parent_id == _self.item.id){
			return t("templateType.youCantChooseItselfAsParent") ;
		}
	} ;
}

module.exports.Grid = TemplateTypeGridView;
module.exports.Form = TemplateTypeFormView;
