var InvoiceGridView = require("../views/purchase/purchaseGrid");
var InvoiceFormView = require("../views/purchase/purchaseForm");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;
var moment = require("moment") ;
var DistantFile = require("../api/DistantFile") ;

function PurchaseController() {
	var _self = this ;

	BaseController.call(this, {
		model : "account.invoice",
		modelsToSync : ["account.tax", "account.journal", "account.invoice", 
		"account.invoice.line",  "account.invoice.aetna.remind",
		"account.move.line", "account.account", "account.config.aetna.settings", 
		"sale.order", "sale.order.line", "res.currency", "product.product",
		"product.template", "product.category", "res.partner"],
		socketChangeEvent : 'invoiceChanged',
		name : "purchase",
		gridClass : InvoiceGridView,
		formClass : InvoiceFormView
	}) ;
	
	_self.gui = new BaseGui() ;
	
	BUS.on("loaded", function(){
		_self.socket.on('purchase-pdfStart', _self.onPdfRemindStart);
		_self.socket.on('purchase-pdfFinished', _self.onPdfRemindFinished);
	}) ;
	
	this.onGridLoad = function(){
		_self.viewList.on("changeFilter", _self.refreshList) ;
		_self.viewList.on("validate", _self.validateInvoice) ;
		_self.viewList.on("cancel", _self.cancelInvoice) ;
		_self.refreshCount() ;	
	} ;
	
	var _returnToList = this.returnToList;
	this.returnToList = function(forceRefresh){
		_returnToList(forceRefresh) ;
		_self.refreshCount() ;
	} ;
	
	this.refreshCount = function(){
		var counts = loki.daos["account.invoice"].countByState("in_invoice") ;
		_self.viewList.loadCounts(counts) ;
	} ;
	
	this.listAll = function(){
		var filter = _self.viewList.getFilter() ;
		filter.type = "in_invoice" ;
		return loki.daos["account.invoice"].invoiceFilters(filter) ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
	} ;

	var _initForm = this.initForm ;	
	this.initForm = function(callback){
		_initForm(function(){
			
			_self.doInitFormData(_self.viewForm) ;
			
			_self.viewForm.on("validate", _self.validateInvoice) ;
			_self.viewForm.on("cancel", _self.cancelInvoice) ;
			_self.viewForm.on("useRefund", _self.useRefund) ;
			_self.viewForm.on("refund", _self.refundInvoice) ;
			
			callback() ;
		}) ;
	} ;
	
	this.doInitFormData = function(view){
		_self.productsById = {} ;
		view.productsById = _self.productsById ;
		loki.daos["product.product"].listAll().forEach(function(product){
			view.productsById[product.id] = product ;
			var prodTemplate = loki.daos["product.template"].getById(product.product_tmpl_id) ;
			if(prodTemplate){
				Object.keys(prodTemplate).forEach(function(k){
					if(k !== "id" && k !== "$loki"){
						product[k] = prodTemplate[k] ;
					}
				}) ;
			}
		}) ;
		view.taxesById = {} ;
		loki.daos["account.tax"].listAll().forEach(function(tax){
			view.taxesById[tax.id] = tax ;
		}) ;
		view.options.newRecord.currency_id = loki.daos["res.currency"].searchFirst({name : "EUR"}).id ;
		
		view.options.newRecord.date_due = new Date() ;
	} ;
	
	this.loadRecord = function(record){
		record.invoice_line = loki.daos["account.invoice.line"].search({invoice_id : record.id}, "id") ;
		
		record.payments = [] ;
		if(record.payment_ids && record.payment_ids.length > 0){
			record.payments = loki.daos["account.move.line"].search({id : { operator : "in", value: record.payment_ids}}, "id") ;
		}
		
		_self.viewForm.load(record) ;
	} ;
	
	this.processValues = function(data, callback){
		data.recordToSave.invoice_line.forEach(function(line){
			var product = _self.productsById[line.product_id] ;
			if(product){
				line.account_id = product.property_account_expense ;
				if(!line.account_id){
					var category = loki.daos["product.category"].getById(product.categ_id) ;
					line.account_id = category.property_account_expense_categ ;
				}
			}
		});
		
		data.recordToSave.journal_id = loki.daos["account.journal"].searchFirst({type : "sale"}).id ;
		
		var customer = loki.daos["res.partner"].getById(data.recordToSave.partner_id) ;
		if(customer){
			data.recordToSave.account_id = customer.property_account_payable ;
		}
		
		callback() ;
	} ;
	
	this.validateInvoice = function(ev){
		_self.gui.startWait() ;
		
		
		if(!Array.isArray(ev.data)){
			ev.data = [ev.data] ;
		}
		var ids = ev.data.map(function(invoice){ return invoice.id ;}) ;
		
		
		cli("account.invoice").validateMulti(ids, function(err){
			if(err){ return _self.gui.endWaitError(err); }
				
			_self.doSync(function(){
			
				if(ev.source.constructor === InvoiceGridView){
					//from list, refresh list
					_self.refreshCount();
					_self.refreshList();	
				}else{
					//from form, refresh the form
					_self.doSync(function(err){
						if(err){ return _self.viewList.endWaitError(err) ; }
						
						var record = loki.daos[_self.options.model].searchFirst({id : ids[0]}) ;
						_self.currentRecord = record ;
					
						_self.loadRecord(record) ;
						
					}) ;
				}
				_self.gui.endWait() ;
			}) ;
 				
		}) ;
	} ;
	
	this.cancelInvoice = function(ev){
		_self.gui.startWait() ;
		
		
		if(!Array.isArray(ev.data)){
			ev.data = [ev.data] ;
		}
		var ids = ev.data.map(function(invoice){ return invoice.id ;}) ;
		
		
		cli("account.invoice").cancelMulti(ids, function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.doSync(function(){
				
				if(ev.source.constructor === InvoiceGridView){
					//from list, refresh list
					_self.refreshCount();
					_self.refreshList();	
				}else{
					//from form, refresh the form
					_self.doSync(function(err){
						if(err){ return _self.viewList.endWaitError(err) ; }
						
						var record = loki.daos[_self.options.model].searchFirst({id : ids[0]}) ;
						_self.currentRecord = record ;
					
						_self.loadRecord(record) ;
						
					}) ;
				}
				
				_self.gui.endWait() ;
			}) ;
		}) ;
	} ;
	

}

module.exports = new PurchaseController();
