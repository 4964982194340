var ajax = require("aetna-ajax").ajax ;

function Auth(serverUrl){
	this.login = function(login, password, callback){
        ajax(serverUrl + "/api/auth/login", {login : login, password : password}, callback);
    };
	
	this.info = function(callback){
        ajax(serverUrl + "/api/auth/info", {}, callback);
    };
	
	this.logout = function(callback){
        ajax(serverUrl + "/api/auth/logout", {}, callback);
    };
    
    this.changePassword = function(passwordOld, passwordNew, callback){
        ajax(serverUrl + "/api/auth/changePassword", 
        {passwordOld : passwordOld, passwordNew : passwordNew}, callback);
    };
}

module.exports  = Auth ;