var LokiDao = function(loki, collName){
    var _self = this ;
    
    this.coll = function(){
        return loki.collections[collName] ;
    } ;
    
    this.getByPk = function(id){
        if(typeof(id) === "string" && id.length < 10){
            id = parseInt(id,10) ;
        }
        return _self.coll().findOne({id : id});
    } ;
    
    this.getById = this.getByPk ;
    
    var translateSearch = function(search){
        var lokiSearch = [] ;
        
        Object.keys(search).forEach(function(k){
            var val = search[k] ;
            
            if(val && val.operator === "between" && Array.isArray(val.value)){
                var between1 = {} ;
                between1[k] = { $gte : val.value[0] } ;
                var between2 = {} ;
                between2[k] = { $lte : val.value[1] } ;
                lokiSearch.push(between1) ;
                lokiSearch.push(between2) ;
            }else{
                var translatedVal = val ;
                if(val && typeof(val) === "object" && val.operator){
                    var translatedOperator = val.operator ;
                    
                    switch(val.operator){
                        case "=" : 
                            translatedOperator = "$eq" ;
                        break ;
                        case ">" : 
                            translatedOperator = "$gt" ;
                        break ;
                        case ">=" : 
                            translatedOperator = "$gte" ;
                        break ;
                        case "<" : 
                            translatedOperator = "$lt" ;
                        break ;
                        case "<=" : 
                            translatedOperator = "$lte" ;
                        break ;
                        case "<>" : 
                            translatedOperator = "$ne" ;
                        break ;
                        case "in" : 
                            translatedOperator = "$in" ;
                        break ;
                        case "contains" : 
                            translatedOperator = "$contains" ;
                        break ;
                        case "notIn" : 
                            translatedOperator = "$containsNone" ;
                        break ;
                    }
                    translatedVal = {} ;
    				translatedVal[translatedOperator] = val.value ;
    			}else if(Array.isArray(val)){
                    translatedVal = { $in : val } ;
                }else if(val && typeof(val) === "object" && val.constructor === RegExp){
                    translatedVal = { $regex : val } ;
                }
                var translateSearch = {} ;
                translateSearch[k] = translatedVal ;
                lokiSearch.push(translateSearch) ;
            }
            
        }) ;
        
        return lokiSearch ;
    } ;
    
    this.search = function(search, limit, sortColumn){
        if(!sortColumn && !limit){
            return _self.coll().find({$and : translateSearch(search)}) ;
        }else{
            if(typeof(limit) === "string"){
                sortColumn = limit;
                limit = null;
            }
            var chain = _self.coll().chain()
                    .find({$and : translateSearch(search)}) ;
            if(sortColumn){
                chain = chain.simplesort(sortColumn) ;
            }
            if(limit){
                chain = chain.limit(limit) ;
            }
            return chain.data();
        }
    } ;
    
    this.searchFirst = function(search, sortColumn){
        if(!sortColumn){
            return _self.coll().findOne({$and : translateSearch(search)}) ;
        }else{
            
            var sorts = sortColumn.split(",") ;
            var coumpoundSort = [] ;
            sorts.forEach(function(s){
                s = s.trim() ;
                var splitted = s.split(" ") ;
                if(splitted.length > 1 && splitted[1].toLowerCase() === "desc"){
                    coumpoundSort.push([splitted[0], true]) ;
                }else{
                    coumpoundSort.push([splitted[0], false]) ;
                }
            }) ;
            
             var result = _self.coll().chain().find({$and : translateSearch(search)})
                  .compoundsort(coumpoundSort)
                  .limit(1)
                  .data();
            if(result.length === 0){ return null; }
            return result[0] ;
        }
    } ;
    
    this.listAll = function(sortColumn){
        if(!sortColumn){
            return _self.coll().find() ;
        }else{
            return _self.coll().chain().find()
                  .simplesort(sortColumn)
                  .data();
        }
    } ;
    
    this.countBy = function(field, search){
        var lokiSearch = {} ;
        if(search){
            lokiSearch = translateSearch(search) ;
        }
        return this.coll().chain().find(lokiSearch).mapReduce(
			function(record){ 
			    var m = {};
			    m[field] = record[field] ;
				return m ;
			}, 
			function(records){ 
				var byCount = {}; 
				records.forEach(function(d){ 
				    if(!byCount[d[field]]){ 
				   		byCount[d[field]] = 0; 
				    }
					byCount[d[field]]++;
				}); 
				return byCount ;
		} ) ;
    } ;
    
} ;

module.exports = LokiDao ;