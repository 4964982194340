var loki = require("../loki") ;
var Handlebars = require("handlebars") ;
var moment = require("moment") ;

Handlebars.registerHelper("formatDate", function(datetime, format) {
	return moment(datetime).format(format);
});

module.exports.computeRef = function(task, refDef){
    var savedTask = JSON.parse(JSON.stringify(task)) ;
		
	if(!refDef){
    	var projectSettings=null;
    	var settings = loki.daos["project.config.settings"].listAll();
    	projectSettings = settings[0] ;
	    refDef = projectSettings.AetnaDocumentReference ;
	}
	
	var employee = loki.daos["hr.employee"].searchFirst({user_id : parseInt(savedTask.user_id, 10)}) ;
	savedTask.employee = employee ;
	
	savedTask.user = loki.daos["res.users"].searchFirst({id : parseInt(savedTask.user_id, 10)}) ; 
						
	var project = loki.daos["project.project"].searchFirst({id : parseInt(savedTask.project_id, 10)}) ;
	savedTask.project = project ;
	
	savedTask.timestamp = moment.utc(savedTask.create_date).toDate() ;
	
	if(project.partner_id){
		var customer = loki.daos["res.partner"].searchFirst({id : parseInt(project.partner_id, 10)}) ;
		savedTask.customer = customer;
	}else{
		savedTask.customer = {} ;
	}
						
	var templateRef = Handlebars.compile(refDef);
	
	return templateRef(savedTask);
} ;
