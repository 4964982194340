var ContractGridView = require("../views/contract/contractGrid");
var ContractFormView = require("../views/contract/contractForm");
var InvoiceFormView = require("../views/facto/factoForm");
var factoController = require("./factoController") ;
var ScheduleGenView = require("../views/contract/scheduleGen");
var CloseContractView = require("../views/contract/closeContract");
var GenInvoiceFromSchedulesView = require("../views/contract/genInvoiceFromSchedules");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var cli = require("aetna-ajax/Cli").cli ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;
var async = require("async") ;
var moment = require("moment") ;
var numbro = require("numbro");
var numbroLangs = [require("numbro/languages/fr-FR.js")] ;
var DistantFile = require("../api/DistantFile") ;

numbroLangs.forEach(function(l){
	numbro.culture(l.langLocaleCode, l);
}) ;

function ContractController() {
	var _self = this ;

	BaseController.call(this, {
		model : "aetna.contract.contract",
		modelsToSync : ["aetna.contract.contract", 
		"aetna.contract.product",  "aetna.contract.invoice.schedule",
		"aetna.contract.invoice.schedule.line", "account.invoice"],
		socketChangeEvent : 'contractChanged',
		name : "contract",
		gridClass : ContractGridView,
		formClass : ContractFormView
	}) ;
	
	BUS.on("loaded", function(){
		_self.socket.on('contract-pdfStart', _self.onPdfContractStart);
		_self.socket.on('contract-pdfFinished', _self.onPdfContractFinished);
	}) ;
	
	_self.gui = new BaseGui() ;

	var _doSync = this.doSync ;
	this.doSync = function(forceRefresh, callback){
		if(typeof(forceRefresh) === "function"){
			callback = forceRefresh ;
			forceRefresh = false;
		}
		if(!callback){ callback = function(){}; }
		_doSync(forceRefresh, function(err){
			if(err){ return callback(err) ; }
			_self.productsById = {};
			loki.daos["product.product"].listAll().forEach(function(product){
				_self.productsById[product.id] = product ;
				var prodTemplate = loki.daos["product.template"].getById(product.product_tmpl_id) ;
				if(prodTemplate){
					Object.keys(prodTemplate).forEach(function(k){
						if(k !== "id" && k !== "$loki"){
							product[k] = prodTemplate[k] ;
						}
					}) ;
				}
			}) ;
			
			
			_self.taxesById = {} ;
			loki.daos["account.tax"].listAll().forEach(function(tax){
				_self.taxesById[tax.id] = tax ;
			}) ;
			
			callback() ;
		}) ;
	} ;
	
	
	this.onGridLoad = function(){
		_self.viewList.on("changeFilter", _self.refreshList) ;
		_self.viewList.on("createInvoice", _self.createInvoiceMulti) ;
		_self.viewList.on("genSchedule", _self.openSchedule) ;
		_self.viewList.on("validate", _self.onValidate) ;
		_self.viewList.on("close", _self.onClose) ;
		_self.viewList.on("delete", _self.onDeleteFromList) ;
		_self.viewList.on("cancel", _self.onCancel) ;
		_self.refreshCount() ;
		
	} ;
	
	var _returnToList = this.returnToList;
	this.returnToList = function(forceRefresh){
		_returnToList(forceRefresh) ;
		_self.refreshCount() ;
	} ;
	
	this.refreshCount = function(){
		var counts = loki.daos["aetna.contract.contract"].countByState() ;
		_self.viewList.loadCounts(counts) ;
	} ;
	
	this.listAll = function(){
		var filter = _self.viewList.getFilter() ;
		return loki.daos["aetna.contract.contract"].contractFilters(filter) ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
	} ;

	var _initForm = this.initForm ;	
	this.initForm = function(callback){
		_initForm(function(){
			_self.viewForm.productsById = _self.productsById ;
			_self.viewForm.on("genSchedule", _self.openSchedule) ;
			_self.viewForm.on("createInvoiceManually", _self.createInvoiceManually) ;
			_self.viewForm.on("openInvoice", _self.openInvoice) ;
			_self.viewForm.on("deleteSchedule", _self.deleteSchedule) ;
			_self.viewForm.on("createInvoice", _self.createInvoiceFromSchedule) ;
			_self.viewForm.on("actionValidate", _self.onValidate) ;
			_self.viewForm.on("actionClose", _self.onClose) ;
			_self.viewForm.on("actionCancel", _self.onCancel) ;
			_self.viewForm.on("openContractWord", _self.openContractWord) ;
			callback() ;
		}) ;
	} ;
	
	
	
	this.loadRecord = function(record){
		record.product_ids = loki.daos["aetna.contract.product"].search({contract_id : record.id}, "id") ;
		record.schedules_ids = loki.daos["aetna.contract.invoice.schedule"].search({contract_id : record.id}, "date_schedule") ;
		
		record.listInvoices = loki.daos["account.invoice"].search({AetnaContract_id : record.id}, "date_invoice desc") ;
		
		record.listInvoices = record.listInvoices.filter(function(invoice){
			var schedule = loki.daos["aetna.contract.invoice.schedule"].searchFirst({invoice_id : invoice.id}) ;
			
			invoice.strNumber = invoice.AetnaRef||"" ;
			invoice.strDateInvoice = invoice.date_invoice?moment(invoice.date_invoice).format("L"):"" ;
			invoice.strAmount = numbro(invoice.amount_untaxed).format("0,0.00") ;
			
			return !schedule ;
		}) ;
		
		var lang = storage(context().name).get("lang");
		numbro.language(lang);
		
		var hasVariableSchedules = false ;
		var totalPlanned = 0;
		var totalInvoiced = 0;
		var totalPaid = 0;
		
		record.schedules_ids.forEach(function(schedule){
			schedule.strDate = moment(schedule.date_schedule).format("L") ;
			schedule.strDateDone = schedule.date_done?moment(schedule.date_done).format("L"):"" ;
			
			schedule.lines = loki.daos["aetna.contract.invoice.schedule.line"].search({schedule_id : schedule.id}, "id") ;
			
			var productsNames = [] ;
			var total = 0 ;
			var hasVariable = false ;
			var strTotal ;
			schedule.lines.some(function(l){
				productsNames.push(l.name) ;
				if(l.price_subtotal === 0){
					hasVariable = true ; 
					hasVariableSchedules = true ; 
					return true ;
				}
				total += l.price_subtotal  ;
			}) ;
			
			
			if(hasVariable){
				if(total === 0){
					strTotal = t("contract.variable") ; 
				}else{
					strTotal = numbro(total).format("0,0.00") +" + "+t("contract.variable") ; 
				}
			}else{
				strTotal = numbro(total).format("0,0.00"); 
			}
			
			schedule.strTotal = strTotal ;
			schedule.strProducts = productsNames.join(", ") ;
			
			schedule.invoiceNumber = "" ;
			
			var invoice = null;
			if(schedule.invoice_id){
				invoice = loki.daos["account.invoice"].getByPk(schedule.invoice_id) ;
				if(invoice){
					totalInvoiced += total ;
					
					if(invoice.residual<0.01){
						totalPaid += invoice.amount_untaxed ;
					}else{
						var taxRate = invoice.amount_tax/invoice.amount_total ;
						var paid = invoice.amount_total-invoice.residual ;
						paid = paid-(paid*taxRate) ;
						totalPaid += paid ;
					}
					
					schedule.invoiceNumber = invoice.AetnaRef ;
				}
			}else{
				totalPlanned+=total ;
			}
			
			schedule.style = "" ;
			if(invoice){
				if(invoice.residual < 0.1){
					schedule.style = "success" ;
				}else if(moment(invoice.date_due).isBefore(moment())){
					schedule.style = "danger" ;
				}
			}else if (moment(schedule.date_schedule).isBefore(moment())){
				schedule.style = "warning" ;
			}
			
		}) ;
		
		if(hasVariableSchedules){
			if(totalPlanned === 0){
				record.strTotalCtr = t("contract.variable") ; 
			}else{
				record.strTotalCtr = numbro(totalPlanned).format("0,0.00") +" + "+t("contract.variable") ; 
			}
		}else{
			record.strTotalCtr = numbro(totalPlanned).format("0,0.00");
		}
		
		record.strTotalInvoiced = numbro(totalInvoiced).format("0,0.00");
		record.strTotalPaid = numbro(totalPaid).format("0,0.00");
		
		_self.currentRecord = record ;
		_self.viewForm.load(record) ;
	} ;
	
	
	
	this.onPdfContractStart = function(contract){
		if(_self.viewForm){
			_self.viewForm.onPdfContractStart(contract) ;
		}
	} ;
	
	this.onPdfContractFinished = function(contract){
		if(_self.viewForm){
			_self.viewForm.onPdfContractFinished(contract) ;
		}
	} ;
	
	this.openContractWord = function(ev){
		_self.gui.startWait() ;
		
		DistantFile.openDistantFile("aetna.contract.contract", ev.data.id, function(err){
			if(err){ return _self.gui.endWaitError(err) ;}
			_self.gui.endWait() ;
		}) ;
	} ;
	
	
	this.createInvoiceManually = function(){
		var contract = _self.currentRecord ;
		
		_self.viewForm.startWait() ;	
		factoController.initForm(function(){
			factoController.viewForm.createNew({
				partner_id : contract.partner_id 
			}) ;
			
			factoController.viewForm.fields.fields["account.invoice~AetnaContract_id"].refresh() ;
			factoController.viewForm.fields.fields["account.invoice~AetnaContract_id"].load(contract.id);
			
			factoController.viewForm.fields.fields["account.invoice~AetnaContract_id"].setReadOnly(true) ;
			factoController.viewForm.fields.fields["account.invoice~partner_id"].setReadOnly(true) ;
			
			factoController.viewForm.show() ;
			
			factoController.viewForm.removeAllListeners("returnToList") ;
			
			factoController.viewForm.on("returnToList", function(){
				factoController.viewForm.hide() ;
				_self.openRecord({data : {id : contract.id}}) ;
			}) ;
			
			_self.viewForm.endWait() ;	
			_self.viewForm.hide() ;
		});

	};
	
	this.openInvoice = function(ev){
		var invoice = ev.data ;
		invoice = loki.daos["account.invoice"].getById(invoice.id) ;
		var contract = _self.currentRecord ;
		
		_self.viewForm.startWait() ;	
		factoController.initForm(function(){
			
			factoController.currentRecord = invoice ;
					
			factoController.loadRecord(invoice) ;
			
			factoController.viewForm.show() ;
			
			factoController.viewForm.removeAllListeners("returnToList") ;
			
			factoController.viewForm.on("returnToList", function(){
				factoController.viewForm.hide() ;
				_self.openRecord({data : {id : contract.id}}) ;
			}) ;
			
			_self.viewForm.endWait() ;	
			_self.viewForm.hide() ;
		});

	};
	
	this.openSchedule = function(ev){
		var contract = _self.currentRecord ;
		if(ev.data && ev.data.contract){
			contract = ev.data.contract ;
			contract.product_ids = loki.daos["aetna.contract.product"].search({contract_id : contract.id}, "id") ;
		}
		var source = ev.source ;
		
		
		var scheduleView = new ScheduleGenView() ;
		scheduleView.productsById = _self.productsById ;
		
		scheduleView.openInPopup({
			size: BootstrapDialog.SIZE_VERY_WIDE, 
			title: t("contract.genSchedule") 
		}, function(){
			scheduleView.load(contract) ;
		});
		
		
		scheduleView.on("cancel", scheduleView.closePopup) ;
		scheduleView.on("generate", function(ev){
			_self.gui.startWait() ;
			
			var calls = [function(cb){cb() ;}] ;
			
			ev.data.schedules.forEach(function(schedule){
				calls.push(function(cb){
					cli("aetna.contract.invoice.schedule").create(schedule, cb) ;
				}) ;
			}) ;
			
			async.series(calls, function(err){
				if(err){
					return _self.gui.endWaitError(err) ;
				}
				
				_self.gui.endWait() ;
				
				scheduleView.closePopup() ;
				if(source.constructor === ContractFormView){
					_self.doSync(function(){
						var record = loki.daos[_self.options.model].searchFirst({id : contract.id}) ;
						_self.loadRecord(record) ;
					}) ;
				}else{
					_self.doSync(function(){
						_self.refreshCount() ;
						_self.refreshList() ;
					}) ;
				}
			}) ;
		}) ;
	} ;
	
	this.deleteSchedule = function(ev){
		var schedule = ev.data ;
		
		_self.viewForm.startWait() ;
		
		cli("aetna.contract.invoice.schedule").delete({id : schedule.id}, function(err){
			if(err){ return _self.viewForm.endWaitError(err); }

			_self.doSync(true, function(){
				_self.viewForm.endWait() ;
				var record = loki.daos[_self.options.model].searchFirst({id : _self.currentRecord.id}) ;
				_self.loadRecord(record) ;
			}) ;
		}) ;

	} ;
	
	
	
	this.prepareInvoiceFromSchedule = function(contract, schedule){
		var invoice = {} ;
		invoice.schedule = schedule ;
		invoice.name = schedule.name;
		invoice.partner_id = contract.partner_id ;
		invoice.partner_name = contract.partner_name ;
		invoice.date_invoice = new Date() ;
		invoice.date_due = new Date() ;
		invoice.state = "draft" ;
		invoice.invoice_line = [] ;
		if(contract.payment_type_id){
			invoice.AetnaPaymentType_id = contract.payment_type_id ;
		}
		invoice.AetnaContract_id = contract.id ;
		
		schedule.lines.forEach(function(line){
			invoice.invoice_line.push({
				product_id : line.product_id,
				name : line.name,
				price_unit : line.price_unit,
				quantity : line.quantity,
				invoice_line_tax_id : _self.productsById[line.product_id].taxes_id,
				price_subtotal : line.price_unit * line.quantity
			});
		}) ;
		return invoice ;
	} ;
	
	this.createInvoiceFromSchedule = function(ev){
		var schedule = ev.data ;
		var contract = _self.currentRecord ;
		
		var invoice = _self.prepareInvoiceFromSchedule(contract, schedule) ;
		_self.gui.startWait() ;
		var viewGenInvoice = new GenInvoiceFromSchedulesView() ;
		viewGenInvoice.productsById = _self.productsById ;
		viewGenInvoice.taxesById = _self.taxesById ;
		viewGenInvoice.openInPopup({
			size: BootstrapDialog.SIZE_VERY_WIDE, 
			title: t("contract.genInvoices") 
		}, function(){
			_self.gui.endWait() ;
			viewGenInvoice.load([invoice]) ;
		});
		
		viewGenInvoice.on("cancel", viewGenInvoice.closePopup) ;
		viewGenInvoice.on("validate", function(ev){
			_self.gui.startWait() ;
			_self.generateInvoices(ev.data.initialInvoices, ev.data.invoices, function(err){
				if(err){ return _self.gui.endWaitError(err); }
				_self.gui.endWait() ;
				viewGenInvoice.closePopup() ;
				var record = loki.daos[_self.options.model].searchFirst({id : _self.currentRecord.id}) ;
				_self.loadRecord(record) ;
			}) ;
		}) ;
	} ;
	
	this.createInvoiceMulti = function(ev){
		var contracts = ev.data ;
		
		var invoices = [] ;
		
		contracts.forEach(function(ctr){
			var notInvoicedSchedules = loki.daos["aetna.contract.invoice.schedule"].search({
				contract_id : ctr.id, invoice_id : false}, "date_schedule") ;
			if(notInvoicedSchedules.length > 0 && moment(notInvoicedSchedules[0].date_schedule).isBefore(moment())){
				notInvoicedSchedules[0].lines = loki.daos["aetna.contract.invoice.schedule.line"].search(
					{schedule_id : notInvoicedSchedules[0].id}, "id") ;
    			invoices.push(_self.prepareInvoiceFromSchedule(ctr, notInvoicedSchedules[0])) ;
    		}	
		}) ;
		
		_self.gui.startWait() ;
		var viewGenInvoice = new GenInvoiceFromSchedulesView() ;
		viewGenInvoice.productsById = _self.productsById ;
		viewGenInvoice.taxesById = _self.taxesById ;
		viewGenInvoice.openInPopup({
			size: BootstrapDialog.SIZE_VERY_WIDE, 
			title: t("contract.genInvoices") 
		}, function(){
			_self.gui.endWait() ;
			viewGenInvoice.load(invoices) ;
		});
		
		viewGenInvoice.on("cancel", viewGenInvoice.closePopup) ;
		viewGenInvoice.on("validate", function(ev){
			_self.gui.startWait() ;
			_self.generateInvoices(ev.data.initialInvoices, ev.data.invoices, function(err){
				if(err){ return _self.gui.endWaitError(err); }
				viewGenInvoice.closePopup() ;
				_self.refreshCount() ;
				_self.refreshList() ;
				_self.gui.endWait() ;
			}) ;
		}) ;
	} ;
	
	this.generateInvoices = function(initialInvoices, invoices, callback){
		var invoiceIds = [] ;
		
		var calls = [function(cb){cb();}] ;
		
		invoices.forEach(function(invoice, i){
			calls.push(function(cb){
				var thisInvoice = invoice ;
				
				var total_tax_product = 0;
				var total_tax_service = 0;
				
				thisInvoice.invoice_line.forEach(function(line){
					var product = _self.productsById[line.product_id] ;
					if(product){
						var category = loki.daos["product.category"].getById(product.categ_id) ;
						line.account_id = category.property_account_income_categ ;
						
						var lineTaxes = 0 ;
						if(product.taxes_id){
							product.taxes_id.forEach(function(taxId){
								var tax = _self.taxesById[taxId] ;
								lineTaxes += line.price_subtotal * tax.amount ;
							}) ;
						}
						if(product.type === "service"){
							total_tax_service += lineTaxes ;
						}else{
							total_tax_product += lineTaxes ;
						}
					}
				});
				
				thisInvoice.AetnaTaxProductTotal = total_tax_product ;
				thisInvoice.AetnaTaxServiceTotal = total_tax_service ;
				
				var customer = loki.daos["res.partner"].getById(thisInvoice.partner_id) ;
				if(customer){
					thisInvoice.account_id = customer.property_account_receivable ;
				}
				
				var initialInvoice = initialInvoices[i] ;
				
				
				cli("account.invoice").create(thisInvoice, function(err, savedRecord){
					if(err){ return cb(err) ; }
					
					invoiceIds.push(savedRecord.id) ;
					
					var updatedSchedule = {
						id : initialInvoice.schedule.id,
						invoice_id : savedRecord.id,
						date_done : new Date(),
						state : "done",
						lines : initialInvoice.schedule.lines
					} ;
					

					thisInvoice.invoice_line.forEach(function(line, y){
						updatedSchedule.lines[y].name = line.name;
						updatedSchedule.lines[y].quantity = line.quantity;
						updatedSchedule.lines[y].price_subtotal = line.price_subtotal;
					}) ;
					
					cli("aetna.contract.invoice.schedule").save(updatedSchedule, cb) ;

				}) ;
			}) ;
		}) ;
		
		async.parallel(calls, function(err){
			if(err){ return callback(err); }
			
			cli("account.invoice").validateMulti(invoiceIds, function(err){
				if(err){ return callback(err); }
				
				_self.doSync(function(){
					callback() ;
					window.location.href = cli().serverUrl+"/api/account.invoice/downloadPdf?id="+invoiceIds.join(",") ;
				}) ;
			}) ;
		}) ;
	} ;
	
	this.onClose = function(ev){
		var contract =  ev.data ;
		var source = ev.source ;
		
		_self.gui.startWait() ;
		var viewCloseContract = new CloseContractView() ;
		viewCloseContract.openInPopup({
			title: t("contract.closeContract") 
		}, function(){
			_self.gui.endWait() ;
		});
		
		viewCloseContract.on("cancel", viewCloseContract.closePopup) ;
		viewCloseContract.on("validate", function(ev){
			_self.gui.startWait() ;
			var date_end = ev.data.date_end ;
			
			cli("aetna.contract.contract").save({id : contract.id, state : "closed", date_end : date_end}, function(err){
				if(err){
					return _self.gui.endWaitError(err) ;
				}
				
				_self.gui.endWait() ;
				viewCloseContract.closePopup() ;
				if(source.constructor === ContractFormView){
					_self.doSync(function(){
						var record = loki.daos[_self.options.model].searchFirst({id : contract.id}) ;
						_self.loadRecord(record) ;
					}) ;
				}else{
					_self.doSync(function(){
						_self.refreshCount() ;
						_self.refreshList() ;
					}) ;
				}
			}) ;
		}) ;
	} ;
	
	this.onValidate = function(ev){
		var contract = ev.data ;
		
		_self.gui.startWait() ;
		cli("aetna.contract.contract").save({id : contract.id, state : "open"}, function(err){
			if(err){
				return _self.gui.endWaitError(err) ;
			}
			
			_self.gui.endWait() ;
			
			if(ev.source.constructor === ContractFormView){
				_self.doSync(function(){
					var record = loki.daos[_self.options.model].searchFirst({id : contract.id}) ;
					_self.loadRecord(record) ;
				}) ;
			}else{
				_self.doSync(function(){
					_self.refreshCount() ;
					_self.refreshList() ;
				}) ;
			}
		}) ;
	} ;
	
	this.onCancel = function(ev){
		var contract = ev.data ;
		
		_self.gui.startWait() ;
		cli("aetna.contract.contract").save({id : contract.id, state : "cancel"}, function(err){
			if(err){
				return _self.gui.endWaitError(err) ;
			}
			
			_self.gui.endWait() ;
			
			if(ev.source.constructor === ContractFormView){
				_self.doSync(function(){
					var record = loki.daos[_self.options.model].searchFirst({id : contract.id}) ;
					_self.loadRecord(record) ;
				}) ;
			}else{
				_self.doSync(function(){
					_self.refreshCount() ;
					_self.refreshList() ;
				}) ;
			}
		}) ;
	} ;
	
	this.onDeleteFromList = function(ev){
		var contract = ev.data ;
		
		_self.gui.startWait() ;
		cli("aetna.contract.contract").delete({id : contract.id}, function(err){
			if(err){
				return _self.gui.endWaitError(err) ;
			}
			
			_self.doSync(true, function(){
				_self.refreshCount() ;
				_self.refreshList() ;
				_self.gui.endWait() ;
			}) ;
		}) ;
	} ;
}

module.exports = new ContractController();
