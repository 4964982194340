var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var DocListView = require("../views/doc/list");
var NotifsListView = require("../views/doc/notifs");
var CreateDocView = require("../views/doc/createDoc");
var CreateRevView = require("../views/doc/createRev");
var ConfirmActionView = require("../views/doc/confirmAction");
var ShareView = require("../views/doc/share");
var DocDetailsView = require("../views/doc/docDetails");
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var cliFactory = require("aetna-ajax/Cli") ;
var cli = cliFactory.cli ;
var BaseGui = require("aetna-views/BaseGui") ;
var async = require("async") ;
var loki = require("../api/loki") ;
var DistantFile = require("../api/DistantFile") ;
var projectController = require("./projectController") ;
var ProjectTaskRef = require("../api/utils/ProjectTaskRef") ;
var mustache = require("mustache") ;

function DocController() {
    var _self = this;
    
    this.socket ;
	
	var initialized =false ;
	this.currentMode = "list" ;

    this.init = function (callback) {
    	if(initialized){ return callback(); }
    	
    	initialized = true ;
    	
		_self.gui = new BaseGui() ;
		
		BUS.on("notLogged", _self.notLogged) ;
		BUS.on("logoutDone", _self.notLogged) ;
		//BUS.on("loggedAndInitialized", _self.logged) ;
		
		BUS.on("synchroOfflineFinished", function(ev){
			if(!ev.data || !ev.data.nosync){
				_self.online() ;
			}
		}) ;
		
		BUS.on("nextRev", _self.nextRev) ;
		BUS.on("previousRev", _self.previousRev) ;
		BUS.on("openScreen", _self.onOpenScreen) ;
		BUS.on("offline", _self.reload) ;
		BUS.on("project.task-syncEnd", _self.onSyncEnd) ;
		
		
		BUS.on("loaded", function(){
			_self.socket.on('task-pdfStart', _self.onPdfStart);
			_self.socket.on('task-pdfFinished', _self.onPdfFinished);
			_self.socket.on('newTask', _self.onDocsModif);
			_self.socket.on('updateTask', _self.onDocsModif);
			_self.socket.on('newProject', _self.onNewProject);
			
			_self.socket.on('pdfFinished', _self.emitChange);
			_self.socket.on('newTask', _self.emitChange);
			_self.socket.on('updateTask', _self.emitChange);
		}) ;
		
		BUS.on("project.task-pdfStart", function(ev){
			_self.onPdfStart(ev.data) ;
		}) ;
		BUS.on("project.task-pdfFinished", function(ev){
			_self.onPdfFinished(ev.data) ;
		}) ;
		BUS.on("project.task-pdfError", function(ev){
			_self.onPdfError(ev.data) ;
		}) ;
		
		callback() ;
    };
    
    this.onOpenScreen = function onOpenScreen(ev){
		var screenName = ev.data ;
		if(ev.data.screen){
			screenName = ev.data.screen ;
		}
		
		if(screenName === "dico"){
			_self.logged() ;
		}else{
			_self.notLogged() ;
		}
	} ;
	
	this.logged = function(){
		BUS.on("backOnline", _self.logged) ;
		
		_self.gui.startWait() ;
		_self.doSync(function(){
			_self.stagesByName = storage(context().name).get("stagesByName") ;
	
			_self.viewList = new DocListView(cli().serverUrl, cli().schemas);
			
			_self.viewList.allowedProjects = projectController.listAll() ;
			_self.viewList.allEmployee = loki.daos["hr.employee"].listAll() ;
			
			_self.viewList.allCustomers = {} ;
			loki.daos["res.partner"].listAll().forEach(function(c){
				_self.viewList.allCustomers[c.id] = c ;
			}) ;
			
			_self.viewList.allUsers = {} ;
			loki.daos["res.users"].listAll().forEach(function(c){
				_self.viewList.allUsers[c.id] = c ;
			}) ;
			
			_self.viewList.allEmployees = {} ;
			loki.daos["hr.employee"].listAll().forEach(function(u){
				_self.viewList.allEmployees[u.user_id] = u ;	
			}) ;
			
			_self.viewList.allowedProjects = _self.viewList.allowedProjects.sort(function(a,b){
				if(a.partner_id === b.partner_id){
					if(a.state === "open" && b.state !== "open"){
						return -1;
					}else if(a.state === b.state ){
						return (a.AetnaCode < b.AetnaCode) ? -1 : 1;
					}else{
						return 1;
					}
				}
				
				var libCustomerA = "";
				var libCustomerB = "";
				if(a.partner_id){
					libCustomerA = _self.viewList.allCustomers[a.partner_id].ref + " - "+a.partner_name ;
				}
				if(b.partner_id){
					libCustomerB = _self.viewList.allCustomers[b.partner_id].ref + " - "+b.partner_name ;
				}
				return (libCustomerA < libCustomerB) ? -1 : 1;
			});
			
			_self.viewList.on("createDoc", _self.openCreateDoc) ;
			_self.viewList.on("createDocFrom", _self.openCreateDoc) ;
			_self.viewList.on("applyAction", _self.applyAction) ;
			_self.viewList.on("applyActionMulti", _self.applyActionMulti) ;
			_self.viewList.on("openDocWeb", _self.openDocWeb) ;
			_self.viewList.on("downloadDoc", _self.downloadDoc) ;
			_self.viewList.on("changeFilter", _self.refreshDocs) ;
			_self.viewList.on("changeProject", _self.refreshDocs) ;
			_self.viewList.on("changeProject", _self.refreshCounts) ;
			_self.viewList.on("changeUser", _self.refreshDocs) ;
			_self.viewList.on("changeUser", function(){ _self.refreshCounts() ; }) ;
			_self.viewList.on("openNotifs", _self.openNotifications) ;
			_self.viewList.on("openDocDetails", _self.openDocDetails) ;
			_self.viewList.on("createRev", _self.createRev) ;
			_self.viewList.on("share", _self.shareDoc) ;
			_self.viewList.on("follow", _self.followDoc) ;
			_self.viewList.on("unfollow", _self.unfollowDoc) ;
			_self.viewList.on("uploadFile", _self.uploadFile) ;
			_self.viewList.on("reload", _self.reload) ;
			_self.viewList.on("exportXls", _self.exportXls) ;
			
	        _self.viewList.init(function () {
	        	_self.viewList.show() ;
	        	_self.currentMode = "list" ;
				_self.refreshDb(function(){
					_self.gui.endWait() ;
					
					if(window.location.hash && window.location.hash.indexOf("#openDoc=") === 0 ){
						var id = parseInt(window.location.hash.substring("#openDoc=".length), 10) ;
						window.location.hash = "" ;
						
						_self.openDocDetails({data : {id : id} }) ;
					}
				});
			});			
		}) ;
	} ;
	
	this.emitChange = function(){
		BUS.emit("docChange") ;
	} ;
	
	this.doSync = function(forceRefresh, callback){
		if(typeof(forceRefresh) === "function"){
			callback = forceRefresh ;
			forceRefresh = false;
		}
		loki.sync(["project.project", "project.task", "project.aetnatasknotification", "ir.attachment", "hr.aetna.group",
			"aetna.modif.track", "aetna.modif.track.detail", "res.partner", "hr.employee", "project.config.settings"], forceRefresh, callback) ;
	} ;
	
	this.onDocsModif = function(){
		_self.doSync(function(err){
			if(err){
				return _self.gui.error(err) ;
			}
			_self.refreshCounts() ;	
		}) ;
	} ;
	
	this.onNewProject = function(){
		if(!_self.viewList){ return; }
		loki.sync(["project.project"], false, function(){
			_self.viewList.allowedProjects = projectController.listAll() ;
			_self.viewList.refreshItemsProject();
		}) ;
	};
	
	this.refreshDb = function(forceRefresh, callback){
		if(typeof(forceRefresh) === "function"){
			callback = forceRefresh ;
			forceRefresh = false;
		}
		if(!callback){ callback = function(){}; } 
		_self.doSync(forceRefresh, function(err){
			if(err){
				callback(err) ;
				return _self.gui.error(err) ;
			}
			_self.refreshCounts() ;	
			_self.refreshDocs() ;
			callback() ;
		}) ;
	} ;
	

	
	this.notLogged = function(){
		if(_self.viewList){
			_self.viewList.hide() ;
		}
		if(_self.viewDoc){
			_self.viewDoc.hide() ;
		}
	} ;
	
	this.refreshCounts = function(forceRefresh){
		if(!_self.viewList){ return ; }
		var stagesByName = storage(context().name).get("stagesByName") ;
		var user = storage(context().name).get("user") ;
		
		var filter = _self.viewList.getFilter() ;
		
		var countAffectedByStage = loki.daos["project.task"].countAffectedByStage(user.id, filter.user_id, filter.projects)  ;
		var countFollowed = loki.daos["project.task"].countFollowed(user.id, filter.user_id, filter.projects)  ;
		var countCreated = loki.daos["project.task"].countCreatedBy(user.id, filter.user_id, filter.projects)  ;
		var countAffectOther = loki.daos["project.task"].countAffectOther(user.id, filter.user_id, filter.projects)  ;

		var actionsByLabel = {} ;
		
		var sorted = Object.keys(stagesByName).sort(function(a, b){
			var sequenceA = stagesByName[a].sequence;
			var sequenceB = stagesByName[b].sequence;
			
			if(stagesByName[a].name === "PUBLISHED"){ sequenceA = 1000 ; }
			if(stagesByName[a].name === "DELETED"){ sequenceA = 1001 ; }
			if(stagesByName[b].name === "PUBLISHED"){ sequenceB = 1000 ; }
			if(stagesByName[b].name === "DELETED"){ sequenceB = 1001 ; }
			
			return  sequenceA - sequenceB ;
		}) ;
		
		sorted.forEach(function(n){
			var stage = stagesByName[n] ;
			if(stage.AetnaActionCategory){
				if(!actionsByLabel[stage.AetnaActionCategory]){
					actionsByLabel[stage.AetnaActionCategory] = {
						count : 0,
						stages : []
					} ;
				}
				actionsByLabel[stage.AetnaActionCategory].stages.push(stage.name) ;
				if(countAffectedByStage[stage.id] !== undefined){
					actionsByLabel[stage.AetnaActionCategory].count += countAffectedByStage[stage.id] ;
				}
			}
		}) ;
		
		var actions = Object.keys(actionsByLabel).map(function(a){ 
			return {
				label : a, 
				count : actionsByLabel[a].count, 
				active : ["PUBLISHED", "DELETED"].indexOf(stagesByName[actionsByLabel[a].stages[0]].name)===-1?"active":"",
				icon : stagesByName[actionsByLabel[a].stages[0]].AetnaActionIcon, 
				stages : actionsByLabel[a].stages 
				} ;
		}) ;
			

			
		_self.viewList.loadCounts({
			actions : actions,
			countFollowed  : countFollowed,
			countCreated  : countCreated,
			countAffectOther  : countAffectOther
		}, forceRefresh) ;
	} ;
	
	this.refreshDocs = function(ev){
		var resetExpanded = ev && ev.event === "changeFilter" ;
		
		var filter = _self.viewList.getFilter() ;
		var user = storage(context().name).get("user") ;
		
		var tasks = loki.daos["project.task"].userTaskFilters(user.id, filter.user_id, 
			filter.actions.map(function(s){ return _self.stagesByName[s].id ;}),
			filter.followedDocs,
			filter.createdBy,
			filter.affectOther,
			filter.projects
		) ;
		
		
		
		_self.viewList.load(tasks, resetExpanded) ;
		//_self.viewList.show() ;	
		_self.currentMode = "list" ;
	} ;
	
	this.onSyncEnd = function(ev){
		if(_self.viewList && _self.viewList.grid){
			_self.doSync(function(){
				var displayedRecords = _self.viewList.grid.getDisplayedRecords() ;
				displayedRecords.forEach(function(r){
					var rec = loki.daos["project.task"].getById(r.id) ;
					if(rec.write_date !== r.write_date){
						Object.keys(r).forEach(function(k){
							r[k] = rec[k] ;
						}) ;
					}
					_self.viewList.grid.refreshRow(r) ;
				}) ;
			}) ;
		}
	} ;
	
	
	
	this.openCreateDoc = function(ev){
		_self.gui.startWait() ;
		
		loki.sync(["document.aetna.category", "document.aetna.template"], function(err){
			if(err){ _self.gui.endWaitError(err) ;}
			
			var allCategories = loki.daos["document.aetna.category"].listAll() ;
			allCategories = JSON.parse(JSON.stringify(allCategories)) ;
			
			var cateTree = [] ;
			var allCates = {} ;
			while(allCategories.length>0){
				allCategories.forEach(function(c, i){
					var sub = {
							category : c,
							children : [],
							templates : []
						} ;
					if(!c.parent_id){
						cateTree.push(sub) ;
						allCates[c.id] = sub ;
						allCategories.splice(i, 1) ;
					}else if(allCates[c.parent_id]){
						allCates[c.id] = sub ;
						allCates[c.parent_id].children.push(sub) ;
						allCategories.splice(i, 1) ;
					}
				}) ;
			}
			
			var allTemplates = loki.daos["document.aetna.template"].listAll() ;
		
			_self.gui.endWait() ;
							
			allTemplates.forEach(function(template){
				if(template.category_id){
					allCates[template.category_id].templates.push(template) ;
				}
			}) ;
			
			var allProjects = {};
			projectController.listAll().forEach(function(p){
				allProjects[p.id] = p ;
			}) ;
			
			var view = new CreateDocView(cli().serverUrl, cateTree, cli().schemas);
			
			view.allProjects = allProjects ;
			view.allUsers = {} ;
			loki.daos["res.users"].listAll().forEach(function(u){
				view.allUsers[u.id] = u ;	
			}) ;
			
			view.allEmployees = {} ;
			loki.daos["hr.employee"].listAll().forEach(function(u){
				view.allEmployees[u.user_id] = u ;	
			}) ;
			
			if(ev.event === "createDocFrom"){
				view.templateType = "existing" ;
				view.existingDoc = ev.data ;
			}
			
			view.openInPopup({
				size: BootstrapDialog.SIZE_VERY_WIDE, 
				title: t("doc.createDoc") ,
				draggable: true
			});
			
			view.on("openExisting", _self.listDocsPublished) ;
	
			view.on("cancel", function (ev) {
				view.closePopup() ;
			});
	
			view.on("validate", function (ev) {
				view.startWait() ;
				
				var stagesByName = storage(context().name).get("stagesByName") ;
				
				var task = ev.data.task;
				var notifications = ev.data.notifications;
				var template = ev.data.template;
				var existingDoc = ev.data.existingDoc;
				var uploadFile = ev.data.uploadFile;
				
				var workflow = loki.daos["project.project"].getByPk(task.AetnaWorkflow_id) ;
				
				task.stage_id = workflow.AetnaStartStage_id||stagesByName["DRAFT"].id ;
				
				var userId;
				notifications.some(function(n){
					if(n.AetnaStage_id[0] === task.stage_id){
						userId = n.AetnaUser_id ;
						return true ;
					}
				}) ;
				
				task.user_id = userId ;
				
				cli("project.task").create(task, notifications,template, existingDoc, uploadFile, function(err, result){
					view.endWait() ;
					if(err){ 
						var msg = err ;
						if(err.code && err.code.error === "TEMPLATE_FAIL"){
							msg = t("doc.templateError") ;
						}
						return view.error(msg) ;
						
					}
					view.info(t("doc.createSuccess"), function(){
						_self.refreshDb() ;
						view.closePopup() ;
					}) ;
				}) ;
			});
		}) ;
	} ;
	
	this.createRev = function(event){
		if(cliFactory.getContext() === "offline"){
			return _self.gui.error(t("main.offlineNotAvailable")) ;
		}
		var task = event.data ;
		
		var notifications = loki.daos["project.aetnatasknotification"].search({AetnaTask_id : task.id}) ;
		
		var stagesByName = storage(context().name).get("stagesByName") ;
		notifications = notifications.map(function(n){
			n.stageName = stagesByName[n.AetnaStage_name].description ;
			return n;
		}) ;
		
		var originalDoc = task ;
		
		var newDoc = {} ;	
		newDoc.project_id = originalDoc.project_id ;
		newDoc.name = originalDoc.name ;
		newDoc.AetnaSubtitle = originalDoc.AetnaSubtitle ;
		newDoc.AetnaWorkflow_id = originalDoc.AetnaWorkflow_id ;
		newDoc.AetnaParentRev_id = originalDoc.id ;
		newDoc.AetnaSeq = originalDoc.AetnaSeq ;
		newDoc.AetnaSeqProject = originalDoc.AetnaSeqProject ;
		
		newDoc.AetnaRev = originalDoc.AetnaRev+1 ;
		newDoc.AetnaRev_last = originalDoc.AetnaRev_last+1 ;
		
		//create a copy to compute the same reference with only the rev changing
		var originalCopy = JSON.parse(JSON.stringify(originalDoc)) ;
		originalCopy.AetnaRev = newDoc.AetnaRev ;
		newDoc.AetnaRef = ProjectTaskRef.computeRef(originalCopy) ;
		
		cli().schemas.MAIN.tables.some(function(table){
			if(table.tableName === "project.task") {
				table.columns.forEach(function(col){
					if(col.name.indexOf("x_") === 0){
						newDoc[col.name] = originalDoc[col.name] ;
					}
				}) ;
				return true ;
			}
		}) ;
		
		var allProjects = {};
		projectController.listAll().forEach(function(p){
			allProjects[p.id] = p ;
		}) ;
			
		var view = new CreateRevView(cli().serverUrl, cli().schemas);
		view.allProjects = allProjects ;
		
		view.allUsers = {} ;
		loki.daos["res.users"].listAll().forEach(function(u){
			view.allUsers[u.id] = u ;	
		}) ;
		
		view.allEmployees = {} ;
		loki.daos["hr.employee"].listAll().forEach(function(u){
			view.allEmployees[u.user_id] = u ;	
		}) ;
		
		view.openInPopup({
			size: BootstrapDialog.SIZE_WIDE, 
			title: t("doc.revCreation") 
		}, function(){
			_self.gui.endWait() ;
			view.load(task, newDoc, notifications) ;
		});
		
		view.on("cancel", function (ev) {
			view.closePopup() ;
		});

		view.on("validate", function (ev) {
			view.startWait() ;
			
			var stagesByName = storage(context().name).get("stagesByName") ;
			
			var newTask = ev.data.task;
			var notifications = ev.data.notifications;
			var existingDoc = task.id;
			
			var workflow = loki.daos["project.project"].getByPk(newTask.AetnaWorkflow_id) ;
					
			newTask.stage_id = workflow.AetnaStartStage_id||stagesByName["DRAFT"].id ;

			var userId;
			notifications.some(function(n){
				if(n.AetnaStage_id[0] === newTask.stage_id){
					userId = n.AetnaUser_id ;
					return true ;
				}
			}) ;
			
			newTask.user_id = userId ;
			
			cli("project.task").create(newTask, notifications,undefined, existingDoc, undefined, function(err, result){
				view.endWait() ;
				if(err){ return view.error(err) ;}
				view.info(t("doc.createSuccess"), function(){
					
					if(event.source === _self.viewList){
						_self.refreshDb() ;
					}else{
						event.source.nextRev() ;
					}
					view.closePopup() ;
				}) ;
			}) ;
		});
	} ;
	
	
	this.shareDoc = function(event){
		var tasks = event.data ;
		
		if(!Array.isArray(tasks)){
			tasks = [tasks] ;
		}
		
		_self.gui.startWait() ;
			
		cli("project.task").shareDoc(tasks.map(function(task){ return task.id ;}),  function(err, result, tasksPdfOk){
			if(err){ return _self.gui.endWaitError(err) ;}
			
			_self.doSync(function(err){
				if(err){ return _self.gui.endWaitError(err) ;}	
				
				var linksByTask = [] ;

				tasks.forEach(function(task){
					var iconType = "file-o" ;
					if(task.AetnaExt){
						switch(task.AetnaExt.toLowerCase()){
							case "docx":
							iconType = "file-word-o" ;
							break;
							case "xlsx":
							iconType = "file-excel-o" ;
							break;
							case "pptx":
							iconType = "file-powerpoint-o" ;
							break;
						}
					}
					
					var links = [] ;
					var attachs = loki.daos["ir.attachment"].search({res_model : "project.task", res_id : task.id}) ;
					attachs.forEach(function(a){
						links.push({
							task: task,
							taskName : task.name,
							taskRef : task.AetnaRef,
							taskId : task.id,
							webConvert : a.AetnaConvertWeb,
							type : "main",
							id : a.id, 
							url : cli().serverUrl+"/public/share/"+a.AetnaPublicId+"/"+encodeURIComponent(a.name),
							iconType : iconType
						}) ;
						if(tasksPdfOk.indexOf(task.id) !== -1){
							var subAttachs = loki.daos["ir.attachment"].search({res_model : "ir.attachment", res_id : a.id}) ;	
							subAttachs.forEach(function(s){
								links.push({
									task: task,
									taskId : task.id,
									webConvert : s.AetnaConvertWeb,
									type : "pdf",
									id : a.id, 
									url : cli().serverUrl+"/public/share/"+s.AetnaPublicId+"/"+encodeURIComponent(s.name),
									iconType : "file-pdf-o"
								}) ;
							}) ;
						}
					}) ;
					linksByTask.push({
						task : task,
						taskName : task.name,
						taskRef : task.AetnaRef,
						taskId : task.id,
						links : links
					}) ;
				}) ;
				
				
				var view = new ShareView(cli().serverUrl, cli().schemas);

				view.openInPopup({
					size: BootstrapDialog.SIZE_WIDE, 
					title: t("doc.share") 
				}, function(){
					view.load(linksByTask) ;
					_self.gui.endWait() ;
				});
				
				
				view.on("sendMailAttach", function(ev){
					_self.gui.startWait() ;
					
					
					if(!Array.isArray(ev.data)){
						ev.data = [ev.data] ;
					}
					var tasksAndType = ev.data.map(function(li){
						return { record : li.task, type : li.type } ;
					}) ;
					
					var bodyText = t("doc.mailFileBody");
					if(ev.data.length > 1 ){
						bodyText = t("doc.mailFileBodyMany");
					}
					
					var subjectText = t("doc.mailFileSubject");
					if(ev.data.length > 1 ){
						subjectText = t("doc.mailFileSubjectMany");
					}
					
					BUS.emit("sendMailAttach", {
						model : "project.task",
						body : bodyText,
						subject : subjectText,
						docsAndType : tasksAndType,
						callback : function(err){
							if(err){ return _self.gui.endWaitError(t("doc.cantOpenOutlook", {err : err})) ;}
							_self.gui.endWait() ;
						}
					}) ;
				}) ;
				
				view.on("sendMailLink", function(ev){
					_self.gui.startWait() ;
					
					
					if(!Array.isArray(ev.data)){
						ev.data = [ev.data] ;
					}
					var url = "<br/>" ;
					
					ev.data.forEach(function(d){
						var extname = "";
						var indexExt = d.url.lastIndexOf(".") ;
						if(indexExt !== -1){
							extname = " ("+d.url.substring(indexExt+1).toUpperCase()+")" ;
						}
						url += '<a href="'+d.url+'">'+d.task.AetnaRef+" - "+d.task.name+extname+"</a><br />" ;
					}) ;
					
					var bodyText = t("doc.mailLinkBodyDesktop", {url : url});
					if(ev.data.length > 1 ){
						bodyText = t("doc.mailLinkBodyManyDesktop", {url : url});
					}
					
					var subjectText = t("doc.mailLinkSubject");
					if(ev.data.length > 1 ){
						subjectText = t("doc.mailLinkSubjectMany");
					}
					
					BUS.emit("sendMailAttach", {
						model : "project.task",
						body : bodyText,
						subject : subjectText,
						docsAndType : [],
						callback : function(err){
							if(err){ return _self.gui.endWaitError(t("doc.cantOpenOutlook", {err : err})) ;}
							_self.gui.endWait() ;
						}
					}) ;
				}) ;
				
				view.on("unshare", function(ev){
					_self.gui.startWait() ;
					
					var calls = [function(cb){ cb(); }] ;
					tasks.forEach(function(task){
						calls.push(function(cb){
							cli("project.task").unshareDoc(task.id, cb) ;
						}) ;
					}) ;
			
					async.parallel(calls, function(err){
						if(err){ return _self.gui.endWaitError(err) ;}

						_self.gui.info(t("doc.docUnshared")) ;
						
						view.closePopup() ;

						_self.gui.endWait() ;
					}) ;
				}) ;

			}) ;
		}) ;
	} ;
	
	this.followDoc = function(event){
		var task = event.data ;
		
		_self.gui.startWait() ;
		
		var user = storage(context().name).get("user") ;
			
		cli("project.task").addFollower(task.id, user.id,  function(err, result){
			if(err){ return _self.gui.endWaitError(err) ;}
			_self.gui.endWait() ;
			
			_self.gui.info(t("doc.docIsFollowed")) ;
			_self.doSync(function(){
				_self.refreshDocs() ;
			}) ;
		}) ;
	} ;
	
	this.unfollowDoc = function(event){
		var task = event.data ;
		
		_self.gui.startWait() ;
			
		var user = storage(context().name).get("user") ;
		
		cli("project.task").removeFollower(task.id, user.id,  function(err, result){
			if(err){ return _self.gui.endWaitError(err) ;}
			
			_self.gui.endWait() ;
			
			_self.gui.info(t("doc.docIsUnfollowed")) ;
			_self.doSync(function(){
				_self.refreshDocs() ;
			}) ;
		}) ;
	} ;
	
	this.listDocsPublished = function(ev){
		ev.source.startWait() ;
		_self.doSync(function(err){
			ev.source.endWait() ;
			if(err){
				return ev.source.error(err) ;
			}
			var tasks = loki.daos["project.task"].search({stage_id : _self.stagesByName["PUBLISHED"].id}) ;
			ev.source.loadDocs(tasks) ;
		}) ;
	} ;
	
	this.applyAction = function(ev){
		
		var actionCycle = loki.daos["project.task"].getActionCycle(ev.data.taskId, ev.data.action) ;
		
		if(!actionCycle){
			return ev.source.error(t("doc.invalidWorkflowAction")) ;
		}
		
		var stageDef = loki.daos["project.task.type"].getById(actionCycle.cycle.AetnaStageAfter_id) ;
		
		var subject = stageDef.AetnaNotificationSubject ;
		var body = stageDef.AetnaNotificationEmail ;
		var mail = null ;
		if(subject){
			var task =  loki.daos["project.task"].getById(ev.data.taskId) ;
			var data = {
				title : task.name,
				subtitle : task.AetnaSubtitle,
				status : stageDef.description,
				rev : task.AetnaRev,
				reference : task.AetnaRef,
				user : storage(context().name).get("user").name,
				project : task.project_name,
				link : cli().serverUrl+"#openDoc="+task.id
			} ;
			Object.keys(task).forEach(function(key){
				if(key.indexOf("x_") === 0){
					data[key.substring(2)] = task[key] ;
				}
			});
			
			subject = mustache.render(subject, data) ;
			body =  mustache.render(body, data) ;
			mail = {
				subject : subject,
				body_html : body
			} ;
		}
		
		var view = new ConfirmActionView(cli().serverUrl)  ;
		
		view.openInPopup({
				size: BootstrapDialog.SIZE_WIDE, 
				title: t("doc.applyAction") 
			}, function(){
				view.load(t("doc.areYouSureToApply", {
						action : t("doc.action."+ev.data.action),
						user : loki.daos["res.users"].getById(actionCycle.user_id).name,
						status : stageDef.description
					}),
					mail
				) ;
		}) ;
		
		view.on("validate", function(evValid){
			ev.source.startWait() ;
			DistantFile.backupDoc("project.task",ev.data.taskId, function(err){
				if(err){ 
					return ev.source.error("Error backup file "+JSON.stringify(err)) ;
				}
				cli("project.task").applyAction(ev.data.taskId, ev.data.action, evValid.data.subject, evValid.data.body_html, function(err, result){
					ev.source.endWait() ;
					view.closePopup() ;
					if(err){ 
						if(err.code === "Invalid workflow action"){
							err = t("doc.invalidWorkflowAction") ;
						}
						return ev.source.error(err) ;
					}
					if(ev.source === _self.viewList){
						_self.refreshDb() ;
					}else{
						_self.openDocDetails({data: {id : ev.data.taskId}}) ;
					}
					
				}) ;
			}) ;
			
		}) ;
	} ;
	
	this.applyActionMulti = function(ev){
		_self.viewList.startWait() ;
		
		var calls = [function(cb){ cb() ;}] ;
		
		ev.data.tasks.forEach(function(task){
			calls.push(function(cb){
				cli("project.task").applyAction(task.id, ev.data.action, null, null, cb) ;
			}) ;
		}) ;
		
		async.series(calls, function(err){
			_self.viewList.endWait() ;
			if(err){ 
				if(err.code === "Invalid workflow action"){
					err = t("doc.invalidWorkflowAction") ;
				}
				return _self.viewList.error(err) ;
			}
			
			_self.refreshDb() ;
		}) ;
	} ;
	
	this.openDocWeb = function(ev){
		_self.gui.startWait() ;
		
		DistantFile.openDistantFile("project.task", ev.data.id, function(err){
			if(err){ return _self.gui.endWaitError(err) ;}
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.uploadFile = function(ev){
		_self.gui.startWait() ;
		
		DistantFile.replaceDistantFile("project.task", ev.data.id, ev.data.file, function(err){
			if(err){ return _self.gui.endWaitError(err) ;}
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.downloadDoc = function(ev){
		cli("project.task").downloadDoc(ev.data.id) ;
	} ;
	
	
	this.openNotifications = function(ev){
		var notifications = loki.daos["aetna.modif.track"].search({res_model : "project.task", res_id : ev.data.id}) ;
			
		notifications = JSON.parse(JSON.stringify(notifications)) ;
		
		var stagesByName = storage(context().name).get("stagesByName") ;

		notifications = notifications.map(function(n){
			//"<span>Stage changed</span><div>     • <b>Stage</b>: WAIT_VALID → REWORK</div><div>     • <b>Assigned to</b>: Administrator → Olivier Oriol</div>"
			
			var details = loki.daos["aetna.modif.track.detail"].search({track_id : n.id}) ;
			
			var body = [] ;
			details.forEach(function(d){
				var thisDetail = t(d.field_name)+" : "+d.value_before+" → "+d.value_after ;
				if(d.field_name === "fields.project.task.stage_id"){
					thisDetail = t(d.field_name)+" : "+
						(stagesByName[d.value_before]?stagesByName[d.value_before].description:"")+" → "+
						(stagesByName[d.value_after]?stagesByName[d.value_after].description:"") ;
				}
				body.push(thisDetail) ;
			}) ;
			
			n.body = body.join("<br />") ;
			n.label = t(n.label) ;
			
			return n;
		}) ;
		
		var view = new NotifsListView(cli().serverUrl)  ;
		
		view.openInPopup({
				size: BootstrapDialog.SIZE_WIDE, 
				title: t("doc.notifications") 
			}, function(){
			view.load(notifications) ;
			view.show() ;
			_self.currentMode = "list" ;
		}) ;
	} ;
	
	this.openDocDetails = function(ev){
		_self.viewList.startWait() ;
		
		var task = loki.daos["project.task"].getByPk(ev.data.id) ;
		
		_self.doSync(function(err){
			if(err){ return _self.viewList.endWaitError(err) ;}
			var notifications = loki.daos["project.aetnatasknotification"].search({AetnaTask_id : ev.data.id}) ;
			
			notifications = JSON.parse(JSON.stringify(notifications)) ;
			
			var stagesByName = storage(context().name).get("stagesByName") ;
			notifications = notifications.map(function(n){
				n.stageName = stagesByName[n.AetnaStage_name].description ;
				return n;
			});
			
			
			var allProjects = {};
			projectController.listAll().forEach(function(p){
				allProjects[p.id] = p ;
			}) ;
			
			_self.viewDoc = new DocDetailsView(cli().serverUrl, cli().schemas)  ;
			
			_self.viewDoc.allProjects = allProjects ;
			_self.viewDoc.allUsers = {} ;
			loki.daos["res.users"].listAll().forEach(function(u){
				_self.viewDoc.allUsers[u.id] = u ;	
			}) ;
			
			_self.viewDoc.allEmployees = {} ;
			loki.daos["hr.employee"].listAll().forEach(function(u){
				_self.viewDoc.allEmployees[u.user_id] = u ;	
			}) ;
			
			_self.viewDoc.init(function(){
				_self.viewDoc.load(task, notifications) ;
				_self.viewDoc.show() ;
				_self.viewList.endWait() ;
				_self.viewList.hide() ;
				_self.currentMode = "details" ;
			}) ;
			
			_self.viewDoc.on("returnToList", _self.returnToList) ;
			_self.viewDoc.on("modify", _self.onModify) ;
			_self.viewDoc.on("applyAction", _self.applyAction) ;
			_self.viewDoc.on("createRev", _self.createRev) ;
			_self.viewDoc.on("openDocWeb", _self.openDocWeb) ;
			_self.viewDoc.on("downloadDoc", _self.downloadDoc) ;
			_self.viewDoc.on("openNotifs", _self.openNotifications) ;
			
		}) ;
	} ;
	
	this.onModify = function(ev){
		_self.viewDoc.startWait() ;
		var taskBefore = ev.data.taskBefore	 ;
		var task = ev.data.task;
		var notifications = ev.data.notifications;
		
		cli("project.task").update(task, notifications, function(err, result){
			_self.viewDoc.endWait() ;
			if(err){ return _self.viewDoc.error(err) ;}
			BUS.emit("project.task_modif", {before : taskBefore, after : task}) ;
			_self.viewDoc.info(t("doc.saveSuccess"), function(){
				_self.openDocDetails({data : task}) ;
			}) ;
		}) ;
	} ;
	
	this.returnToList = function(){
		_self.viewDoc.hide() ;
		_self.viewList.show() ;
		_self.currentMode = "list" ;
		_self.refreshDb() ;
	} ;
	
	this.online = function(){
		if(_self.viewList && _self.currentMode === "list"){
			_self.refreshDb() ;
		}
	} ;
	
	
	this.nextRev = function(ev){
		var doc = loki.daos["project.task"].searchFirst({AetnaParentRev_id : ev.data.doc.id}) ;
		_self.openDocDetails({data : {id : doc.id}}) ;
	} ;
	
	this.previousRev = function(ev){
		var doc = ev.data.doc ;
		
		_self.openDocDetails({data : {id : doc.AetnaParentRev_id}}) ;
	} ;
	
	this.onPdfStart = function(doc){
		if(_self.viewDoc){
			_self.viewDoc.onPdfStart(doc) ;
		}
	} ;
	
	this.onPdfFinished = function(doc){
		if(_self.viewDoc){
			_self.viewDoc.onPdfFinished(doc) ;
		}
	} ;
	
	this.onPdfError = function(){
		if(_self.viewList){
			_self.viewList.error(t("doc.cantConvertPdf")) ;
		}else if(_self.viewDoc){
			_self.viewDoc.error(t("doc.cantConvertPdf")) ;
		}
	};
	
	this.reload = function(ev){
		if(_self.viewList){
			_self.viewList.startWait() ;
			var forceRefresh = false ;
			if(ev && ev.data && ev.data.forceRefresh){
				forceRefresh = true ;
			}
			_self.refreshDb(forceRefresh, function(){
				_self.viewList.endWait() ;	
			}) ;
		}
	} ;
	
	this.exportXls = function(ev){
		cli().gridToXls(
            ev.data.columns,
            ev.data.lines,
            ev.data.columnGroups,
            t("doc.list")+".xlsx"
        ) ;
	} ;
	
}

module.exports = new DocController();
