var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var UploadField = require("aetna-views/fields/upload");


function UploadDocView(serverUrl, schemas){
    BaseView.call(this, t, "dms", "uploadDoc") ;
	
	var _self = this ;
	
	this.initialVisibility = "visible" ;
   
	this._initElements = function(callback){
		_self.uploadFile = new UploadField(t) ;
		_self.uploadFile.filedrag = _self.EL.drop ;
		_self.uploadFile.init(_self.EL.uploadFile) ;
		
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
		_self.EL.cancel.emit() ;
	};
	
	
	this.onValidate  = function(done){
		if(!_self.uploadFile.getFile()){
			_self.error(t("doc.pleaseChooseFile")) ;
			return done(false) ;
		}
		
		_self.emit("validate", { 
			uploadFile : _self.uploadFile.getFile()
		}) ;
	} ;
}

module.exports = UploadDocView;
