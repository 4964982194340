var pkg = require("../package.json") ;
var Parameters = require("aetna-views/Parameters");


var baseUrl = Parameters.get("url") || window.location.origin;

console.log("base url", baseUrl) ;

if(!Parameters.get("url") && window.location.origin === "file://"){
	//desktop
	if(pkg.production){
		baseUrl = "https://ads.pro.aetna-softwares.com";
	}else{
		baseUrl = "http://adspro.local.test:3000" ;
	}
}

var context = require("aetna-views/context").init(pkg, baseUrl);
var storage = require("aetna-storage").storage(context().name);



var mainController = require("./controllers/mainController") ;
var loginController = require("./controllers/loginController") ;
var docController = require("./controllers/docController") ;
var offlineController = require("./controllers/offlineController") ;
var desktopController = require("./controllers/desktopController") ;
var customerController = require("./controllers/customerController") ;
var userController = require("./controllers/userController") ;
var templateController = require("./controllers/templateController") ;
var invoiceTemplateController = require("./controllers/invoiceTemplateController") ;
var refundTemplateController = require("./controllers/refundTemplateController") ;
var templateTypeController = require("./controllers/templateTypeController") ;
var taskStageController = require("./controllers/taskStageController") ;
var workflowController = require("./controllers/workflowController") ;
var projectController = require("./controllers/projectController") ;
var projectSettingsController = require("./controllers/projectSettingsController") ;
var projectCustomFieldController = require("./controllers/projectCustomFieldController") ;
var factoController = require("./controllers/factoController") ;
var refundController = require("./controllers/refundController") ;
var productController = require("./controllers/productController") ;
var payTypeController = require("./controllers/payTypeController") ;
var accountController = require("./controllers/accountController") ;
var invoiceSettingsController = require("./controllers/invoiceSettingsController") ;
var fiscalYearController = require("./controllers/fiscalYearController") ;
var remindSettingsController = require("./controllers/remindSettingsController") ;
var contractController = require("./controllers/contractController") ;
var contractTemplateController = require("./controllers/contractTemplateController") ;
var accountingVATController = require("./controllers/accountingVATController") ;
var accountingExportController = require("./controllers/accountingExportController") ;
var accountingImportController = require("./controllers/accountingImportController") ;
var companyController = require("./controllers/companyController") ;
var hrGroupController = require("./controllers/hrGroupController") ;
var hrDepartmentController = require("./controllers/hrDepartmentController") ;
var statsInvoicesController = require("./controllers/statsInvoicesController") ;
var purchaseController = require("./controllers/purchaseController") ;
var saleController = require("./controllers/saleController") ;
var saleOrderTemplateController = require("./controllers/saleOrderTemplateController") ;
var saleSettingsController = require("./controllers/saleSettingsController") ;
var taxController = require("./controllers/taxController") ;
var DmsController = require("./controllers/DmsController") ;
var dmsInvoiceController = new DmsController("aetna.dms.scan.invoice", "dmsInvoice") ;
var Cli = require("./api/cli");
var DistantFile = require("./api/DistantFile");
var OfflineCli = require("./api/offline/OfflineCli");
var cli = require("aetna-ajax/Cli") ;
var EventBus = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;
var LibsLoader = require("aetna-views/LibsLoader") ;
var loki = require("./api/loki") ;
var ProgressView = require("./views/main/progress") ;
var t = require("aetna-views/locales/tr").t ;

var async = require("async");
var moment = require("moment");
require("moment/locale/fr") ;

var localesInit = require("aetna-views/locales/init") ;

var gui = new BaseGui() ;


function loadCli(callback){
	localesInit({defaultLang : "fr-FR", moment : moment}, function(err, t){
        if(err){
            console.log("can't initialyze locales", err) ;
        }
		
		cli.initCli(new Cli(baseUrl), function (err, onlineCli) {
			if (err) {
				console.log("ERROR ", err);
				
				//can't init the online cli, start in offline mode
				cli.setContext("offline");
			}
			
			DistantFile.setCli(cli) ;
			
			// if(!err){
			// 	storage.set("schemas", onlineCli.schemas);
			// }
			
			cli.initCli(new OfflineCli(baseUrl), "offline", function (err, offlineCli) {
				if (err) {
					console.log("ERROR ", err);
				}
				
				callback(err,onlineCli, offlineCli) ;
			});
		});
  }) ;
}

function main(callback) {
	
	//clean old unused storage keys (to avoid quota exceeded)
	storage.remove("schemas") ;
	
	//gui.startWait() ;
	
	//load CSS theme
	var parser = document.createElement('a');
	parser.href = baseUrl;
	
	parser.hostname; // => "example.com"
	
	var dbName = parser.hostname.substring(0, parser.hostname.indexOf(".")) ;

	var libsLoader = new LibsLoader.Loader({basePath : "themes/"}) ;
	libsLoader.load(dbName+"/css/theme.css") ;
	
	libsLoader.load(dbName+"/script.js") ;
	
	
	loadCli(function(err, onlineCli, offlineCli){
		if(err){
            console.log("error when loading cli", err) ;
        }
        
        var socket = io.connect(baseUrl);
						
		socket.on('disconnect', function(data) {
		   //EventBus.EMIT("disconnected") ;
		   if(cli.getContext() !== "offline"){
		   		cli.setContext("offline"); //switch to offline mode
		   		EventBus.EMIT("offline") ;
		   }
		});
		socket.on('connect', function(data) {
		   //EventBus.EMIT("connected") ;
		   if(cli.getContext() !== "normal"){
		   		if(!onlineCli){
		   			//no online cli available (= we started in offline mode), retry to init the cli
			   		loadCli(function(err, onlineCli){
			   			if(onlineCli){
			   				//init worked, go to normal mode
					   		cli.setContext("normal");
					   		EventBus.EMIT("online") ;		
			   			}
			   		}) ;	
		   		}else{
			   		cli.setContext("normal"); //go back to normal mode
			   		EventBus.EMIT("online") ;
		   		}
		   		
		   }
		});
		
	
		
		docController.socket = socket ;
		customerController.socket = socket ;
		userController.socket = socket ;
		templateController.socket = socket ;
		invoiceTemplateController.socket = socket ;
		refundTemplateController.socket = socket ;
		templateTypeController.socket = socket ;
		taskStageController.socket = socket ;
		workflowController.socket = socket ;
		projectController.socket = socket ;
		projectSettingsController.socket = socket ;
		projectCustomFieldController.socket = socket ;
		factoController.socket = socket ;
		refundController.socket = socket ;
		productController.socket = socket ;
		payTypeController.socket = socket ;
		accountController.socket = socket ;
		invoiceSettingsController.socket = socket ;
		fiscalYearController.socket = socket ;
		remindSettingsController.socket = socket ;
		contractController.socket = socket ;
		contractTemplateController.socket = socket ;
		accountingVATController.socket = socket ;
		accountingExportController.socket = socket ;
		accountingImportController.socket = socket ;
		companyController.socket = socket ;
		hrGroupController.socket = socket ;
		hrDepartmentController.socket = socket ;
		statsInvoicesController.socket = socket ;
		purchaseController.socket = socket ;
		saleController.socket = socket ;
		saleOrderTemplateController.socket = socket ;
		saleSettingsController.socket = socket ;
		taxController.socket = socket ;
		dmsInvoiceController.socket = socket ;
		
		
		async.series([
			loginController.init,
			mainController.init,
			docController.init,
			desktopController.init,
			offlineController.init,
			customerController.init,
			userController.init,
			templateController.init,
			invoiceTemplateController.init,
			refundTemplateController.init,
			templateTypeController.init,
			workflowController.init,
			projectController.init,
			projectSettingsController.init,
			projectCustomFieldController.init,
			taskStageController.init,
			factoController.init,
			refundController.init,
			productController.init,
			payTypeController.init,
			accountController.init,
			invoiceSettingsController.init,
			fiscalYearController.init,
			remindSettingsController.init,
			contractController.init,
			contractTemplateController.init,
			accountingVATController.init,
			accountingExportController.init,
			accountingImportController.init,
			companyController.init,
			hrGroupController.init,
			hrDepartmentController.init,
			statsInvoicesController.init,
			purchaseController.init,
			saleController.init,
			saleOrderTemplateController.init,
			saleSettingsController.init,
			taxController.init,
			dmsInvoiceController.init
		], function () {
			EventBus.EMIT("loaded") ;
			//gui.endWait() ;
			
			if(!onlineCli){
		   		cli.setContext("offline"); //switch to offline mode
		   		EventBus.EMIT("offline") ;
			}

			if(callback){
				callback(EventBus, cli, gui, storage, loki, DistantFile) ;
				var progressPopup = new ProgressView() ;
				progressPopup.openInPopup({
					size: BootstrapDialog.SIZE_SMALL, 
					title: t("main.checkUpdate") ,
					closable: false,
					closeByBackdrop: false,
					closeByKeyboard: false,
				}) ;
				DistantFile.checkUpdate(function(){
					progressPopup.closePopup() ;
				}, function(progress, total){
					var percent = 0 ;
					if(total > 0){
						percent = Math.round(100*progress / total);
					}
					progressPopup.setProgress(percent) ;
				}) ;
			}

			
			
		});
        
	}) ;
}

module.exports = main;