var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

var model = "hr.aetna.group" ;
var moduleName = "hrGroup" ;

function HrGroupGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"name",
			"description"
    	],
	}) ;
}

function HrGroupFormView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		member_ids : []
    	}
	}) ;
	
	
}

module.exports.Grid = HrGroupGridView;
module.exports.Form = HrGroupFormView;
