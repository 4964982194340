var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineAccountInvoiceAetnaTvaExport(){
	OfflineCrudCli.call(this, "account.invoice.aetna.tvaexport");
	
	    
	this.doExport = function(ids, callback){
 	     return callback(t("main.offlineNotAvailable")) ;
    };
    
}

module.exports = OfflineAccountInvoiceAetnaTvaExport ;