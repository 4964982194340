var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var loki = require("../../api/loki.js") ;
var PdfView = require("aetna-views/views/pdf/pdf") ;
var t = require("aetna-views/locales/tr").t ;

var model = "sale.order" ;
var moduleName = "sale" ;

function SaleGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"AetnaRef",
			"date_order",
			"partner_id",
			"client_order_ref",
			"amount_untaxed",
			"amount_total",
			"state"
    	],
    	filters : [
    		{
    			code : "draft",
    			label : t("fields.values.sale.order.state.draft"),
    			icon : "fa-pencil",
    			color : "text-default"
    		},
    		{
    			code : "sent",
    			label : t("fields.values.sale.order.state.sent"),
    			icon : "fa-paper-plane",
    			color : "text-default"
    		},
    		{
    			code : "progress",
    			label : t("fields.values.sale.order.state.progress"),
    			icon : "fa-check-square",
    			color : "text-success"
    		},
    		{
    			code : "manual",
    			label : t("fields.values.sale.order.state.manual"),
    			icon : "fa-eur",
    			color : "text-primary"
    		},
    		{
    			code : "done",
    			label : t("fields.values.sale.order.state.done"),
    			icon : "fa-archive",
    			color : "text-muted"
    		},
    		{
    			code : "cancel",
    			label : t("fields.values.sale.order.state.cancel"),
    			icon : "fa-trash",
    			color : "text-danger"
    		}
    	]
	}) ;
}

function SaleFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		state : "draft",
    		date_order : new Date(),
    		order_line : [{}]
    	},
    	firstField : "sale.order~client_order_ref"
	}) ;
	
	this.customHeaderButtons = '<div class="sale_saleForm__actionForm">'+
	'<button class="btn btn-sm btn-primary pull-left sale_saleCreatePdf" id="actionCreatePdf">'+
			'<i class="fa fa-file-pdf-o"></i> '+t("sale.createPdf")+'</button>'+
	'<button class="btn btn-sm btn-primary pull-left sale_saleSend" id="actionSend">'+
			'<i class="fa fa-paper-plane"></i> '+t("sale.markAsSent")+'</button>'+
	'<button class="btn btn-sm btn-success pull-left sale_saleAccept" id="actionAccept">'+
			'<i class="fa fa-check-square"></i> '+t("sale.markAsAccept")+'</button>'+
	'<button class="btn btn-sm btn-primary pull-left sale_saleCanBeInvoice" id="actionCanBeInvoice">'+
			'<i class="fa fa-eur"></i> '+t("sale.markAsCanBeInvoice")+'</button>'+
	'<button class="btn btn-sm btn-primary pull-left sale_saleCreateInvoice" id="actionCreateInvoice">'+
			'<i class="fa fa-cog"></i> '+t("sale.markAsCreateInvoice")+'</button>'+
	'<button class="btn btn-sm btn-danger pull-left sale_saleCancel" id="actionCancel">'+
			'<i class="fa fa-cog"></i> '+t("sale.markAsCancel")+'</button>'+
	'</div>' ;
	
	this.on("initDone", function(){
		_self.templateLines = new TemplateFields();
		
		_self.templateLines.init(_self.EL.lines, _self.fields) ;
		
		_self.templateLines.on("lineChange", _self.onLineChange) ;
		
		_self.pdfView = new PdfView(t) ;
		_self.pdfView.init(_self.EL.pdfViewer) ;
		
		_self.EL.actionCreatePdf.addEventListener("click", _self.onCreatePdf) ;
		_self.EL.printAction.addEventListener("click", _self.onPrintContract) ;
		_self.EL.openWord.addEventListener("click", _self.editContract) ;
		
		_self.EL.actionSend.addEventListener("click", function(){_self.doAction("send")}) ;
		_self.EL.actionAccept.addEventListener("click", function(){_self.doAction("accept")}) ;
		_self.EL.actionCanBeInvoice.addEventListener("click", function(){_self.doAction("canBeInvoice")}) ;
		_self.EL.actionCreateInvoice.addEventListener("click", function(){_self.doAction("createInvoice")}) ;
		_self.EL.actionCancel.addEventListener("click", function(){_self.doAction("cancel")}) ;
	}) ;
	
	var _setReadOnly = this.setReadOnly ;
	this.setReadOnly= function(readOnly){
		_setReadOnly(readOnly) ;
		_self.templateLines.setReadOnly(readOnly) ;
	} ;
	
	var _load = this.load ;
	this.load = function(item, attach){
		_load(item) ;
		
		_self.templateLines.load(item) ;
		
		_self.attach = attach ;
		if(attach){
			_self.pdfView.showPdf(serverUrl+"/api/sale.order/downloadPdf?id="+item.id) ;
			$(_self.container).addClass("hasAttach");
		}else{
			$(_self.container).removeClass("hasAttach");
		}
		
	} ;
	
	var _getValues = this.getValues;
	this.getValues = function(){
		var values = _getValues() ;
		
		var lines = _self.templateLines.val().order_line ;
		values.recordToSave.order_line = lines.slice(0,lines.length-1) ;
		delete values.recordToSave.name ;//remove to be computed by odoo
		
		return values ;
	} ;
	
	
	this.onLineChange = function(ev){
		var line = ev.data.line ;
		if(ev.data.col === "product_id"){
			if(line.product_id){
				var product = loki.daos["product.product"].getById(line.product_id) ;
				
				if(product){
					if(!line.name){
						line.name = product.name ;
					}
					line.price_unit = product.lst_price ;
					line.tax_id = product.taxes_id ;
					
					_self.templateLines.refreshLine(ev.data.def) ;
				}
			}
		}else if(ev.data.col === "product_uom_qty" || ev.data.col === "price_unit"){
			if(line.product_uom_qty && line.price_unit){
				line.price_subtotal = line.product_uom_qty * line.price_unit ;
				_self.templateLines.refreshLine(ev.data.def) ;
			}
		}
		
		var lines = _self.templateLines.val() ;
		
		var total_untaxed = 0;
		var total_tax = 0;
		var total_total = 0;
		
		lines.order_line.forEach(function(line){
			if(line.price_subtotal){
				total_untaxed += line.price_subtotal ;
				
				if(line.product_id){
					var product = loki.daos["product.product"].getById(line.product_id) ;
					var lineTaxes = 0 ;
					if(product.taxes_id){
						product.taxes_id.forEach(function(taxId){
							var tax = loki.daos["account.tax"].getById(taxId) ;
							lineTaxes += line.price_subtotal * tax.amount ;
						}) ;
					}
					
					total_tax += lineTaxes ;
				}
			}
		}) ;
		
		total_total = total_untaxed + total_tax ;
		
		_self.fields.fields["sale.order~amount_untaxed"].load(total_untaxed) ;
		_self.fields.fields["sale.order~amount_tax"] .load(total_tax) ;
		_self.fields.fields["sale.order~amount_total"].load(total_total) ;
		
		if(!line.product_uom_qty){
			setTimeout(function(){
				_self.templateLines.focus(ev.data.def, "product_uom_qty") ;
			}, 1) ;
		}
	} ;
	
	this.onCreatePdf = function(){
		_self.confirm(t("sale.confirmCreatePdf"), function(yes){
			if(yes){
				_self.emit("createPdf", _self.item) ;
			}
		}) ;
	} ;
	
	this.onPrintContract = function(){
		window.location.href = serverUrl+"/api/sale.order/downloadPdf?id="+_self.item.id ;
	} ;
	
	this.editContract = function(){
		_self.emit("openContractWord",_self.item) ;
	} ;
	
	this.onPdfStart = function(item){
		if(_self.item && item && item.id == _self.item.id){ // /!\ compare string and number
			_self.pdfView.startWait() ;
		}
	} ;
	
	this.onPdfFinished = function(item){
		if(_self.item && item && item.id == _self.item.id){ // /!\ compare string and number
			_self.pdfView.showPdf(serverUrl+"/api/sale.order/downloadPdf?id="+item.id) ;
			_self.pdfView.endWait() ;
		}
	} ;
	
	var _displayButtons = this.displayButtons;
	this.displayButtons = function(){
		_displayButtons() ;
		if(_self.item.state !== "draft"){
			_self.EL.actionSend.style.display = "none" ;
		}
		if(_self.item.state !== "sent"){
			_self.EL.actionAccept.style.display = "none" ;
		}
		if(_self.item.state !== "progress"){
			_self.EL.actionCanBeInvoice.style.display = "none" ;
		}
		if(_self.item.state !== "manual"){
			_self.EL.actionCreateInvoice.style.display = "none" ;
		}
		if(["draft", "sent", "progress", "manual"].indexOf(_self.item.state) === -1){
			_self.EL.actionCancel.style.display = "none" ;
		}
	} ;
	
	this.doAction = function(action){
		_self.confirm(t("sale.confirm_"+action), function(yes){
			if(yes){
				_self.emit(action, _self.item) ;
			}
		}) ;
	}
	
}

module.exports.Grid = SaleGridView;
module.exports.Form = SaleFormView;
