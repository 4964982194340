var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var SyncView = require("../views/desktop/sync");
var ConflictView = require("../views/desktop/conflict");
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;

function DesktopController() {
    var _self = this;
	
	var initialized = false ;
    this.init = function (callback) {
    	if(initialized){ return callback(); }
    	
    	initialized = true ;
		
		if(window.isDesktop){
			_self.syncView = new SyncView() ;
			
			BUS.on("project.task-syncStart", _self.syncStart) ;
			BUS.on("project.task-syncEnd", _self.syncEnd) ;
			BUS.on("project.task-syncError", _self.syncError) ;
			BUS.on("project.task-syncProgress", _self.syncProgress) ;
			BUS.on("synchroOfflineStart", _self.syncStart) ;
			BUS.on("synchroOfflineFinished", _self.syncEnd) ;
			BUS.on("synchroOfflineProgress", _self.syncProgress) ;
			BUS.on("synchroError", _self.syncError) ;
			BUS.on("project.task-synchroError", _self.syncError) ;
			BUS.on("project.task-conflict", _self.onConflict) ;
			
			_self.syncView.init(function(){
				callback() ;	
			}) ;
		}else{
			callback() ;
		}
    };
	
	this.syncStart = function(ev){
		_self.syncView.syncStart() ;
	} ;
	
	this.syncEnd = function(ev){
		_self.syncView.syncEnd() ;
	} ;
	
	this.syncError = function(ev){
		_self.syncView.syncError() ;
	} ;
	
	this.syncProgress = function(ev){
		_self.syncView.setProgress(ev.data.total, ev.data.progress) ;
	}
	
	this.onConflict = function(ev){
		var conflictView = new ConflictView() ;
		conflictView.openInPopup({
				size: BootstrapDialog.SIZE_VERY_WIDE, 
				title: t("desktop.conflict") ,
				closable: false,
	            closeByBackdrop: false,
	            closeByKeyboard: false,
			}, function(){
				
			conflictView.load(ev.data.local, ev.data.server, ev.data.hashLastDownload, ev.data.hashLastTime) ;
		}) ;
		
		conflictView.on("openLocal", function(){
			BUS.emit("openLocal", {
				model : "project.task",
				id : ev.data.local.model.id
			}) ;
		}) ;
		conflictView.on("openServer", function(){
			BUS.emit("openServer", {
				model : "project.task",
				id : ev.data.server.model.id
			}) ;
		}) ;
		
		conflictView.on("useLocal", function(){
			ev.data.callback("upload") ;
			conflictView.closePopup() ;
			
		}) ;
		
		conflictView.on("useServer", function(){
			ev.data.callback("download") ;
			conflictView.closePopup() ;
		}) ;
		
	} ;
	
}

module.exports = new DesktopController();
