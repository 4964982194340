var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineAccountInvoiceAetnaRemindSettings(serverUrl){
	OfflineCrudCli.call(this, "account.invoice.aetna.remind.settings");
	
	this.create = function(remindSettings, uploadFile, callback, callbackProgress){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.update = function(remindSettings, uploadFile, callback, callbackProgress){
 	     return callback(t("main.offlineNotAvailable")) ;
    };
    
	this.getWebDavDoc = function(id, callback){
 	     return callback(t("main.offlineNotAvailable")) ;
    };
}

module.exports = OfflineAccountInvoiceAetnaRemindSettings ;