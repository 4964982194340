var InvoiceGridView = require("../views/facto/factoGrid");
var InvoiceFormView = require("../views/facto/factoForm");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var cli = require("aetna-ajax/Cli").cli ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;
var moment = require("moment") ;
var DistantFile = require("../api/DistantFile") ;
var async = require("async") ;

function FactoController() {
	var _self = this ;

	BaseController.call(this, {
		model : "account.invoice",
		modelsToSync : ["account.tax", "account.journal", "account.invoice", 
		"account.invoice.line",  "account.invoice.aetna.remind",
		"account.move.line", "account.account", "account.config.aetna.settings", 
		"sale.order", "sale.order.line", "res.currency", "product.product",
		"product.template", "product.category", "res.partner"],
		socketChangeEvent : 'invoiceChanged',
		name : "facto",
		gridClass : InvoiceGridView,
		formClass : InvoiceFormView
	}) ;
	
	_self.gui = new BaseGui() ;
	
	BUS.on("loaded", function(){
		_self.socket.on('remind-pdfStart', _self.onPdfRemindStart);
		_self.socket.on('remind-pdfFinished', _self.onPdfRemindFinished);
	}) ;
	
	this.onGridLoad = function(){
		_self.viewList.allReminds = loki.daos["account.invoice.aetna.remind.settings"].listAll("sequence") ;
		
		_self.viewList.on("changeFilter", _self.refreshList) ;
		_self.viewList.on("validate", _self.validateInvoice) ;
		_self.viewList.on("genRemind", _self.genRemind) ;
		_self.viewList.on("cancel", _self.cancelInvoice) ;
		_self.viewList.on("refund", _self.refundInvoice) ;
		_self.viewList.on("searchForPay", _self.searchForPay) ;
		_self.viewList.on("validatePay", _self.validatePay) ;
		
		_self.refreshCount() ;	
	} ;
	
	var _returnToList = this.returnToList;
	this.returnToList = function(forceRefresh){
		_returnToList(forceRefresh) ;
		_self.refreshCount() ;
	} ;
	
	this.refreshCount = function(){
		var counts = loki.daos["account.invoice"].countByState("out_invoice") ;
		_self.viewList.loadCounts(counts) ;
	} ;
	
	this.listAll = function(){
		var filter = _self.viewList.getFilter() ;
		filter.type = "out_invoice" ;
		return loki.daos["account.invoice"].invoiceFilters(filter) ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
	} ;

	var _initForm = this.initForm ;	
	this.initForm = function(callback){
		_initForm(function(){
			
			_self.doInitFormData(_self.viewForm) ;
			
			_self.viewForm.on("validate", _self.validateInvoice) ;
			_self.viewForm.on("cancel", _self.cancelInvoice) ;
			_self.viewForm.on("openRemindWord", _self.openRemindWord) ;
			_self.viewForm.on("genRemind", _self.genRemind) ;
			_self.viewForm.on("useRefund", _self.useRefund) ;
			_self.viewForm.on("refund", _self.refundInvoice) ;
			
			callback() ;
		}) ;
	} ;
	
	this.doInitFormData = function(view){
		_self.productsById = {} ;
		view.productsById = _self.productsById ;
		loki.daos["product.product"].listAll().forEach(function(product){
			view.productsById[product.id] = product ;
			var prodTemplate = loki.daos["product.template"].getById(product.product_tmpl_id) ;
			if(prodTemplate){
				Object.keys(prodTemplate).forEach(function(k){
					if(k !== "id" && k !== "$loki"){
						product[k] = prodTemplate[k] ;
					}
				}) ;
			}
		}) ;
		view.taxesById = {} ;
		loki.daos["account.tax"].listAll().forEach(function(tax){
			view.taxesById[tax.id] = tax ;
		}) ;
		view.options.newRecord.currency_id = loki.daos["res.currency"].searchFirst({name : "EUR"}).id ;
		
		
		var invoiceLastDate = loki.daos["account.invoice"].searchFirst({state : {
				operator : "notIn", 
				value : ["draft", "cancel"] }, type: "out_invoice"}, "date_invoice desc") ;
		view.lastInvoiceDate = invoiceLastDate?invoiceLastDate.date_invoice:moment("2000-01-01").toDate() ; ;
		
		var settings = loki.daos["account.config.aetna.settings"].searchFirst({}) ;
		var dueType = settings.AetnaDueType ;
		var dueDelay = settings.AetnaDueDelay ;
		if(!dueType){
			dueType = "immediate" ;
		}
		if(!dueDelay){
			dueDelay = 0 ;
		}
		
		switch(dueType){
			case "immediate" : 
				view.options.newRecord.date_due = new Date() ;
				break;
			case "delay" : 
				view.options.newRecord.date_due = moment().add(dueDelay, 'days').toDate() ;
				break;
			case "delayEndOfMonth" : 
				view.options.newRecord.date_due = moment().add(dueDelay, 'days').endOf("month").toDate() ;
				break;
		}
		
	} ;
	
	this.loadRecord = function(record){
		record.invoice_line = loki.daos["account.invoice.line"].search({invoice_id : record.id}, "id") ;
		
		record.payments = [] ;
		if(record.payment_ids && record.payment_ids.length > 0){
			record.payments = loki.daos["account.move.line"].search({id : { operator : "in", value: record.payment_ids}}, "id") ;
		}
		
		
		record.refunds = [] ;
		if(record.state === "open"){
			record.refunds = loki.daos["account.invoice"].search({type : "out_refund", partner_id : record.partner_id, state : "open"}, "id") ;
		}
		
		record.reminds = [] ;
		if(record.remind_ids && record.remind_ids.length > 0){
			record.reminds = loki.daos["account.invoice.aetna.remind"].search({id : {operator : "in", value : record.remind_ids}}, "id") ;
		}
		
		record.nextRemindDate = null ;
		
		if(record.state !== "draft" && record.state !== "cancel"){
			var nextRemindSequence = (record.AetnaLastRemindNumber||0)+1 ;
			var remindSettings = loki.daos["account.invoice.aetna.remind.settings"].searchFirst({sequence : nextRemindSequence}) ;
			if(remindSettings){
				var startDate = record.date_due ;
				record.nextRemindDate = moment(startDate).add(remindSettings.delay, 'days').format("L") ;
			}
		}else{
			record.stateDraftCancel = true ;
		}
		_self.viewForm.load(record) ;
	} ;
	
	this.processValues = function(data, callback){
		data.recordToSave.invoice_line.forEach(function(line){
			var product = _self.productsById[line.product_id] ;
			if(product){
				line.account_id = product.property_account_income ;
				if(!line.account_id){
					var category = loki.daos["product.category"].getById(product.categ_id) ;
					line.account_id = category.property_account_income_categ ;
				}
			}
		});
		
		data.recordToSave.journal_id = loki.daos["account.journal"].searchFirst({type : "sale"}).id ;
		
		var customer = loki.daos["res.partner"].getById(data.recordToSave.partner_id) ;
		if(customer){
			data.recordToSave.account_id = customer.property_account_receivable ;
		}
		
		callback() ;
	} ;
	
	this.validateInvoice = function(ev){
		_self.gui.startWait() ;
		
		
		if(!Array.isArray(ev.data)){
			ev.data = [ev.data] ;
		}
		
		
		var ids = ev.data.map(function(invoice){ return invoice.id ;}) ;
		
		
		var invoiceLastDate = loki.daos["account.invoice"].searchFirst({state : {
			operator : "notIn", 
			value : ["draft", "cancel"] }, type: "out_invoice"}, "date_invoice desc") ;
		var hasWrongDate = ids.some(function(id){
			var invoice = loki.daos["account.invoice"].getById(id) ;
			if(invoiceLastDate && moment(invoice.date_invoice).isBefore(moment(invoiceLastDate.date_invoice), 'day')){
				hasWrongDate = true ;
				_self.gui.endWaitError(t("facto.dateMustBeAfter", {lastInvoiceDate : moment(_self.lastInvoiceDate).format("L")})) ;
				return true ;
			}
		}) ;
		
		if(hasWrongDate){ return ; }
		
		
		
		cli("account.invoice").validateMulti(ids, function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			cli("account.invoice").computeTaxExport(ids, function(err){
				
				if(err){ return _self.gui.endWaitError(err); }
				
				_self.doSync(function(){
				
					if(ev.source.constructor === InvoiceGridView){
						//from list, refresh list
						_self.refreshCount();
						_self.refreshList();	
					}else{
						//from form, refresh the form
						_self.doSync(function(err){
							if(err){ return _self.viewList.endWaitError(err) ; }
							
							var record = loki.daos[_self.options.model].searchFirst({id : ids[0]}) ;
							_self.currentRecord = record ;
						
							_self.loadRecord(record) ;
							
						}) ;
					}
					
					
					_self.gui.endWait() ;
					window.location.href = cli().serverUrl+"/api/account.invoice/downloadPdf?id="+ids.join(",") ;
				}) ;
 				
			});
		}) ;
	} ;
	
	this.genRemind = function(ev){
		_self.gui.startWait() ;
		
		
		if(!Array.isArray(ev.data)){
			ev.data = [ev.data] ;
		}
		var remindsTodo = ev.data.map(function(invoice){ 
			var remindToDo = invoice.remindToDo ;
			if(!remindToDo){
				remindToDo = invoice.AetnaLastRemindNumber + 1 ;
			}
			var otherInvoicesToRemind = invoice.otherInvoicesToRemind ;
			if(!otherInvoicesToRemind){
				otherInvoicesToRemind = loki.daos["account.invoice"]
					.invoiceFilters({ type :"out_invoice", toRemind : true})
					.filter(function(inv){ 
						return inv.partner_id === invoice.partner_id && inv.id !== invoice.id ;
					}) ;
			}
			
			return {
				id : invoice.id,
				remindToDo : remindToDo,
				otherInvoicesToRemind : otherInvoicesToRemind.map(function(oi){ return oi.id ;})
			} ;
			
		}) ;
		
		
		cli("account.invoice.aetna.remind").createRemindMulti(remindsTodo, function(err, createdReminds){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.doSync(function(){
				if(ev.source.constructor === InvoiceGridView){
					//from list, refresh list
					_self.refreshCount();
					_self.refreshList();	
				}else{
					//from form, refresh the form
					_self.openRecord({data : { id : remindsTodo[0].id}}) ;
				}
				
				_self.gui.endWait() ;
				
				var remindIds = createdReminds.map(function(r){ return r.id ;}) ;
				window.location.href = cli().serverUrl+"/api/account.invoice.aetna.remind/downloadPdf?id="+remindIds.join(",") ;
			}) ;
		}) ;
	} ;
	
	this.openRemindWord = function(ev){
		_self.gui.startWait() ;
		
		DistantFile.openDistantFile("account.invoice.aetna.remind", ev.data.id, function(err){
			if(err){ return _self.gui.endWaitError(err) ;}
			_self.gui.endWait() ;
		}) ;
		
	} ;
	
	this.onPdfRemindStart = function(remind){
		if(_self.viewForm){
			_self.viewForm.onPdfRemindStart(remind) ;
		}
	} ;
	
	this.onPdfRemindFinished = function(remind){
		if(_self.viewForm){
			_self.viewForm.onPdfRemindFinished(remind) ;
		}
	} ;
	
	this.cancelInvoice = function(ev){
		_self.gui.startWait() ;
		
		
		if(!Array.isArray(ev.data)){
			ev.data = [ev.data] ;
		}
		var ids = ev.data.map(function(invoice){ return invoice.id ;}) ;
		
		
		cli("account.invoice").cancelMulti(ids, function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.doSync(function(){
				
				if(ev.source.constructor === InvoiceGridView){
					//from list, refresh list
					_self.refreshCount();
					_self.refreshList();	
				}else{
					//from form, refresh the form
					_self.doSync(function(err){
						if(err){ return _self.viewList.endWaitError(err) ; }
						
						var record = loki.daos[_self.options.model].searchFirst({id : ids[0]}) ;
						_self.currentRecord = record ;
					
						_self.loadRecord(record) ;
						
					}) ;
				}
				
				_self.gui.endWait() ;
			}) ;
		}) ;
	} ;
	
	this.searchForPay = function(ev){
		var amount = ev.data.amount ;
		var customer = ev.data.customer  ;

		var search = {
			state : "open",
			type : "out_invoice"
		};
		
		if(customer){
			search.partner_id = parseInt(customer,10) ;
		}else{
			//use between to avoid rounding issues
			search.residual = { operator : "between", value : [amount-0.0001, amount+0.0001] };
		}
		
		var invoices = loki.daos["account.invoice"].search(search) ;
		
		_self.viewList.loadForPay(invoices) ;
	} ;
	
	this.validatePay = function(ev){
		//_self.emit("validatePay", {
			// 	date: date,
			// 	journal : journal,
			// 	amount : amount,
			// 	invoices : invoices
			// });
			
		var pay = {
			date : ev.data.date,
			journal_id : ev.data.journal,
			amount : ev.data.amount,
			name : ev.data.invoices.map(function(invoice){return invoice.AetnaRef ;}).join(", ")
		} ;
		pay.line_cr_ids = [] ;
		pay.type = "receipt" ;
		var amount = pay.amount ;
		
		_self.viewList.startWait() ;
		var journal = loki.daos["account.journal"].getByPk(pay.journal_id) ;
		pay.account_id = journal.default_credit_account_id ;

		ev.data.invoices.forEach(function(invoice){
			var amountInvoice = invoice.residual ;
			if(amountInvoice > amount){
				amountInvoice = amount ;
			}
			amount = amount - amountInvoice;
			var reconcile = (amountInvoice === invoice.residual) ;
			pay.partner_id = invoice.partner_id ;

			var moves = loki.daos["account.move.line"].search({invoice : invoice.id}) ;
			
			var move_line;
			moves.some(function(move){
				var account = loki.daos["account.account"].getByPk(move.account_id);
				
				if(account && account.type === "receivable"){
					move_line = move ;
					return true ;
				}
			}) ;					

			pay.line_cr_ids.push({
				name : invoice.AetnaRef,
				amount : amountInvoice,
				reconcile : reconcile,
				move_line_id : move_line.id,
				account_id : move_line.account_id
			}) ;
		}) ;

		cli("account.voucher").create(pay, function(err, createdPay){
			if(err){ return _self.viewList.endWaitError(err) ; }
			
			cli("account.voucher").execWorkflow(createdPay.id, "proforma_voucher", function(err){
				if(err){ return _self.viewList.endWaitError(err) ; }	
				
				cli("account.invoice").computeTaxExport(
							ev.data.invoices.map(function(invoice){ return invoice.id;}), function(err){
					if(err){ return _self.viewList.endWaitError(err) ; }		

					_self.doSync(function(){
						_self.viewList.endWait() ;
		 				_self.viewList.nextInputPay() ;
					}) ;
					
				}) ;
			}) ;
		}) ;
	} ;
	
	
	this.useRefund = function(ev){
		var refund = ev.data.refund ;
		var invoice = ev.data.invoice ;
		var pay = {
			date : new Date(),
			journal_id : loki.daos["account.journal"].searchFirst({type : "sale_refund" }).id,
			amount : 0
		} ;
		pay.line_cr_ids = [] ;
		pay.line_dr_ids = [] ;
		pay.type = "receipt" ;
		pay.partner_id = invoice.partner_id ;
		var journal = loki.daos["account.journal"].getByPk(pay.journal_id) ;
		pay.account_id = journal.default_credit_account_id ;
		
		var amount = Math.min(refund.residual, invoice.residual) ;
		
		var moves = loki.daos["account.move.line"].search({invoice : invoice.id}) ;
			
		var move_line;
		moves.some(function(move){
			var account = loki.daos["account.account"].getByPk(move.account_id);
			
			if(account && account.type === "receivable"){
				move_line = move ;
				return true ;
			}
		}) ;
		
		pay.line_cr_ids.push({
			amount : amount,
			reconcile : (amount === invoice.residual),
			move_line_id : move_line.id,
			account_id : move_line.account_id
		}) ;
		
		moves = loki.daos["account.move.line"].search({invoice : refund.id}) ;
			
		moves.some(function(move){
			var account = loki.daos["account.account"].getByPk(move.account_id);
			
			if(account && account.type === "receivable"){
				move_line = move ;
				return true ;
			}
		}) ;
		
		pay.line_dr_ids.push({
			amount : amount,
			reconcile : (amount === refund.residual),
			move_line_id : move_line.id,
			account_id : move_line.account_id
		}) ;
		
		
		_self.gui.startWait() ;

		cli("account.voucher").create(pay, function(err, createdPay){
			if(err){ return _self.gui.endWaitError(err) ; }
			
			cli("account.voucher").execWorkflow(createdPay.id, "proforma_voucher", function(err){
				if(err){ return _self.gui.endWaitError(err) ; }	
				
				cli("account.invoice").computeTaxExport(
							[invoice.id, refund.id], function(err){
					if(err){ return _self.gui.endWaitError(err) ; }		

					_self.doSync(function(){
						_self.openRecord({data : { id : invoice.id}}) ;

						_self.gui.endWait() ;
						
					}) ;
					
				}) ;
			}) ;
		}) ;
	} ;
	
	this.refundInvoice = function(ev){
		var invoice = ev.data ;
		
		_self.gui.startWait() ;
		
		
		cli("account.invoice").refundInvoice(invoice.id, function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.doSync(function(){
				if(ev.source.constructor === InvoiceGridView){
					//from list, refresh list
					_self.refreshCount();
					_self.refreshList();	
				}else{
					//from form, refresh the form
					_self.doSync(function(err){
						if(err){ return _self.viewList.endWaitError(err) ; }
						
						var record = loki.daos[_self.options.model].searchFirst({id : invoice.id}) ;
						_self.currentRecord = record ;
					
						_self.loadRecord(record) ;
						
					}) ;
				}
				
				_self.gui.endWait() ;
			}) ;
		}) ;
	} ;
}

module.exports = new FactoController();
