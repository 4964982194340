var DmsGridView = require("../views/dms/dmsGrid");
var DmsFormView = require("../views/dms/dmsForm");
var UploadDocView = require("../views/dms/uploadDoc");
var t = require("aetna-views/locales/tr").t ;
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var async = require("async") ;

function DmsController(model, name) {
	var _self = this ;

	BaseController.call(this, {
		model : model,
		modelsToSync : [model],
		socketChangeEvent : 'workflowChanged',
		name : name||"dms",
		gridClass : DmsGridView,
		formClass : DmsFormView,
		gridContainer : name+"_"+name+"Grid__container",
		formContainer : name+"_"+name+"Form__container"
	}) ;
	
	_self.gui = new BaseGui() ;
	
	this.getFieldDef = function(model, name){
		var result = null;
		cli().schemas.MAIN.tables.some(function(table){
			if(table.tableName === model) {
				table.columns.some(function(col){
					if(col.name === name){
						result = col ;
						return true ;
					}
				}) ;
				return true ;
			}
		}) ;
		return result ;
	} ;
	
	this.formatFields = function(fields){
		return fields.filter(function(f){
				return !f.parent_id ;
			}).map(function(f){
				var def = _self.getFieldDef(model, f.name) ;
				return {
					model : model,
					name : f.name,
					readonly : f.readonly,
					table_edit : f.table_edit,
					table_hide : f.table_hide,
					table_width : f.table_width,
					control : f.control,
					control_id : f.control_id,
					subfields : fields.filter(function(f2){
						return f2.parent_id === f.id ;
					}).map(function(f2){
						return {
							model : def.type.split(":")[1],
							name : f2.name,
							readonly : f2.readonly,
							control : f2.control,
							control_id : f2.control_id,
							table_edit : f2.table_edit,
							table_hide : f2.table_hide
						};
					})
				} ;
			}) ;
	} ;
	
	var _openGrid = this.openGrid ;
	this.openGrid = function(callback){
		loki.sync(["workflow", "workflow.activity", "aetna.dms.workflow.function",
			"aetna.dms.workflow.field", "aetna.dms.workflow.act.field"], true, function(){
			
			var workflow = loki.daos["workflow"].searchFirst({osv : model}) ;
			var fields = loki.daos["aetna.dms.workflow.field"].search({wkf_id : workflow.id}, "sequence") ;

			if(fields.length === 0){
				var alwaysHideCols = ["__last_update", "create_date", 
				"create_uid", "display_name", "id", "scan_date", "scan_uid", 
				"write_date", "write_uid"] ;
				cli().schemas.MAIN.tables.some(function(table){
					if(table.tableName === model) {
						table.columns.forEach(function(col){
							if(alwaysHideCols.indexOf(col.name) === -1){
								fields.push(col) ;
							}
						}) ;
						return true ;
					}
				}) ;
			}

			_self.wkFields = _self.formatFields(fields) ;
			_self.actFields = {} ;
			
			var activities = loki.daos["workflow.activity"].search({wkf_id : workflow.id}, "sequence") ;
			activities.forEach(function(a){
				var fieldsAct = loki.daos["aetna.dms.workflow.act.field"].search({act_id : a.id}, "sequence")  ;
				if(fieldsAct.length > 0){
					var thisActField = _self.formatFields(fieldsAct) ; 
					
					_self.actFields[a.name] = _self.wkFields.map(function(f){
						var finalField = JSON.parse(JSON.stringify(f)) ;
						thisActField.forEach(function(af){
							if(af.name === f.name){
								Object.keys(af).forEach(function(k){
									if(k === "subfields"){
										af[k].forEach(function(subaf){
											var subf = null;
											finalField.subfields.some(function(sf){
												if(sf.name === subaf.name){
													subf = sf ;
													return true ;
												}
											}) ;
											if(!subf){
												finalField.subfields.push(subaf) ;
											}else{
												Object.keys(subaf).forEach(function(subk){
													if(typeof(subf[subk]) !== "boolean" && subaf[subk] !== false){
														subf[subk] = af[subk] ;
													}else if(typeof(f[subk]) === "boolean"){
														subf[subk] = af[subk] ;
													}
												}) ;
											}
										}) ;									
									}else{
										if(typeof(f[k]) !== "boolean" && af[k] !== false){
											finalField[k] = af[k] ;
										}else if(typeof(f[k]) === "boolean"){
											finalField[k] = af[k] ;
										}
									}
								}) ;
							}
						}) ;
						return finalField ;
					}) ;
				}
			}) ;
			
			_self.options.modelsToSync = [model] ;
			_self.wkFields.forEach(function(field){
				if(field.subfields){
					field.subfields.forEach(function(s){
						if(_self.options.modelsToSync.indexOf(s.model) === -1){
							_self.options.modelsToSync.push(s.model) ;	
						}
					}) ;
				}
			}) ;
			Object.keys(_self.actFields).forEach(function(state){
				_self.actFields[state].forEach(function(field){
					if(field.subfields){
						field.subfields.forEach(function(s){
							if(_self.options.modelsToSync.indexOf(s.model) === -1){
								_self.options.modelsToSync.push(s.model) ;	
							}
						}) ;
					}
				}) ;
			}) ;
			
			_openGrid(callback)	 ;
		})  ;
	} ;
	
	this.newGridInstance = function(){
		var workflow = loki.daos["workflow"].searchFirst({osv : model}) ;
		var activities = loki.daos["workflow.activity"].search({wkf_id : workflow.id}, "AetnaSequence") ;
		
		return new DmsGridView(model, activities,  _self.wkFields, _self.actFields, cli().serverUrl, cli().schemas);
	} ;
	
	this.newFormInstance = function(){
		var form = new DmsFormView(model, _self.wkFields, _self.actFields, cli().serverUrl, cli().schemas);
		form.on("fieldChange", _self.onFieldChange) ;
		return form ;
	} ;
	
	this.onGridLoad = function(){
		_self.viewList.on("changeFilter", _self.refreshList) ;
		_self.viewList.on("addLineToSave", _self.addToSave) ;
		_self.viewList.on("fieldChange", _self.onFieldChange) ;
		_self.refreshCount() ;	
	} ;
	
	this.refreshCount = function(){
		var counts = loki.daos[model].countBy("state") ;
		_self.viewList.loadCounts(counts) ;
	} ;
	
	this.listAll = function(){
		var filter = _self.viewList.getFilter() ;
		
		var states = [] ;
		Object.keys(filter).forEach(function(k){
			if(filter[k]){
				states.push(k) ;
			}
		}) ;
		
		var search = {} ;
		if(states.length > 0){
			search = {state : {operator : "in", value : states}} ;
		}
		
		var records = loki.daos[model].search(search) ;
		
		//load a copy to avoid touching the cache
		records = JSON.parse(JSON.stringify(records)) ;
		
		var fieldsWithSubfields = _self.wkFields.filter(function(field){
			return field.subfields.length > 0 ;
		}) ;
		
		if(fieldsWithSubfields.length > 0){
			records.forEach(function(record){
				fieldsWithSubfields.forEach(function(f){
					var subModel = f.subfields[0].model ;
					var value = record[f.name] ;
					if(value){
						value.forEach(function(v, i){
							if(typeof(v) === "number"){
								//we receive only the id, get the whole object
								value[i] = loki.daos[subModel].getById(v) ;
							}
						}) ;
					}
				}) ;
			}) ;
		}
		
		
		return records ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
	} ;
	
	
	this.createNew = function(ev){
		var viewUpload = new UploadDocView(cli().serverUrl, cli().schemas);
		
		viewUpload.openInPopup({
			title: t("dms.chooseFile") 
		});
		
		viewUpload.on("validate",  _self.createDoc) ;
		viewUpload.on("cancel", function(){
			viewUpload.closePopup() ;
		}) ;
	} ;
	
	
	this.createDoc = function(ev){
		_self.gui.startWait() ;
		var user = storage(context().name).get("user") ;
		
		var record = {
			scan_uid : user.id,
			scan_date : new Date() 
		} ;
		cli("aetna.dms.scan.base").createDoc(_self.options.model, record , ev.data.uploadFile, function(err, createdDoc){
			if(err){
				return _self.gui.endWaitError(err) ;
			}	
			
			_self.doSync(function(){
				_self.refreshList() ;
				_self.gui.endWait() ;
			}) ;
		}) ;
	};
	
	
	this.onSave = function(ev){
		_self.viewForm.startWait();
		
		_self.doSave(ev.data, function(err, record){
			if(err){ return _self.viewForm.endWaitError(err) ; }
			
			//submit to next workflow step after save
			cli(_self.options.model).execWorkflow(record.id, "submit", function(err){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				
				//TODO : go to next doc in batch input mode
				_self.returnToList() ;
				_self.viewForm.endWait() ;
			}) ;
		}) ;
	} ;
	
	_self.recordsToSave = {} ;
	_self.recordsSaving = {} ;
	_self.isSaving = false ;
	this.addToSave = function(ev){
		var newRecord = ev.data.newRecord;
		var oldRecord = ev.data.oldRecord;
		var recid = ev.data.recid;
		
		
		
		
		newRecord.id = oldRecord.id ;
			
		if(!_self.recordsToSave[recid]){
			_self.recordsToSave[recid] = {} ;
		}
			
		_self.recordsToSave[recid].oldRecord = oldRecord ;
		_self.recordsToSave[recid].newRecord = newRecord ;
		_self.recordsToSave[recid].detectTime = new Date() ;
		
		_self.doLineSave() ;
	} ;
	
	this.doLineSave = function(){
		if(_self.isSaving){
			return; //already saving something
		}
		_self.isSaving = true ;
		var calls = [function(cb){ cb() ;}] ;
		Object.keys(_self.recordsToSave).forEach(function(recid){
			var r = _self.recordsToSave[recid] ;
			if(new Date().getTime() - r.detectTime.getTime() > 10000){
				//no activity from last 10sec, save it
				_self.recordsSaving[recid] = r ;
				delete _self.recordsToSave[recid] ;
			}
		}) ;
		
		Object.keys(_self.recordsSaving).forEach(function(recid){
			calls.push(function(cb){
				var r = _self.recordsSaving[recid] ;
				_self.viewList.savingInProgressStatus(recid) ;
				cli(_self.options.model).save(r.newRecord, function(err){
					if(err){
						_self.viewList.savingErrorStatus(recid) ;
						_self.viewList.error(err) ;
						return cb(err) ;
					}
					delete _self.recordsSaving[recid] ;
					_self.viewList.saveOKStatus(recid) ;
					cb() ;
				}) ;
			}) ;
		}) ;
		async.parallelLimit(calls, 2, function(){
			_self.isSaving = false ;
			
			if(Object.keys(_self.recordsToSave).length > 0 || Object.keys(_self.recordsSaving).length > 0){
				setTimeout(function(){
					_self.doLineSave();
				}, 5000) ;
			}
		}) ;
	} ;
	
	this.onFieldChange = function(ev){
		var fields = _self.wkFields;
		if(_self.actFields[ev.data.activity]){
			fields = _self.actFields[ev.data.activity];
		}
		
		fields.forEach(function(f){
			if(f.name === ev.data.code){
				if(f.control){
					if(typeof(f.control) !== "function"){
						f.control = new Function("fields", "cli", "loki",  "error", "ok", f.control) ;
					}
				}
				if(f.control_id){
					var functionRecord = loki.daos["aetna.dms.workflow.function"].getById(f.control_id) ;
					if(functionRecord){
						f.control = new Function("fields", "cli", "loki",  "error", "ok", functionRecord.body) ;
					}
				}
				
				if(f.control){
					f.control(ev.data.fields, cli, loki, function error(msg, fields){
						if(!fields){
							fields = [ev.data.code] ;
						}else{
							fields = [].splice.call(arguments,1);
						}
						ev.source.addFieldError(fields, msg, ev.data.fields.id) ;
						if(ev.data.callback){
							ev.data.callback(msg||"error") ;
						}
					}, function ok(fields){
						if(!fields){
							fields = [ev.data.code] ;
						}else{
							fields = [].splice.call(arguments,0) ;
						}
						ev.source.clearFieldError(fields, ev.data.fields.id) ;
						if(ev.data.callback){
							ev.data.callback() ;
						}
					}) ;
				}
				
				return true ;
			}
		}) ;
	} ;

}

module.exports = DmsController;
