var context = require("aetna-views/context").context;
var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var storage = require("aetna-storage").storage ;
var BUS = require("aetna-events").EventSource.BUS;
var Parameters = require("aetna-views/Parameters");
var pkg = require("../../../package.json") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");

function MainView(serverUrl){
    BaseView.call(this, t, "main", "main") ;
	
	var _self = this ;
	this.serverUrl = serverUrl ;
   
	this._initElements = function(){
		BUS.ON("loggedAndInitialized", _self.onLogged) ;
		
		_self.EL.logout.emit() ;
		_self.EL.company.emit() ;
		_self.EL.customers.emit() ;
		_self.EL.project.emit() ;
		_self.EL.product.emit() ;
		_self.EL.payType.emit() ;
		_self.EL.tax.emit() ;
		_self.EL.invoiceSettings.emit() ;
		_self.EL.user.emit() ;
		_self.EL.hrGroup.emit() ;
		_self.EL.hrDepartment.emit() ;
		_self.EL.template.emit() ;
		_self.EL.fiscalYear.emit() ;
		_self.EL.invoiceTemplate.emit() ;
		_self.EL.refundTemplate.emit() ;
		_self.EL.contractTemplate.emit() ;
		_self.EL.remindSettings.emit() ;
		_self.EL.templateType.emit() ;
		_self.EL.taskStage.emit() ;
		_self.EL.workflow.emit() ;
		_self.EL.projectSettings.emit() ;
		_self.EL.projectCustomField.emit() ;
		_self.EL.changePass.emit() ;
		_self.EL.userPref.emit() ;
		_self.EL.dico.emit() ;
		_self.EL.facto.emit() ;
		_self.EL.refund.emit() ;
		_self.EL.contract.emit() ;
		_self.EL.accountingVat.emit() ;
		_self.EL.accountingExport.emit() ;
		_self.EL.accountingImport.emit() ;
		_self.EL.statsInvoices.emit() ;
		_self.EL.purchase.emit() ;
		_self.EL.sale.emit() ;
		_self.EL.saleOrderTemplate.emit() ;
		_self.EL.saleSettings.emit() ;
		_self.EL.dmsInvoice.emit() ;
		
		
		_self.templateExtservers = new TemplateWidget(_self.EL.menuExtServers);

		_self.templateExtservers.on("render", _self.onRenderExtservers) ;

		$(_self.container).find('[data-placement="top"]').tooltip() ;
		
		var urlParam = Parameters.get("url") ;
		if(urlParam){
			_self.EL.customUrl.innerHTML = t("main.useUrl", {url : urlParam}) ;
			_self.EL.customUrl.style.display = "block" ;
		}
		_self.EL.version.innerHTML = "v."+pkg.humanVersion ;
		document.title = "ADS PRO "+pkg.humanVersion ;

		BUS.on("openScreen", _self.onOpenScreen) ;
		
	} ;
	
	this.onRenderExtservers = function(){
		_self.$EL.menuExtServers.find("a").each(function(i,a){
			a.addEventListener("click", function(){
				var w = window.open(a.getAttribute("data-url"));
				
				w.addEventListener("load", function(){
					w.document.login_form.username.value = a.getAttribute("data-login");
					w.document.login_form.password.value = a.getAttribute("data-password");
					w.document.login_form.submit();
				});
			}) ;
		});
		
	} ;
	
	this.setOffline = function(isOffline){
		if(isOffline){
			$(document.body).addClass("isOffline");
		}else{
			$(document.body).removeClass("isOffline");
			_self.EL.logo.src = _self.serverUrl+"/api/company/logo" ;
		}
	} ;
	
	this.onOpenScreen = function(ev){
		_self.$EL.liDico.removeClass("active") ;
		_self.$EL.invoicing.removeClass("active") ;
		_self.$EL.liContract.removeClass("active") ;
		_self.$EL.liRefs.removeClass("active") ;
		_self.$EL.liSettings.removeClass("active") ;
		_self.$EL.liAccounting.removeClass("active") ;
		_self.$EL.liStats.removeClass("active") ;
		_self.$EL.liPurchase.removeClass("active") ;
		_self.$EL.liSale.removeClass("active") ;
		_self.$EL.dms.removeClass("active") ;

		
		var moduleName = ev.data ;
		var screenName = ev.data ;
		if(ev.data.module){
			moduleName = ev.data.module ;
			screenName = ev.data.screen ;
		}
		switch(moduleName){
			case "dico" :
				_self.$EL.liDico.addClass("active") ;
				break ;
			case "facto" :
				_self.$EL.invoicing.addClass("active") ;
				break ;
			case "contract" :
				_self.$EL.liContract.addClass("active") ;
				break ;
			case "ref" :
				_self.$EL.liRefs.addClass("active") ;
				break ;
			case "settings" :
				_self.$EL.liSettings.addClass("active") ;
				break ;
			case "accounting" :
				_self.$EL.liAccounting.addClass("active") ;
				break ;
			case "stats" :
				_self.$EL.liStats.addClass("active") ;
				break ;
			case "purchase" :
				_self.$EL.liPurchase.addClass("active") ;
				break ;
			case "sale" :
				_self.$EL.liSale.addClass("active") ;
				break ;
			case "dms" :
				_self.$EL.dms.addClass("active") ;
				break ;
		}
		
		var currentMenu = _self.$EL.topMenu.find(".active")[0] ;
		var currentClass = currentMenu.className.split(" ")[0] ;
		var isOffline = document.body.className.indexOf("isOffline") !== -1 ;
		document.body.className = currentClass + (isOffline?" isOffline":"") ;
		
		document.getElementById("moduleTitle").innerHTML = currentMenu.children[0].getAttribute("data-original-title") ;
		var trKey = screenName+".module" ;
		if(t(trKey) !== trKey){
			document.getElementById("moduleTitle").innerHTML = t(trKey) ;
		}
		
	} ;
	
	_self.onLogged = function(){
		_self.ensureInit(function(){
			_self.EL.logo.src = _self.serverUrl+"/api/company/logo" ;
			var user = storage(context().name).get("user") ;
			_self.EL.welcome.innerHTML = t("main.welcomeUser", user) ;
			_self.EL.companyName.innerHTML = user.company_name ;
		});
	} ;
	
	
	
	
	_self.setUser = function(user){
		
		if(!user.extservers || user.extservers.length === 0){
			_self.EL.liExtservers.style.display = "none" ;
		}else{
			_self.templateExtservers.render(user) ;
		}
		
		
		if(user.moduleDico){
			_self.EL.liDico.style.display = "inline-block" ;
		}else{
			_self.EL.liDico.style.display = "none" ;
		}
		
		if(user.moduleFacto){
			_self.EL.invoicing.style.display = "inline-block" ;
		}else{
			_self.EL.invoicing.style.display = "none" ;
		}
		
		if(user.moduleContract){
			_self.EL.liContract.style.display = "inline-block" ;
		}else{
			_self.EL.liContract.style.display = "none" ;
		}
		
		if(user.moduleAccounting){
			_self.EL.liAccounting.style.display = "inline-block" ;
		}else{
			_self.EL.liAccounting.style.display = "none" ;
		}
		
		if(user.modulePurchase){
			_self.EL.liPurchase.style.display = "inline-block" ;
		}else{
			_self.EL.liPurchase.style.display = "none" ;
		}
		
		if(user.moduleSale){
			_self.EL.liSale.style.display = "inline-block" ;
		}else{
			_self.EL.liSale.style.display = "none" ;
		}
		
		if(user.moduleSaleSettings){
			_self.EL.liSaleOrderTemplate.style.display = "list-item" ;
			_self.EL.liSaleSettings.style.display = "list-item" ;
		}else{
			_self.EL.liSaleOrderTemplate.style.display = "none" ;
			_self.EL.liSaleSettings.style.display = "none" ;
		}
		
		if(user.moduleAccountingVAT){
			_self.EL.liAccountingVat.style.display = "list-item" ;
		}else{
			_self.EL.liAccountingVat.style.display = "none" ;
		}
		
		if(user.moduleAccountingExport){
			_self.EL.liAccountingExport.style.display = "list-item" ;
		}else{
			_self.EL.liAccountingExport.style.display = "none" ;
		}
		
		if(user.moduleAccountingImport){
			_self.EL.liAccountingImport.style.display = "list-item" ;
		}else{
			_self.EL.liAccountingImport.style.display = "none" ;
		}
		
		if(user.moduleSettingsContract){
			_self.EL.liContractTemplate.style.display = "list-item" ;
		}else{
			_self.EL.liContractTemplate.style.display = "none" ;
		}
		
		if(user.moduleSettingsUsers){
			_self.EL.liUser.style.display = "list-item" ;
			_self.EL.liHrDepartment.style.display = "list-item" ;
		}else{
			_self.EL.liUser.style.display = "none" ;
			_self.EL.liHrDepartment.style.display = "none" ;
		}
		
		if(user.moduleSettingsHrGroups){
			_self.EL.liHrGroup.style.display = "list-item" ;
		}else{
			_self.EL.liHrGroup.style.display = "none" ;
		}
		
		if(user.moduleSettingsUsers || user.moduleSettingsHrGroups){
			_self.EL.liUserSeparator.style.display = "list-item" ;
		}else{
			_self.EL.liUserSeparator.style.display = "none" ;
		}
		
		if(user.moduleSettingsTemplates){
			_self.EL.liTemplate.style.display = "list-item" ;
		}else{
			_self.EL.liTemplate.style.display = "none" ;
		}
		
		if(user.moduleSettingsInvoiceTemplates){
			_self.EL.liInvoiceTemplate.style.display = "list-item" ;
			_self.EL.liRefundTemplate.style.display = "list-item" ;
		}else{
			_self.EL.liInvoiceTemplate.style.display = "none" ;
			_self.EL.liRefundTemplate.style.display = "none" ;
		}
		
		if(user.moduleSettingsRemind){
			_self.EL.liRemindSettings.style.display = "list-item" ;
		}else{
			_self.EL.liRemindSettings.style.display = "none" ;
		}
		
		if(user.moduleSettingsTemplatesType){
			_self.EL.liTemplateType.style.display = "list-item" ;
		}else{
			_self.EL.liTemplateType.style.display = "none" ;
		}
		
		if(user.moduleSettingsTemplates || user.moduleSettingsTemplatesType){
			_self.EL.liTemplateSeparator.style.display = "list-item" ;
		}else{
			_self.EL.liTemplateSeparator.style.display = "none" ;
		}
		
		if(user.moduleSettingsCompany){
			_self.EL.liCompany.style.display = "list-item" ;
		}else{
			_self.EL.liCompany.style.display = "none" ;
		}
		
		if(user.moduleSettingsCustomers){
			_self.EL.liCustomers.style.display = "list-item" ;
		}else{
			_self.EL.liCustomers.style.display = "none" ;
		}
		
		if(user.moduleSettingsProjects){
			_self.EL.liProject.style.display = "list-item" ;
		}else{
			_self.EL.liProject.style.display = "none" ;
		}
		
		if(user.moduleSettingsProducts){
			_self.EL.liProduct.style.display = "list-item" ;
		}else{
			_self.EL.liProduct.style.display = "none" ;
		}
		
		if(user.moduleSettingsAccounting){
			_self.EL.liPayType.style.display = "list-item" ;
			_self.EL.liTax.style.display = "list-item" ;
			_self.EL.liInvoiceSettings.style.display = "list-item" ;
			_self.EL.liFiscalYear.style.display = "list-item" ;
		}else{
			_self.EL.liPayType.style.display = "none" ;
			_self.EL.liTax.style.display = "none" ;
			_self.EL.liInvoiceSettings.style.display = "none" ;
			_self.EL.liFiscalYear.style.display = "none" ;
		}
		
		if(user.moduleSettingsWorkflow){
			_self.EL.liWorkflow.style.display = "list-item" ;
		}else{
			_self.EL.liWorkflow.style.display = "none" ;
		}
		
		if(user.moduleSettingsWorkflowStep){
			_self.EL.liTaskStage.style.display = "list-item" ;
		}else{
			_self.EL.liTaskStage.style.display = "none" ;
		}
		
		if(user.moduleSettingsWorkflow || user.moduleSettingsWorkflowStep){
			_self.EL.liWorkflowSeparator.style.display = "list-item" ;
		}else{
			_self.EL.liWorkflowSeparator.style.display = "none" ;
		}
		
		if(user.moduleSettingsReference){
			_self.EL.liProjectSettings.style.display = "list-item" ;
		}else{
			_self.EL.liProjectSettings.style.display = "none" ;
		}
		
		if(user.moduleSettingsCustomFields){
			_self.EL.liProjectCustomField.style.display = "list-item" ;
		}else{
			_self.EL.liProjectCustomField.style.display = "none" ;
		}
		
		
		if(user.moduleStatsInvoices){
			_self.EL.liStatsInvoices.style.display = "list-item" ;
		}else{
			_self.EL.liStatsInvoices.style.display = "none" ;
		}
		
		if(user.moduleDms){
			_self.EL.dms.style.display = "inline-block" ;
		}else{
			_self.EL.dms.style.display = "none" ;
		}
		
		if(user.moduleDmsInvoice){
			_self.EL.liDmsInvoice.style.display = "list-item" ;
		}else{
			_self.EL.liDmsInvoice.style.display = "none" ;
		}
		
		// if(_self.$EL.liSettings.find("ul>li[role=separator]").filter(function(i, li){ return li.style.display !== "none" ;}).length > 0){
		// 	_self.EL.liSettings.style.display = "inline-block" ;
		// }else{
		// 	_self.EL.liSettings.style.display = "none" ;
		// }
		
		
		if(_self.$EL.liRefs.find("ul>li").filter(function(i, li){ return li.style.display !== "none" ;}).length > 0){
			_self.EL.liRefs.style.display = "inline-block" ;
		}else{
			_self.EL.liRefs.style.display = "none" ;
		}
		
		if(_self.$EL.liAccounting.find("ul>li").filter(function(i, li){ return li.style.display !== "none" ;}).length > 0){
			_self.EL.liAccounting.style.display = "inline-block" ;
		}else{
			_self.EL.liAccounting.style.display = "none" ;
		}
		
		if(_self.$EL.liStats.find("ul>li").filter(function(i, li){ return li.style.display !== "none" ;}).length > 0){
			_self.EL.liStats.style.display = "inline-block" ;
		}else{
			_self.EL.liStats.style.display = "none" ;
		}
		
		_self.$EL.liSettings.find("ul>li[role=separator]").each(function(i,sep){ 
			var nexts = [] ;
			var prevs = [] ;
			$(sep).nextAll().each(function(i, next){
				nexts.push(next);
			}) ;
			
			$(sep).prevAll().each(function(i, prev){
				prevs.push(prev) ;
			}) ;
			
			var hasNext = false ;
			nexts.some(function(next){
				if(next.getAttribute("role") === "separator" && next.style.display !== "none"){
					return true;
				}
				if(next.style.display !== "none"){
					hasNext = true ;
					return true ;
				}
			}) ;
			
			var hasPrev = false ;
			prevs.some(function(prev){
				if(prev.getAttribute("role") === "separator" && prev.style.display !== "none"){
					return true;
				}
				if(prev.style.display !== "none"){
					hasPrev = true ;
					return true ;
				}
			}) ;

			
			if(hasPrev && hasNext){
				sep.style.display = "list-item" ;
			}else{
				sep.style.display = "none" ;
			}
		}) ;
		
		if(_self.$EL.liSettings.find("ul>li").filter(function(i, li){ return li.style.display !== "none" ;}).length > 0){
			_self.EL.liSettings.style.display = "inline-block" ;
		}else{
			_self.EL.liSettings.style.display = "none" ;
		}
		
	} ;
	
}

module.exports = MainView;
