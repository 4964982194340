var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function AccountInvoiceAetnaTvaExport(serverUrl){
	CrudCli.call(this, serverUrl, "account.invoice.aetna.tvaexport");
	
	    
	this.doExport = function(ids, callback){
         ajax(serverUrl + "/api/account.invoice.aetna.tvaexport/doExport", {ids : ids}, callback);
    };
    
}

module.exports = AccountInvoiceAetnaTvaExport ;