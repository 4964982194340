var TemplateViews = require("../views/template/templateViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var BUS = require("aetna-events").EventSource.BUS;
var DistantFile = require("../api/DistantFile") ;
var BaseGui = require("aetna-views/BaseGui") ;

function TemplateController() {
	var _self = this ;
	BaseController.call(this, {
		model : "document.aetna.template",
		socketChangeEvent : 'templateChanged',
		name : "template",
		gridClass : TemplateViews.Grid,
		formClass : TemplateViews.Form
	}) ;
	
	_self.gui = new BaseGui() ;
	
	BUS.on("loaded", function(){
		_self.socket.on('template-pdfStart', _self.onPdfStart);
		_self.socket.on('template-pdfFinished', _self.onPdfFinished);
	}) ;
				
	this.onPdfStart = function(doc){
		if(_self.viewForm){
			_self.viewForm.onPdfStart(doc) ;
		}
	} ;
	
	this.onPdfFinished = function(doc){
		if(_self.viewForm){
			_self.viewForm.onPdfFinished(doc) ;
		}
	} ;
	
	this.doSync = function(forceRefresh, callback){
		loki.sync([
			"document.aetna.template", 
			"document.aetna.category",
			"ir.attachment"
		], forceRefresh, callback) ;
	} ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(function(){
			_self.viewForm.on("openTemplateWeb", _self.openTemplateWeb) ;
			
			callback() ;
		}) ;
	} ;
	
	this.onSave = function(ev){
		_self.viewForm.startWait();
		
		if(_self.options.defaultValues){
			Object.keys(_self.options.defaultValues).forEach(function(k){
				if(!ev.data.recordToSave[k]){
					ev.data.recordToSave[k] = _self.options.defaultValues[k] ;
				}
			}) ;
		}
		
		if(ev.data.isCreate){
			cli(_self.options.model).create(ev.data.recordToSave,  ev.data.uploadFile, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				_self.doSync(function(){
					_self.viewForm.load(savedRecord) ;
					_self.viewForm.endWait() ;	
				}) ;
			}) ;
		}else{
			cli(_self.options.model).update( ev.data.recordToSave, 
				ev.data.uploadFile, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				
				_self.doSync(function(){
					_self.viewForm.load(savedRecord) ;
					_self.viewForm.endWait() ;
				}) ;
			}) ;
		}	
	} ;
	
	
	this.openTemplateWeb = function(ev){
		_self.gui.startWait() ;
		
		DistantFile.openDistantFile("document.aetna.template", ev.data.id, function(err){
			if(err){ return _self.gui.endWaitError(err) ;}
			_self.gui.endWait() ;
		}) ;
		
		
	} ;
}

module.exports = new TemplateController();
