var currentImplementation = "webdav";

/******* WEBDAV IMPLEMENTATION *****************/
var ajax = require("aetna-ajax/ajax.js").ajax;
var cli = null;
function openWebdav(model, id, callback){
    
    ajax(cli.cli().serverUrl + "/api/"+model+"/getWebDavDoc", {id : id},function(err, infos){
        if(err){ return callback(err); }
        
        var url = cli.cli().serverUrl+"/webdav/"+infos.filePath ;
		
		var openMode = "ofe" ;
		
		if(/.doc(x){0,1}$/i.test(infos.filePath)){
			url = "ms-word:"+openMode+"|u|"+url ;
		}else if(/.xls(x){0,1}$/i.test(infos.filePath)){
			url = "ms-excel:"+openMode+"|u|"+url ;
		}else if(/.ppt(x){0,1}$/i.test(infos.filePath)){
			url = "ms-powerpoint:"+openMode+"|u|"+url ;
		}
		
		callback();
		
		window.location.href = url;	
    });
}

function replaceWeb(model, id, file, callback){
    ajax(cli.cli().serverUrl + "/api/"+model+"/uploadDoc", {id : id, contents : file}, callback);
}

function getPdfUrlWeb(model, id, callback){
    return callback(null, cli.cli().serverUrl+"/api/"+model+"/downloadPdf?id="+id) ;
}


module.exports.setCli = function(cliImpl){
    cli = cliImpl ;
} ; 

/******* DESKTOP IMPLEMENTATION *****************/
var desktopSyncers = {} ;
function openDesktop(model, id, callback){
    desktopSyncers[model].openFile(id, callback) ;
} 

function replaceDesktop(model, id, file, callback){
    desktopSyncers[model].replaceFile(id, file, callback) ;
}

function isFileAvailableDesktop(model, id){
    return desktopSyncers[model].isFileAvailable(id) ;
} 

function getPdfUrlDesktop(model, id, callback){
    desktopSyncers[model].getPdfUrl(id, callback) ;
}

module.exports.uploadOfflineDoc = function(model, oldId, newId, callback){
    if(desktopSyncers[model]){
        desktopSyncers[model].uploadOfflineDoc(oldId, newId, callback) ;
    }else{
        callback() ;
    }
} ;

module.exports.downloadDoc = function(model, id, callback){
    if(desktopSyncers[model]){
        desktopSyncers[model].downloadDoc(id, callback) ;
    }else{
        callback() ;
    }
} ;

module.exports.backupDoc = function(model, id, callback){
    if(desktopSyncers[model]){
        desktopSyncers[model].backupDoc(id, callback) ;
    }else{
        callback() ;
    }
} ;


module.exports.convertToPdf = function(model, id, callback){
    if(desktopSyncers[model]){
        desktopSyncers[model].convertToPdfBasic(id, callback) ;
    }else{
        callback() ;
    }
} ;

module.exports.copyFile = function(model, file, dest, callback){
    if(desktopSyncers[model]){
        desktopSyncers[model].copyFile(file, dest, callback) ;
    }else{
        callback() ;
    }
} ;

module.exports.setSyncers = function(syncers){
    desktopSyncers = syncers ;
} ;

var desktopUpdater = null;
module.exports.setUpdater = function(updater){
    desktopUpdater = updater ;
} ;

/******* GENERIC CALLS *****************/
module.exports.setImplementation = function(implementation){
    currentImplementation = implementation ;
} ;

module.exports.openDistantFile = function(model, id, callback){
    if(currentImplementation === "webdav") {
        return openWebdav(model, id, callback) ;
    }else if(currentImplementation === "desktop") {
        return openDesktop(model, id, callback) ;
    }
} ;

module.exports.isFileAvailable = function(model, id){
    console.log("CONTEXT ?????? "+currentImplementation) ;
    if(cli.getContext() === "normal") { return true ; }
    if(currentImplementation === "webdav") {
        return true ;
    }else if(currentImplementation === "desktop") {
        return isFileAvailableDesktop(model, id) ;
    }
} ;

module.exports.getPdfUrl = function(model, id, callback){
    if(currentImplementation === "webdav") {
        return getPdfUrlWeb(model, id, callback) ;
    }else if(currentImplementation === "desktop") {
        return getPdfUrlDesktop(model, id, callback) ;
    }
} ;

module.exports.replaceDistantFile = function(model, id, file, callback){
    if(currentImplementation === "webdav") {
        return replaceWeb(model, id,  file, callback) ;
    }else if(currentImplementation === "desktop") {
        return replaceDesktop(model, id, file, callback) ;
    }
} ;

module.exports.syncNow = function(model, callback){
    if(currentImplementation === "webdav") {
        return callback() ;
    }else if(currentImplementation === "desktop") {
        return desktopSyncers[model].sync(callback) ;
    }
} ;

module.exports.checkUpdate = function(callback, callbackProgress){
    if(desktopUpdater){
        desktopUpdater.uploadApp(callback, callbackProgress) ;
    }else{
        callback() ;
    }
} ;

module.exports.quitApp = function(){
    if(desktopUpdater){
        desktopUpdater.quitApp() ;
    }
} ;