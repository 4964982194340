var t = require("aetna-views/locales/tr").t ;
var BaseView = require("aetna-views/BaseView") ;
var BUS = require("aetna-events").EventSource.BUS;
var FieldsEMA = require("../fields/FieldsEMA") ;

function CloseContractView(serverUrl, schemas){
	var _self = this ;
	
	BaseView.call(this, t,"contract", "closeContract") ;
	
   	this.fields = new FieldsEMA(t, serverUrl)  ;
	
	this._initElements = function(callback){
		_self.fields.init(_self, function(){
			_self.fields.load({date_end : new Date()}) ;
            callback() ;
        }) ;
        _self.EL.cancel.emit() ;
        _self.EL.validate.addEventListener("click", _self.onValidate) ;
	} ;
	

	this.onValidate = function(){
		
		if(_self.fields.fields["aetna.contract.contract~date_end"].isEmpty()){
			_self.fields.fields["aetna.contract.contract~date_end"].error() ;
			return _self.error(t("contract.thereIsErrorPleaseCorrect")) ;
		}
		
		
		_self.emit("validate", {date_end : _self.fields.fields["aetna.contract.contract~date_end"].val()}) ;
	} ;
}

module.exports = CloseContractView;
