var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var FieldAutocomplete = require("aetna-views/fields/autocomplete");


function CreateDocView(serverUrl, schemas){
    BaseView.call(this, t, "doc", "createRev") ;
	
	var _self = this ;
   
   	this.fields = new FieldsEMA(t, serverUrl) ;

	this._initElements = function(callback){
		_self.templateNotifications = new TemplateWidget(_self.EL.notifications) ;
        _self.templateNotifications.on("render", _self.renderNotifications) ;
		
		_self.EL.cancel.emit("cancel") ;
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
		
		_self.fields.init(_self, function(){
			_self.fields.fields["project.task~AetnaRev"].setReadOnly("true") ;
            callback() ;
        }) ;
	};
	
	this.load = function(originalDoc, newDoc, notifications){
		_self.doc = newDoc ;
		_self.notifications = notifications ;
			
		_self.templateNotifications.render({ notifications : _self.notifications}) ;
		
		_self.fields.load(_self.doc) ;

	} ;
	
	this.renderNotifications = function(){
		if(_self.templateNotifications.LISTS.user){
			_self.templateNotifications.LISTS.user.forEach(function(itemUser, i){
				
				var project = _self.allProjects[_self.doc.project_id] ;
				var itemsUser = [] ;
				var memberUsers = [] ;
				var otherUsers = [] ;
				Object.keys(_self.allUsers).forEach(function(uId){
					if(_self.allEmployees[uId].AetnaActive){
						if(project.members.indexOf(parseInt(uId,10)) !== -1){
							memberUsers.push({
								key : uId,
								value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
							}) ;
						}else if(project.AetnaPublicStatus === "public"){
							otherUsers.push({
								key : uId,
								value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
							}) ;
						}
					}
				}) ;
				
				memberUsers = memberUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
				otherUsers = otherUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
				
				if(memberUsers.length > 0){
					itemsUser.push({
						value : t("doc.memberUsers"),
						subEntries : memberUsers
					}) ;
				}
				if(otherUsers.length > 0){
					if(memberUsers.length > 0){
						itemsUser.push({
							value : t("doc.otherUsers"),
							subEntries : otherUsers
						}) ;
					}else{
						itemsUser = otherUsers ;
					}
				}
				
				var thisField = new FieldAutocomplete(t, "AetnaUser_id", itemsUser, itemUser.data.stageName) ;
				thisField.setRequired(true) ;
				thisField.init(itemUser.el, function(){
					thisField.load(itemUser.data.AetnaUser_id) ;
				}) ;
				itemUser.field = thisField ;
	        }) ;
		}
	} ;
	

	this.onValidate  = function(done){
		_self.fields.clearError() ;
		
		var hasError = false ;
		if(_self.templateNotifications.LISTS.user){
			_self.templateNotifications.LISTS.user.forEach(function(itemUser, i){
				var f = itemUser.field ;
	            if(f.isEmpty()){
	                f.error() ;
	                hasError = true ;
	            }else if(!f.isValid()){
	                f.error() ;
	                hasError = true ;
	            }
			});
		}
		
		if(hasError){
            return _self.error(t("doc.thereIsErrorPleaseCorrect"));
        }
		
		
		var notifs = [] ;
		if(_self.templateNotifications.LISTS.user){
			_self.templateNotifications.LISTS.user.forEach(function(itemUser, i){
				var notification = itemUser.data ;
				notification.AetnaUser_id = itemUser.field.val() ;
				notifs.push({
					AetnaStage_id : notification.AetnaStage_id,
					AetnaUser_id : itemUser.field.val() 
				}) ;
			});
		}
		
		
		
		_self.emit("validate", { 
			task : _self.doc,
			notifications : notifs}) ;
	} ;
}

module.exports = CreateDocView;
