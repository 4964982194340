var LokiDao = require("../LokiDao") ;
var moment = require("moment") ;

var LokiAetnaContractContract = function(loki){
    LokiDao.call(this, loki, "aetna.contract.contract") ;
    
    var _self = this ;
    
    this.contractFilters = function(filter){
        var searchFilters = [] ;
        
		if(filter.draft){
			searchFilters.push({ state : "draft" }) ;
		}
        
		if(filter.closed){
			searchFilters.push({ state : "closed" }) ;
		}
		
		if(filter.cancel){
			searchFilters.push({ state : "cancel" }) ;
		}
		
		
		if(filter.open || filter.toInvoice || filter.scheduleRenew){
			searchFilters.push({ state : "open" }) ;
		}
		
		
		var search = {} ;
		if(searchFilters.length>1){
			search = {
				$or : searchFilters	
			};
		}else if(searchFilters.length>0){
			search = searchFilters[0] ;
		}
		
		var whereFunction = function(invoice){ 
			var hasFilter = Object.keys(filter).some(function(k){
				return filter[k] ;
			}) ;
			if(!hasFilter){ return true ; }
			
			var state = _self.getExtendedState(invoice) ;
			
			if(filter.draft && state === "draft"){ return true ; }
			
			if(filter.waiting && state === "waiting"){ return true ; }
			
			if(filter.toInvoice && state === "toInvoice"){ return true ; }
			
			if(filter.scheduleRenew && state === "scheduleRenew"){ return true ; }
			
			if(filter.closed && state === "closed"){ return true ; }
			
			if(filter.cancel && state === "cancel"){ return true ; }
			
			if(filter.open && state === "open"){ return true ; }
			
			
			return false ;
		
		} ;
		
		
		return this.coll().chain().find(search).where(whereFunction).data() ;
    } ;
    
    
    this.getExtendedState = function(contract){
    	if(contract.state === "open"){
    		var allSchedules = loki.daos["aetna.contract.invoice.schedule"].search({contract_id : contract.id}) ;
    		var notInvoicedSchedules = loki.daos["aetna.contract.invoice.schedule"].search({contract_id : contract.id, invoice_id : false}, "date_schedule") ;
    		
    		if(notInvoicedSchedules.length>0 && moment(notInvoicedSchedules[0].date_schedule).isBefore(moment())){
    			return "toInvoice" ;
    		}
    		
    		if(allSchedules.length > 0 && notInvoicedSchedules.length === 0){
    			return "scheduleRenew" ;
    		}
    		
    		return "open" ;
		}
		return contract.state;
    } ;
    
    
    this.countByState = function(userId){
    	return this.coll().chain().find({}).mapReduce(
			function(contract){ 
				return { state : _self.getExtendedState(contract) } ;
			}, 
			function(contracts){ 
				var byState = {}; 
				contracts.forEach(function(d){ 
				    if(!byState[d.state]){ 
				   		byState[d.state] = 0; 
				    }
					byState[d.state]++;
				}); 
				return byState ;
		} ) ;
    } ;
    
    
} ;

module.exports = LokiAetnaContractContract ;