var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var str = require("aetna-string") ;

var model = "ir.model.fields" ;
var moduleName = "projectCustomField" ;

function ProjectCustomFieldGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"name",
			"field_description",
			"ttype"
    	],
	}) ;
}

function ProjectCustomFieldFormView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord: {
    		model: "project.task",
    		state : "manual"
    	}
	}) ;
	
	_self.on("initDone", function(){
		_self.fields.fields["ir.model.fields~ttype"].addEventListener("change", _self.onChangeType) ;
	}) ;
	
	var _load = this.load ;
	this.load = function(item){
		_load(item) ;
		_self.onChangeType() ;
	} ;
	
	this.onChangeType = function(){
		var type = _self.fields.fields["ir.model.fields~ttype"].val() ;
		if(type === "selection"){
			_self.EL.selection.style.display = "block" ;
			_self.fields.fields["ir.model.fields~selection"].setRequired(true) ;
		}else{
			_self.EL.selection.style.display = "none" ;
			_self.fields.fields["ir.model.fields~selection"].setRequired(false) ;
		}
		if(type === "char"){
			_self.EL.size.style.display = "block" ;
			_self.fields.fields["ir.model.fields~size"].setRequired(true) ;
		}else{
			_self.EL.size.style.display = "none" ;
			_self.fields.fields["ir.model.fields~size"].setRequired(false) ;
		}
	} ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		if(_self.isModif){
			_self.fields.fields["ir.model.fields~name"].setReadOnly(true) ;
			// _self.fields.fields["ir.model.fields~field_description"].setReadOnly(true) ;
			// _self.fields.fields["ir.model.fields~required"].setReadOnly(true) ;
			_self.fields.fields["ir.model.fields~ttype"].setReadOnly(true) ;
		}
	} ;

	
	var _getValues = this.getValues ;
	this.getValues = function(){
		var values = _getValues() ;
		
		values.recordToSave.name = "x_"+values.recordToSave.name ;
		values.recordToSave.selection = "["+
		values.recordToSave.selection
			.map(function(s){return "('"+str.toASCII(s).replace(/'/g, "\\'")+"','"+s.replace(/'/g, "\\'")+"')"})
			.join(",")+
			"]" ;
		
		if(values.recordToSave.ttype !== "char"){
			delete values.recordToSave.size ;
		}
		if(values.recordToSave.ttype !== "selection"){
			delete values.recordToSave.selection ;
		}
		
		return values ;
	} ;
}

module.exports.Grid = ProjectCustomFieldGridView;
module.exports.Form = ProjectCustomFieldFormView;
