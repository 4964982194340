var RemindSettingsViews = require("../views/remindSettings/remindSettingsViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;
var t = require("aetna-views/locales/tr").t ;
var DistantFile = require("../api/DistantFile") ;

function RemindSettingsController() {
	var _self = this ;
	BaseController.call(this, {
		model : "account.invoice.aetna.remind.settings",
		socketChangeEvent : 'remindSettingsChanged',
		name : "remindSettings",
		gridClass : RemindSettingsViews.Grid,
		formClass : RemindSettingsViews.Form
	}) ;
	
	var gui = new BaseGui() ;
	_self.viewList = gui ;
	_self.viewList.hide = function(){} ;
	_self.viewList.show = function(){} ;
	
	BUS.on("loaded", function(){
		_self.socket.on('remindSettings-pdfStart', _self.onPdfStart);
		_self.socket.on('remindSettings-pdfFinished', _self.onPdfFinished);
	}) ;
	
	this.onPdfStart = function(doc){
		if(_self.viewForm){
			_self.viewForm.onPdfStart(doc) ;
		}
	} ;
	
	this.onPdfFinished = function(doc){
		if(_self.viewForm){
			_self.viewForm.onPdfFinished(doc) ;
		}
	} ;
	
	this.doSync = function(forceRefresh, callback){
		loki.sync([
			"account.invoice.aetna.remind.settings", 
			"ir.attachment"
		], forceRefresh, callback) ;
	} ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(function(){
			_self.viewForm.on("openTemplateWeb", _self.openTemplateWeb) ;
			
			callback() ;
		}) ;
	} ;
	
	this.onSave = function(ev){
		_self.viewForm.startWait();
		
		if(!ev.data.recordToSave){
			ev.data.recordToSave = {} ;
		}
		
		if(ev.data.isCreate){
			cli(_self.options.model).create(ev.data.recordToSave,  ev.data.uploadFile, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				_self.doSync(function(){
					_self.viewForm.load(savedRecord) ;
					_self.viewForm.endWait() ;	
				}) ;
			}) ;
		}else{
			cli(_self.options.model).update( ev.data.recordToSave, 
				ev.data.uploadFile, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				
				_self.doSync(function(){
					_self.viewForm.load(savedRecord) ;
					_self.viewForm.endWait() ;
				}) ;
			}) ;
		}	
	} ;
	
	this.openTemplateWeb = function(ev){
		gui.startWait() ;
		
		DistantFile.openDistantFile("account.invoice.aetna.remind.settings", ev.data.id, function(err){
			if(err){ return gui.endWaitError(err) ;}
			gui.endWait() ;
		}) ;
		
		
	} ;
}

module.exports = new RemindSettingsController();
