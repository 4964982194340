var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var str = require("aetna-string") ;
var PdfView = require("aetna-views/views/pdf/pdf") ;
var userUtils = require("../../utils/userUtils") ;
var moment = require("moment") ;

var model = "account.invoice" ;
var moduleName = "facto" ;

function InvoiceFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		state : "draft",
    		type : "out_invoice",
    		date_invoice : new Date(),
    		invoice_line : [{}]
    	},
    	firstField : "account.invoice~name"
	}) ;
	
	this.customHeaderButtons = '<div class="facto_factoForm__actionForm">'+
	'<button class="btn btn-primary btn-sm pull-left" id="validateAction">'+
	'<i class="fa fa-check-circle"></i> <span data-i18n="facto.validate"></button>'+
	
	'<button class="btn btn-danger btn-sm pull-left" id="cancelAction">'+
	'<i class="fa fa-trash"></i> <span data-i18n="facto.cancel"></button>'+
	
	'<button class="btn btn-danger btn-sm pull-left" id="refundAction">'+
	'<i class="fa fa-undo"></i> <span data-i18n="facto.refundInvoice"></button>'+
	
	'<button class="btn btn-primary btn-sm pull-left" id="printAction">'+
	'<i class="fa fa-print"></i> <span data-i18n="facto.print"></button>'+
	
	'</div>' ;
	
		
	this.on("initDone", function(){
		_self.templateLines = new TemplateFields();
		_self.templatePay = new TemplateFields();
		_self.templateRefund = new TemplateFields();
		_self.templateReminds = new TemplateFields();
		_self.templatePdfs = new TemplateWidget(_self.EL.tabsPdf);
		
		_self.templateLines.init(_self.EL.lines, _self.fields) ;
		_self.templatePay.init(_self.EL.payments, _self.fields) ;
		_self.templateRefund.init(_self.EL.refunds, _self.fields) ;
		_self.templateReminds.init(_self.EL.reminds, _self.fields) ;

		_self.templateLines.on("initField", _self.onLineFieldInit) ;
		_self.templateLines.on("lineChange", _self.onLineChange) ;
		_self.EL.validateAction.addEventListener("click", _self.onValidateInvoice) ;
		_self.EL.cancelAction.addEventListener("click", _self.onCancelInvoice) ;
		_self.EL.refundAction.addEventListener("click", _self.onRefundInvoice) ;
		_self.EL.printAction.addEventListener("click", _self.onPrintInvoice) ;
		
		_self.templatePdfs.on("render", _self.onRenderPdf) ;
		_self.templateReminds.template.on("render", _self.onRenderReminds) ;
		_self.templateRefund.template.on("render", _self.onRenderRefunds) ;
		
		var hasRightContract = userUtils.hasRight("moduleContract") ;
		if(hasRightContract){
			_self.EL.contractContainer.style.display = "block" ;
		}else{
			_self.EL.contractContainer.style.display = "none" ;
		}
		
		var hasRightProject = userUtils.hasRight("moduleDico") ;
		if(hasRightProject){
			_self.EL.projectContainer.style.display = "block" ;
		}else{
			_self.EL.projectContainer.style.display = "none" ;
		}
		
		
		// _self.pdfView = new PdfView(t) ;
		// _self.pdfView.init(_self.EL.pdf) ;
	}) ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		
		if(_self.isCreate){
		}else if(_self.isModif){
		}else {
			if(_self.item.state === "open"){
				_self.EL.refundAction.style.display = "block" ;
			}else{
				_self.EL.refundAction.style.display = "none" ;
			}
			
			if(_self.item.state !== "draft"){
				_self.EL.modify.style.display = "none" ;
				_self.EL.validateAction.style.display = "none" ;
				_self.EL.cancelAction.style.display = "none" ;
				
				if(_self.item.state !== "cancel"){
					_self.EL.tabsPdf.style.display = "block" ;
					_self.EL.residual.style.display = "block" ;
					_self.EL.printAction.style.display = "block" ;
				}else{
					_self.EL.tabsPdf.style.display = "none" ;
					_self.EL.residual.style.display = "none" ;
					_self.EL.printAction.style.display = "none" ;
				}
			}else{
				_self.EL.modify.style.display = "block" ;
				_self.EL.tabsPdf.style.display = "none" ;
				_self.EL.residual.style.display = "none" ;
				_self.EL.validateAction.style.display = "block" ;
				_self.EL.cancelAction.style.display = "block" ;
				_self.EL.printAction.style.display = "none" ;
			}
		}
		
		_self.EL.delete.style.display = "none" ;
	} ;
	
	var _setReadOnly = this.setReadOnly ;
	this.setReadOnly= function(readOnly){
		_setReadOnly(readOnly) ;
		_self.templateLines.setReadOnly(readOnly) ;
	} ;
	
	this.onLineFieldInit = function(ev){
		if(ev.data.name === "account.invoice.line~product_id"){
			ev.data.field.addEventListener("change", function(){
				var line = ev.data.def.data ;
				var productId = ev.data.field.val() ;
				if(productId){
					var product = _self.productsById[productId] ;
					if(product){
						if(!line.name){
							line.name = product.name ;
						}
						line.price_unit = product.lst_price ;
						line.invoice_line_tax_id = product.taxes_id ;
						
						_self.templateLines.refreshLine(ev.data.def) ;
						
						if(!line.quantity){
							_self.templateLines.focus(ev.data.def, "quantity") ;
						}
					}
				}
			}) ;
		}
	} ;
	
	this.onLineChange = function(ev){
		if(ev.data.col === "quantity" || ev.data.col === "price_unit"){
			var line = ev.data.line ;
			if(line.quantity && line.price_unit){
				line.price_subtotal = line.quantity * line.price_unit ;
				_self.templateLines.refreshLine(ev.data.def) ;
			}
		}
		
		var lines = _self.templateLines.val() ;
		
		var total_untaxed = 0;
		var total_tax = 0;
		var total_total = 0;
		var total_tax_product = 0;
		var total_tax_service = 0;
		
		lines.invoice_line.forEach(function(line){
			if(line.price_subtotal){
				total_untaxed += line.price_subtotal ;
				
				if(line.product_id){
					var product = _self.productsById[line.product_id] ;
					var lineTaxes = 0 ;
					if(product.taxes_id){
						product.taxes_id.forEach(function(taxId){
							var tax = _self.taxesById[taxId] ;
							lineTaxes += line.price_subtotal * tax.amount ;
						}) ;
					}
					
					total_tax += lineTaxes ;
					if(product.type === "service"){
						total_tax_service += lineTaxes ;
					}else{
						total_tax_product += lineTaxes ;
					}
				}
			}
		}) ;
		
		total_total = total_untaxed + total_tax ;
		
		_self.fields.fields["account.invoice~amount_untaxed"].load(total_untaxed) ;
		_self.fields.fields["account.invoice~amount_tax"] .load(total_tax) ;
		_self.fields.fields["account.invoice~amount_total"].load(total_total) ;
		_self.fields.fields["account.invoice~AetnaTaxProductTotal"].load(total_tax_product) ;
		_self.fields.fields["account.invoice~AetnaTaxServiceTotal"].load(total_tax_service) ;
	} ;
	
	
	var normalLoad = this.load ;
	this.load = function(item){
		normalLoad(item) ;
		
		_self.templateLines.load(item) ;
		_self.templatePay.load(item) ;
		_self.templateRefund.load(item) ;
		_self.templateReminds.load(item) ;
		_self.templatePdfs.render(item) ;
		
		if(!item.refunds || item.refunds.length === 0){
			_self.EL.refunds.style.display = "none" ;
		}else{
			_self.EL.refunds.style.display = "block" ;
		}
		
		if(item.id){
			//_self.pdfView.showPdf(serverUrl+"/api/account.invoice/downloadPdf?id="+item.id) ;
		}
	} ;
	
	this.onRenderReminds = function(){
		if(_self.templateReminds.template.EL.genRemind){
			_self.templateReminds.template.EL.genRemind.addEventListener("click", function(){
				_self.confirm(t("facto.areYouSureGenerateNextRemind"), function(yes){
					if(yes){
						_self.emit("genRemind", _self.item) ;
					}
				}) ;
			}) ;
		}
	} ;
	
	this.onRenderRefunds = function(){
		if(_self.templateRefund.template.LISTS.useRefund){
			_self.templateRefund.template.LISTS.useRefund.forEach(function(useRefund){
				useRefund.el.addEventListener("click", function(){
					_self.confirm(t("facto.areYouSureUseThisRefund"), function(yes){
						if(yes){
							_self.emit("useRefund",
								{invoice : _self.item, refund : useRefund.data}) ;
						}
					}) ;
				}) ;
			}) ;
		}
	} ;
	
	this.onRenderPdf = function(){
		_self.remindPdf = {}  ;
		if(_self.item.id && _self.item.state !== "draft" && _self.item.state !== "cancel"){
			var pdfView = new PdfView(t) ;
			pdfView.init(_self.templatePdfs.EL.pdfMain, function(){
				pdfView.showPdf(serverUrl+"/api/account.invoice/downloadPdf?id="+_self.item.id) ;
			}) ;
		}
		if(_self.templatePdfs.LISTS.pdfRemind){
			_self.templatePdfs.LISTS.pdfRemind.forEach(function(item){
				var pdfViewRemind = new PdfView(t) ;
				_self.remindPdf[item.data.id] = pdfViewRemind ;
				pdfViewRemind.init(item.el, function(){
					pdfViewRemind.showPdf(serverUrl+"/api/account.invoice.aetna.remind/downloadPdf?id="+item.data.id) ;
				}) ;
			}) ;
		}
		if(_self.templatePdfs.LISTS.printRemind){
			_self.templatePdfs.LISTS.printRemind.forEach(function(item){
				item.el.addEventListener("click", function(){
					window.location.href = serverUrl+"/api/account.invoice.aetna.remind/downloadPdf?id="+item.data.id ;
				}) ;
			}) ;
		}
		if(_self.templatePdfs.LISTS.openRemind){
			_self.templatePdfs.LISTS.openRemind.forEach(function(item){
				item.el.addEventListener("click", function(){
					_self.emit("openRemindWord", item.data) ;
				}) ;
			}) ;
		}
	} ;
	
	this.onPdfRemindStart = function(remind){
		if(remind && _self.remindPdf[remind.id]){ // /!\ compare string and number
			_self.remindPdf[remind.id].startWait() ;
		}
	} ;
	
	this.onPdfRemindFinished = function(remind){
		if(remind && _self.remindPdf[remind.id]){ // /!\ compare string and number
			_self.remindPdf[remind.id].showPdf(serverUrl+"/api/account.invoice.aetna.remind/downloadPdf?id="+remind.id) ;
			_self.remindPdf[remind.id].endWait() ;
		}
	} ;
	
	var _getValues = this.getValues;
	this.getValues = function(){
		var values = _getValues() ;
		
		var lines = _self.templateLines.val().invoice_line ;
		values.recordToSave.invoice_line = lines.slice(0,lines.length-1) ;
		
		return values ;
	} ;
	
	var _checkInput = this.checkInput ;
	this.checkInput = function(){
		var error = _checkInput() ;
		if(error){ return error ;}
		
		var values = _self.getValues() ;
		
		if(values.recordToSave.invoice_line.length === 0){
			return t("facto.pleaseAddLine") ;
		}
		
		if(moment(values.recordToSave.date_invoice).isBefore(moment(_self.lastInvoiceDate), 'day')){
			return t("facto.dateMustBeAfter", {lastInvoiceDate : moment(_self.lastInvoiceDate).format("L")}) ;
		}
	} ;
	
	this.onValidateInvoice = function(){
		_self.confirm(t("facto.areYouSureValidate"), function(yes){
			if(yes){
				_self.emit("validate", _self.item) ;
			}
		});
	} ;
	
	this.onCancelInvoice = function(){
		_self.confirm(t("facto.areYouSureCancel"), function(yes){
			if(yes){
				_self.emit("cancel", _self.item) ;
			}
		});
	} ;
	
	this.onRefundInvoice = function(){
		_self.confirm(t("facto.areYouSureRefund"), function(yes){
			if(yes){
				_self.emit("refund", _self.item) ;
			}
		});
	} ;
	
	this.onPrintInvoice = function(){
		window.location.href = serverUrl+"/api/account.invoice/downloadPdf?id="+_self.item.id ;
	} ;
	
}

module.exports = InvoiceFormView;
