var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;
var formSubmit = require("aetna-ajax/ajax.js").formSubmit;
var DistantFile = require("./DistantFile") ;
var async = require("async") ;

function ProjectTask(serverUrl){
	CrudCli.call(this, serverUrl, "project.task");
	
	this.create = function(task, notifications, template, existingDoc, uploadFile, callback, callbackProgress){
		var params = {
		    task : task, 
		    notifications : notifications, 
		    template : template, 
		    existingDoc: existingDoc, 
		    uploadFile: uploadFile
		} ;
		
		//if(window.isDesktop){
			//desktop mode, the PDF creation will be done by the client after
			params.noPdf = true ;
		//}
		
        ajax(serverUrl + "/api/project.task/create", "POST", params, function(err, result){
		    if(err){ return callback(err); }
		    
		    // if(window.isDesktop){
		    //     DistantFile.downloadDoc("project.task", result.id, function(err){
		    //     	if(err){ return callback(err); }
		        	
		    //     	callback(null, result); //give back for user, PDF convert will be done in background
		        	
		    //     	// DistantFile.convertToPdf("project.task", result.id, function(err){
			   //     // 	console.log("convert PDF done", err) ;
			   //     // }) ;
		    //     }) ;
		    // }else{
		        callback(null, result) ;
		    // }
		    
		}, callbackProgress, true, 1200000);
    };
    
	this.update = function(task, notifications, callback){
        ajax(serverUrl + "/api/project.task/update", 
		{task : task, notifications : notifications}, callback);
    };
	
	
	this.applyAction = function(taskId, action,subject, body, callback){
		//make sure the file is uploaded before affect to someone else
		DistantFile.syncNow("project.task", function(err){
			if(err){ return callback(err); }
	        ajax(serverUrl + "/api/project.task/applyAction", {
	        	taskId : taskId, 
	        	action : action,
	        	mailSubject : subject,
	        	mailBody : body
	        }, callback);
		});
    };
	
	this.listInProgress = function(login, status, callback){
		if(typeof(status) === "function"){
			callback = status;
			status = null;
		}
        ajax(serverUrl + "/api/project.task/listInProgress", {login : login, status : status}, callback);
    };
	
	this.getByPksWithDoc = function(ids, callback){
        ajax(serverUrl + "/api/project.task/getByPksWithDoc", {ids : ids}, callback);
    };
	
	this.countUserDocByStatus = function(login, callback){
        ajax(serverUrl + "/api/project.task/countUserDocByStatus", {login : login}, callback);
    };
	
	this.getWebDavDoc = function(id, callback){
        ajax(serverUrl + "/api/project.task/getWebDavDoc", {id : id},callback);
    };
	
	this.shareDoc = function(ids, callback){
		if(!Array.isArray(ids)){
			ids = [ids] ;
		}
		
		var tasksPdfOk = [] ;	
		var calls = [function(cb){ cb() ;}] ;
		if(window.isDesktop){
			//desktop mode, create the PDF before share
			ids.forEach(function(id){
				calls.push(function(cb){
					DistantFile.convertToPdf("project.task", id, function(err){
						if(err){ return cb(err); }
						tasksPdfOk.push(id) ;
						cb() ;
					}) ;
				}) ;
			}) ;
		}
		
		async.parallel(calls, function(err){
			if(err){ 
				console.log("error while convert PDF", err); //only log, don't stop on PDF not available
			}
			
        	ajax(serverUrl + "/api/project.task/shareDoc", {id : ids, convertPdf : !window.isDesktop}, function(err, result){
        		if(err){ return callback(err); }
        		if(!window.isDesktop){
        			tasksPdfOk = result.convertedPdf; //files are converted by the server
        		}
        		callback(null, result.results, tasksPdfOk) ;
        	});
		}) ;
    };

	this.unshareDoc = function(id, callback){
        ajax(serverUrl + "/api/project.task/unshareDoc", {id : id},callback);
    };
	
	this.addFollower = function(taskId, userId, callback){
        ajax(serverUrl + "/api/project.task/addFollower", {
        	taskId : taskId,
        	userId : userId
        },callback);
    };
    
	this.removeFollower = function(taskId, userId, callback){
        ajax(serverUrl + "/api/project.task/removeFollower", {
        	taskId : taskId,
        	userId : userId
        },callback);
    };
	
	this.downloadDoc = function(id, callback){
        formSubmit(serverUrl + "/api/project.task/downloadDoc", {id : id});
    };
	
}

module.exports = ProjectTask ;