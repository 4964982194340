var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var PdfView = require("aetna-views/views/pdf/pdf") ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var loki = require("../../api/loki") ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var callIf = require("aetna-func/func").callIf ;

var moduleName = "dms" ;

function DmsFormView(model, wkFields, actFields, serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model
	}) ;
	
	this.customHeaderButtons = '<div class="dms_dmsForm__actionForm">'+
	'<button class="btn btn-primary btn-sm pull-left" id="validateAction">'+
	'<i class="fa fa-check-circle"></i> <span data-i18n="dms.validate"></button>'+
	
	'<button class="btn btn-danger btn-sm pull-left" id="rejectAction">'+
	'<i class="fa fa-trash"></i> <span data-i18n="dms.reject"></button>'+
	
	'</div>' ;
	
	_self.templatesSub = [] ;
	
	var _initElements = this._initElements ;
	this._initElements = function(callback){
		_self.templateFields = new TemplateWidget(_self.EL.fields);

		
		_self.templateFields.on("render", _self.renderFields);
		_self.EL.validateAction.addEventListener("click", _self.onValidate);
		
		_initElements(callback) ;
	} ;
	
	this.onFieldChange = function(field){
		_self.emit("fieldChange", {
			field : field, 
			code : field.code, 
			activity : _self.item.state,
			fields : _self.getValues().recordToSave
		}) ;
	} ;
	
	this.addFieldError = function(fieldCodes, msg){
		_self.fields.usedFields.forEach(function(f){
			if(fieldCodes.indexOf(f.code) !== -1){
				f.error(msg) ;
			}
		}) ;
	} ;
	
	this.clearFieldError = function(fieldCodes){
		_self.fields.usedFields.forEach(function(f){
			if(fieldCodes.indexOf(f.code) !== -1){
				f.clearError() ;
			}
		}) ;
	} ;
	
	this.renderFields = function(){
		if(_self.templateFields.LISTS.subfield){
			_self.templateFields.LISTS.subfield.forEach(function(subfield){
				
				var templateSubfields = new TemplateFields();
				
				subfield.el.innerHTML = subfield.el.innerHTML.replace(/&#123;/g, "{")
					.replace(/<!--%%%START%%%subfields%%%[0-9]+%%%-->/g, "")
					.replace(/<!--%%%END%%%subfields%%%[0-9]+%%%-->/g, "") 
					.replace(/<!--{{#line}}-->/g, "<!--{{#"+subfield.data.name+"}}-->") 
					.replace(/<!--{{\/line}}-->/g, "<!--{{/"+subfield.data.name+"}}-->") ;
				
				templateSubfields.init(subfield.el, _self.fields) ;
				
				_self.templatesSub.push(templateSubfields) ;
				
				templateSubfields.fieldName = subfield.data.name ;
				/*subfield.el;
				console.log("subfield>>>",subfield.data) ;*/
			}) ;
		}
	} ;
	
	this.on("initDone", function(){
		_self.pdfView = new PdfView(t) ;
		_self.pdfView.init(_self.EL.pdfMain) ;
	}) ;
	
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		_self.EL.validate.style.display = "none" ;
		_self.EL.cancel.style.display = "none" ;
		_self.EL.modify.style.display = "none" ;
		_self.EL.delete.style.display = "none" ;
		_self.EL.btnRead.style.display = "block" ;
		_self.EL.returnToList.style.display = "block" ;
	} ;
	
	
	var normalLoad = this.load ;
	this.load = function(item){
		var fieldsToDisplay = wkFields ;
		
		if(actFields[item.state]){
			fieldsToDisplay = actFields[item.state] ;		
		}
		
		
		callIf((_self.fieldsToDisplay !== fieldsToDisplay), function(cb){
			_self.fieldsToDisplay = fieldsToDisplay ;
			
			_self.templateFields.render({fields : fieldsToDisplay}) ;
			
			_self.fields = new FieldsEMA(t, serverUrl) ;
			
			_self.fields.init(_self, function(){
				_self.fields.usedFields.forEach(function(f){
					if(f.container.getAttribute("readonly") || (f.container.getAttribute("data-readonly") === "true")){
						f.alwaysReadOnly = true ;
					}
				}) ;
				cb() ;
	        }) ;
		}, function(){
			//fields are initialized, load data
			
			normalLoad(item) ;
			
			if(item.id){
				_self.pdfView.showPdf(serverUrl+"/api/aetna.dms.scan.base/downloadPdf?model="+model+"&id="+item.id) ;
			}
			
			_self.templatesSub.forEach(function(template){
				if(!item[template.fieldName]){
					item[template.fieldName] = [] ;
				}
				item[template.fieldName].push({}) ;
				
				template.load(item) ;
			}) ;
			
			_self.fields.usedFields.forEach(function(f){
				f.addEventListener("change", function(){
					_self.onFieldChange(f) ;
				}) ;
			}) ;
			
			_self.onModify() ;
		});
	} ;
	
	
	var _getValues = this.getValues;
	this.getValues = function(){
		var values = _getValues() ;
		
		
		_self.templatesSub.forEach(function(template){
			var lines = template.val()[template.fieldName] ;
			
			values.recordToSave[template.fieldName] = lines.slice(0,lines.length-1) ;
		}) ;
		
		
		return values ;
	} ;
	
	
	this.checkInput = function(){
		var fieldInError = false ;
		_self.fields.usedFields.forEach(function(f){
			if(f.hasError()){
				fieldInError = true ; 
			}else{
				if(!f.isReadOnly() && f.isRequired() && f.isEmpty()){
					f.error() ;	
					fieldInError = true ;
				}
				if(!f.isEmpty() && !f.isValid()){
					f.error(t("invalidFormat")) ;
					fieldInError = true ;
				}
			}
		}) ;
		
		if(fieldInError){
			return t(_self.options.module+".thereIsErrorPleaseCorrect") ;
		}
		
	} ;
	
}

module.exports = DmsFormView;
