var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var moment = require("moment") ;

function FiscalYearGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : "fiscalYear",
    	model : "account.fiscalyear",
    	colsToShow : [
    		"name",
			"code",
			"date_start",
			"date_stop",
			"state"
    	],
	}) ;
}

function FiscalYearFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : "fiscalYear",
    	model : "account.fiscalyear",
    	newRecord : { periods : [{}]}
	}) ;
	
	this.on("initDone", function(){
		_self.templatePeriods = new TemplateFields();
		_self.templatePeriods.init(_self.EL.periods, _self.fields) ;
		_self.EL.genPeriods.addEventListener("click", _self.genPeriods) ;
	});
	
	_self.genPeriods = function(){
		var start = moment(_self.fields.fields["account.fiscalyear~date_start"].val()) ;
		var stop = moment(_self.fields.fields["account.fiscalyear~date_stop"].val()) ;
		var periods = _self.templatePeriods.val().periods ;
		periods =  periods.slice(0,periods.length-1) ;
		var created = false ;
		while (start.isBefore(stop)){
			if(!periods.some(function(p){
				return moment(p.date_start).isSame(start, "day") ;
			})){
				periods.push({
					name : start.format("MM/YYYY"),
					date_start : moment(start).toDate(),
					date_stop : moment(start).endOf("month").toDate(),
				}) ;
				created = true ;
			} 
			
			start.add(1, "month") ;
		}
		if(created){
			periods.push({}) ;
			_self.templatePeriods.load({periods : periods}) ;
		}
	} ;
	
	var _setReadOnly = this.setReadOnly ;
	this.setReadOnly= function(readOnly){
		_setReadOnly(readOnly) ;
		_self.templatePeriods.setReadOnly(readOnly) ;
	} ;
	
	var _load = this.load ;
	this.load = function(item){
		_load(item) ;
		_self.templatePeriods.load(item) ;
	} ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		if(_self.item && _self.item.periods && _self.item.periods.some(function(p){ return p.hasMove ;})){
			_self.EL.delete.style.display = "none" ;
		}
	} ;
	
	var _getValues = this.getValues;
	this.getValues = function(){
		var values = _getValues() ;
		
		var periods = _self.templatePeriods.val().periods ;
		values.recordToSave.periods = periods.slice(0,periods.length-1) ;
		
		return values ;
	} ;
	
	var _checkInput = this.checkInput ;
	this.checkInput = function(){
		var msg = _checkInput() ;
		if(msg) { return msg; }
		
		var values = _getValues() ;
		
		var periods = _self.templatePeriods.val().periods ;
		periods = periods.slice(0,periods.length-1) ;
		periods.some(function(p){
			if(!p.date_start){
				msg = t("fiscalYear.inputAllPeriodDates") ;
				return true ;
			}
			if(!p.date_stop){
				msg = t("fiscalYear.inputAllPeriodDates") ;
				return true ;
			}
			
			if(moment(p.date_start).isBefore(moment(values.recordToSave.date_start), "day")){
				msg = t("fiscalYear.periodMustBeInYear") ;
				return true ;
			}
			if(moment(p.date_stop).isAfter(moment(values.recordToSave.date_stop), "day")){
				msg = t("fiscalYear.periodMustBeInYear") ;
				return true ;
			}
		}) ;
		
		return msg;
		
	} ;
}

module.exports.Grid = FiscalYearGridView;
module.exports.Form = FiscalYearFormView;
