var BaseCli = require("aetna-ajax/BaseCli") ;
var Auth = require("./auth") ;
var Synchro = require("./Synchro") ;
var AccountImport = require("./AccountImport") ;
var ProjectTask = require("./ProjectTask") ;
var ResUsers = require("./ResUsers") ;
var DocumentAetnaTemplate = require("./DocumentAetnaTemplate") ;
var AccountInvoiceAetnaTemplate = require("./AccountInvoiceAetnaTemplate") ;
var AccountRefundAetnaTemplate = require("./AccountRefundAetnaTemplate") ;
var AccountInvoice = require("./AccountInvoice") ;
var AccountInvoiceAetnaRemindSettings = require("./AccountInvoiceAetnaRemindSettings") ;
var AccountInvoiceAetnaRemind = require("./AccountInvoiceAetnaRemind") ;
var AccountInvoiceAetnaTvaExport = require("./AccountInvoiceAetnaTvaExport") ;
var AccountMoveLine = require("./AccountMoveLine") ;
var AetnaContractTemplate = require("./AetnaContractTemplate") ;
var AetnaContractContract = require("./AetnaContractContract") ;
var IrModelFields = require("./IrModelFields") ;
var SaleOrderAetnaTemplate = require("./SaleOrderAetnaTemplate") ;
var SaleOrder = require("./SaleOrder") ;

function Cli(serverUrl){
    BaseCli.call(this, serverUrl) ;
	
	this.auth = new Auth(serverUrl) ;
	
	this["synchro"] = new Synchro(serverUrl) ;
	this["account.import"] = new AccountImport(serverUrl) ;
	
	this["project.task"] = new ProjectTask(serverUrl) ;
	this["res.users"] = new ResUsers(serverUrl) ;
	this["document.aetna.template"] = new DocumentAetnaTemplate(serverUrl) ;
	this["account.invoice"] = new AccountInvoice(serverUrl) ;
	this["account.invoice.aetna.template"] = new AccountInvoiceAetnaTemplate(serverUrl) ;
	this["account.refund.aetna.template"] = new AccountRefundAetnaTemplate(serverUrl) ;
	this["account.invoice.aetna.remind.settings"] = new AccountInvoiceAetnaRemindSettings(serverUrl) ;
	this["account.invoice.aetna.remind"] = new AccountInvoiceAetnaRemind(serverUrl) ;
	this["account.invoice.aetna.tvaexport"] = new AccountInvoiceAetnaTvaExport(serverUrl) ;
	this["account.move.line"] = new AccountMoveLine(serverUrl) ;
	this["aetna.contract.template"] = new AetnaContractTemplate(serverUrl) ;
	this["aetna.contract.contract"] = new AetnaContractContract(serverUrl) ;
	this["ir.model.fields"] = new IrModelFields(serverUrl) ;
	this["sale.order.aetna.template"] = new SaleOrderAetnaTemplate(serverUrl) ;
	this["sale.order"] = new SaleOrder(serverUrl) ;
}

module.exports = Cli ;