var LokiDao = require("../LokiDao") ;

var LokiAccountInvoiceAetnaTemplate = function(loki){
    LokiDao.call(this, loki, "account.invoice.aetna.template") ;
    
    this.getDoc = function(template){
     	return loki.daos["ir.attachment"].searchFirst({res_model : "account.invoice.aetna.template", res_id : template.id});
    } ;
} ;

module.exports = LokiAccountInvoiceAetnaTemplate ;
