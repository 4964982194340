var t = require("aetna-views/locales/tr").t ;
var FieldGeneric = require("aetna-views/fields/generic") ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var str = require("aetna-string") ;
var moment = require("moment") ;
var BaseView = require("aetna-views/BaseView.js") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var loki = require("../../api/loki") ;

var model = "account.invoice.aetna.tvaexport" ;
var moduleName = "accountingVAT" ;

function OneGridView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
    		'type',
			'invoice_id',
			'date_ready',
			'amount_untaxed',
			'amount_tax',
			'state',
			'date_done'
    	],
    	gridOptions : {
    		show : {
    			selectColumn : false,
    			toolbarReload  : false, 
    			toolbarColumns : false
    		}
    	},
    	summary : {
	    	colsToSum : ["amount_tax", "amount_untaxed"],
	    	colsToCount : ["invoice_name"]
    	}
	}) ;
	
	_self.allTaxes = {} ;
	_self.gridName = model.replace(/\./g, "_")+"__grid_list" ;
	
	_self.fields.fields["account.invoice.aetna.tvaexport~~grid"].colsCustom = [
		{field : "type",size :"80px"},
		{field : "amount_tax",size :"80px"},
		{field : "amount_untaxed",size :"80px"},
		{field : "date_ready",size :"120px"},
		{field : "date_done",size :"120px"},
		{field : "invoice_name",size :"20%", render:function(record) {
			var invoice = loki.daos["account.invoice"].getById(record.invoice_id) ;
			if(invoice){
				return invoice.AetnaRef+" "+invoice.name ;
			}
		}},
		{field : "state", caption : t("fields.account.invoice.aetna.tvaexport.state"), size :"80px", render: function(record){
			var state = record.state ; 
			
			if(!state){ return ""; }
			
			var color = "default" ;
			if(state === "todo"){
				color = "info" ;
			}else if( state === "done" ){
				color = "success" ;
			}
			
			return '<div class="bg-'+color+'" style="height: 37px;line-height: 31px;">'+
			t("fields.values.account.invoice.aetna.tvaexport.state."+state)+'</div>' ;
		}}
	] ;
}


function AccoutingVATGridView(serverUrl, schemas){
	var _self = this ;
	BaseView.call(this, t, moduleName, moduleName+"Grid") ;
	
	_self.allTaxes = {} ;
	
	var filtersLinks = ["filterTodo", "filterDone"] ;
	
	this._initElements = function(){
		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey){
					filtersLinks.forEach(function(otherId){
						if(id !== otherId){
							_self.$EL[otherId].removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
		
		_self.EL.detailsFilters.addEventListener("click", _self.toggleDetailsFilter);
		
		_self.dateStart = new FieldGeneric(t, "date_start", "date", t("accountingVAT.from")) ;
		_self.dateEnd = new FieldGeneric(t, "date_end", "date", t("accountingVAT.to")) ;
		
		_self.dateStart.init(_self.EL.dateStart);
		_self.dateEnd.init(_self.EL.dateEnd);
		
		_self.dateStart.load(moment().subtract(1, 'month').startOf('month').toDate()) ;
		_self.dateEnd.load(moment().subtract(1, 'month').endOf('month').toDate()) ;
		
		_self.dateStart.addEventListener("change", function(){
			_self.emit("changeFilter", {filter : _self.getFilter()}) ;
		});
		_self.dateEnd.addEventListener("change", function(){
			_self.emit("changeFilter", {filter : _self.getFilter()}) ;
		});
		
		_self.templateGrids = new TemplateWidget(_self.EL.grids);
		_self.templateGrids.on("render", _self.renderGrids) ;

		
		_self.EL.doExport.addEventListener("click", _self.doExport) ;
	} ;
	
	this.getFilter = function(){
		var filter = {} ;
		
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;	
			if($a.hasClass("active")){
				filter[str.unCapitalize(id.replace(/^filter/, ""))] = true ;
			}
		});
		
		filter.from = _self.dateStart?_self.dateStart.val():moment().subtract(1, 'month').startOf('month').toDate() ;
		filter.to = _self.dateEnd?_self.dateEnd.val():moment().subtract(1, 'month').endOf('month').toDate() ;
		
		return filter ;
	} ;
	
	this.toggleDetailsFilter = function(){
		_self.$EL.filters.toggleClass("list-group-horizontal") ;
		if(_self.$EL.filters.hasClass("list-group-horizontal")){
			_self.EL.detailsFilters.innerHTML = t("facto.viewDetails") ;
		}else{
			_self.EL.detailsFilters.innerHTML = t("facto.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	
	this.resizeTable = function(){
		
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		_self.EL.gridRow.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields["account.invoice.aetna.tvaexport~~grid"].render() ;
	} ;
	
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		filtersLinks.forEach(function(id){
			var $span = _self.$EL[id].find(".badge") ;
			var count = counts[str.unCapitalize(id.replace(/^filter/, ""))] || 0 ;
			if($span.html() != count){// != because compare string and integer
				$span.html(count) ;
				$span.addClass("animated") ;
				$span.addClass("flash") ;
				
				$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$span.removeClass("animated") ;
					$span.removeClass("flash") ;
				});	
			}
		}) ;
	} ;
	
	this.load = function(records){
		var recordsByTaxes = {} ;
		
		records.forEach(function(record){
			var key = record.tax_id+"_"+record.type ;

			var tax = _self.allTaxes[record.tax_id] ;
			if(tax && tax.amount){ 
				record.amount_untaxed = record.amount_tax / tax.amount ; 
			}else{
				record.amount_untaxed = 0 ; 
			}

		 	if(!recordsByTaxes[key]){
		 		recordsByTaxes[key] = [] ;
		 	}
		 	recordsByTaxes[key].push(record) ;
		}) ;
		
		var gridsRecords = [] ;
		Object.keys(recordsByTaxes).sort().forEach(function(key){
			gridsRecords.push({
				key : key,
				type : t("fields.values.account.invoice.aetna.tvaexport.type."+recordsByTaxes[key][0].type),
				tax_name : recordsByTaxes[key][0].tax_name,
				records : recordsByTaxes[key]
			}) ;
		}) ;
		
		_self.gridsRecords = gridsRecords ;
		
		_self.templateGrids.render({ grids : gridsRecords}) ;
	} ;
	
	
	this.renderGrids = function(){
		if(_self.templateGrids.LISTS.grid){
			_self.templateGrids.LISTS.grid.forEach(function(grid, i){
				var view = new OneGridView(serverUrl, schemas) ;
				view.gridName += "__"+i ;
				view.allTaxes = _self.allTaxes ;
				view.on("exportXls", function (ev) {
					var filter = _self.getFilter() ;
					
					var strStart = moment(filter.from).format("L") ;
					var strEnd = moment(filter.to).format("L") ;
					
					_self.fw(ev, { title: grid.data.type+" - "+grid.data.tax_name + "% ("+strStart+" - "+strEnd+")"});
				});

				grid.data.view = view ;

				view.init(grid.el, function(){
					_self.colsDef = view.fields.fields[model+"~~grid"].columns ;
					view.show() ;
					view.load(grid.data.records) ;
				}) ;
			}) ;
		}	
	} ;
	
	this.doExport = function(){
		var lines = [] ;
		
		_self.gridsRecords.forEach(function(grid){
			grid.records.forEach(function(record){
				lines.push(record) ;
			}) ;
		}) ;
		
		var columnsToPrint = [] ;
		_self.colsDef.forEach(function(col, i){
			if(['invoice_name', 'date_ready', 'amount_untaxed', 'amount_tax', 'type'].indexOf(col.field) !== -1){
				var c = JSON.parse(JSON.stringify(col)) ;
				c.colNum = i ;
				columnsToPrint.push(c) ;
			}
		}) ;
		
		var recordsToPrint = [] ;
		
		var filter = _self.getFilter() ;
					
		var strStart = moment(filter.from).format("L") ;
		var strEnd = moment(filter.to).format("L") ;
					
		_self.gridsRecords.forEach(function(grid){
			grid.records.forEach(function(record){
				lines.push(record) ;
			}) ;
			
			var summary = { invoice_name : 0, amount_tax : 0, amount_untaxed : 0 } ;
			grid.records.forEach(function(l){
				summary.invoice_name ++ ;
				summary.amount_tax += l.amount_tax ;
				summary.amount_untaxed += l.amount_untaxed ;
			}) ;
			
			recordsToPrint.push({
				_BREAK_TITLE : grid.type+" - "+grid.tax_name + "% ("+strStart+" - "+strEnd+")"
			}) ;
			
			grid.records.concat(summary).forEach(function(record){
	            var recid = record.recid ;
	            var line = {} ;
	            var rIndex =  grid.view.fields.fields[model+"~~grid"].grid.grid.get(recid, true) ;
	            columnsToPrint.forEach(function(c){
	            	line[c.field] = record[c.field] ;
	            	if(rIndex !== undefined && rIndex !== null){
	                	var value = $( grid.view.fields.fields[model+"~~grid"].grid.grid.getCellHTML(rIndex, c.colNum, record.summary)).text() ;
	                	line[c.field] = value;
	            	}
	            }) ;
	            recordsToPrint.push(line) ;
	        }) ;
		}) ;
		
		
  
    	
		
		_self.emit("doExport", {lines : lines, columnsToPrint : columnsToPrint, recordsToPrint : recordsToPrint}) ;
	} ;
	
	
	
}

module.exports = AccoutingVATGridView;
