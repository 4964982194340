var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var BUS = require("aetna-events").EventSource.BUS;
var PdfView = require("aetna-views/views/pdf/pdf") ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var DistantFile = require("../../api/DistantFile") ;
var userUtils = require("../../utils/userUtils") ;
var FieldAutocomplete = require("aetna-views/fields/autocomplete");
var loki = require("../../api/loki") ;


function DocDetailsView(serverUrl, schemas){
    BaseView.call(this, t, "doc", "docDetails") ;
	
	var _self = this ;
   
   	this.fields = new FieldsEMA(t, serverUrl) ;
   	
   	this.modifiableFields = [
   		"project.task~name",
   		"project.task~AetnaSubtitle",
   		"project.task~AetnaRestrictGroup_ids"
   	] ;
   	
   	this.usersOfProject = [] ;
	   
	   
	this._initElements = function(callback){
		_self.pdfView = new PdfView(t) ;
		
		
		_self.fields.fields["project.task~AetnaRev"].label = t("doc.rev") ;
		
		_self.user = storage(context().name).get("user") ;


		var itemsProjects = [] ;
		var memberProjects = [] ;
		var otherProjects = [] ;
		Object.keys(_self.allProjects).forEach(function(projectId){
			var project = _self.allProjects[projectId] ;
			if(project.state === "open"){
				if(project.members.indexOf(_self.user.id) !== -1){
					memberProjects.push({
						key : project.id,
						value : project.AetnaCode+" - "+project.name,
						icon: project.AetnaPublicStatus === "private"?"fa fa-lock":""
					}) ;
				}else{
					otherProjects.push({
						key : project.id,
						value : project.AetnaCode+" - "+project.name ,
						icon: project.AetnaPublicStatus === "private"?"fa fa-lock":""
					}) ;
				}
			}
		}) ;
		
		memberProjects = memberProjects.sort(function(p1, p2){ return p1.value > p2.value?1:-1 ; }) ;
		otherProjects = otherProjects.sort(function(p1, p2){ return p1.value > p2.value?1:-1 ; }) ;
		
		if(memberProjects.length > 0){
			itemsProjects.push({
					value : t("doc.projectsYouBelong"),
					subEntries : memberProjects
			}) ;
		}
		if(otherProjects.length > 0){
			if(memberProjects.length > 0){
				itemsProjects.push({
						value : t("doc.otherProjects"),
						subEntries : otherProjects
				}) ;
			}else{
				itemsProjects = otherProjects ;
			}
		}
		
		
		_self.fieldProjects = new FieldAutocomplete(t, "project", itemsProjects, t("fields.project.task.project_id")) ;
		
		_self.fieldProjects.setRequired(true) ;
		
		_self.fieldUser = new FieldAutocomplete(t, "user", [], t("fields.project.task.user_id")) ;
		
		_self.fieldUser.setRequired(true) ;
		
		
		_self.fieldUser.init(_self.EL.userField, function(){
			_self.fieldProjects.init(_self.EL.projectField, function(){
				_self.fields.init(_self, function(){
					_self.pdfView.init(_self.EL.pdf, function(){
						callback() ;
					}) ;
				}) ;
			}) ;
		}) ;
		
		_self.workflowsById = storage(context().name).get("workflowsById") ;

		
		
		
		_self.templateNotifications = new TemplateWidget(_self.EL.notifications) ;
        _self.templateNotifications.on("render", _self.renderNotifications) ;
		
		_self.templateCustomFields = new TemplateWidget(_self.EL.customFields) ;
        _self.templateCustomFields.on("render", _self.renderCustomFields) ;
        
        _self.customFields = [];
		schemas.MAIN.tables.some(function(table){
			if(table.tableName === "project.task") {
				table.columns.forEach(function(col){
					if(col.name.indexOf("x_") === 0){
						_self.customFields.push(col) ;
						_self.modifiableFields.push("project.task~"+col.name) ;
					}
				}) ;
				return true ;
			}
		}) ;
		
		_self.templateCustomFields.render({fields : _self.customFields}) ;
		
		_self.EL.returnToList.emit("returnToList") ;
		_self.EL.modify.addEventListener("click", _self.goToModif) ;
		_self.EL.cancel.addEventListener("click", _self.cancelModif) ;
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
		_self.EL.previousRev.addEventListener("click", _self.previousRev) ;
		_self.EL.nextRev.addEventListener("click", _self.nextRev) ;
		_self.EL.createRev.addEventListener("click", _self.createRev) ;
		_self.EL.openWord.addEventListener("click", _self.openWord) ;
		_self.EL.downloadDoc.addEventListener("click", _self.downloadDoc) ;
		_self.EL.stageHisto.addEventListener("click", _self.stageHisto) ;
		
		_self.fieldProjects.addEventListener("change", _self.onProjectChange) ;
	};
	
	this.onProjectChange =function(){
		if(_self.templateNotifications.LISTS.user){
			_self.templateNotifications.LISTS.user.forEach(function(itemUser, i){
				var notification = itemUser.data ;
				notification.AetnaUser_id = itemUser.field.val() ;
			});
		}
		_self.templateNotifications.render({notifications : _self.notifications}) ;
		
		var project = _self.allProjects[_self.fieldProjects.val()] ;
		var itemsUser = [] ;
		var memberUsers = [] ;
		var otherUsers = [] ;
		Object.keys(_self.allUsers).forEach(function(uId){
			if(_self.allEmployees[uId].AetnaActive){
				if(project.members.indexOf(parseInt(uId,10)) !== -1){
					memberUsers.push({
						key : uId,
						value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
					}) ;
				}else if(project.AetnaPublicStatus === "public"){
					otherUsers.push({
						key : uId,
						value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
					}) ;
				}
			}
		}) ;
		
		memberUsers = memberUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
		otherUsers = otherUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
		
		if(memberUsers.length > 0){
			itemsUser.push({
				value : t("doc.memberUsers"),
				subEntries : memberUsers
			}) ;
		}
		if(otherUsers.length > 0){
			if(memberUsers.length > 0){
				itemsUser.push({
					value : t("doc.otherUsers"),
					subEntries : otherUsers
				}) ;
			}else{
				itemsUser = otherUsers ;
			}
		}
		
		var selectedUser = _self.fieldUser.val() ;
		_self.fieldUser.items = itemsUser ;
		_self.fieldUser.load(selectedUser) ;
	} ;
	
	this.renderNotifications = function(){
		_self.fieldsNotifications = []; 
		
		var restrictGroups = _self.fields.fields["project.task~AetnaRestrictGroup_ids"].val() ;
		var allowedUsers = [];
		if(restrictGroups){
			restrictGroups.forEach(function(g){
				var group = loki.daos["hr.aetna.group"].getById(g) ;
				group.member_ids.forEach(function(uid){
					allowedUsers.push(""+uid) ;
				}) ;
			});
		}
		if(_self.templateNotifications.LISTS.user){
			_self.templateNotifications.LISTS.user.forEach(function(itemUser, i){
				var project = _self.allProjects[_self.fieldProjects.val()] ;
				var itemsListUser = [] ;
				var memberUsers = [] ;
				var otherUsers = [] ;
				Object.keys(_self.allUsers).forEach(function(uId){
					var userOK = _self.allEmployees[uId].AetnaActive ;
					if(restrictGroups && restrictGroups.length > 0 && allowedUsers.indexOf(""+uId) === -1){
						userOK = false ;
					}
					if(userOK){
						if(project.members.indexOf(parseInt(uId,10)) !== -1){
							memberUsers.push({
								key : uId,
								value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
							}) ;
						}else if(project.AetnaPublicStatus === "public"){
							otherUsers.push({
								key : uId,
								value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
							}) ;
						}
					}
				}) ;
				
				memberUsers = memberUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
				otherUsers = otherUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
				
				if(memberUsers.length > 0){
					itemsListUser.push({
						value : t("doc.memberUsers"),
						subEntries : memberUsers
					}) ;
				}
				if(otherUsers.length > 0){
					if(memberUsers.length > 0){
						itemsListUser.push({
							value : t("doc.otherUsers"),
							subEntries : otherUsers
						}) ;
					}else{
						itemsListUser = otherUsers ;
					}
				}
				
				var thisField = new FieldAutocomplete(t, "user", itemsListUser, itemUser.data.stageName) ;
				thisField.init(itemUser.el, function(){
					thisField.load(itemUser.data.AetnaUser_id) ;
					if(!_self.isModify){
						thisField.setReadOnly(true) ;
					}
				}) ;
				_self.fieldsNotifications.push(thisField) ;
				itemUser.field = thisField ;
	        }) ;	
		}
		
	} ;
	
	this.renderCustomFields = function(){
		if(_self.templateCustomFields.EL_LISTS.field){
			_self.templateCustomFields.EL_LISTS.field.forEach(function(elField, i){
				
				_self.fields.fields["project.task~"+_self.customFields[i].name].label = _self.customFields[i].desc ;
				if(_self.customFields[i].required){
					elField.setAttribute("required", "required") ;
				}
				
				_self.fields.createAndInitField("project.task", _self.customFields[i].name, elField, function(){
					
				}) ; 

	        }) ;
		}
	} ;
	
	this.getPossibleActions = function(record){
		var possibleActions = [] ;
		if(record.AetnaWorkflow_id){ 
        	var w = _self.workflowsById[record.AetnaWorkflow_id] ;
			
			if(record.user_id === _self.user.id){
				w.AetnaCycles_ids.forEach(function(c){
					if(c.AetnaStage_name === record.stage_name){
						possibleActions.push(c.AetnaAction) ;
					}
				}) ;
			}
		}
		return possibleActions ;
	} ;
	
	this.load = function(doc, notifications){
		_self.doc= doc ;
		_self.notifications= notifications ;
		_self.originalNotifications= JSON.parse(JSON.stringify(notifications)) ;
		
		
		if(DistantFile.isFileAvailable("project.task", doc.id) && 
			["docx", "xlsx", "pptx",  "pdf"].indexOf(doc.AetnaExt.toLowerCase()) !== -1){
			_self.pdfView.startWait() ;
			DistantFile.getPdfUrl("project.task", doc.id, function(err, url){
				_self.pdfView.endWait() ;
				if(err){
					//not available
					return ;//_self.pdfView.error(err) ;
				}
				_self.pdfView.showPdf(url) ;
			}) ;
		}else{
			_self.EL.pdf.style.display = 'none' ;
			_self.EL.nopdf.style.display = 'block' ;
		}
		
		_self.usersOfProject = _self.allProjects[doc.project_id].members ;
		
		_self.fieldProjects.load(doc.project_id) ;
		_self.fields.load(doc) ;
		
		_self.templateNotifications.render({notifications : notifications}) ;
		
		_self.onProjectChange() ;
		
		_self.fieldUser.load(doc.user_id) ;
		
		_self.fieldProjects.setReadOnly(true) ;
		_self.fieldUser.setReadOnly(true) ;
		_self.fields.setReadOnly(true) ;
		
		
		var officeSoftware ;
		if(doc.AetnaExt){
			switch(doc.AetnaExt.toLowerCase()){
				case "docx":
				officeSoftware = "Word" ;
				break;
				case "xlsx":
				officeSoftware = "Excel" ;
				break;
				case "pptx":
				officeSoftware = "PowerPoint" ;
				break;
			}
		}
		if(officeSoftware){
			_self.EL.openWord.style.display = "block" ;
			_self.EL.downloadDoc.style.display = "none" ;
			_self.EL.openWordLabel.innerHTML = t("doc.openDoc", {soft : officeSoftware}) ;
		}else{
			_self.EL.openWord.style.display = "none" ;
			_self.EL.downloadDoc.style.display = "block" ;
		}
		
		
		
		if(_self.doc.AetnaRev === 0){
			_self.EL.previousRev.style.display = "none" ;
		}else{
			_self.EL.previousRev.style.display = "inline-block" ;
		}
		
		if(_self.doc.AetnaRev === _self.doc.AetnaRev_last){
			_self.EL.nextRev.style.display = "none" ;
		}else{
			_self.EL.nextRev.style.display = "inline-block" ;
		}
		
		if(_self.doc.stage_name === "PUBLISHED" && _self.doc.AetnaRev_last === _self.doc.AetnaRev){
			_self.EL.createRev.style.display = "block" ;
		}else{
			_self.EL.createRev.style.display = "none" ;			
		}
		
		_self.$EL.btnActions.html("") ;
		var possibleActions = _self.getPossibleActions(_self.doc) ;
        ["SUBMIT",  "PUBLISH", "REWORK", "DELETE"].forEach(function(action, i){
        	var style="info" ;
        	var icon = "" ;
			switch(action){
				case "SUBMIT":
					style = "primary" ;
					icon = "fa-arrow-circle-right" ;
					break;
				case "PUBLISH":
					style = "success" ;
					icon = "fa-check-circle-o" ;
					break;
				case "REWORK":
					style = "warning" ;
					icon = "fa-retweet" ;
					break;
				case "DELETE":
					style = "danger" ;
					icon = "fa-trash" ;
					break;
			}
			var disabled = possibleActions.indexOf(action) === -1 ? ' disabled="disabled"':'' ;
			var margin = i===0?' style="margin-left: 100px;"':'' ;
			
			var $bt = $('<button class="btn btn-sm btn-'+style+' pull-left" '+margin+' data-action="'+action+'" '+disabled+'><i class="fa '+icon+'"></i> '+t("doc.action."+action)+'</button>') ;
			$bt.appendTo(_self.$EL.btnActions) ;
			$bt.on("click", function(){
				_self.applyAction(_self.doc, action) ;
			}) ;
        }) ;
	} ;
	
	this.applyAction = function(doc, action){
		_self.emit("applyAction", {taskId : doc.id, action : action}) ;		
	} ;
	
	this.createRev = function(doc, action){
		_self.emit("createRev", _self.doc) ;		
	} ;
	
	this.goToModif = function(){
		_self.isModify = true ;
		_self.EL.btnRead.style.display = "none" ;
		_self.EL.btnActions.style.display = "none" ;
		_self.EL.btnModif.style.display = "block" ;
		
		_self.modifiableFields.forEach(function(f){
			_self.fields.fields[f].setReadOnly(false) ;
		}) ;
		_self.fieldProjects.setReadOnly(false) ;
		_self.fieldUser.setReadOnly(false) ;
		
		//assign rights are given to users having special right and to the manager of the project
		var hasRightAssign = userUtils.hasRight("moduleDicoAssign") ;
		if(!hasRightAssign){
			hasRightAssign = _self.allProjects[_self.doc.project_id].user_id === _self.user.id ;
		}
		
		if(!hasRightAssign){
			["project.task~AetnaRestrictGroup_ids"].forEach(function(f){
				_self.fields.fields[f].setReadOnly(true) ;
			}) ;
			_self.fieldProjects.setReadOnly(true) ;
			_self.fieldUser.setReadOnly(true) ;
		}
		
		if(_self.fieldsNotifications){
			_self.fieldsNotifications.forEach(function(f){
				f.setReadOnly(!hasRightAssign) ;
			}) ;
		}
	} ;
	
	this.cancelModif = function(){
		_self.EL.btnRead.style.display = "block" ;
		_self.EL.btnActions.style.display = "block" ;
		_self.EL.btnModif.style.display = "none" ;
		
		_self.load(_self.doc, _self.originalNotifications) ;
	} ;
	
	this.onValidate = function(){
		var missRequiredField = false ;
		_self.fields.usedFields.forEach(function(f){
			f.clearError();
			if(!f.isReadOnly() && f.isRequired() && f.isEmpty()){
				f.error() ;
				missRequiredField = true ;
			}
		}) ;
		
		if(_self.fieldsNotifications){
			_self.fieldsNotifications.forEach(function(f){
				f.clearError();
				if(f.isEmpty()){
					f.error() ;
					missRequiredField = true ;
				}
			}) ;
		}
		
		if(missRequiredField){
			return _self.error(t("doc.thereIsErrorPleaseCorrect")) ;
		}
		
		var restrictGroups = _self.fields.fields["project.task~AetnaRestrictGroup_ids"].val() ;
		if(restrictGroups && restrictGroups.length > 0){
			var allowedUsers = [];
			restrictGroups.forEach(function(g){
				var group = loki.daos["hr.aetna.group"].getById(g) ;
				group.member_ids.forEach(function(uid){
					allowedUsers.push(""+uid) ;
				}) ;
			});
			
			var chosenUserOk = true ;
			if(_self.fieldsNotifications){
				_self.fieldsNotifications.forEach(function(f){
					f.clearError();
					if(allowedUsers.indexOf(""+f.val()) === -1){
						chosenUserOk = false ;
						f.error() ;
					} 
				}) ;
			}
			if(allowedUsers.indexOf(""+_self.fieldUser.val()) === -1){
				chosenUserOk = false ;
				_self.fieldUser.error() ;
			} 
			
			
			if(!chosenUserOk){
				return _self.error(t("doc.chosenUserDoesNotBelongToRestrictGroup")) ;
			}
		}
		
		
		
		
		if(_self.templateNotifications.LISTS.user){
			_self.templateNotifications.LISTS.user.forEach(function(itemUser, i){
				var notification = itemUser.data ;
				notification.AetnaUser_id = itemUser.field.val() ;
			});
		}
		
		
		var values = _self.fields.values() ;
		values["project.task"].id = _self.doc.id ;
		values["project.task"].project_id = _self.fieldProjects.val() ;
		values["project.task"].user_id = _self.fieldUser.val() ;

		delete values["project.task"].stage_id ; //don't send state it should be set only by workflow actions

		_self.emit("modify", { 
			taskBefore : JSON.parse(JSON.stringify(_self.doc)),
			task : values["project.task"],
			notifications : _self.notifications}) ;
	} ;
	
	
	this.onPdfStart = function(doc){
		if(_self.doc && doc && doc.id == _self.doc.id){ // /!\ compare string and number
			_self.pdfView.startWait() ;
		}
	} ;
	
	this.onPdfFinished = function(doc){
		if(_self.doc && doc && doc.id == _self.doc.id){ // /!\ compare string and number
			DistantFile.getPdfUrl("project.task", doc.id, function(err, url){
				_self.pdfView.endWait() ;
				if(err){
					return _self.pdfView.error(err) ;
				}
				_self.pdfView.showPdf(url) ;
			}) ;
		}
	} ;
	
	this.nextRev = function(){
		BUS.emit("nextRev", {doc : _self.doc, callback : function(err, doc){
			if(err){ return _self.error(err) ; }
			_self.load(doc) ;
		}})	 ;
	} ;
	
	this.previousRev = function(){
		BUS.emit("previousRev", {doc : _self.doc, callback : function(err, doc){
			if(err){ return _self.error(err) ; }
			_self.load(doc) ;
		}})	 ;
	} ;
	
	this.openWord = function(){
		// if(window.isDesktop) {
		// 	BUS.emit("openDoc", _self.doc) ;
		// }else{
			_self.emit("openDocWeb", _self.doc) ;
		// }
	} ;

	this.downloadDoc = function(){
		if(window.isDesktop) {
			BUS.emit("openDoc", _self.doc) ;
		}else{
			_self.emit("downloadDoc", _self.doc) ;
		}
	} ;
	
	this.stageHisto = function(){
		_self.emit("openNotifs", _self.doc) ;
	} ;
	
}

module.exports = DocDetailsView;
