var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

var model = "hr.department" ;
var moduleName = "hrDepartment" ;

function HrDepartmentGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"name"
    	],
	}) ;
}

function HrDepartmentFormView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		
    	}
	}) ;
	
	
}

module.exports.Grid = HrDepartmentGridView;
module.exports.Form = HrDepartmentFormView;
