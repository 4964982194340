var CompanyViews = require("../views/company/companyViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;


function CompanyController() {
	var _self = this ;

	BaseController.call(this, {
		model : "res.company",
		socketChangeEvent : 'company',
		name : "company",
		gridClass : CompanyViews.Grid,
		formClass : CompanyViews.Form
	}) ;
	
	this.gui = new BaseGui() ;
	_self.viewList = _self.gui ;
	_self.viewList.hide = function(){} ;
	_self.viewList.show = function(){} ;
	
	this.openGrid = function(){
		//no grid
		_self.doSync(function(err){
			if(err){ return _self.gui.error(err); }
			var records = _self.listAll() ;
					
			if(records.length === 0){
				_self.createNew() ;
			}else{
				_self.openRecord({data: {id : records[0].id}}) ;
			}
		});
	} ;
}

module.exports = new CompanyController();
