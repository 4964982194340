var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;

function SyncView(serverUrl){
    BaseView.call(this, t, "desktop", "sync") ;
	
	var _self = this ;
	
	this.initialVisibility = "visible" ;
   
	this._initElements = function(){
		
	};
	
	this.setProgress = function(total, progress){
		_self.EL.progress.style.display = "block" ;
		_self.EL.progress.setAttribute("aria-valuenow", progress) ;
		_self.EL.progress.setAttribute("aria-valuemax", total) ;
		if(total === 0){
			_self.EL.progress.style.width = "0%" ;
		}else{
			_self.EL.progress.style.width = ((progress/total)*100) + "%" ;
		}
		_self.EL.progress.innerHTML = progress+" / "+total ;
	} ;
	
	this.syncStart = function(){
		_self.EL.syncIndicator.style.display = "block" ;
		_self.EL.syncError.style.display = "none" ;
		_self.EL.progress.style.display = "none" ;
	} ;

	this.syncEnd = function(){
		_self.EL.syncIndicator.style.display = "none" ;
	} ;
	
	this.syncError = function(){
		_self.EL.syncIndicator.style.display = "none" ;
		_self.EL.syncError.style.display = "block" ;
	} ;

}

module.exports = SyncView;
