var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var loki = require("../../api/loki") ;
var str = require("aetna-string") ;
var PdfView = require("aetna-views/views/pdf/pdf") ;

var model = "aetna.contract.contract" ;
var moduleName = "contract" ;

function ContractFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		state : "draft",
    		product_ids : [{}]
    	}
	}) ;
	
	this.customHeaderButtons = '<div class="contract_contractForm__actionForm">'+
	
	'<button class="btn btn-sm btn-primary pull-left" id="actionValidate">'+
				'<i class="fa fa-check-circle"></i> '+t("contract.validate")+'</button>'+
	
	'<button class="btn btn-sm btn-warning pull-left" id="actionClose">'+
				'<i class="fa fa-ban"></i> '+t("contract.close")+'</button>'+
				
	'<button class="btn btn-sm btn-danger pull-left" id="actionCancel">'+
				'<i class="fa fa-trash"></i> '+t("contract.cancel")+'</button>'+
	
	'</div>' ;
	
	
		
	this.on("initDone", function(){
		_self.templateProducts = new TemplateFields();
		_self.templateSchedule = new TemplateWidget(_self.EL.schedules_ids);
		_self.templateInvoices = new TemplateWidget(_self.EL.listInvoices);
		
		_self.templateProducts.init(_self.EL.product_ids, _self.fields) ;

		_self.templateProducts.on("initField", _self.onLineFieldInit) ;

		_self.EL.printAction.addEventListener("click", _self.onPrintContract) ;
		_self.EL.openWord.addEventListener("click", _self.editContract) ;
		_self.EL.actionValidate.addEventListener("click", _self.onActionValidate) ;
		_self.EL.actionCancel.addEventListener("click", _self.onActionCancel) ;
		_self.EL.actionClose.addEventListener("click", _self.onActionClose) ;
		
		_self.pdfView = new PdfView(t) ;
		_self.pdfView.init(_self.EL.pdfViewer) ;
		
		_self.templateSchedule.on("render", _self.renderSchedule) ;
		_self.templateInvoices.on("render", _self.renderInvoice) ;
		
	}) ;
	
	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		
		if(_self.isCreate){
		}else if(_self.isModif){
		}else {
			
			var state = _self.item.state ;
			
			if(state === "draft"){
				_self.EL.actionValidate.style.display = "block" ;
				_self.EL.delete.style.display = "block" ;
				_self.EL.schedules_ids.style.display = "none" ;
			}else {
				_self.EL.actionValidate.style.display = "none" ;
				_self.EL.delete.style.display = "none" ;
				_self.EL.schedules_ids.style.display = "block" ;
			}
			
			if(state === "open"){
				_self.EL.actionCancel.style.display = "block" ;
				_self.EL.actionClose.style.display = "block" ;
			}else {
				_self.EL.actionCancel.style.display = "none" ;
				_self.EL.actionClose.style.display = "none" ;
			}
			
		}
	} ;
	
	var _setReadOnly = this.setReadOnly ;
	this.setReadOnly= function(readOnly){
		_setReadOnly(readOnly) ;
		_self.templateProducts.setReadOnly(readOnly) ;
	} ;
	
	var normalLoad = this.load ;
	this.load = function(item){
		normalLoad(item) ;
		
		_self.templateProducts.load(item) ;
		_self.templateSchedule.render(item) ;
		_self.templateInvoices.render(item) ;
		
		if(item.id){
			_self.pdfView.showPdf(serverUrl+"/api/aetna.contract.contract/downloadPdf?id="+item.id) ;
		}
	} ;
	
	
	var _getValues = this.getValues;
	this.getValues = function(){
		var values = _getValues() ;
		
		var product_ids = _self.templateProducts.val().product_ids ;
		values.recordToSave.product_ids = product_ids.slice(0,product_ids.length-1) ;
		
		return values ;
	} ;
	
	var _checkInput = this.checkInput ;
	this.checkInput = function(){
		var error = _checkInput() ;
		if(error){ return error ;}
		
		var values = _self.getValues() ;
		
		if(values.recordToSave.product_ids.length === 0){
			return t("contract.pleaseAddProduct") ;
		}
		
		var errorLine = null;
		values.recordToSave.product_ids.some(function(p, i){
			if(!p.product_id){
				errorLine =  t("contract.pleaseChoseProductOnLine", {line: i+1}) ;	
				return ;
			}
			if(!p.state){
				errorLine = t("contract.pleaseChoseStateOnLine", {line: i+1}) ;	
				return ;
			}
			if(!p.invoice_period){
				errorLine =  t("contract.pleaseChosePeriodOnLine", {line: i+1}) ;	
				return ;
			}
		}) ;
		
		if(errorLine){ return errorLine ;}
		
	} ;
	
	this.onPrintContract = function(){
		window.location.href = serverUrl+"/api/aetna.contract.contract/downloadPdf?id="+_self.item.id ;
	} ;
	
	this.onPdfContractStart = function(contract){
		if(_self.contract && contract && contract.id == _self.contract.id){ // /!\ compare string and number
			_self.pdfView.startWait() ;
		}
	} ;
	
	this.onPdfContractFinished = function(contract){
		if(_self.contract && contract && contract.id == _self.contract.id){ // /!\ compare string and number
			_self.pdfView.showPdf(serverUrl+"/api/aetna.contract.contract/downloadPdf?id="+contract.id) ;
			_self.pdfView.endWait() ;
		}
	} ;
	
	this.editContract = function(){
		_self.emit("openContractWord",_self.contract) ;
	} ;
	
	this.onLineFieldInit = function(ev){
		if(ev.data.name === "aetna.contract.product~product_id"){
			ev.data.field.addEventListener("change", function(){
				var line = ev.data.def.data ;
				var productId = ev.data.field.val() ;
				if(productId){
					if(!line.date_start){
						line.date_start = new Date() ;
					}
					if(!line.state){
						line.state = "active";
					}
					
					line.price_unit = _self.productsById[productId].lst_price ;		
					
					_self.templateProducts.refreshLine(ev.data.def) ;
					
					if(!line.invoice_period){
						_self.templateProducts.focus(ev.data.def, "invoice_period") ;
					}
					
				}
			}) ;
		}
	} ;
	
	this.renderSchedule = function(ev){
		_self.$EL.schedules_ids.find('[data-toggle="tooltip"]').tooltip() ;
		
		_self.templateSchedule.EL.genSchedule.addEventListener("click", function(){
			_self.emit("genSchedule") ;
		}) ;
		if(_self.contract.state === "open"){
			_self.templateSchedule.EL.genSchedule.style.display = "block" ;
		}else{
			_self.templateSchedule.EL.genSchedule.style.display = "none" ;
		}
		
		if(_self.templateSchedule.LISTS.createInvoice){
			_self.templateSchedule.LISTS.createInvoice.forEach(function(createInvoice){
				createInvoice.el.addEventListener("click", function(){
					_self.emit("createInvoice", createInvoice.data) ;
				}) ;
			}) ;
		}
		
		if(_self.templateSchedule.LISTS.deleteSchedule){
			_self.templateSchedule.LISTS.deleteSchedule.forEach(function(deleteSchedule){
				deleteSchedule.el.addEventListener("click", function(){
					_self.confirm(t("contract.areYouSureDeleteSchedule"), function(yes){
						if(yes){
							_self.emit("deleteSchedule", deleteSchedule.data) ;
						}
					}) ;
				}) ;
			}) ;
		}
		
		if(_self.templateSchedule.LISTS.viewInvoice){
			_self.templateSchedule.LISTS.viewInvoice.forEach(function(viewInvoice){
				viewInvoice.el.addEventListener("click", function(){
					_self.emit("openInvoice", {id : viewInvoice.data.invoice_id}) ;
				}) ;
			}) ;
		}

	} ;
	
	this.renderInvoice = function(ev){
		_self.$EL.listInvoices.find('[data-toggle="tooltip"]').tooltip() ;
		
		_self.templateInvoices.EL.createInvoiceManually.addEventListener("click", function(){
			_self.emit("createInvoiceManually") ;
		}) ;
		if(_self.contract.state === "open"){
			_self.templateInvoices.EL.createInvoiceManually.style.display = "block" ;
		}else{
			_self.templateInvoices.EL.createInvoiceManually.style.display = "none" ;
		}
		
		if(_self.templateInvoices.LISTS.viewInvoice){
			_self.templateInvoices.LISTS.viewInvoice.forEach(function(viewInvoice){
				viewInvoice.el.addEventListener("click", function(){
					_self.emit("openInvoice", viewInvoice.data) ;
				}) ;
			}) ;
		}
	} ;
	
	this.onActionValidate = function(){
		_self.emit("actionValidate", _self.contract) ;
	} ;
	
	this.onActionCancel = function(){
		_self.emit("actionCancel", _self.contract) ;
	} ;
	
	this.onActionClose = function(){
		_self.emit("actionClose", _self.contract) ;
	} ;
	
}

module.exports = ContractFormView;
