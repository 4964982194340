var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var FieldMultiple = require("aetna-views/fields/multiple");
var FieldAutocomplete = require("aetna-views/fields/autocomplete");
var BUS = require("aetna-events").EventSource.BUS;
var DocGridView= require("./gridDocs") ;

var LibsLoader = require("aetna-views/LibsLoader") ;
LibsLoader.load([
	"animate.css/animate.min.css"
]) ;

function DocListView(serverUrl, schemas){
    BaseView.call(this, t, "doc", "list") ;
	
	var _self = this ;
   
   	this.fields = new FieldsEMA(t, serverUrl) ;
	this.allowedProjects = [] ;
	   
	this._initElements = function(callback){
		
		_self.grid = new DocGridView(serverUrl, schemas) ;
		
		_self.grid.idGrid = "gridDocMain" ;
		
		_self.grid.on("select", _self.onSelect) ;
		_self.grid.on("openDoc", _self.openDoc) ;
		_self.grid.on("downloadDoc", _self.downloadDoc) ;
		_self.grid.on("openNotifs", _self.openNotifs) ;
		_self.grid.on("openDocDetails", _self.openDetails) ;
		_self.grid.on("applyAction", _self.applyAction) ;
		_self.grid.on("createRev", _self.createRev) ;
		_self.grid.on("createDoc", _self.fw) ;
		_self.grid.on("createDocFrom", _self.fw) ;
		_self.grid.on("share", _self.fw) ;
		_self.grid.on("uploadFile", _self.fw) ;
		_self.grid.on("follow", _self.fw) ;
		_self.grid.on("unfollow", _self.fw) ;
		_self.grid.on("reload", _self.fw) ;
		_self.grid.on("exportXls", _self.fw) ;
		_self.grid.on("actionMultiClicked", _self.applyActionMulti) ;
		
		var context = require("aetna-views/context").context;
		var storage = require("aetna-storage").storage ;

		_self.workflowsById = storage(context().name).get("workflowsById") ;

		_self.user = storage(context().name).get("user") ;


		_self.templateActions = new TemplateWidget(_self.EL.actions);
		_self.templateCounts = new TemplateWidget(_self.EL.counts);
		_self.templateActions.on("render", _self.renderActions);
		_self.templateCounts.on("render", _self.renderCounts);
		
		var itemsProjects = _self.computeItemsProjects();
		
		var itemsUser = [
			{
				key : "ALL",
				value : t("doc.allUsers")
			}
		] ;
		
		
		var activeUsers = {
			value : t("doc.activeUsers"),
			subEntries : [
				
			]
		};
		
		_self.allEmployee.filter(function(e){ return _self.allUsers[e.user_id] && _self.allEmployees[e.user_id].AetnaActive ;})
		.sort(function(u1, u2){
			var libA = u1.otherid + " - "+u1.name ;
			var libB = u2.otherid + " - "+u2.name ;
			
			if(libA > libB){
				return 1 ;
			}else if(libA === libB){
				return 0 ;
			}else {
				return -1 ;
			}
		}).forEach(function(user){
			if(user.otherid){
				activeUsers.subEntries.push({
					key : user.user_id,
					value : user.otherid + " - "+user.name 
				}) ;
			}
		}) ;
		
		
		var inactiveUsers = {
			value : t("doc.inactiveUsers"),
			subEntries : [
				
			]
		};
		
		_self.allEmployee.filter(function(e){ return _self.allUsers[e.user_id] && !_self.allEmployees[e.user_id].AetnaActive ;}).sort(function(u1, u2){
			var libA = u1.otherid + " - "+u1.name ;
			var libB = u2.otherid + " - "+u2.name ;
			
			if(libA > libB){
				return 1 ;
			}else if(libA === libB){
				return 0 ;
			}else {
				return -1 ;
			}
		}).forEach(function(user){
			if(user.otherid){
				inactiveUsers.subEntries.push({
					key : user.user_id,
					value : user.otherid + " - "+user.name 
				}) ;
			}
		}) ;
		
		itemsUser.push(activeUsers);
		if(inactiveUsers.subEntries.length > 0){
			itemsUser.push(inactiveUsers);
		}
		
		
		_self.fieldProjects = new FieldAutocomplete(t, "projects", itemsProjects, "") ;
		
		
		_self.fieldUser = new FieldAutocomplete(t, "user", itemsUser, null) ;
		
		
		_self.fields.init(_self, function(){
			_self.fieldProjects.init(_self.EL.selectProjects, function(){
				_self.fieldUser.init(_self.EL.selectUser, function(){
					_self.fieldProjects.load("ALL") ;
					
					_self.fieldProjects.addEventListener("change", _self.onProjectChange) ;
					
					_self.fieldUser.load(_self.user.id) ;
					
					_self.fieldUser.addEventListener("change", _self.onUserChange) ;
					
					_self.grid.init(_self.EL.grid, function(){
	            		callback() ;
					}) ;
				}) ;
			}) ;
        }) ;
		
		
		_self.EL.currentUser.innerHTML = _self.allEmployees[_self.user.id].otherid ;
		_self.EL.currentUser.addEventListener("click", function(){
			_self.fieldUser.load(_self.user.id) ;
			_self.onUserChange() ;
		}) ;
		_self.EL.allUsers.addEventListener("click", function(){
			_self.fieldUser.load("ALL") ;
			_self.onUserChange() ;
		}) ;
		
		_self.EL.detailsActions.addEventListener("click", _self.toggleDetailsActions);
		_self.EL.detailsCounts.addEventListener("click", _self.toggleDetailsCounts);
		
	};
	
	this.computeItemsProjects = function(){
		var itemsProjects = [
			{
				key : "ALL",
				value : t("doc.allOpenProjects")
			}
		] ;
		
		var currentCustomer ;
		var currentParent ;
		_self.allowedProjects.forEach(function(project){
			if(project.partner_id !== currentCustomer){
				var code = "";
				if(project.partner_id){
					code = _self.allCustomers[project.partner_id].ref ;
				}
				currentParent = {
					value : code +(code?" - ":"") + (project.partner_name||t("doc.unknownCustomer")),
					subEntries : [
						{
							key : "CUSTOMER_"+project.partner_id ,
							value : t("doc.allProjectsOfCustomer", {customer : project.partner_name||t("doc.unknownCustomer")}),
							//title : t("doc.projetsOfCustomers", {customer : code||t("doc.unknownCustomer")}),
						}
					]
				} ;
				itemsProjects.push(currentParent) ;
				currentCustomer = project.partner_id ;
			}
			
			var value =  project.AetnaCode + " - "+ project.name ;
			if(project.state === "close"){
				value += " ("+t("doc.closed")+")" ;
			}
			
			currentParent.subEntries.push({
				key : project.id,
				value : value ,
				//title : project.AetnaCode
			}) ;
		}) ;
		return itemsProjects;
	};
	
	this.refreshItemsProject = function(){
		var val = _self.fieldProjects.val();
		_self.fieldProjects.items = _self.computeItemsProjects();
		_self.fieldProjects.load(val);
	};
	
	this.previousProjects = ["ALL"] ;
	this.onProjectChange = function(){
		var projects = [_self.fieldProjects.val()] ;
		
		if(!projects || projects.length === 0){
			projects = ["ALL"] ;
			_self.fieldProjects.load(projects) ;
		}
		
		if(projects.length > 1 && projects.indexOf("ALL") !== -1){
			if(_self.previousProjects.indexOf("ALL") !== -1){
				projects.splice(projects.indexOf("ALL"), 1) ;
				_self.fieldProjects.load(projects) ;
			}else{
				projects = ["ALL"] ;
				_self.fieldProjects.load(projects) ;
			}
		}
		
		var customers = projects
			.filter(function(p){ return p.indexOf("CUSTOMER") === 0 ;})
			.map(function(p){ return p.substring(p.indexOf("_")+1) ;}) ;
			
		var entriesToRemove = [] ;
		customers.forEach(function(customerId){
			var wasPresent = _self.previousProjects.indexOf("CUSTOMER_"+customerId) !== -1 ;
			var projectsOfThisCustomer = _self.allowedProjects.filter(function(ap){
				return ""+ap.partner_id == customerId && ap.state === "open"; 
			}).map(function(ap){ return ""+ap.id ;}) ;
			var hasProjectsOfThisCustomer = projects.some(function(p){
				return projectsOfThisCustomer.indexOf(p)!==-1;
			}) ;
			if(wasPresent && hasProjectsOfThisCustomer){
				//was present but now a project individually is selected
				entriesToRemove.push("CUSTOMER_"+customerId) ;
			}else{
				projectsOfThisCustomer.forEach(function(ap){
					if(projects.indexOf(ap) !== -1){
						entriesToRemove.push(ap) ;
					}
				}) ;
			}
		}) ;
		
		entriesToRemove.forEach(function(entryToRemove){
			projects.splice(projects.indexOf(entryToRemove), 1) ;
		}) ;
		_self.fieldProjects.load(projects) ;
		
		_self.previousProjects = projects ;
		_self.emit("changeProject", {filter : _self.getFilter()}) ;
	} ;
	
	this.onUserChange = function(){
		_self.emit("changeUser", {filter : _self.getFilter()}) ;
	} ;
	
	this.getPossibleActions = function(record){
		var possibleActions = [] ;
		if(record.AetnaWorkflow_id){ 
        	var w = _self.workflowsById[record.AetnaWorkflow_id] ;
			
			if(record.user_id === _self.user.id){
				w.AetnaCycles_ids.forEach(function(c){
					if(c.AetnaStage_name === record.stage_name){
						possibleActions.push(c.AetnaAction) ;
					}
				}) ;
			}
		}
		return possibleActions ;
	} ;
	
	this.load = function(docs, resetExpanded){
		_self.$EL.btMulti.html("") ;
		_self.grid.load(docs, resetExpanded) ;
		
	} ;
	
	this.onFilterClick = function(ev, $a){
		if(!ev.ctrlKey && !ev.shiftKey && ev.button === 0){
			_self.$EL.actions.find("a").each(function(y, link){
				if(link !== $a[0]){
					$(link).removeClass("active") ;			
				}
			}) ;
			_self.$EL.counts.find("a").each(function(y, link){
				if(link !== $a[0]){
					$(link).removeClass("active") ;			
				}
			}) ;
		
		}
		$a.toggleClass("active") ;
		_self.emit("changeFilter", {filter : _self.getFilter()}) ;
		ev.preventDefault() ;
	} ;
	
	this.renderActions = function(){
		_self.$EL.actions.find('[data-toggle="tooltip"]').tooltip() ;
		
		_self.templateActions.LISTS.link.forEach(function(el){
			$(el.el).attr('title', $(el.el).attr("data-title-base")+" ("+el.data.count+")").tooltip('fixTitle') ;
		}) ;
		
		_self.templateActions.$EL_LISTS.link.forEach(function($a, i){
			$a.on('contextmenu', function(ev){
				_self.onFilterClick(ev, $a) ;
		    });
			$a.on("click", function(ev){
				_self.onFilterClick(ev, $a) ;
			}) ;
		}) ;
		
	} ;
	
	this.renderCounts = function(){
		_self.$EL.counts.find('[data-toggle="tooltip"]').tooltip() ;
		
		
		_self.templateCounts.$EL["createdFilter"].attr('title',
			_self.templateCounts.$EL["createdFilter"]
			.attr("data-title-base")+" ("+_self.templateCounts.data.countCreated+")").tooltip('fixTitle') ;
		
		_self.templateCounts.$EL["affectOtherFilter"].attr('title',
			_self.templateCounts.$EL["affectOtherFilter"]
			.attr("data-title-base")+" ("+_self.templateCounts.data.countAffectOther+")").tooltip('fixTitle') ;
		
		_self.templateCounts.$EL["followFilter"].attr('title',
			_self.templateCounts.$EL["followFilter"]
			.attr("data-title-base")+" ("+_self.templateCounts.data.countFollowed+")").tooltip('fixTitle') ;
		
		["createdFilter", "affectOtherFilter", "followFilter"].forEach(function(c){
			_self.templateCounts.$EL[c].on('contextmenu', function(ev){
				_self.onFilterClick(ev, _self.templateCounts.$EL[c]) ;
		    });
			_self.templateCounts.$EL[c].on("click", function(ev){
				_self.onFilterClick(ev, _self.templateCounts.$EL[c]) ;
			}) ;
		});
		
		
	} ;
	
	this.getFilter = function(){
		var followedDocs = false ;
		var affectOther = false ;
		var createdBy = false ;
		var stagesActions = [] ;
		if(_self.templateActions.rendered){
			_self.templateActions.$EL_LISTS.link.forEach(function($a, i){
				if($a.hasClass("active")){
					_self.counts.actions[i].stages.forEach(function(s){
						if(stagesActions.indexOf(s)===-1){
							stagesActions.push(s) ;
						}
					}) ;
				}
			}) ;
		}
		
		if(_self.templateCounts.rendered){
			followedDocs = _self.templateCounts.$EL.followFilter.hasClass("active") ;
			affectOther = _self.templateCounts.$EL.affectOtherFilter.hasClass("active") ;
			createdBy = _self.templateCounts.$EL.createdFilter.hasClass("active") ;
		}
		
		var projects = [] ;
		
		var selectedProjects = [_self.fieldProjects.val()] ;
		selectedProjects.some(function(p){
			if(p === "ALL"){
				projects = _self.allowedProjects.filter(function(ap){
					return ap.state ==="open" ;
				}).map(function(ap){
					return ap.id ;
				}) ;
				return true ;
			}else if(p.indexOf("CUSTOMER") === 0){
				var customerId = p.substring(p.indexOf("_")+1) ;
				_self.allowedProjects.filter(function(ap){
					return (""+ap.partner_id == customerId) && ap.state === "open"; 
				}).forEach(function(ap){
					projects.push(ap.id) ;
				}) ;
			}else{
				projects.push(parseInt(p, 10)) ;
			}
		}) ;
		
		var selectedUser = _self.fieldUser.val() ;
		if(selectedUser !== "ALL"){
			selectedUser = parseInt(selectedUser, 10) ;
		}
		return {
			actions : stagesActions,
			followedDocs : followedDocs,
			affectOther : affectOther,
			createdBy : createdBy,
			projects : projects,
			user_id : selectedUser
		};
	} ;
	
	var countsLoaded = false ;
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		if(countsLoaded && !forceRefresh){
			_self.templateActions.$EL_LISTS.count.forEach(function($span, i){
				if($span.html() != counts.actions[i].count){// != because compare string and integer
					$span.html(counts.actions[i].count) ;
					$span.addClass("animated") ;
					$span.addClass("flash") ;
					
					$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
						$span.removeClass("animated") ;
						$span.removeClass("flash") ;
					});	
				}
				

			}) ;
			_self.templateActions.$EL_LISTS.link.forEach(function($link, i){
				$link.attr('title', $link.attr("data-title-base")+" ("+counts.actions[i].count+")").tooltip('fixTitle') ;
			}) ;
			
			["countFollowed", "countCreated", "countAffectOther"].forEach(function(c){
				var $el = _self.templateCounts.$EL[c] ;
				$el.parent().attr('title', $el.parent().attr("data-title-base")+" ("+counts[c]+")").tooltip('fixTitle') ;
				if($el.html() != counts[c]){// != because compare string and integer
					$el.html(counts[c]) ;
					$el.addClass("animated") ;
					$el.addClass("flash") ;
					
					$el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
						$el.removeClass("animated") ;
						$el.removeClass("flash") ;
					});	
				}
			}) ;
			
			

			
		}else{
			countsLoaded = true ;
			_self.templateActions.render(counts) ;
			_self.templateCounts.render(counts) ;
		}
	} ;
	
	this.createRev = function(ev){
		var doc = ev.data ;
		_self.emit("createRev", doc) ;
	} ;
	
	this.openDoc = function(ev){
		var doc = ev.data ;
		// if(window.isDesktop) {
		// 	BUS.emit("openDoc", doc) ;
		// }else{
			_self.emit("openDocWeb", doc) ;
		// }
	} ;
	
	this.downloadDoc = function(ev){
		var doc = ev.data ;
		if(window.isDesktop) {
			BUS.emit("openDoc", doc) ;
		}else{
			_self.emit("downloadDoc", doc) ;
		}
	} ;
	
	this.openNotifs = function(ev){
		var doc = ev.data ;
		_self.emit("openNotifs", doc) ;
	} ;
	this.openDetails = function(ev){
		var doc = ev.data ;
		_self.emit("openDocDetails", doc) ;
	} ;

	this.applyAction = function(ev){
		var doc = ev.data.doc ;
		var action = ev.data.action ;
		_self.emit("applyAction", {taskId : doc.id, action : action}) ;		
	} ;

	this.applyActionMulti = function(ev){
		var docs = ev.data.records ;
		var action = ev.data.action ;
		_self.confirm(t("doc.areYouSureToApplyMulti", {action : t("doc.action."+action), count : docs.length}), function(yes){
			if(yes){
				_self.emit("applyActionMulti", {tasks : docs, action : action}) ;		
			}
		}) ;
	} ;
	
	this.toggleDetailsActions = function(){
		_self.$EL.actions.toggleClass("list-group-horizontal") ;
		if(_self.$EL.actions.hasClass("list-group-horizontal")){
			_self.EL.detailsActions.innerHTML = t("doc.viewDetails") ;
		}else{
			_self.EL.detailsActions.innerHTML = t("doc.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	this.toggleDetailsCounts = function(){
		_self.$EL.counts.toggleClass("list-group-horizontal") ;
		if(_self.$EL.counts.hasClass("list-group-horizontal")){
			_self.EL.detailsCounts.innerHTML = t("doc.viewDetails") ;
		}else{
			_self.EL.detailsCounts.innerHTML = t("doc.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	this.onShow = function(){
		_self.resizeTable() ;
	} ;
	
	this.resizeTable = function(){
		return ;
		var headerHeight = 35 ;
		
		if(_self.$EL.topPart.is(":visible")){
			headerHeight += _self.$EL.topPart.height() ;
		}
		
		if(_self.$EL.btMulti.is(":visible")){
			headerHeight += _self.$EL.btMulti.height() ;
		}

		_self.EL.grid.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.grid.render() ;
		
	} ;
	
	
	this.onSelect = function(ev){
		if(!_self.grid.isMulti){ return ; }
		var records = ev.data.records;
		var possibleActions = [] ;
		
		records.forEach(function(r, i){
			if(i===0){
				possibleActions = _self.getPossibleActions(r) ;
			}else{
				var thisPossibleActions = _self.getPossibleActions(r) ;
				possibleActions = possibleActions.filter(function(a){ 
					return thisPossibleActions.indexOf(a) !== -1; 
				}) ;
			}
		}) ;
		
		_self.grid.showActions(possibleActions) ;
	} ;

	this.onPdfStart = function(doc){
		_self.grid.onPdfStart(doc) ;
	} ;
	
	this.onPdfFinished = function(doc){
		_self.grid.onPdfFinished(doc) ;
	} ;

}

module.exports = DocListView;
