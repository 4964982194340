var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var t = require("aetna-views/locales/tr").t ;

function OfflineAuth(serverUrl){
	this.login = function(login, password, callback){
		var user = storage(context().name).get("user") ;
		
		if(!user){
		    return callback("Never logged, can't log in offline mode") ;
		}
		
		if(user.login === login){
		    return callback(null, user) ;
		}else{
		     return callback("Not the same login as previous, can't log in offline mode") ;
		}
    };
	
	this.info = function(callback){
        var user = storage(context().name).get("user") ;
		
		if(!user){
		    return callback("Never logged, can't log in offline mode") ;
		}
		
	    return callback(null, user) ;
    };
	
	this.logout = function(callback){
	     var user = storage(context().name).get("user") ;
		
		if(!user){
		    return callback("Never logged, can't log in offline mode") ;
		}
		
	    return callback(null, user) ;
    };
    
    this.changePassword = function(passwordOld, passwordNew, callback){
        return callback(t("main.offlineNotAvailable")) ;
    };
}

module.exports  = OfflineAuth ;