var AccountingExportGrid = require("../views/accountingExport/accountingExportGrid");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;
var moment = require("moment") ;

function AccountingExportController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.move.line",
		modelsToSync : ["account.move.line", "account.journal"],
		socketChangeEvent : 'accountChanged',
		name : "accountingExport",
		gridClass : AccountingExportGrid,
		formClass : null
	}) ;
	
	this.initGrid = function(){
		_self.viewList.allJournal = {} ;
		loki.daos["account.journal"].listAll().forEach(function(journal){
			_self.viewList.allJournal[journal.id] = journal ;	
		}) ;
	};
	
	this.onGridLoad = function(){
		_self.viewList.on("changeFilter", _self.refreshList) ;
		_self.viewList.on("doExport", _self.doExport) ;
		_self.refreshCount() ;
	} ;
	
	this.refreshCount = function(){
		var filter = _self.viewList.getFilter() ;
		filter.from = moment(filter.from).startOf('day').toDate() ;
		filter.to = moment(filter.to).endOf('day').toDate() ;
		var counts = loki.daos["account.move.line"].countByState(filter) ;
		_self.viewList.loadCounts(counts) ;
	} ;
	
	this.listAll = function(){
		var filter = _self.viewList.getFilter() ;
		filter.from = moment(filter.from).startOf('day').toDate() ;
		filter.to = moment(filter.to).endOf('day').toDate() ;
		var moves = loki.daos["account.move.line"].moveFilters(filter) ;
		
		moves.forEach(function(move){
			move.invoiceRef = "" ;
			
			if(move.invoice){
				var invoice = loki.daos["account.invoice"].getById(move.invoice) ;
				move.invoiceRef = invoice.AetnaRef||invoice.number ;
			}
		}) ;
		
		return moves ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
		_self.refreshCount();
	} ;
	
	this.doExport = function(ev){
		
		var dateStart = moment(ev.data.from).startOf('day').toDate() ; 
		var dateEnd = moment(ev.data.to).endOf('day').toDate() ; 
		var state = null ;
		if(ev.data.todo && !ev.data.done){
			state = "todo" ;
		}else if(!ev.data.todo && ev.data.done){
			state = "done" ;
		}
		
		_self.viewList.startWait() ;
		
		cli("account.move.line").doExport(dateStart, dateEnd, state, function(err){
			if(err){
				return _self.viewList.endWaitError(err) ;
			}
			_self.doSync(function(){
				_self.viewList.endWait() ;
				_self.refreshList() ;
			}) ;
		})  ;
		
	} ;
	
}

module.exports = new AccountingExportController();
