var BaseView = require("aetna-views/BaseView.js") ;
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;

function ChangePassView(serverUrl){
    BaseView.call(this, t, "login", "changePass") ;
	
	var _self = this ;
   
   	var fields = new FieldsEMA(t, serverUrl) ;
   	
	   
	this._initElements = function(callback){
		
        fields.init(_self, function(){
            callback() ;
        }) ;
		
		_self.EL.cancel.addEventListener("click", _self.closePopup) ;
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
		
		fields.fields["res.users~password"].isValid = function(){
			return _self.passwordFormatCheck(fields.fields["res.users~password"].val()) ;
		};
		fields.fields["res.users~passwordConfirm"].isValid = function(){
			return _self.passwordFormatCheck(fields.fields["res.users~passwordConfirm"].val()) ;
		};

	};
	
	this.passwordFormatCheck = function(pass){
		if(pass.length<8){
			return false ;	
		}
		if(!/[A-Za-a]/.test(pass)){
			return false ;	
		}
		if(!/[0-9]/.test(pass)){
			return false ;	
		}
		return true ;
	} ;
	
	//fields.fields["res.users~password"].isValid = 
	
	this.onValidate = function(){
	
		
		var fieldInError = false ;
		fields.usedFields.forEach(function(f){
			f.clearError();
			if(!f.isReadOnly() && f.isRequired() && f.isEmpty()){
				f.error() ;	
				fieldInError = true ;
			}
			if(!f.isEmpty() && !f.isValid()){
				if(f.code === "password" || f.code === "passwordConfirm"){
					f.error(t("user.passwordHelp")) ;
				}else{
					f.error(t("invalidFormat")) ;
				}
				fieldInError = true ;
			}
		}) ;
		
		var values = fields.values()["res.users"];
		
		if(values.password !== values.passwordConfirm){
			fields.fields["res.users~passwordConfirm"].error(t("login.notEquals")) ;
			fieldInError = true ;
		}
		
		if(fieldInError){
			return _self.error(t("login.thereIsErrorPleaseCorrect")) ;
		}
		
		_self.emit("validate", values) ;
	} ;
		
	
}

module.exports = ChangePassView;
