var InvoiceSettingsViews = require("../views/invoiceSettings/invoiceSettingsViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var t = require("aetna-views/locales/tr").t ;
var async = require("async") ;
var moment = require("moment") ;

function InvoiceSettingsController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.config.aetna.settings",
		modelsToSync : ["account.config.aetna.settings"],
		socketChangeEvent : 'invoiceSettingsChanged',
		name : "invoiceSettings",
		gridClass : null,
		formClass : InvoiceSettingsViews.Form,
		
	}) ;
	
	this.openGrid = function(){
		//no grid
		_self.initForm(function(){
			_self.doSync(function(err){
				if(err){ return _self.viewForm.error(err); }

				var record = _self.listAll()[0] ;

				_self.loadRecord(record) ;
				
				_self.viewForm.show() ;
			}) ;	
		});
	} ;
    
    this.checkCanDelete = function(data, callback){
    	callback(false) ;
	} ;
	
}

module.exports = new InvoiceSettingsController();
