var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineAetnaContractContract(){
	OfflineCrudCli.call(this, "aetna.contract.contract");
	
	this.getWebDavDoc = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
}

module.exports = OfflineAetnaContractContract ;