var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TaskStageViews = require("../views/taskStage/taskStageViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;

function TaskStageController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "project.task.type",
		modelsToSync : ["project.task.type", "project.aetnaworkflowcycle"],
		socketChangeEvent : 'projectTaskTypeChanged',
		name : "taskStage",
		uniqueCols : "name",
		gridClass : TaskStageViews.Grid,
		formClass : TaskStageViews.Form
	}) ;
    
    
    this.listAll = function(){
		return loki.daos["project.task.type"].listAll().map(function(stage){
			if(stage.AetnaActionIcon){
				var actionIcon = stage.AetnaActionIcon.split(/\s/) ;
				stage.AetnaActionIcon_Icon = actionIcon[0] ;
				stage.AetnaActionIcon_Color = actionIcon[1]?actionIcon[1].replace("text-", ""):"default" ;
			}
			stage.AetnaSendEmail = !!stage.AetnaNotificationSubject ;
			return stage;
		}) ;
	};
	
    this.checkCanDelete = function(data, callback){
    	var taskStage = data.recordToDelete ;
    	
    	var workflows = loki.daos["project.aetnaworkflowcycle"].search({AetnaStage_id : taskStage.id}) ;
    	if(workflows.length>0){
    		_self.viewForm.error(t("taskStage.cantDeleteUsedInWorkflow", {count : workflows.length})) ;
			return callback(false) ;
    	}
    	
    	
		return callback(true) ;
	} ;
	
	this.refreshStages = function(){
		_self.doSync(function(){
			var stages = loki.daos["project.task.type"].listAll() ;
			var stagesByName = {} ;
			stages.forEach(function(s){
				stagesByName[s.name] = s ;
			}) ;
			
			storage(context().name).set("stagesByName", stagesByName) ;
		});
	} ;
	
	this.on("created", this.refreshStages) ;
	this.on("modified", this.refreshStages) ;
	this.on("deleted", this.refreshStages) ;
}

module.exports = new TaskStageController();
