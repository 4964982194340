var ProjectViews = require("../views/project/projectViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

function ProjectController() {
	var _self = this ;


	
	BaseController.call(this, {
		model : "project.project",
		modelsToSync : ["project.project", "hr.employee"],
		socketChangeEvent : 'projectChanged',
		name : "project",
		gridClass : ProjectViews.Grid,
		formClass : ProjectViews.Form,
		uniqueCols : ["AetnaCode"]
	}) ;
	
	this.initGrid = function(){
		_self.viewList.allCustomers = {} ;
		loki.daos["res.partner"].listAll().forEach(function(c){
			_self.viewList.allCustomers[c.id] = c ;
		}) ;
	} ;
	
	this.listAll = function(){
		var user = storage(context().name).get("user") ;
		var emp = loki.daos["hr.employee"].searchFirst({user_id: user.id});
		if(emp && emp.AetnaRestrictOnProject){
			return loki.daos["project.project"].search({
				AetnaIsWorkflow : false, 
				members : {operator : "contains", value : user.id}});
		}else{
			return loki.daos["project.project"].search({
				AetnaIsWorkflow : false, 
				AetnaPublicStatus : "private",
				members : {operator : "contains", value : user.id}})
				.concat(
					loki.daos["project.project"].search({
				AetnaIsWorkflow : false, 
				AetnaPublicStatus : "public"})) ;
		}
	};
    
    
    this.checkCanDelete = function(data, callback){
    	var project = data.recordToDelete ;
    	
    	var task = loki.daos["project.task"].searchFirst({project_id : project.id}) ;
    	if(task){
    		_self.viewForm.error(t("project.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
}

module.exports = new ProjectController();
