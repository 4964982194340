var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;
var loki = require("../api/loki") ;
var EventSource = require("aetna-events").EventSource ;
var execWithOrWithoutCb = require("aetna-func/func").execWithOrWithoutCb ;
var BaseGui = require("aetna-views/BaseGui") ;
var ProgressView = require("../views/main/progress") ;

function BaseController(options) {
	EventSource.call(this) ;
    var _self = this;
    
    this.options = {
    	model : "model", // name of the table
    	modelsToSync : null, // can give a list of table to sync
    	socketChangeEvent : "model.change", //socketio event change (not yet implemented)
    	name : "controllerName", // the name of the controller
    	defaultValues : null, // default values of a new record
    	gridClass : null, // class of grid view
    	gridContainer : null, // id of grid container
    	formClass : null, // class of form view
    	formContainer : null, // id of form container
    	uniqueCols : null, // unique column to check for duplicate
    	forceRefreshOnModif : false, // force refresh on modif (usefull if you can delete link record)
    	filter: null /*
    	{ field : 'state' }
    	*/
    } ;	
    Object.keys(this.options).forEach(function(k){
    	if(options[k] !== undefined){
    		_self.options[k] = options[k] ;
    	}
    }) ;
	
	_self.gui = new BaseGui() ;

	var initialized =false ;
	

    this.init = function (callback) {
    	if(initialized){ return callback(); }
    	
    	initialized = true ;
    	
		BUS.on("openScreen", _self.onOpenScreen) ;
		BUS.on("logged", _self.onOpenScreen) ;
		
		BUS.on("loaded", function(){
			_self.socket.on(_self.options.socketChangeEvent, _self.doSync);
		}) ;
		
		callback() ;
    };
	
	this.onOpenScreen = function onOpenScreen(ev){
		var screenName = ev.data ;
		if(ev.data.screen){
			screenName = ev.data.screen ;
		}
		
		if(screenName === _self.options.name){
			_self.openGrid() ;
		}else{
			if(_self.viewList){
				_self.viewList.hide() ;
			}
			if(_self.viewForm){
				_self.viewForm.hide() ;
			}
		}
	} ;
	
	/**
	 * Override to do something when load the grid
	 */
	this.onGridLoad = function(){} ;
	
	this.newGridInstance = function(){
		return new _self.options.gridClass(cli().serverUrl, cli().schemas);
	} ;
	
	this.openGrid = function(callback){
		if(!callback){ callback = function(){} ;}
		if(_self.viewForm){
			_self.viewForm.hide() ;
		}
		
		_self.gui.startWait() ;
		
		_self.doSync(function(){
			_self.viewList = _self.newGridInstance() ;
		
			if(_self.options.filter){
				_self.viewList.on("changeFilter", _self.refreshList) ;
			}
		
			_self.initGrid() ;
			// _self.viewList.on("createDoc", _self.openCreateDoc) ;
			
			var container = undefined;
			if(_self.options.gridContainer){
				container = document.getElementById(_self.options.gridContainer) ;
			}
			
	        _self.viewList.init(container, function () {
	        	_self.viewList.show() ;
				_self.doSync(function(err){
					if(err){ return _self.viewList.endWaitError(err); }
					_self.refreshList() ;
					execWithOrWithoutCb(_self.onGridLoad, function(){
						_self.gui.endWait() ;	
						callback() ;
					}) ;
				});
			});
			
			_self.viewList.on("exportXls", _self.exportXls) ;
			_self.viewList.on("openRecord", _self.openRecord) ;
			_self.viewList.on("createNew", _self.createNew) ;
			_self.viewList.on("reload", _self.onReload) ;
		}) ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
		if(_self.options.filter){
			var counts = loki.daos[_self.options.model].countBy(_self.options.filter.field) ;
			_self.viewList.loadCounts(counts) ;
		}
	} ;
	
	this.initGrid = function(){
		
	} ;
	
	this.doSync = function(forceRefresh, callback){
		var models = [_self.options.model] ;
		if(_self.options.modelsToSync){
			models = _self.options.modelsToSync ;
		}
		
		loki.sync(models, forceRefresh, callback) ;
	} ;
	
	this.listAll = function(){
		if(_self.options.filter){
			var filter = _self.viewList.getFilter() ;
		
			var filters = [] ;
			Object.keys(filter).forEach(function(k){
				if(filter[k]){
					filters.push(k) ;
				}
			}) ;
			
			var search = {} ;
			if(filters.length > 0){
				search = [] ;
				search[_self.options.filter.field] =  {operator : "in", value : filters} ;
			}
			
			return loki.daos[_self.options.model].search(search) ;
		}else{
			return loki.daos[_self.options.model].listAll() ;
		}
	};
	
	this.exportXls = function(ev){
		cli().gridToXls(
            ev.data.columns,
            ev.data.lines,
            ev.data.columnGroups,
            (ev.data.title||t(_self.options.name+".list"))+".xlsx"
        ) ;
	} ;
	
	this.newFormInstance = function(){
		return new _self.options.formClass(cli().serverUrl, cli().schemas);
	} ;
	
	this.initForm = function(callback){
		_self.viewForm = _self.newFormInstance();
			
		var container = undefined;
		if(_self.options.formContainer){
			container = document.getElementById(_self.options.formContainer) ;
		}
			
		_self.viewForm.init(container, callback) ;
		_self.viewForm.on("returnToList", _self.returnToList) ;
		_self.viewForm.on("save", _self.onSave) ;
		_self.viewForm.on("delete", _self.onDelete) ;
	} ;
	
	this.processValues = function(data, callback){
		return callback() ;
	} ;
	
	this.onSave = function(ev){
		_self.viewForm.startWait();
		
		_self.doSave(ev.data, function(err, record){
			if(err){ return _self.viewForm.endWaitError(err) ; }
			
			_self.currentRecord = record ;
			_self.loadRecord(record) ;
			_self.viewForm.endWait() ;
			if(ev.data.isCreate){
				_self.emit("created") ;
			}else{
				_self.emit("modified") ;
			}
		}) ;
	} ;
	
	
	
	this.doSave = function(dataToSave, callback){
		if(_self.options.defaultValues){
			Object.keys(_self.options.defaultValues).forEach(function(k){
				if(!dataToSave.recordToSave[k]){
					dataToSave.recordToSave[k] = _self.options.defaultValues[k] ;
				}
			}) ;
		}
		
		execWithOrWithoutCb(_self.processValues, dataToSave, function(err){
			if(err){ return callback(err) ; }

			if(dataToSave.isCreate){
				var controlUniqueOK = true ;
				if(_self.options.uniqueCols){
					if(!Array.isArray(_self.options.uniqueCols)){
						_self.options.uniqueCols = [_self.options.uniqueCols] ;
					}
					controlUniqueOK = !_self.options.uniqueCols.some(function(unique){
						var searchCriteria = {} ;
						searchCriteria[unique] = dataToSave.recordToSave[unique] ;
						var foundOther = loki.daos[_self.options.model].searchFirst(searchCriteria) ;
						if(foundOther){
							callback(t(_self.viewForm.options.module+".recordAlreadyExist", 
							{col : unique, colName : t("fields."+_self.options.model+"."+unique), value :dataToSave.recordToSave[unique] }))  ;
							return true ;
						}
					}) ;
				}
				
				if(!controlUniqueOK){ return ; }
				
				cli(_self.options.model).create(dataToSave.recordToSave, function(err, savedRecord){
					if(err){ return callback(err) ; }
					
					_self.doSync(function(){
						var record = loki.daos[_self.options.model].searchFirst({id : savedRecord.id}) ;
						callback(null, record) ;
					}) ;
				}) ;
			}else{
				cli(_self.options.model).save(dataToSave.recordToSave, function(err, savedRecord){
					if(err){ return callback(err) ; }
					
					_self.doSync(_self.options.forceRefreshOnModif, function(){
						var record = loki.daos[_self.options.model].searchFirst({id : dataToSave.recordToSave.id}) ;
						callback(null, record) ;
					}) ;
				}) ;
			}	
		}) ;
	} ;
	
	this.checkCanDelete = function(data, callback){
		return callback(true) ;
	} ;
	
	this.onDelete = function(ev){
		_self.viewForm.startWait();
		
		_self.checkCanDelete(ev.data, function(ok){
			if(ok){
				cli(_self.options.model).delete({id :ev.data.recordToDelete.id}, function(err){		
					if(err){ return _self.viewForm.endWaitError(err); }
		
					_self.viewForm.endWait() ;
					_self.returnToList(true) ;
					_self.emit("deleted") ;
				}) ;
			}else{
				_self.viewForm.endWait() ;
			}
		}) ;
		
	} ;
	
	
	this.onReload = function(ev){
		var forceRefresh = false ;
		if(ev && ev.data && ev.data.forceRefresh){
			forceRefresh = true ;
		}
		_self.returnToList(forceRefresh) ;
	} ;
	
	
	this.returnToList = function(forceRefresh){
		if(_self.viewForm){
			_self.viewForm.hide() ;
		}
		_self.viewList.show() ;
    	_self.viewList.startWait() ;
		_self.doSync(forceRefresh===true, function(err){
			if(err){ return _self.viewList.endWaitError(err); }
			_self.refreshList() ;
			_self.viewList.endWait() ;	
		});
	} ;
	
	this.openRecord = function(ev){
		_self.viewList.startWait() ;
		_self.doSync(function(err){
			if(err){ return _self.viewList.endWaitError(err) ; }
			
			var record = loki.daos[_self.options.model].searchFirst({id : ev.data.id}) ;
			_self.currentRecord = record ;
			
			_self.initForm(function(){
				_self.loadRecord(record) ;
				
				_self.viewForm.show() ;
				
				_self.viewList.endWait() ;	
				_self.viewList.hide() ;
			}) ;
			
		}) ;
	} ;
	
	this.loadRecord = function(record){
		_self.currentRecord = record ;
		_self.viewForm.load(record) ;
	} ;
	
	this.createNew = function(ev){
		_self.viewList.startWait() ;
		
		_self.initForm(function(){
			_self.viewForm.createNew((ev && ev.data )?ev.data.newRecord:null) ;
			_self.viewForm.show() ;
			
			_self.viewList.endWait() ;	
			_self.viewList.hide() ;
		});
	} ;

	
	this.progressPopup = null;
	this.startWaitProgress = function(){
		if(_self.progressPopup){ return; }
		_self.progressPopup = new ProgressView() ;
		_self.progressPopup.openInPopup({
			size: BootstrapDialog.SIZE_SMALL, 
			title: t("pleaseWait") ,
			closable: false,
			closeByBackdrop: false,
			closeByKeyboard: false,
		}) ;
	} ;

	this.setProgress = function(percentOrValue, totalOrNothing){
		if(!_self.progressPopup){ return; }
		var percent = percentOrValue ;
		if(totalOrNothing){
			percent = Math.round(100*percentOrValue/totalOrNothing) ;
		}
		_self.progressPopup.setProgress(percent) ;
	} ;

	this.endWaitProgress = function(){
		if(!_self.progressPopup){ return; }
		_self.progressPopup.closePopup() ;
		_self.progressPopup = null;
	} ;

	this.endWaitProgressError = function(error){
		_self.endWaitProgress() ;
		_self.gui.error(error) ;
	} ;
}

module.exports = BaseController;
