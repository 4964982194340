var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var loki = require("../../api/loki") ;
var str = require("aetna-string") ;

var model = "account.invoice" ;
var moduleName = "purchase" ;

function InvoiceGridView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"AetnaRef",
			"partner_id",
			"name",
			"date_invoice",
			"date_due",
			"partner_id",
			"amount_untaxed",
			"amount_total",
			"residual",
			"state",
			"action"
    	],
	}) ;
	
	
	this.staticHTML = null;
	this.staticCSS = null;
	
	_self.fields.fields["account.invoice~~grid"].colsCustom = [
		{field : "state", caption : t("fields.account.invoice.state"), size :"150px", render: function(record){
			var state = loki.daos["account.invoice"].getExtendedState(record) ; 
			
			var color = "info" ;
			if(state === "open"){
				color = "primary" ;
			}else if(state === "cancel"){
				color = "warning" ;
			}else if(state === "paid"){
				color = "success" ;
			}
			
			return '<div class="bg-'+color+'" style="height: 37px;line-height: 31px;">'+
			t("purchase."+state)+'</div>' ;
		}},
		
		{field : "action", caption : " ", size :"250px", render: function(record){
			var state = loki.daos["account.invoice"].getExtendedState(record) ; 

			if(state === "draft"){
				return '<button class="btn btn-sm btn-primary" data-action="validate">'+
				'<i class="fa fa-check-circle"></i> '+t("purchase.validate")+'</button>&nbsp;'+
				
				'<button class="btn btn-sm btn-danger" data-action="cancel">'+
				'<i class="fa fa-trash"></i> '+t("purchase.cancel")+'</button>';
			}else if(state === "waiting" || state === "toRemind"){
				'<button class="btn btn-sm btn-danger" data-action="refund">'+
				'<i class="fa fa-undo"></i> '+t("purchase.refundInvoice")+'</button>';
			}else{
				return "" ;
			}
		}}
	] ;
	
	
	
	var filtersLinks = ["filterDraft", "filterOpen", "filterPaid", "filterCancel"] ;
	
	_self.once("initDone", function(){
		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey){
					filtersLinks.forEach(function(otherId){
						if(id !== otherId){
							_self.$EL[otherId].removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
		
		_self.EL.detailsFilters.addEventListener("click", _self.toggleDetailsFilter);

		_self.fields.fields[_self.options.model+"~~grid"].addEventListener("click", _self.onTableClick) ;
		_self.fields.fields[_self.options.model+"~~grid"].addEventListener("selectionChanged", _self.onTableSelect) ;
		
		_self.EL.validateAndPrint.addEventListener("click", _self.validateAndPrint);
		
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.on("select", _self.onSelect) ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.onExpand = _self.onExpand ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.onColumnResize = _self.onColumnResize ;
	}) ;
	
	_self.on("changeFilter", function(ev){
		var showMulti = false ;
		if(ev.data.filter.draft){
			_self.EL.divValidateMultiple.style.display = "block" ;
			_self.$EL.pointerValidate.addClass("animated") ;
			_self.$EL.pointerValidate.addClass("bounce") ;
			
			_self.$EL.pointerValidate.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
				_self.$EL.pointerValidate.removeClass("animated") ;
				_self.$EL.pointerValidate.removeClass("bounce") ;
			});	
			showMulti = true ;
		}else{
			_self.EL.divValidateMultiple.style.display = "none" ;
		}
		
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.show.selectColumn = showMulti ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.multiSelect = showMulti ;
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh() ;
		_self.resizeTable() ;
	}) ;
	
	
	this.getFilter = function(){
		var filter = {} ;
		
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;	
			if($a.hasClass("active")){
				filter[str.unCapitalize(id.replace(/^filter/, ""))] = true ;
			}
		});
		
		return filter ;
	} ;
	
	this.toggleDetailsFilter = function(){
		_self.$EL.filters.toggleClass("list-group-horizontal") ;
		if(_self.$EL.filters.hasClass("list-group-horizontal")){
			_self.EL.detailsFilters.innerHTML = t("purchase.viewDetails") ;
		}else{
			_self.EL.detailsFilters.innerHTML = t("purchase.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	
	this.resizeTable = function(){
		
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		if(_self.$EL.inputPayRow.is(":visible")){
			headerHeight += _self.$EL.inputPayRow.height() ;
		}

		_self.EL.gridRow.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields["account.invoice~~grid"].render() ;
	} ;
	
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		filtersLinks.forEach(function(id){
			var $span = _self.$EL[id].find(".badge") ;
			var count = counts[str.unCapitalize(id.replace(/^filter/, ""))] || 0 ;
			if($span.html() != count){// != because compare string and integer
				$span.html(count) ;
				$span.addClass("animated") ;
				$span.addClass("flash") ;
				
				$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$span.removeClass("animated") ;
					$span.removeClass("flash") ;
				});	
			}
		}) ;
	} ;
	
	this.onTableClick = function(ev){
		if(!ev.originalEvent || !ev.originalEvent.target){ return; }
		
		console.log(ev.originalEvent.target) ;
		
		if(ev.originalEvent.target.tagName === "SELECT"){
			ev.preventDefault() ;
		}
		
		var action = ev.originalEvent.target.getAttribute("data-action") ;
		if(action === "validate"){
			_self.confirm(t("purchase.areYouSureValidate"), function(yes){
				if(yes){
					_self.emit("validate", _self.fields.fields["account.invoice~~grid"].getRecord(ev.recid)) ;
				}
			});
		}else if(action === "cancel"){
			_self.confirm(t("purchase.areYouSureCancel"), function(yes){
				if(yes){
					_self.emit("cancel", _self.fields.fields["account.invoice~~grid"].getRecord(ev.recid)) ;
				}
			});
		}else if(action === "refund"){
			_self.confirm(t("purchase.areYouSureRefund"), function(yes){
				if(yes){
					_self.emit("refund", _self.fields.fields["account.invoice~~grid"].getRecord(ev.recid)) ;
				}
			});
		}
	} ;
	
	this.validateAndPrint = function(){
		 var selection = _self.fields.fields["account.invoice~~grid"].getSelectedRecords()  ;
		 if(selection.length === 0){
		 	return _self.error(t("purchase.pleaseSelectAnInvoice")) ;
		 }
		 _self.confirm(t("purchase.areYouSureValidateMulti", {count: selection.length}), function(yes){
			if(yes){
				_self.emit("validate", selection) ;
			}
		});
		 
	} ;
}

module.exports = InvoiceGridView;
