var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var UploadField = require("aetna-views/fields/upload");
var PdfView = require("aetna-views/views/pdf/pdf") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");


var moduleName = "template" ;
var model = "document.aetna.template" ;

function TemplateGridView(serverUrl){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
    		"extension",
    		"name",
			"category_id"
    	],
	}) ;
	
	
	
	this.fields.fields["document.aetna.template~~grid"].colsCustom = [
		{field : "extension",  size :"20px", searchable : true, sortable : true, render : function(record){
				var icon = "file-o" ;
				if(record.extension){
					switch(record.extension.toLowerCase()){
						case "docx":
						icon = "file-word-o text-primary" ;
						break;
						case "xlsx":
						icon = "file-excel-o text-success" ;
						break;
						case "pptx":
						icon = "file-powerpoint-o text-danger" ;
						break;
					}
				}
				return '<i class="fa fa-'+icon+'" title="'+record.extension+'"></i>' ;
			}}
		] ;
}

function TemplateFormView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model
	}) ;
	
	_self.pdfView = new PdfView(t) ;
	
	this.once("initDone", function(){
		_self.uploadFile = new UploadField(t) ;
		_self.uploadFile.filedrag = _self.EL.drop ;
		_self.uploadFile.init(_self.EL.uploadFile) ;
		
		_self.pdfView.init(_self.EL.pdf) ;
		
		_self.EL.openWord.addEventListener("click", _self.openWord) ;
        _self.templateCustomFields = new TemplateWidget(_self.EL.keywords) ;

		_self.customFields = [];
		schemas.MAIN.tables.some(function(table){
			if(table.tableName === "project.task") {
				table.columns.forEach(function(col){
					if(col.name.indexOf("x_") === 0){
						_self.customFields.push({
							code : "{"+col.name.substring(2)+"}",
							label : col.desc
						}) ;
					}
				}) ;
				return true ;
			}
		}) ;
		_self.templateCustomFields.render({fields : _self.customFields}) ;
	}) ;
	
	
	this.on("changeEditionMode", function(){
		_self.pdfView.ensureInit(function(){
			if(_self.template.id){
				_self.pdfView.showPdf(serverUrl+"/api/document.aetna.template/downloadPdf?id="+_self.template.id) ;		
			}
		}) ;
		
		var officeSoftware ;
		if(_self.template.extension){
			switch(_self.template.extension.toLowerCase()){
				case "docx":
				officeSoftware = "Word" ;
				break;
				case "xlsx":
				officeSoftware = "Excel" ;
				break;
				case "pptx":
				officeSoftware = "PowerPoint" ;
				break;
			}
		}
		
		if(officeSoftware || window.isDesktop){
			var labelOpen = t("doc.openDoc", {soft : officeSoftware}) ;
			if(!officeSoftware){
				labelOpen = t("doc.openDocNoSoft") ;
			}
			_self.EL.openWord.style.display = "block" ;
			_self.EL.openWordLabel.innerHTML = t(labelOpen, {soft : officeSoftware}) ;
		}else{
			_self.EL.openWord.style.display = "none" ;
		}
		
		if(_self.isCreate){
			_self.EL.uploadFileRow.style.display = "block" ;
			_self.$EL.uploadFileLabel.addClass("required") ;
			_self.EL.openWordRow.style.display = "none" ;
			_self.EL.pdf.style.display = "none" ;
		}else if(_self.isModif){
			_self.EL.uploadFileRow.style.display = "block" ;
			_self.$EL.uploadFileLabel.removeClass("required") ;
			_self.EL.openWordRow.style.display = "none" ;
			_self.EL.pdf.style.display = "none" ;
		}else{
			_self.EL.uploadFileRow.style.display = "none" ;
			_self.EL.openWordRow.style.display = "block" ;
			_self.EL.pdf.style.display = "block" ;
		}
	}) ;
	
	this.openWord = function(){
		_self.emit("openTemplateWeb", _self.template) ;
	} ;
	
	
	var _getValues = this.getValues ;
	
	this.getValues = function(){
		var values = _getValues() ;
		values.uploadFile = _self.uploadFile.getFile();
		return values ;
	} ;
	
	var _checkInput = this.checkInput ;
	
	this.checkInput = function(){
		if(_self.isCreate){
			if(! _self.uploadFile.getFile()){
				return t("template.pleaseChooseFile") ;
			}
		}
		
		return _checkInput() ; 
	} ;
	
	this.onPdfStart = function(template){
		if(_self.template && template && template.id == _self.template.id){ // /!\ compare string and number
			_self.pdfView.startWait() ;
		}
	} ;
	
	this.onPdfFinished = function(template){
		if(_self.template && template && template.id == _self.template.id){ // /!\ compare string and number
			_self.pdfView.showPdf(serverUrl+"/api/document.aetna.template/downloadPdf?id="+template.id) ;
			_self.pdfView.endWait() ;
		}
	} ;
	
}

module.exports.Grid = TemplateGridView;
module.exports.Form = TemplateFormView;
