var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;
var formSubmit = require("aetna-ajax/ajax.js").formSubmit;

function AccountMoveLine(serverUrl){
	CrudCli.call(this, serverUrl, "account.move.line");
	
	    
	this.doExport = function(dateStart, dateEnd, state, callback){
        formSubmit(serverUrl + "/api/account.move.line/doExport", {dateStart : dateStart, dateEnd : dateEnd, state: state}, callback);
    };
    
}

module.exports = AccountMoveLine ;