var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function AetnaContractContract(serverUrl){
	CrudCli.call(this, serverUrl, "aetna.contract.contract");
	
	this.getWebDavDoc = function(id, callback){
        ajax(serverUrl + "/api/aetna.contract.contract/getWebDavDoc", {id : id},callback);
    };
}

module.exports = AetnaContractContract ;