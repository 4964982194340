var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function IrModelFields(serverUrl){
	CrudCli.call(this, serverUrl, "ir.model.fields");
	
    this.create = function(obj, callback){
        ajax(serverUrl + "/api/ir.model.fields/create", obj, callback, 60000);
    };


    this.update = function(obj, callback){
        ajax(serverUrl + "/api/ir.model.fields/update", obj, callback, 60000);
    };
    
    this.save = function(obj, callback){
        ajax(serverUrl + "/api/ir.model.fields/save", obj, callback, 60000);
    };
    
    
}

module.exports = IrModelFields ;