var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function ResUsers(serverUrl){
	CrudCli.call(this, serverUrl, "res.users");
	
	this.create = function(user, employee, callback){
        ajax(serverUrl + "/api/res.users/create", "POST",
		{
		    user : user, 
		    employee : employee
		}, callback, 20000);
    };
    
	this.save = function(user, employee, callback){
        ajax(serverUrl + "/api/res.users/save", 
		{user : user, employee : employee}, callback, 20000);
    };
	
	
}

module.exports = ResUsers ;