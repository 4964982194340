var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineResUsers(serverUrl){
	OfflineCrudCli.call(this, "res.users");
	
	this.create = function(user, employee, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
    
	this.save = function(user, employee, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
}

module.exports = OfflineResUsers ;