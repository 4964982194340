var BaseView = require("aetna-views/BaseView.js") ;
var BUS = require("aetna-events").EventSource.BUS;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;

function UserPrefView(serverUrl){
    BaseView.call(this, t, "login", "userPref") ;
	
	var _self = this ;
   
   	var fields = new FieldsEMA(t, serverUrl) ;
   	
	   
	this._initElements = function(callback){
		
        fields.init(_self, function(){
            callback() ;
        }) ;
		
		_self.EL.cancel.addEventListener("click", _self.closePopup) ;
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
	};
	
	this.load = function(employee){
		fields.load(employee) ;
	} ;
	
	
	this.onValidate = function(){
	
		
		var fieldInError = false ;
		fields.usedFields.forEach(function(f){
			f.clearError();
			if(!f.isReadOnly() && f.isRequired() && f.isEmpty()){
				f.error() ;	
				fieldInError = true ;
			}
			if(!f.isEmpty() && !f.isValid()){
				f.error(t("invalidFormat")) ;
				fieldInError = true ;
			}
		}) ;
		
		var values = fields.values()["hr.employee"];
		
		if(fieldInError){
			return _self.error(t("login.thereIsErrorPleaseCorrect")) ;
		}
		
		_self.emit("validate", values) ;
	} ;
		
	
}

module.exports = UserPrefView;
