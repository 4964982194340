var UserViews = require("../views/user/userViews");
var BaseController = require("./BaseController") ;
var cli = require("aetna-ajax/Cli").cli ;
var loki = require("../api/loki") ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var t = require("aetna-views/locales/tr").t ;

function UserController() {
	var _self = this ;
	BaseController.call(this, {
		model : "res.users",
		socketChangeEvent : 'res.users.change',
		name : "user",
		defaultValues : {
			notify_email : "none"
		},
		uniqueCols: ["login"],
		gridClass : UserViews.Grid,
		formClass : UserViews.Form
	}) ;
	
	this.doSync = function(forceRefresh, callback){
		loki.sync(["res.users", "hr.employee", "ir.model.data", "hr.aetna.extserver", "hr.department"], forceRefresh, callback) ;
	} ;
	
	this.groupId = function(name){
		var idDef = loki.daos["ir.model.data"].searchFirst({complete_name : name, model : "res.groups"}) ;
		return idDef.res_id ;
	} ;
	
	this.groupName = function(id){
		var idDef = loki.daos["ir.model.data"].searchFirst({res_id : id, model : "res.groups"}) ;
		return idDef.complete_name ;
	} ;
	
	this.initGrid = function(){
		_self.viewList.allEmployees = {} ;
		loki.daos["hr.employee"].listAll().forEach(function(e){
			_self.viewList.allEmployees[e.user_id] = e ;
		}) ;
	} ;
	
	this.mapUser = function(user){
		var employee = loki.daos["hr.employee"].searchFirst({user_id : user.id}) ;
		if(employee){
			user.otherid = employee.otherid;
			user.department_name = employee.department_name;
			user.AetnaActive = employee.AetnaActive?t("yes"):t("no");
			user.AetnaReceiveNotification = employee.AetnaReceiveNotification?t("yes"):t("no");
		}
		
		user.extservers = loki.daos["hr.aetna.extserver"].search({user_id : user.id}) ;
		
		user.moduleDico = (user.groups_id.indexOf(_self.groupId("document_ema.document_ema_dico_group_user")) !== -1) ;
		user.moduleDicoAssign = (user.groups_id.indexOf(_self.groupId("project_ema.project_ema_dico_group_assign")) !== -1) ;
		user.moduleContract = (user.groups_id.indexOf(_self.groupId("contract_ema.aetna_contract_ema_contract_group_user")) !== -1) ;
		user.moduleFacto = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_group_user")) !== -1) ;
		user.moduleSale = (user.groups_id.indexOf(_self.groupId("sale_ema.ema_sale_group_user")) !== -1) ;
		user.moduleSaleSettings = (user.groups_id.indexOf(_self.groupId("sale_ema.ema_sale_group_settings")) !== -1) ;
		user.moduleFactoRemind = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_group_remind")) !== -1) ;
		user.moduleAccounting = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_accounting_user")) !== -1) ;
		user.moduleAccountingVAT = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_accounting_vat_user")) !== -1) ;
		user.moduleAccountingExport = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_accounting_export_user")) !== -1) ;
		user.moduleAccountingImport = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_import")) !== -1) ;

		user.modulePurchase = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_purchase_group_user")) !== -1) ;
		
		user.moduleSettingsRemind = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_group_remind_settings")) !== -1) ;
		
		user.moduleSettingsUsers = (user.groups_id.indexOf(_self.groupId("base.group_system")) !== -1 && 
										user.groups_id.indexOf(_self.groupId("base.group_hr_manager")) !== -1 ) ;
		user.moduleSettingsCompany = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_company_config_manager")) !== -1) ;
		user.moduleSettingsHrGroups = (user.groups_id.indexOf(_self.groupId("hr_ema.hr_ema_hr_group_settings")) !== -1) ;
		user.moduleSettingsCustomers = (user.groups_id.indexOf(_self.groupId("base.group_sale_manager")) !== -1) ;
		user.moduleSettingsProjects = (user.groups_id.indexOf(_self.groupId("project.group_project_manager")) !== -1) ;
		user.moduleSettingsTemplates = (user.groups_id.indexOf(_self.groupId("document_ema.document_ema_dico_group_template_manager")) !== -1) ; 
		user.moduleSettingsTemplatesType = (user.groups_id.indexOf(_self.groupId("document_ema.document_ema_dico_group_template_type_manager")) !== -1) ; 
		user.moduleSettingsReference = (user.groups_id.indexOf(_self.groupId("project_ema.document_ema_dico_group_ref_manager")) !== -1) ; 
		user.moduleSettingsCustomFields = (user.groups_id.indexOf(_self.groupId("project_ema.project_ema_dico_group_customfields_manager")) !== -1) ; 
		user.moduleSettingsWorkflow = (user.groups_id.indexOf(_self.groupId("project_ema.project_ema_dico_group_workflow_manager")) !== -1) ; 
		user.moduleSettingsWorkflowStep = (user.groups_id.indexOf(_self.groupId("project_ema.project_ema_dico_group_workflow_type_manager")) !== -1) ; 
		
		user.moduleSettingsInvoiceTemplates = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_group_template")) !== -1) ; 
		user.moduleSettingsProducts = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_group_products")) !== -1) ; 
		user.moduleSettingsAccounting = (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_group_accounting_manager")) !== -1) ; 
		
		user.moduleSettingsContract =  (user.groups_id.indexOf(_self.groupId("contract_ema.aetna_contract_ema_contract_settings")) !== -1) ;
		
		user.moduleStatsInvoices =  (user.groups_id.indexOf(_self.groupId("account_ema.account_ema_facto_stats")) !== -1) ;
		
		user.moduleDms =  (user.groups_id.indexOf(_self.groupId("dms_ema.dms_ema_dms_user")) !== -1) ;
		user.moduleDmsInvoice =  (user.groups_id.indexOf(_self.groupId("dms_ema.dms_ema_invoice_user")) !== -1) ;
		
		
		
		return user ;
	} ;
	
	this.listAll = function(){
		var users = loki.daos[_self.options.model].listAll() ;
		
		var currentUser = storage(context().name).get("user") ;
		
		users = users.filter(function(user){
			if(user.login === "admin") {
				//ignore special user admin
				return false ; 
			}
			return user.groups_id.every(function(groupId){
				return currentUser.groups_id.indexOf(groupId) !== -1 ;
			}) ;
		}) ;
		
		users.forEach(_self.mapUser) ;
		
		return users ;
	};
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		var currentUser = storage(context().name).get("user") ;
		var accessibleGroups = [] ;
		currentUser.groups_id.forEach(function(id){
			accessibleGroups.push(_self.groupName(id)) ;
		}) ;
		
		_initForm(function(){
			//show only accessible group option
			_self.viewForm.setAccessibleGroups(accessibleGroups) ;
			callback() ;
		}) ;
	} ;
	
	this.openRecord = function(ev){
		_self.viewList.startWait() ;
		
		
		
		_self.doSync(function(err){
			if(err){ return _self.viewList.endWaitError(err) ; }
			
			var record = loki.daos[_self.options.model].searchFirst({id : ev.data.id}) ;
			var employee = loki.daos["hr.employee"].searchFirst({user_id : ev.data.id}) ;
			
			_self.initForm(function(){
				
				
				_self.viewForm.load({ user : record, employee: employee}) ;
				
				_self.viewForm.show() ;
				
				_self.viewList.endWait() ;	
				_self.viewList.hide() ;
			}) ;
			
		}) ;
	} ;
	
	
	this.onSave = function(ev){
		_self.viewForm.startWait();
		
		var user = ev.data.recordToSave ;
		if(user.password === "********"){
			delete user.password ;
		}
		var currentUser = storage(context().name).get("user") ;
		user.lang = currentUser.lang ;
		
		var employee = ev.data.allValues["hr.employee"] ;
		
		if(_self.options.defaultValues){
			Object.keys(_self.options.defaultValues).forEach(function(k){
				if(!ev.data.recordToSave[k]){
					ev.data.recordToSave[k] = _self.options.defaultValues[k] ;
				}
			}) ;
		}
		
		user.groups_id = [
			_self.groupId("base.group_document_user"), //Knowledge user
			_self.groupId("base.group_partner_manager"), //Contact Creation
			// _self.groupId("base.group_multi_company"), ///Multi Companies
			_self.groupId("base.group_no_one"), //Technical Features
			_self.groupId("base.group_mono_salesteams"), //Do Not Use Sales Teams
			// _self.groupId("project.group_tasks_work_on_tasks"), //Task's Work on Tasks
			_self.groupId("account.group_account_manager"), //Accounting & Finance : Invoicing & Payments
			_self.groupId("share.group_share_user"), //Sharing : User
		] ;
		
		
		if(user.moduleDico){
			user.groups_id.push(_self.groupId("document_ema.document_ema_dico_group_user")) ;
		}
		
		if(user.moduleDicoAssign){
			user.groups_id.push(_self.groupId("project_ema.project_ema_dico_group_assign")) ;
		}
		
		if(user.moduleFacto){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_group_user")) ;
		}
		
		if(user.moduleSale){
			user.groups_id.push(_self.groupId("sale_ema.ema_sale_group_user")) ;
		}
	
		if(user.moduleSaleSettings){
			user.groups_id.push(_self.groupId("sale_ema.ema_sale_group_settings")) ;
		}
		
		if(user.modulePurchase){
			user.groups_id.push(_self.groupId("account_ema.account_ema_purchase_group_user")) ;
		}
		
		if(user.moduleContract){
			user.groups_id.push(_self.groupId("contract_ema.aetna_contract_ema_contract_group_user")) ;
		}
		
		
		if(user.moduleAccounting){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_accounting_user")) ;
		}
		
		if(user.moduleAccountingVAT){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_accounting_vat_user")) ;
		}
		
		if(user.moduleAccountingExport){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_accounting_export_user")) ;
		}
		
		if(user.moduleAccountingImport){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_import")) ;
		}
		
		if(user.moduleFactoRemind){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_group_remind")) ;
		}
		
		if(user.moduleSettingsRemind){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_group_remind_settings")) ;
		}
		
		if(user.moduleSettingsInvoiceTemplates){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_group_template")) ;
		}
		
		if(user.moduleSettingsProducts){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_group_products")) ;
		}
		
		if(user.moduleSettingsAccounting){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_group_accounting_manager")) ;
		}
		
		if(user.moduleSettingsTemplates){
			user.groups_id.push(_self.groupId("document_ema.document_ema_dico_group_template_manager")) ;
		}
		
		if(user.moduleSettingsTemplatesType){
			user.groups_id.push(_self.groupId("document_ema.document_ema_dico_group_template_type_manager")) ;
		}

		if(user.moduleSettingsReference){
			user.groups_id.push(_self.groupId("project_ema.document_ema_dico_group_ref_manager")) ;
		}
		
		if(user.moduleSettingsCustomFields){
			user.groups_id.push(_self.groupId("project_ema.project_ema_dico_group_customfields_manager")) ;
		}
		
		if(user.moduleSettingsWorkflow){
			user.groups_id.push(_self.groupId("project_ema.project_ema_dico_group_workflow_manager")) ;
		}
		
		if(user.moduleSettingsWorkflowStep){
			user.groups_id.push(_self.groupId("project_ema.project_ema_dico_group_workflow_type_manager")) ;
		}
		
		if(user.moduleSettingsContract){
			user.groups_id.push(_self.groupId("contract_ema.aetna_contract_ema_contract_settings")) ;
		}
		if(user.moduleSettingsCompany){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_company_config_manager")) ;
		}
		
		if(user.moduleSettingsHrGroups){
			user.groups_id.push(_self.groupId("hr_ema.hr_ema_hr_group_settings")) ;
		}
		
		if(user.moduleSettingsUsers){
			user.groups_id.push(_self.groupId("base.group_erp_manager")) ;//Administration : Access right
			user.groups_id.push(_self.groupId("base.group_system")) ;//Administration : Settings
			user.groups_id.push(_self.groupId("base.group_user")) ;//HR : employee
			user.groups_id.push(_self.groupId("base.group_hr_user")) ;//HR : officer
			user.groups_id.push(_self.groupId("base.group_hr_manager")) ;//HR : manager
		}
		
		if(user.moduleSettingsCustomers){
			user.groups_id.push(_self.groupId("base.group_sale_manager")) ;//Sales : Manager
		}else{
			user.groups_id.push(_self.groupId("base.group_sale_salesman_all_leads")) ;//Sales : see all leads
		}
		
		if(user.moduleSettingsProjects){
			user.groups_id.push(_self.groupId("project.group_project_manager")) ;//Project : Manager
		}else{
			user.groups_id.push(_self.groupId("project.group_project_user")) ;//Project : User
		}
		
		if(user.moduleStatsInvoices){
			user.groups_id.push(_self.groupId("account_ema.account_ema_facto_stats")) ;
		}
		
		if(user.moduleDms){
			user.groups_id.push(_self.groupId("dms_ema.dms_ema_dms_user")) ;
		}

		if(user.moduleDmsInvoice){
			user.groups_id.push(_self.groupId("dms_ema.dms_ema_invoice_user")) ;
		}
		
		delete user.moduleDico ;
		delete user.moduleFacto ;
		delete user.moduleSale ;
		delete user.moduleFactoRemind ;
		delete user.moduleSettingsUsers ;
		delete user.moduleSettingsTemplates ;
		delete user.moduleSettingsTemplatesType ;
		delete user.moduleSettingsCustomers ;
		delete user.moduleSettingsProjects ;
		delete user.moduleSettingsReference ;
		delete user.moduleSettingsCustomFields ;
		delete user.moduleSettingsWorkflow ;
		delete user.moduleSettingsWorkflowStep ;
		delete user.moduleStatsInvoices ;
		delete user.modulePurchase ;
		delete user.moduleDms ;
		delete user.moduleDmsInvoice ;
		
		
		var controlUniqueOK = true ;
		if(_self.options.uniqueCols){
			if(!Array.isArray(_self.options.uniqueCols)){
				_self.options.uniqueCols = [_self.options.uniqueCols] ;
			}
			controlUniqueOK = !_self.options.uniqueCols.some(function(unique){
				var searchCriteria = {} ;
				searchCriteria[unique] = ev.data.recordToSave[unique] ;
				var foundOther = loki.daos[_self.options.model].searchFirst(searchCriteria) ;
				if(foundOther && foundOther.id !== user.id){
					_self.viewForm.endWaitError(t(_self.viewForm.options.module+".recordAlreadyExist", 
					{col : unique, colName : t("fields."+_self.options.model+"."+unique), value : ev.data.recordToSave[unique] }))  ;
					return true ;
				}
			}) ;
		}
		
		if(!controlUniqueOK){ return ; }
		
		if(ev.data.isCreate){
			
			
			
			var existingEmployee = loki.daos["hr.employee"].searchFirst({otherid : employee.otherid}) ;
			if(existingEmployee){
				return _self.viewForm.endWaitError(t("user.trigrammeAlreadyExist")) ;
			}

			
			cli(_self.options.model).create(user, employee, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				
				
				
				_self.doSync(function(){
					_self.viewForm.load({ user : savedRecord, employee: employee}) ;
					_self.viewForm.endWait() ;
				}) ;
			}) ;
		}else{
			var existingEmployees = loki.daos["hr.employee"].search({otherid : employee.otherid}) ;
			if(existingEmployees.some(function(e){ return e.user_id != user.id ; })){
				return _self.viewForm.endWaitError(t("user.trigrammeAlreadyExist")) ;
			}

			
			
			cli(_self.options.model).save(user, employee, function(err, savedRecord){
				if(err){ return _self.viewForm.endWaitError(err) ; }
				
				_self.doSync(function(){
					_self.viewForm.load({ user : savedRecord, employee: employee}) ;
					_self.viewForm.endWait() ;
				}) ;
			}) ;
		}	
	} ;
}

module.exports = new UserController();
