var ajax = require("aetna-ajax/ajax.js").ajax;

function Import(serverUrl){
    this.import = function(type, file, callback, callbackProgress){
		var params = {
		    type : type, 
		    file : file
		} ;
		
        ajax(serverUrl + "/api/account.import/import", "POST", params, callback, callbackProgress, true, 1200000);
    };
}

module.exports = Import ;