

var t = require("aetna-views/locales/tr").t ;
var FieldGeneric = require("aetna-views/fields/generic") ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var str = require("aetna-string") ;
var moment = require("moment") ;
var BaseView = require("aetna-views/BaseView.js") ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");

var model = "account.move.line" ;
var moduleName = "accountingExport" ;

function OneGridView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
    		'date',
    		'period_id',
    		'partner_id',
    		'name',
    		'account_id',
    		'invoice',
    		'credit',
    		'debit',
    		'state',
    		'AetnaDateExportAccount'
    	],
    	gridOptions : {
    		show : {
    			selectColumn : false,
    			toolbarReload  : false, 
    			toolbarColumns : false
    		}
    	},
    	summary : {
	    	colsToSum : ["credit", "debit"],
	    	colsToCount : ["name"]
    	}
	}) ;
	
	_self.allJournal = {} ;
	_self.gridName = model.replace(/\./g, "_")+"__grid_list" ;
	
	_self.fields.fields["account.move.line~~grid"].colsCustom = [
		{field : "date",  size :"80px"},
		{field : "period_name",  size :"80px"},
		{field : "credit",  size :"80px"},
		{field : "debit",  size :"80px"},
		{field : "state", caption : t("fields.account.move.line.state"), size :"80px", render: function(record){
			
			if(record.summary){ return ""; }
			
			var state = record.AetnaDateExportAccount?"done":"todo" ; 
			
			var color = "default" ;
			if(state === "todo"){
				color = "info" ;
			}else if( state === "done" ){
				color = "success" ;
			}
			
			return '<div class="bg-'+color+'" style="height: 37px;line-height: 31px;">'+
			t("fields.values.account.move.line.state."+state)+'</div>' ;
		}},
		{field : "invoice",  size :"150px", render: function(record){
			return record.invoiceRef ;
		}}
	] ;
}


function AccoutingExportGridView(serverUrl, schemas){
	var _self = this ;
	BaseView.call(this, t, moduleName, moduleName+"Grid") ;
	
	_self.allJournal = {} ;
	
	var filtersLinks = ["filterTodo", "filterDone"] ;
	
	this._initElements = function(){
		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey){
					filtersLinks.forEach(function(otherId){
						if(id !== otherId){
							_self.$EL[otherId].removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
		
		_self.EL.detailsFilters.addEventListener("click", _self.toggleDetailsFilter);
		
		_self.dateStart = new FieldGeneric(t, "date_start", "date", t("accountingExport.from")) ;
		_self.dateEnd = new FieldGeneric(t, "date_end", "date", t("accountingExport.to")) ;
		
		_self.dateStart.init(_self.EL.dateStart);
		_self.dateEnd.init(_self.EL.dateEnd);
		
		_self.dateStart.load(moment().subtract(1, 'month').startOf('month').toDate()) ;
		_self.dateEnd.load(moment().subtract(1, 'month').endOf('month').toDate()) ;
		
		_self.dateStart.addEventListener("change", function(){
			_self.emit("changeFilter", {filter : _self.getFilter()}) ;
		});
		_self.dateEnd.addEventListener("change", function(){
			_self.emit("changeFilter", {filter : _self.getFilter()}) ;
		});
		
		_self.templateGrids = new TemplateWidget(_self.EL.grids);
		_self.templateGrids.on("render", _self.renderGrids) ;

		
		_self.EL.doExport.addEventListener("click", _self.doExport) ;
	} ;
	
	this.getFilter = function(){
		var filter = {} ;
		
		filtersLinks.forEach(function(id){
			var $a = _self.$EL[id] ;	
			if($a.hasClass("active")){
				filter[str.unCapitalize(id.replace(/^filter/, ""))] = true ;
			}
		});
		
		filter.from = _self.dateStart?_self.dateStart.val():moment().subtract(1, 'month').startOf('month').toDate() ;
		filter.to = _self.dateEnd?_self.dateEnd.val():moment().subtract(1, 'month').endOf('month').toDate() ;
		
		return filter ;
	} ;
	
	this.toggleDetailsFilter = function(){
		_self.$EL.filters.toggleClass("list-group-horizontal") ;
		if(_self.$EL.filters.hasClass("list-group-horizontal")){
			_self.EL.detailsFilters.innerHTML = t("facto.viewDetails") ;
		}else{
			_self.EL.detailsFilters.innerHTML = t("facto.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	
	this.resizeTable = function(){
		
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		_self.EL.grids.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields["account.invoice.aetna.tvaexport~~grid"].render() ;
	} ;
	
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		filtersLinks.forEach(function(id){
			var $span = _self.$EL[id].find(".badge") ;
			var count = counts[str.unCapitalize(id.replace(/^filter/, ""))] || 0 ;
			if($span.html() != count){// != because compare string and integer
				$span.html(count) ;
				$span.addClass("animated") ;
				$span.addClass("flash") ;
				
				$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$span.removeClass("animated") ;
					$span.removeClass("flash") ;
				});	
			}
		}) ;
	} ;
	
	this.load = function(records){
		var recordsByJournal = {} ;
		
		records.forEach(function(record){
			var key = record.journal_id ;

		 	if(!recordsByJournal[key]){
		 		recordsByJournal[key] = [] ;
		 	}
		 	recordsByJournal[key].push(record) ;
		}) ;
		
		var gridsRecords = [] ;
		Object.keys(recordsByJournal).sort().forEach(function(key){
			var journal = _self.allJournal[recordsByJournal[key][0].journal_id]  ;
			var label = journal.name ;
			if(["sale", "sale_refund", "purchase", "purchase_refund"].indexOf(journal.type) !== -1){
				label = t("fields.values.account.journal.type."+journal.type) ;
			}
			gridsRecords.push({
				key : key,
				journal : label,
				records : recordsByJournal[key]
			}) ;
		}) ;
		
		_self.gridsRecords = gridsRecords ;
		
		_self.templateGrids.render({ grids : gridsRecords}) ;
	} ;
	
	
	this.renderGrids = function(){
		if(_self.templateGrids.LISTS.grid){
			_self.templateGrids.LISTS.grid.forEach(function(grid, i){
				var view = new OneGridView(serverUrl, schemas) ;
				view.gridName += "__"+i ;
				view.allJournal = _self.allJournal ;
				view.on("exportXls", function (ev) {
					var filter = _self.getFilter() ;
					
					var strStart = moment(filter.from).format("L") ;
					var strEnd = moment(filter.to).format("L") ;
					
					_self.fw(ev, { title: grid.data.journal+" ("+strStart+" - "+strEnd+")"});
				});

				grid.data.view = view ;

				view.init(grid.el, function(){
					_self.colsDef = view.fields.fields[model+"~~grid"].columns ;
					view.show() ;
					view.load(grid.data.records) ;
				}) ;
			}) ;
		}	
	} ;
	
	this.doExport = function(){
		var filter = _self.getFilter() ;
		
		_self.emit("doExport", filter) ;
	} ;
	
	
	
}

module.exports = AccoutingExportGridView;
