var LokiDao = require("../LokiDao") ;
var moment = require("moment") ;

var LokiAccountMoveLine = function(loki){
    LokiDao.call(this, loki, "account.move.line") ;
    
    var _self = this ;
    
    
    this.getExportState = function(move){
    	if(move.AetnaDateExportAccount){ 
    		return "done" ;
    	}else{
    		return "todo" ;
    	}
    } ;
    
    this.moveFilters = function(filter){
    	var searchDate = null;
    	
    	if(filter.to){
    		filter.to = moment(filter.to).endOf("day").toDate() ;
    	}
    	
    	if(filter.from && filter.to){
    		searchDate = {
    			$and : [
    				{date : { $gte : moment(filter.from).format("YYYY-MM-DD") } },
    				{date : { $lte : moment(filter.to).format("YYYY-MM-DD") } }
				]
    		} ;
    	}else if(filter.from){
    		searchDate = {date : { $gte : moment(filter.from).format("YYYY-MM-DD") } } ;
    	}else if(filter.to){
    		searchDate = {date : { $lte : moment(filter.to).format("YYYY-MM-DD") } } ;
    	}
    	
        var searchFilters = [] ;
        
		if(filter.todo && !filter.done){
			searchFilters.push({ AetnaDateExportAccount : false }) ;
		}
        
		if(filter.done && !filter.todo){
			searchFilters.push({ AetnaDateExportAccount : {$ne : false } } ) ;
		}
		
		
		
		var search = null ;
		if(searchFilters.length>1){
			search = {
				$or : searchFilters	
			};
		}else if(searchFilters.length>0){
			search = searchFilters[0] ;
		}
		if(searchDate){
			if(search){
				search = {
					$and : [
						search,
						searchDate
					]
				} ;
			}else{
				search = searchDate ;
			}
		}
		
		if(!search){ search = {} ; }
		
		
		
		return this.coll().chain().find(search).data() ;
    } ;
    
    
    this.countByState = function(filter){
    	var searchDate = null;
    	
    	if(filter.to){
    		filter.to = moment(filter.to).endOf("day").toDate() ;
    	}
    	
    	if(filter.from && filter.to){
    		searchDate = {
    			$and : [
    				{date : { $gte : moment(filter.from).format("YYYY-MM-DD") } },
    				{date : { $lte : moment(filter.to).format("YYYY-MM-DD") } }
				]
    		} ;
    	}else if(filter.from){
    		searchDate = {date : { $gte : moment(filter.from).format("YYYY-MM-DD") } } ;
    	}else if(filter.to){
    		searchDate = {date : { $lte : moment(filter.to).format("YYYY-MM-DD") } } ;
    	}
    	
    	var search = {};
    	
    	if(searchDate){
    		search = searchDate ;
    	}
    	
    	
    	return this.coll().chain().find(search).mapReduce(
			function(move){ 
				return { state : _self.getExportState(move) } ;
			}, 
			function(move){ 
				var byState = {}; 
				move.forEach(function(d){ 
				    if(!byState[d.state]){ 
				   		byState[d.state] = 0; 
				    }
					byState[d.state]++;
				}); 
				return byState ;
		} ) ;
    } ;
    
    
     
} ;

module.exports = LokiAccountMoveLine ;