var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var Wizard = require("aetna-views/widgets/Wizard");
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var FieldAutocomplete = require("aetna-views/fields/autocomplete");
var UploadField = require("aetna-views/fields/upload");
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var DocGridView= require("./gridDocs") ;
var loki = require("../../api/loki") ;

function CreateDocView(serverUrl, cateTree, schemas){
    BaseView.call(this, t, "doc", "createDoc") ;
	
	var _self = this ;
   
   	this.fields = new FieldsEMA(t, serverUrl) ;
	this.cateTree = cateTree ;
	this.usersOfProject = [] ;
	
	this._initElements = function(callback){
		
		_self.user = storage(context().name).get("user") ;
		
		var steps = [] ;
		
		steps.push({
					title : t("doc.generalInfo"), 
					element : _self.EL.general,
					onGoToNext : _self.onStepGeneral 
				}) ;
				
		if(!_self.existingDoc){
			steps.push({
					title : t("doc.template"), 
					element : _self.EL.template,
					onGoToNext : _self.onStepTemplate
				}) ;
		}else{
			_self.EL.template.style.display = "none" ;
		}
		
		steps.push({
					title : t("doc.workflow"), 
					element : _self.EL.workflow,
					onGoToNext : _self.onStepWorkflow
				}) ;
		
		_self.wizard = new Wizard({
			validationLabel : t("doc.finishCreate"),
			steps : steps
		}) ;
		
		
		var itemsProjects = [] ;
		var memberProjects = [] ;
		var otherProjects = [] ;
		Object.keys(_self.allProjects).forEach(function(projectId){
			var project = _self.allProjects[projectId] ;
			if(project.state === "open"){
				if(project.members.indexOf(_self.user.id) !== -1){
					memberProjects.push({
						key : project.id,
						value : project.AetnaCode+" - "+project.name,
						icon: project.AetnaPublicStatus === "private"?"fa fa-lock":""
					}) ;
				}else{
					otherProjects.push({
						key : project.id,
						value : project.AetnaCode+" - "+project.name ,
						icon: project.AetnaPublicStatus === "private"?"fa fa-lock":""
					}) ;
				}
			}
		}) ;
		
		memberProjects = memberProjects.sort(function(p1, p2){ return p1.value > p2.value?1:-1 ; }) ;
		otherProjects = otherProjects.sort(function(p1, p2){ return p1.value > p2.value?1:-1 ; }) ;
		
		if(memberProjects.length > 0){
			itemsProjects.push({
					value : t("doc.projectsYouBelong"),
					subEntries : memberProjects
			}) ;
		}
		if(otherProjects.length > 0){
			if(memberProjects.length > 0){
				itemsProjects.push({
						value : t("doc.otherProjects"),
						subEntries : otherProjects
				}) ;
			}else{
				itemsProjects = otherProjects ;
			}
		}
		
		_self.fieldProjects = new FieldAutocomplete(t, "project", itemsProjects, t("fields.project.task.project_id")) ;
		
		_self.fieldProjects.setRequired(true) ;
		
		_self.fields.fields["document.aetna.template~~grid"].colsToShow = ["extension", "name"] ;
		
		_self.fields.fields["document.aetna.template~~grid"].colsCustom = [
			{field : "extension",  size :"20px", searchable : true, sortable : true, render : function(record){
					var icon = "file-o" ;
					if(record.extension){
						switch(record.extension.toLowerCase()){
							case "docx":
							icon = "file-word-o text-primary" ;
							break;
							case "xlsx":
							icon = "file-excel-o text-success" ;
							break;
							case "pptx":
							icon = "file-powerpoint-o text-danger" ;
							break;
						}
					}
					return '<i class="fa fa-'+icon+'" title="'+record.extension+'"></i>' ;
				}}
			] ;
		
		_self.fields.fields["document.aetna.template~~grid"].options = {
			show: { selectColumn: true },
			multiSelect: false	
		} ;
		
		
		_self.fieldProjects.init(_self.EL.projectField, function(){
			_self.fields.init(_self, function(){
	            callback() ;
	        }) ;
		}) ;
		
		_self.templateNotifications = new TemplateWidget(_self.EL.notifications) ;
        _self.templateNotifications.on("render", _self.renderNotifications) ;
        
        _self.templateCustomFields = new TemplateWidget(_self.EL.customFields) ;
        _self.templateCustomFields.on("render", _self.renderCustomFields) ;
        
        _self.customFields = [];
		schemas.MAIN.tables.some(function(table){
			if(table.tableName === "project.task") {
				table.columns.forEach(function(col){
					if(col.name.indexOf("x_") === 0){
						_self.customFields.push(col) ;
					}
				}) ;
				return true ;
			}
		}) ;
		
		_self.templateCustomFields.render({fields : _self.customFields}) ;
		
		var workflowsById = storage(context().name).get("workflowsById") ;
		var stagesByName = storage(context().name).get("stagesByName") ;
		_self.fields.fields["project.task~AetnaWorkflow_id"].addEventListener("change", function(){
			var wfId = _self.fields.fields["project.task~AetnaWorkflow_id"].val() ;
			if(wfId){
				_self.notifications = [] ;
				if(workflowsById[wfId]){
					workflowsById[wfId].AetnaCycles_ids.forEach(function(c){
						if(!_self.notifications.some(function(n){ return n.AetnaStage_name === c.AetnaStage_name})){
							_self.notifications.push({
								AetnaStage_id : c.AetnaStage_id,
								AetnaStage_name : c.AetnaStage_name,
								AetnaStage_description : stagesByName[c.AetnaStage_name].description,
								user : {}
							});
						}
						
					}) ;
					
					_self.templateNotifications.render({ notifications : _self.notifications}) ;
				}
			}
		}) ;
		

		_self.ensureInit(function(){
			_self.addCategorySelect(_self.cateTree, 0) ;
		}) ;
		
		
		_self.$EL.tabExisting.on('show.bs.tab', _self.onShowExisting) ;
		_self.gridExisting = new DocGridView(serverUrl, schemas) ;
		_self.gridExisting.idGrid = "gridDocCreate" ;
		_self.gridExisting.colsToShow = [
			"AetnaRef",
			"project_name",
			"name",
			"AetnaSubtitle"
		] ;
		_self.gridExisting.multiSelect = false ;
		_self.gridExisting.init(_self.EL.gridExisting) ;
		
		
		_self.uploadFile = new UploadField(t) ;
		_self.uploadFile.filedrag = _self.EL.drop ;
		_self.uploadFile.init(_self.EL.uploadFile) ;
	};
	
	this.addCategorySelect = function(listCate, index){
		var itemsCate = {} ;
		var itemsCateObject = {} ;
		listCate.forEach(function(c){
			itemsCate[c.category.id] = c.category.name ;
			itemsCateObject[c.category.id] = c ;
		}) ;
		var selectCate = new FieldAutocomplete(t, "category", itemsCate, "") ;
		
		
		
		var li = document.createElement("LI") ;
		var div = document.createElement("DIV") ;
		div.setAttribute("data-placeholder", t("doc.chooseCate")) ;
		li.appendChild(div) ;
		_self.EL.category.appendChild(li) ;
		setTimeout(function(){
			selectCate.init(div, function(){
				selectCate.load("-1") ;
				selectCate.addEventListener("change", function(){
					//remove children categories
					var lis = _self.EL.category.children ;
					for(var i=index+1; i < lis.length; i++){
						_self.EL.category.removeChild(lis[i]) ;
					}
					
					var selectedCate = selectCate.val() ;
					
					if(itemsCateObject[selectedCate] && itemsCateObject[selectedCate].children.length>0){
						_self.addCategorySelect(itemsCateObject[selectedCate].children, index+1) ;
					}
					
					_self.fields.fields["document.aetna.template~~grid"].clear() ;
					if(itemsCateObject[selectedCate] && itemsCateObject[selectedCate].templates.length>0){
						_self.fields.fields["document.aetna.template~~grid"].load(itemsCateObject[selectedCate].templates) ;
					}
					
					
					
				}) ;
			}) ;	
		}, 1) ;
			

	} ;
	
	this.renderNotifications = function(){
		if(_self.templateNotifications.EL_LISTS.user){
			_self.templateNotifications.EL_LISTS.user.forEach(function(elUser, i){
				
				var project = _self.allProjects[_self.fieldProjects.val()] ;
				var itemsUser = [] ;
				var memberUsers = [] ;
				var otherUsers = [] ;
				Object.keys(_self.allUsers).forEach(function(uId){
					if(_self.allEmployees[uId].AetnaActive){
						if(project.members.indexOf(parseInt(uId,10)) !== -1){
							memberUsers.push({
								key : uId,
								value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
							}) ;
						}else if(project.AetnaPublicStatus === "public"){
							otherUsers.push({
								key : uId,
								value : _self.allEmployees[uId].otherid+" - "+_self.allUsers[uId].name
							}) ;
						}
					}
				}) ;
				
				memberUsers = memberUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
				otherUsers = otherUsers.sort(function(a, b){  return a.value > b.value ? 1:-1 ; }) ;
				
				if(memberUsers.length > 0){
					itemsUser.push({
						value : t("doc.memberUsers"),
						subEntries : memberUsers
					}) ;
				}
				if(otherUsers.length > 0){
					if(memberUsers.length > 0){
						itemsUser.push({
							value : t("doc.otherUsers"),
							subEntries : otherUsers
						}) ;
					}else{
						itemsUser = otherUsers ;
					}
				}
				
				var thisField = new FieldAutocomplete(t, "user", itemsUser, _self.notifications[i].AetnaStage_description) ;

				thisField.init(elUser, function(){
					if(_self.notifications[i].AetnaStage_name === "DRAFT" || _self.notifications[i].AetnaStage_name === "REWORK"){
						thisField.load(_self.user.id) ;
					}
				}) ;
				_self.notifications[i].field = thisField ;
	        }) ;
		}
	} ;
	
	this.renderCustomFields = function(){
		if(_self.templateCustomFields.EL_LISTS.field){
			_self.templateCustomFields.EL_LISTS.field.forEach(function(elField, i){
			
				_self.fields.fields["project.task~"+_self.customFields[i].name].label = _self.customFields[i].desc ;
				if(_self.customFields[i].required){
					elField.setAttribute("required", "required") ;
				}
				
				
				_self.fields.createAndInitField("project.task", _self.customFields[i].name, elField, function(){
					
				}) ; 
				
	        }) ;
		}
		
	} ;
	
	this.onStepGeneral  = function(done){
		_self.fields.clearError() ;
		_self.fieldProjects.clearError() ;
		
		var requiredFields =["project.task~name",
								"project.task~AetnaSubtitle"
								] ;
								
		_self.customFields.forEach(function(field){
			if(field.required){
				requiredFields.push("project.task~"+field.name) ;
			}
		}) ;
			
		var hasError = false ;
		requiredFields.forEach(function(fieldName){
			var f = _self.fields.fields[fieldName] ;
            if(f.isEmpty()){
                f.error() ;
                hasError = true ;
            }else if(!f.isValid()){
                f.error() ;
                hasError = true ;
            }
        });
        
        if(_self.fieldProjects.isEmpty()){
        	_self.fieldProjects.error() ;
            hasError = true ;
        }
		
		
		if(hasError){
            _self.error(t("doc.thereIsErrorPleaseCorrect"));
        }
		
		if(hasError){
			return done(false) ;
		}
		
		_self.usersOfProject = _self.allProjects[_self.fieldProjects.val()].members ;
		
		done(true) ;
	} ;
	
	this.onStepTemplate  = function(done){
		_self.templateType = null;
		var href = _self.$EL.tabTemplate.find(".active a").attr("href") ;
		
		if(/^#template/.test(href)){
			_self.templateType = "template" ;
		}else if(/^#existing/.test(href)){
			_self.templateType = "existing" ;
		}else if(/^#upload/.test(href)){
			_self.templateType = "upload" ;
		}
		
		switch(_self.templateType){
			case "template":
				if(!_self.fields.fields["document.aetna.template~~grid"].val()){
					_self.error(t("doc.pleaseChooseTemplate")) ;
					return done(false) ;
				}
				break;
			case "existing":
				if(_self.gridExisting.getSelectedRecords().length === 0){
					_self.error(t("doc.pleaseChooseDocument")) ;
					return done(false) ;
				}
				_self.existingDoc = _self.gridExisting.getSelectedRecords()[0] ;
				break;
			case "upload":
				if(!_self.uploadFile.getFile()){
					_self.error(t("doc.pleaseChooseFile")) ;
					return done(false) ;
				}
				break;
		}
	
		
		done(true) ;
	} ;
	
	this.onStepWorkflow  = function(done){
		_self.fields.clearError() ;
		
		var requiredFields =[_self.fields.fields["project.task~AetnaWorkflow_id"]].concat(
			_self.notifications.map(function(n){ return n.field ;})) ;
			
		var hasError = false ;
		requiredFields.forEach(function(f){
            if(f.isEmpty()){
                f.error() ;
                hasError = true ;
            }else if(!f.isValid()){
                f.error() ;
                hasError = true ;
            }
        });
		
		
		var restrictGroups = _self.fields.fields["project.task~AetnaRestrictGroup_ids"].val() ;
		if(restrictGroups && restrictGroups.length > 0){
			var allowedUsers = [];
			restrictGroups.forEach(function(g){
				var group = loki.daos["hr.aetna.group"].getById(g) ;
				group.member_ids.forEach(function(uid){
					allowedUsers.push(""+uid) ;
				}) ;
			});
			
			
			var chosenUserOk = true ;
			
			_self.notifications.forEach(function(n){
				if(allowedUsers.indexOf(""+n.field.val()) === -1){
					chosenUserOk = false ;
					n.field.error() ;
				} 
			}) ;
			
			if(!chosenUserOk){
				_self.error(t("doc.chosenUserDoesNotBelongToRestrictGroup")) ;
				return done(false) ;
			}
		}
		
		
		if(hasError){
            _self.error(t("doc.thereIsErrorPleaseCorrect"));
        }
		
		if(hasError){
			return done(false) ;
		}
		
		var values = _self.fields.values() ;
		
		var notifs = [] ;
		_self.notifications.forEach(function(n){
			notifs.push({
				AetnaStage_id : n.AetnaStage_id,
				AetnaUser_id : n.field.val() 
			}) ;
		}) ;
		
		
		var template, existingDoc, uploadFile ;
		switch(_self.templateType){
			case "template":
				template = _self.fields.fields["document.aetna.template~~grid"].val() ;
				break;
			case "existing":
				existingDoc = _self.existingDoc.id ;
				break;
			case "upload":
				uploadFile = _self.uploadFile.getFile();
				break;
		}
		
		var task = values["project.task"] ;
		task.project_id = _self.fieldProjects.val() ;
		
		_self.emit("validate", { 
			task : task,
			template : template, 
			existingDoc : existingDoc, 
			uploadFile : uploadFile, 
			notifications : notifs}) ;
	} ;
	
	this.onShowExisting = function(){
		_self.emit("openExisting") ;
	} ;
	
	this.loadDocs = function(docs){
		_self.gridExisting.load(docs) ;
	}

}

module.exports = CreateDocView;
