var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseView = require("aetna-views/BaseView")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var FieldsEMA = require("../fields/FieldsEMA") ;
var PdfView = require("aetna-views/views/pdf/pdf") ;

function CreateInvoiceView(serverUrl, schemas){
	var _self = this ;
	
	BaseView.call(this, t, "contract", "createInvoice") ;
	
	this.fields = new FieldsEMA(t, serverUrl)  ;
	
	this.initialVisibility = "visible" ;

	this._initElements = function(callback){
		_self.templateLines = new TemplateFields({newLineOnChange : false});
		
		_self.templateLines.on("lineChange", _self.onLineChange) ;
		_self.templateLines.on("initField", _self.onLineFieldInit) ;

		_self.fields.init(_self, function(){
			_self.templateLines.init(_self.EL.lines, _self.fields) ;
			
			_self.fields.fields["account.invoice~partner_id"].setReadOnly(true) ;
			_self.fields.fields["account.invoice~amount_untaxed"].setReadOnly(true) ;
			_self.fields.fields["account.invoice~amount_tax"].setReadOnly(true) ;
			_self.fields.fields["account.invoice~amount_total"].setReadOnly(true) ;
			
            callback() ;
        }) ;
	} ;
	
	this.backgroundQuantity = function(fieldQuantity){
		var quantity = fieldQuantity.val() ;
			
		if(!quantity){
			fieldQuantity.EL.inputField.style.backgroundColor = "red" ;
		}else{
			fieldQuantity.EL.inputField.style.backgroundColor = "white" ;
		}
	} ;
	
	this.onLineFieldInit = function(ev){
		if(ev.data.name === "account.invoice.line~quantity"){
			ev.data.field.addEventListener("change", function(){
				_self.backgroundQuantity(ev.data.field) ;
			}) ;
			
			_self.backgroundQuantity(ev.data.field) ;
		}
	} ;
	
	this.onLineChange = function(ev){
		if(ev.data.col === "quantity" || ev.data.col === "price_unit"){
			var line = ev.data.line ;
			if(line.quantity && line.price_unit){
				line.price_subtotal = line.quantity * line.price_unit ;
				_self.templateLines.refreshLine(ev.data.def) ;
			}
		}
		
		_self.computeTotals() ;
	} ;
	
	this.computeTotals = function(){
		var lines = _self.templateLines.val() ;
		
		var total_untaxed = 0;
		var total_tax = 0;
		var total_total = 0;
		
		lines.invoice_line.forEach(function(line){
			if(line.price_subtotal){
				total_untaxed += line.price_subtotal ;
				
				if(line.product_id){
					var product = _self.productsById[line.product_id] ;
					var lineTaxes = 0 ;
					if(product.taxes_id){
						product.taxes_id.forEach(function(taxId){
							var tax = _self.taxesById[taxId] ;
							lineTaxes += line.price_subtotal * tax.amount ;
						}) ;
					}
					
					total_tax += lineTaxes ;
				}
			}
		}) ;
		
		total_total = total_untaxed + total_tax ;
		
		_self.fields.fields["account.invoice~amount_untaxed"].load(total_untaxed) ;
		_self.fields.fields["account.invoice~amount_tax"] .load(total_tax) ;
		_self.fields.fields["account.invoice~amount_total"].load(total_total) ;
		
		_self.emit("change") ;
	} ;
	
	this.load = function(invoice){
		_self.invoice = invoice ;
		
		_self.fields.load(invoice) ;
		
		_self.templateLines.load(invoice) ;
		
		_self.computeTotals() ;
	} ;
	
	this.getValues = function(){
		var invoice = _self.fields.values()["account.invoice"] ;
		
		var lines = _self.templateLines.val().invoice_line ;
		invoice.invoice_line = lines;
		
		invoice.AetnaContract_id = _self.invoice.AetnaContract_id ;
		
		return invoice ;
	} ;
}

module.exports = CreateInvoiceView;
