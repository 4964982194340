var AccountViews = require("../views/account/accountViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

function AccountController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.account",
		socketChangeEvent : 'accountChanged',
		name : "account",
		gridClass : AccountViews.Grid,
		formClass : AccountViews.Form,
		uniqueCols : ["code"]
	}) ;
	
	this.listAll = function(){
		var types = loki.daos["account.account.type"].search({code : {operator : "in", value: ["income", "bank", "payable", "receivable", "cash", "expense", "view"]}}) ;

		var typesIds = types.map(function(type){ return type.id ;}) ;
		return loki.daos[_self.options.model].search({
			type : {operator : "in", value: ["receivable", "liquidity", "payable", "consolidation", "other", "view"]},
			user_type : {operator : "in", value: typesIds}
		}).filter(function(account){
			return account.code.match(/^[0-9]{3,6}$/) ;
		}) ;
	};
	
    this.checkCanDelete = function(data, callback){
    	var account = data.recordToDelete ;
    	
    	var line = loki.daos["account.journal"].searchFirst({default_credit_account_id : account.id}) ;
    	if(line){
    		_self.viewForm.error(t("account.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
    	line = loki.daos["account.journal"].searchFirst({default_debit_account_id : account.id}) ;
    	if(line){
    		_self.viewForm.error(t("account.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
    	line = loki.daos["res.partner"].searchFirst({property_account_receivable : account.id}) ;
    	if(line){
    		_self.viewForm.error(t("account.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
    	line = loki.daos["res.partner"].searchFirst({property_account_payable : account.id}) ;
    	if(line){
    		_self.viewForm.error(t("account.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(callback) ;
	} ;
}

module.exports = new AccountController();
