var StatsInvoicesGridView = require("../views/statsInvoices/statsInvoicesGrid");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseGui = require("aetna-views/BaseGui") ;

function StatsInvoicesController() {
	var _self = this ;

	BaseController.call(this, {
		model : "aetna.stats.invoices",
		modelsToSync : ["account.invoice"],
		socketChangeEvent : 'invoiceChanged',
		name : "statsInvoices",
		gridClass : StatsInvoicesGridView,
		formClass : null
	}) ;
	
	_self.gui = new BaseGui() ;
	
	this.onGridLoad = function(){
		_self.viewList.on("changeFilter", _self.refreshList) ;
	} ;
	
	this.listAll = function(){
		var filter = _self.viewList.getFilter() ;
		return loki.daos["account.invoice"].getStatsInvoices(filter) ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
	} ;
}

module.exports = new StatsInvoicesController();
