var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;
var FieldsEMA = require("./fields/FieldsEMA") ;
var BUS = require("aetna-events").EventSource.BUS;
var execWithOrWithoutCb = require("aetna-func/func").execWithOrWithoutCb ;
var storage = require("aetna-storage").storage ;
var context = require("aetna-views/context").context;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");

function BaseCrudGridView(serverUrl, options){
    
	var _self = this ;
    
    this.options = {
        module : "module",
    	model : "model", 
    	colsToShow : [],
    	sortData : [ { field: 'create_date', direction: 'desc' } ],
    	gridOptions : null,
    	summary : null,
    	filters : null
    	/*summary : {
    		colsToSum : ["col1", "col2"],
    		colsToCount : ["col3"]
    	},
    	filters : [
    		{
    			code : "...",
    			label : "...",
    			icon : "fa-icon",
    			color : "text-primary"
    		}
    	]
    	*/
    } ;	
    
    Object.keys(this.options).forEach(function(k){
    	if(options[k] !== undefined){
    		_self.options[k] = options[k] ;
    	}
    }) ;
    
    this.staticHTML = 
    '<div class="top-labels">'+
        '<h1>'+
        	'<span data-i18n="'+this.options.module+'.leftTitle" class="header-left-label pull-left" id="headerLeftLabel"></span>'+
        	'<span data-i18n="'+this.options.module+'.list"></span>'+
        '</h1>'+
    '</div>'+
    '<div class="container-fluid">'+
    	'<div class="row" id="rowControls" style="display: none">'+
			'<div class="col-lg-10">'+
				'<h4 data-i18n="'+this.options.module+'.filters" style="display: inline-block"></h4>'+
				'<a data-i18n="'+this.options.module+'.viewDetails" class="'+this.options.module+'_grid__linkDetails" id="detailsFilters"/>'+
				'<div class="list-group  list-group-horizontal" id="filters">'+
					'{{#filters}}'+
					'<a href="#" class="list-group-item" id="filter" title="{{label}}" '+
					'data-toggle="tooltip" data-placement="bottom"  data-filter="{{code}}">'+
						'<span class="badge big-badge" id="count"> - </span>'+
						'<p class="list-group-item-text '+this.options.module+'_grid__filterItem">'+
						'<i class="fa fa-fw {{icon}} fa-2x {{color}}"></i> '+
						'<span class="'+this.options.module+'_grid__filterLabel">{{label}}</span></p>'+
					'</a>'+
					'{{/filters}}'+
				'</div>'+
			'</div>'+
		'</div>'+
    	'<div class="row '+this.options.module+'_'+this.options.module+'Grid__row" id="gridRow">'+
    	   '<div class="col-lg-12 '+this.options.module+'_'+this.options.module+'Grid__cell">'+
    	        '<div class="'+this.options.module+'_'+this.options.module+'Grid__grid" '+
    	        'data-field="'+this.options.model+'~~grid" id="grid"></div>'+
    	    '</div>'+
    	'</div>'+
    '</div>' ;
    
    this.staticCSS = 
    '#'+this.options.module+'_'+this.options.module+'Grid__container .container-fluid,'+
    '#'+this.options.module+'_'+this.options.module+'Grid__container,'+
    '.'+this.options.module+'_'+this.options.module+'Grid__row,'+
    '.'+this.options.module+'_'+this.options.module+'Grid__cell,'+
    '.'+this.options.module+'_'+this.options.module+'Grid__grid,'+
    '.'+this.options.module+'_'+this.options.module+'Grid__grid .fields_grid__grid {'+
        'height : 100%;'+
    '}'+
    '.header-left-label { margin-left: 100px; }'+
    '.big-badge {font-size: 1.1em; margin-top: 4px;}'+
    '.'+this.options.module+'_grid__filterItem { min-width: 80px; line-height: 25px;}'+
    '.'+this.options.module+'_grid__linkDetails { font-size: 0.7em; margin-left: 5px; background-color: #EEE; padding: 5px; border-radius: 5px; cursor : pointer;}'+
    '.list-group-horizontal .'+this.options.module+'_grid__filterLabel { display: none; }'+
    '.list-group-horizontal .list-group-item {display: inline-block;}'+
    '.list-group-horizontal .list-group-item {margin-bottom: 0; margin-left:-4px; margin-right: 0;}'+
    '.list-group-horizontal .list-group-item:first-child { border-top-right-radius:0; border-bottom-left-radius:4px;}'+
    '.list-group-horizontal .list-group-item:last-child {border-top-right-radius:4px; border-bottom-left-radius:0;}';
    
    
    BaseView.call(this, t, this.options.module, this.options.module+"Grid") ;
    
   	this.fields = new FieldsEMA(t, serverUrl)  ;
   	
   
	this._initElements = function(callback){
		document.getElementById("moduleTitle").innerHTML = t(_self.options.module+'.list') ;
		
		if(_self.EL.headerLeftLabel.innerHTML === _self.options.module+'.leftTitle'){
			_self.EL.headerLeftLabel.style.display = "none" ;
		}
		
		_self.fields.fields[_self.options.model+"~~grid"].colsToShow = _self.options.colsToShow ;
		
		
		var toolbar ={
            items: [
            	{ type: 'break' , id:'breakCreate'},
            	{ type: 'html',  id: 'create', html : '<button class="btn btn-sm btn-primary btn-create" data-action="create"><i class="fa fa-plus-square"></i> '+t(_self.module+".createNew")+'</button>'},
            	{ type: 'spacer' },
                { type: 'button',  id: 'xls', icon: 'fa fa-file-excel-o' },
                { type: 'html',  id: 'count', html : '<span class="badge grid-count">42</span>'},
            ],
            onClick: function (event) {
                if (event.target == 'xls') {
                	_self.exportXls() ;
                } else if (event.target == "w2ui-reload") {
                	_self.onReload(event) ;
                }
            }
        } ;
		
		_self.gridName = _self.gridName || _self.options.model.replace(/\./g, "_")+"__grid_list" ;
		_self.fields.fields[_self.options.model+"~~grid"].options = {
			name : _self.gridName,
			show: {
	            header         : false,
	            toolbar     : true,
	            footer        : false,
	            lineNumbers    : false
	        },
		    sortData: _self.options.sortData,
		    toolbar : toolbar
		} ;
		
		if(_self.options.gridOptions){
			$.extend( true, 
				_self.fields.fields[_self.options.model+"~~grid"].options, 
				_self.options.gridOptions );
		}

		if(_self.options.filters){
			_self.templateFilters = new TemplateWidget(_self.EL.filters);
			_self.templateFilters.on("render", _self.renderFilters);
			_self.templateFilters.render({filters : _self.options.filters}) ;
			_self.$EL.filters.find('[data-toggle="tooltip"]').tooltip() ;
			_self.EL.detailsFilters.addEventListener("click", _self.toggleDetailsFilter);
			_self.EL.rowControls.style.display = "block" ;
		}

		_self.fields.init(_self, function(){
			
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.toolbar.on("refresh", _self.onToolbarRefresh) ;

			_self.fields.fields[_self.options.model+"~~grid"].addEventListener("dblClick", _self.onDblClick) ;

            callback() ;
        }) ;
	};
	
	
	_self.onToolbarRefresh = function(ev){
		ev.onComplete = function(){
			var $box = $(this.box);
			if(ev.target === "count"){
				var total = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.total ;
				$box.find(".grid-count").html(total);
			}else if(ev.target === "create"){
				var $btn = $box.find(".btn[data-action=create]");
				
				$btn.off() ;
				$btn.on("click", _self.onCreate) ;
				
			}
		} ;
	} ;
	
	this.onDblClick = function(ev){
     	var record = _self.fields.fields[_self.options.model+"~~grid"].grid.get(ev.recid) ;
		_self.emit("openRecord", record) ;
	} ;
	
	this.onCreate = function(ev){
		_self.emit("createNew") ;
	} ;
	
	
	this.load = function(records){
		var summaryRecord = null;
		
		if(_self.options.summary){
			summaryRecord = {
				summary: true, 
				recid : "S-1"
			} ;
			if(_self.options.summary.colsToSum){
				_self.options.summary.colsToSum.forEach(function(c){
					summaryRecord[c] = 0 ;
				}) ;
			}
			if(_self.options.summary.colsToCount){
				_self.options.summary.colsToCount.forEach(function(c){
					summaryRecord[c] = 0 ;
				}) ;
			}
		}
		records.forEach(function(d){
			d.recid = d.id ;
			
			if(_self.options.summary){
				if(_self.options.summary.colsToSum){
					_self.options.summary.colsToSum.forEach(function(c){
						if(d[c]){
							summaryRecord[c] += d[c] ;
						}
					}) ;
				}
				if(_self.options.summary.colsToCount){
					_self.options.summary.colsToCount.forEach(function(c){
						summaryRecord[c] += 1 ;
					}) ;
				}
			}
		}) ;
		
		var lines = records;
		if(summaryRecord){
			lines = lines.concat(summaryRecord) ;
		}
		
		
		_self.fields.fields[_self.options.model+"~~grid"].load(lines) ;
	} ;
	
	this.refresh = function(){
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.refresh()  ;
	} ;
	
	
	this.exportXls = function(){
        var recordsToPrint = [] ;
      
      	//_self.fields.fields[_self.options.model+"~~grid"].grid.grid.
      
      	var displayedRecords = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.records ;
      	
      	if(_self.fields.fields[_self.options.model+"~~grid"].grid.grid.last.search || 
      				_self.fields.fields[_self.options.model+"~~grid"].grid.grid.last.multi){
      		displayedRecords = [] ;
      		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.last.searchIds.forEach(function(i){
      			displayedRecords.push(_self.fields.fields[_self.options.model+"~~grid"].grid.grid.records[i]) ;
      		}) ;
      	}
      
        displayedRecords.concat(
        	_self.fields.fields[_self.options.model+"~~grid"].grid.grid.summary).forEach(function(record, lineNumber){
        		
            var recid = record.recid ;
            var line = {} ;
            var rIndex = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.get(recid, true) ;
            
            
            _self.fields.fields[_self.options.model+"~~grid"].columns.forEach(function(c, i){
            	
                var value = $(_self.fields.fields[_self.options.model+"~~grid"].grid.grid.getCellHTML(rIndex, i, record.summary)).text() ;

                line[c.field] = value;
            }) ;
            recordsToPrint.push(line) ;
        }) ;

        _self.emit("exportXls", {
            columns : _self.fields.fields[_self.options.model+"~~grid"].columns,
            lines : recordsToPrint
        }) ;
    } ;
    
    this.onReload = function(ev){
		_self.emit("reload", {forceRefresh : ev.originalEvent.ctrlKey}) ;
	} ;
	
	
	this.renderFilters = function(){
		_self.templateFilters.$EL_LISTS.filter.forEach(function($a, i){
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey && ev.button === 0){
					_self.$EL.filters.find("a").each(function(y, link){
						if(link !== $a[0]){
							$(link).removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
	} ;

	this.getFilter = function(){
		var filter = {} ;
		
		_self.$EL.filters.find("a").each(function(y, link){
			var $a = $(link) ;	
			if($a.hasClass("active")){
				filter[link.getAttribute("data-filter")] = true ;
			}
		});
		
		return filter ;
	} ;
	
	this.toggleDetailsFilter = function(){
		_self.$EL.filters.toggleClass("list-group-horizontal") ;
		if(_self.$EL.filters.hasClass("list-group-horizontal")){
			_self.EL.detailsFilters.innerHTML = t(_self.options.module+".viewDetails") ;
		}else{
			_self.EL.detailsFilters.innerHTML = t(_self.options.module+".reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	this.resizeTable = function(){
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		_self.EL.gridRow.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields[_self.options.model+"~~grid"].render() ;
	} ;
	
	
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		_self.$EL.filters.find("a").each(function(y, link){
			var thisFilterCode = link.getAttribute("data-filter") ;
			
			var $span = $(link).find(".badge") ;
			var count = counts[thisFilterCode] || 0 ;
			if($span.html() != count){// != because compare string and integer
				$span.html(count) ;
				$span.addClass("animated") ;
				$span.addClass("flash") ;
				
				$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$span.removeClass("animated") ;
					$span.removeClass("flash") ;
				});	
			}
		});
	} ;
	
}

function BaseCrudFormView(serverUrl, options){
	
	var _self = this ;
	
	this.options = {
        module : "module",
    	model : "model",
    	firstField : null,
    	newRecord : {}
    } ;	
    
    Object.keys(this.options).forEach(function(k){
    	if(options[k] !== undefined){
    		_self.options[k] = options[k] ;
    	}
    }) ;
    
    BaseView.call(this, t, this.options.module, this.options.module+"Form") ;

   	this.fields = new FieldsEMA(t, serverUrl) ;
   	
   	this.headerHTML = 
   	'<div class="top-labels">'+
    '	<h1 data-i18n="'+this.options.module+'.module"></h1>'+
    '	<h2>'+
    '		<div id="btnRead">'+
    '			<button class="btn btn-primary btn-sm pull-left" id="returnToList" data-i18n="[title]'+this.options.module+'.returnToList">'+
    '				<i class="fa fa-arrow-left"></i>'+
    '			</button>'+
    '			<button class="btn btn-primary btn-sm pull-left" id="modify"><i class="fa fa-pencil"></i> <span data-i18n="'+this.options.module+'.modify"></span></button>		'+
    '			<button class="btn btn-danger btn-sm pull-left" id="delete"><i class="fa fa-trash"></i> <span data-i18n="'+this.options.module+'.delete"></span></button>		'+
    '		</div>'+
    '		<div id="btnValidation">'+
    '			<button class="btn btn-primary btn-sm pull-left" id="validate"><i class="fa fa-floppy-o"></i> <span id="labelSave" data-i18n="'+this.options.module+'.saveModifications"></button>		'+
    '			<button class="btn btn-default btn-sm pull-left" id="cancel"><i class="fa fa-ban"></i> <span id="labelCancel" data-i18n="'+this.options.module+'.cancelModifications"></button>		'+
    '		</div>__CUSTOM_BUTTONS__'+
    '		<span data-i18n="'+this.options.module+'.form"></span></h2>'+
    '</div>';
    
    this.customHeaderButtons = "" ;
    
    
    var _realGetHTML = this.getHTML ;
    
    this.getHTML = function(callback){
        _realGetHTML(function(html){
        	var header = _self.headerHTML;
        	header = header.replace("__CUSTOM_BUTTONS__", _self.customHeaderButtons) ;
            var htmlWithHeader = html.replace(/<div[\s]+id="header"[\s]*>[\s]*<\/div>/, header) ;
            callback(htmlWithHeader) ;
        }) ;
    } ;
	   
	this._initElements = function(callback){
		document.getElementById("moduleTitle").innerHTML = t(_self.options.module+'.module') ;
		
		_self.EL.returnToList.emit("returnToList");
		_self.EL.modify.addEventListener("click", _self.onModify);
		_self.EL.validate.addEventListener("click", _self.onValidate);
		_self.EL.cancel.addEventListener("click", _self.onCancel);
		_self.EL.delete.addEventListener("click", _self.onDelete);
		
	
		_self.fields.init(_self, function(){
			_self.fields.usedFields.forEach(function(f){
				if(f.container.getAttribute("readonly")){
					f.alwaysReadOnly = true ;
				}
			}) ;
            callback() ;
        }) ;
	};
	
	this.load = function(item){
		_self.isCreate = false ;
		_self.isModif = false ;
		_self.item = item ;
		_self[_self.options.module] = item ;
		_self.fields.load(item) ;
		_self.setReadOnly(true) ;
		_self.fields.clearError() ;
		_self.displayButtons() ;
		_self.emit("loaded") ;
	} ;
	
	this.onShow = function(){
		_self.displayButtons() ;
	} ;
	
	this.displayButtons = function(){
		var $container = $(_self.container) ;
		if(_self.isCreate){
			$container.addClass("isCreate") ;
			$container.removeClass("isModif") ;
			$container.removeClass("isView") ;
		}else if(_self.isModif){
			$container.removeClass("isCreate") ;
			$container.addClass("isModif") ;
			$container.removeClass("isView") ;
		}else {
			$container.removeClass("isCreate") ;
			$container.removeClass("isModif") ;
			$container.addClass("isView") ;
		}
		
		if(_self.isCreate || _self.isModif){
			_self.EL.btnValidation.style.display = "block" ;
			_self.EL.btnRead.style.display = "none" ;
			if(_self.isCreate){
				_self.EL.labelSave.innerHTML = t(_self.options.module+".saveCreate") ;
				_self.EL.labelCancel.innerHTML = t(_self.options.module+".cancelCreate") ;
			}else{
				_self.EL.labelSave.innerHTML = t(_self.options.module+".saveModification") ;
				_self.EL.labelCancel.innerHTML = t(_self.options.module+".cancelModification") ;
			}
		}else{
			_self.EL.btnValidation.style.display = "none" ;
			_self.EL.btnRead.style.display = "block" ;
		}
		
		
		var userFull = storage(context().name).get("user-full") ;
		$container.find("[data-rights]").each(function(i, el){
			var rights = el.getAttribute("data-rights") ;
			
			var hasRight = rights.split("&").every(function(r){
				return r.trim().split("|").some(function(ri){
					return userFull[ri.trim()] ;
				}) ;
			}) ;
			
			if(!hasRight){
				el.style.display = "none" ;
			}
		}) ;
		
		_self.emit("changeEditionMode") ;
	} ;
	
	this.focusToFirstField = function(){
		setTimeout(function(){
			if(_self.options.firstField){
	    		_self.fields.fields[_self.options.firstField].focus() ;
			}else{
				var dataFields = $(_self.container).find("div[data-field]").filter(function(i, div){
					return !div.getAttribute("readonly") ;
				}) ;
				if(dataFields.length>0){
					_self.fields.fields[dataFields[0].getAttribute("data-field")].focus() ;
				}
			}
		},1) ;
	} ;
	
	this.createNew = function(newRecord){
		var record = {} ;
		if(newRecord){
			Object.keys(newRecord).forEach(function(k){
				record[k] = newRecord[k] ;
			}) ;
		}
		if(_self.options.newRecord){
			Object.keys(_self.options.newRecord).forEach(function(k){
				record[k] = _self.options.newRecord[k] ;
			}) ;
		}
		_self.load(record) ;
		_self.isCreate = true ;
		_self.setReadOnly(false) ;
		_self.fields.usedFields.forEach(function(f){
			if(f.alwaysReadOnly){
				f.setReadOnly(true) ;
			}
		}) ;
		_self.displayButtons() ;    
		_self.focusToFirstField() ;
	} ;
	
	this.onModify = function(){
		_self.setReadOnly(false) ;
		_self.fields.usedFields.forEach(function(f){
			if(f.alwaysReadOnly){
				f.setReadOnly(true) ;
			}
		}) ;
		_self.isModif = true ;
		_self.displayButtons() ;
		_self.focusToFirstField() ;
		_self.emit("modify") ;
	} ;
	
	this.onCancel = function(){
		if(_self.isCreate){
			_self.emit("returnToList") ;
		}else{
			_self.isModif = false ;
			_self.load(_self.item) ;
			_self.setReadOnly(true) ;
			_self.displayButtons() ;
		}
	} ;
	
	this.setReadOnly= function(readOnly){
		_self.fields.setReadOnly(readOnly) ;
	} ;
	
	this.checkInput = function(){
		var fieldInError = false ;
		_self.fields.usedFields.forEach(function(f){
			f.clearError();
			if(!f.isReadOnly() && f.isRequired() && f.isEmpty()){
				f.error() ;	
				fieldInError = true ;
			}
			if(!f.isEmpty() && !f.isValid()){
				f.error(t("invalidFormat")) ;
				fieldInError = true ;
			}
		}) ;
		
		
		if(fieldInError){
			return t(_self.options.module+".thereIsErrorPleaseCorrect") ;
		}
		
	} ;
	
	this.onValidate = function(){
		var values = _self.getValues() ;
		
		execWithOrWithoutCb(_self.checkInput, function(errMessage){
			if(errMessage){ return _self.error(errMessage) }
			
			_self.emit("save", values) ;
		}) ;
	} ;
	
	this.getValues = function(){
		var allValues = _self.fields.values() ;
		_self.recordToSave = allValues[_self.options.model+""] ;
		if(!_self.recordToSave){
			_self.recordToSave = {};
		}
		
		_self.recordToSave.id = _self[_self.options.module].id ;
		if(_self.options.newRecord){
			Object.keys(_self.options.newRecord).forEach(function(k){
				if(!_self.recordToSave[k]){
					_self.recordToSave[k] = _self.options.newRecord[k] ;
				}
			}) ;
		}
		
		return {
			recordToSave : _self.recordToSave , 
			originalRecord: _self.item,
			allValues : allValues,
			isCreate : _self.isCreate,
			isModif : _self.isModif
		} ;
	} ;
	
	this.onDelete = function(){
	   _self.confirm(t(_self.options.module+".confirmDelete"), function(yes){
	       if(yes){
	           _self.emit("delete", {
    				recordToDelete : _self[_self.options.module]
    			}) ;
	       }
	   })  ;
	} ;
	
	
	
	
	
}

module.exports.Grid = BaseCrudGridView;
module.exports.Form = BaseCrudFormView;
