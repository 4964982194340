var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;

var model = "account.tax" ;
var moduleName = "tax" ;

function TaxGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"description",
			"name",
			"type_tax_use"
    	],
	}) ;
}

function TaxFormView(serverUrl, schemas){
	var _self = this ;

	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		active : true,
    		type : "percent",
    		base_sign : 1,
    		tax_sign : 1,
    		ref_base_sign : -1,
    		ref_tax_sign : -1
    	}
	}) ;
	
	var _initElements = this._initElements ;
	
	this._initElements=function(callback){
		var len = _self.accountSettings.AccountNumberLength || 6;
		_self.fields.fields["account.tax~accountNumber"].type = "mask:449{0,"+(len-2)+"}" ;
		
		_initElements(callback) ;
	} ;
	
}

module.exports.Grid = TaxGridView;
module.exports.Form = TaxFormView;
