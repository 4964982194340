var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

var model = "product.template" ;
var moduleName = "product" ;

function ProductGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"default_code",
			"name",
			"list_price",
			"active"
    	],
	}) ;
}

function ProductFormView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		active : true
    	}
	}) ;
	
	var _initElements = this._initElements ;
	
	this._initElements=function(callback){
		var len = _self.accountSettings.AccountNumberLength || 6;
		_self.fields.fields["product.template~accountIncomeNumber"].type = "mask:709{0,"+(len-2)+"}" ;
		_self.fields.fields["product.template~accountExpenseNumber"].type = "mask:609{0,"+(len-2)+"}" ;
		
		_initElements(callback) ;
	} ;
	
	var possibleTypes = ["service", "product"] ;
	_self.fields.fields["product.template~type"].filter = function(a){ 
		return possibleTypes.indexOf(a) !== -1; 
	} ;

}

module.exports.Grid = ProductGridView;
module.exports.Form = ProductFormView;
