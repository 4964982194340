var BaseView = require("aetna-views/BaseView.js") ;
var t = require("aetna-views/locales/tr").t ;

function ProgressView(){
    var _self = this;
    BaseView.call(this, t, "main", "progress") ;

    this._initElements = function(){
        _self.container.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.style.zIndex = 9999999;
    };

    this.setProgress = function(percent){
        _self.ensureInit(function(){
            _self.EL.progress.setAttribute("aria-valuenow", percent);
            _self.EL.progress.style.width = percent+"%";
            _self.EL.progressLabel.innerHTML = percent+"%";
        }) ;
    } ;
}

module.exports = ProgressView;