var OfflineCrudCli = require("../OfflineCrudCli") ;
var t = require("aetna-views/locales/tr").t ;

function OfflineAccountInvoiceAetnaRemind(){
	OfflineCrudCli.call(this, "account.invoice.aetna.remind");
	
	    
	this.createRemind = function(idInvoice, callback){
 	     return callback(t("main.offlineNotAvailable")) ;
    };
    
    this.createRemindMulti = function(idsInvoices, callback){
 	     return callback(t("main.offlineNotAvailable")) ;
    };
	
	this.getWebDavDoc = function(id, callback){
	     return callback(t("main.offlineNotAvailable")) ;
    };
}

module.exports = OfflineAccountInvoiceAetnaRemind ;