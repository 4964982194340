var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function SaleOrder(serverUrl){
	CrudCli.call(this, serverUrl, "sale.order");
	
    
	this.createPdf = function(id, callback){
         ajax(serverUrl + "/api/sale.order/createPdf", {ids : [id]}, callback);
    };
    
  
    this.send = function(ids, callback){
         ajax(serverUrl + "/api/sale.order/send", {ids : ids}, callback);
    };
    this.cancel = function(ids, callback){
         ajax(serverUrl + "/api/sale.order/cancel", {ids : ids}, callback);
    };
    this.canBeInvoice = function(ids, callback){
         ajax(serverUrl + "/api/sale.order/canBeInvoice", {ids : ids}, callback);
    };
    this.accept = function(ids, callback){
         ajax(serverUrl + "/api/sale.order/accept", {ids : ids}, callback);
    };
    this.createInvoice = function(ids, callback){
         ajax(serverUrl + "/api/sale.order/createInvoice", {ids : ids}, callback);
    };
    
}

module.exports = SaleOrder ;