var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

var model = "project.project" ;
var moduleName = "project" ;

function ProjectGridView(serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"AetnaCode",
			"name",
			"partner_ref",
			"partner_id",
			"state"
    	],
	}) ;
	
	this.fields.fields["project.project~~grid"].colsCustom = [{
		caption   : t("fields.res.partner.ref"),
		field     : "partner_ref",
		size      : "70px",
		searchable : true, 
		sortable : true,
		render	  : function(record){
			var customer = _self.allCustomers[record.partner_id] ;
		 	return customer?customer.ref:"" ;
		}
	}] ;
}

function ProjectFormView(serverUrl, schemas){
	var _self = this ;
	var user = storage(context().name).get("user") ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord : {
    		state : "open",
    		date_start : new Date(),
    		user_id : user.id,
    		members : [user.id],
			AetnaPublicStatus : "public"
    	},
    	firstField : "project.project~AetnaCode"
	}) ;
	
	var _getValues = this.getValues;
	this.getValues = function(){
		var values = _getValues() ;
		
		if(!_self.recordToSave.user_id){
			_self.recordToSave.user_id = user.id ;
		}
		
		if(!_self.recordToSave.members){
			_self.recordToSave.members = [] ;
		}
		
		return values ;
	} ;
	
}

module.exports.Grid = ProjectGridView;
module.exports.Form = ProjectFormView;
