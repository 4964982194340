var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateFields = require("aetna-views/widgets/TemplateFields");


var model = "project.project" ;
var moduleName = "workflow" ;

function WorkflowGridView(serverUrl, schemas){
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	colsToShow : [
			"name"
    	],
	}) ;
}

function WorkflowFormView(serverUrl, schemas){
	var _self = this ;
	
	_self.stagesByName = storage(context().name).get("stagesByName") ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model,
    	newRecord: {
    		workflow: {AetnaIsWorkflow : true, AetnaStartStage_id : _self.stagesByName["DRAFT"].id},
    		steps : [{}]
    	}
	}) ;
	
	
	this.on("initDone", function(){
		_self.templateSteps = new TemplateFields();
		
		_self.templateSteps.init(_self.EL.steps, _self.fields) ;
	}) ;
	
	
	var normalLoad = this.load ;
	this.load = function(item){
		normalLoad(item.workflow) ;
		
		_self.templateSteps.load(item) ;
		_self.item = item ;
	} ;
	
	var _getValues = this.getValues ;
	this.getValues = function(){
		var values = _getValues() ;
		var steps=_self.templateSteps.val().steps ;
		
		values.recordToSave.AetnaIsWorkflow = true ;
		values.recordToSave.AetnaCycles_ids = steps.splice(0, steps.length-1) ;
		
		return values ;
	} ;
	
	var _setReadOnly = this.setReadOnly ;
	this.setReadOnly= function(readOnly){
		_setReadOnly(readOnly) ;
		_self.templateSteps.setReadOnly(readOnly) ;
	} ;
	
	var _onModify = this.onModify ;
	this.onModify = function(){
		_onModify() ;
		
		_self.templateSteps.load({steps : _self.item.steps.concat({})}) ;
	} ;
	
	
}

module.exports.Grid = WorkflowGridView;
module.exports.Form = WorkflowFormView;
