var LokiDao = require("../LokiDao") ;

var LokiProjectTask = function(loki){
    LokiDao.call(this, loki, "project.task") ;
    
    
    var _getMyGroups = function(userId){
		var allGroups = loki.daos["hr.aetna.group"].listAll() ;
		var uid = userId ;
		var myGroups = allGroups.filter(function(group){
		 	return group.member_ids.indexOf(uid) !== -1 ;
		}) ;
		return myGroups.map(function(g){ return g.id ;}) ;
	} ;
	
	var _filterRestrict = function(task, myGroups){
		if(!task.AetnaRestrictGroup_ids || task.AetnaRestrictGroup_ids.length === 0){
			return true ;
		}
		return task.AetnaRestrictGroup_ids.some(function(gid){
			return myGroups.indexOf(gid) !== -1 ;
		}) ;
	} ;
    
    /**
     * Return the list of task filtered on stage id
     * 
     * @param userId the id of user
     * @param creatorStages the id of stage to display for task created by user
     * @param affectedStages the id of stage to display for task affected to user
     */
    this.userTaskFilters = function(currentUserId, chosenUserId, userStages, followedDocs, createdBy, affectOther, projects){
    	if(projects.length === 0){
    		return [] ;
    	}

		var searches = [] ;

    	//first par of search the docs affected to selected user by status
    	var searchAffectedEls = [] ;
    	
    	if(chosenUserId && chosenUserId !== "ALL"){
    		if(
    			(!followedDocs && !createdBy && !affectOther) || 
    			(userStages.length > 0)){
    		//an user is selected, take docs affected to this user
    		//filter only if a status is selected or no special filter is used
        	searchAffectedEls.push({user_id : chosenUserId}) ;
    			
    		}
        }
        
        if(userStages.length > 0){
        	//some status are selected
			searchAffectedEls.push({ stage_id : { $in : userStages}}) ;
		}
    	
    	//first searched of status filter on selected user
    	var searchAffected = null ;
    	if(searchAffectedEls.length === 1){
    		searchAffected = searchAffectedEls[0] ;
    	}else if(searchAffectedEls.length > 1){
    		searchAffected = {$and : searchAffectedEls } ;
    	}
    	
    	if(searchAffected){
    		searches.push(searchAffected) ;
    	}
    	
    	var functionAffected = function(task){
    		if(userStages.length > 0 && userStages.indexOf(task.stage_id) === -1){
    			return false ;
    		}
			if(chosenUserId && chosenUserId !== "ALL" && task.user_id !== chosenUserId){
				return false ;
	        } 
	        if(
    			(!followedDocs && !createdBy && !affectOther) || 
    			(userStages.length > 0)){
	        	return true ;
			}
    	} ;
    	
    	
    	if(followedDocs){
    		if(chosenUserId && chosenUserId !== "ALL"){
	    		//filter on followed docs of chosen user
		    	var user = loki.daos["res.users"].searchFirst({id: chosenUserId}) ;
				searches.push({AetnaFollowers_ids : {"$contains" : user.partner_id}}) ;
			}else{
				//no user selected, we must take all and filter in where function later
				searches.push({id : {$ne : 0}}) ;
			}
		}
		
		var functionFollowed = function(task){
    		if(followedDocs && (!task.AetnaFollowers_ids || task.AetnaFollowers_ids.length === 0)){
    			return false ;
    		}
    		if(followedDocs){
	    		return true ;
    		}
    	} ;
		
		if(createdBy){
			if(chosenUserId && chosenUserId !== "ALL"){
				searches.push({
					$and : [
						{create_uid : chosenUserId},
						{ stage_name : {$ne : "DELETED"}}
					]
				}) ;
			}else{
				//no user selected, we must take all and filter in where function later
				searches.push({ stage_name : {$ne : "DELETED"}}) ;
			}
		}
		var functionCreatedBy = function(task){
    		if(createdBy){
    			return true ;
    		}
    	} ;
		
		if(affectOther){
			
			if(chosenUserId && chosenUserId !== "ALL"){
	    		//filter on docs created by the selected user and affected to other user
				searches.push({ $and: [
					{create_uid : chosenUserId},
					{user_id : {$ne : chosenUserId }}
					]
				}) ;
			}else{
				//no user selected, we must take all and filter in where function later
				searches.push({
					$and : [
						{stage_name : {$ne : "DELETED"}},
						{stage_name : {$ne : "PUBLISHED"}}
						]
					}) ;
			}
		}
		var functionAffectOther = function(task){
    		if(affectOther && task.create_uid === task.user_id){
    			return false ;
    		}
    		if(affectOther){
    			return true ;
    		}
    	} ;
		
		var searchGlobal = { project_id : { $in : projects} };
		if(searches.length === 1){
			searchGlobal = {
				$and : [
					searchGlobal,
					searches[0]
				]
			} ;
		}else if(searches.length > 1){
			searchGlobal = {
				$and : [
					searchGlobal,
					{
						$or : searches
					}
				]
			} ;
		}
		
		return this.coll().chain().find(searchGlobal).where(function(task){
			if(!functionAffected(task) && !functionFollowed(task) && !functionCreatedBy(task) && !functionAffectOther(task)){
				//for the case when no user is selected 
				return false ;
			}
			return _filterRestrict(task, _getMyGroups(currentUserId)) ;
		}).data() ;
    } ;
    
    /**
     * map reduce on stage
     * 
     * for SQL familiar this is similar to :
     *      SELECT stage_id, count(1) FROM projet_task WHERE ... GROUP BY stage_id
     * 
     * (the WHERE part is given by the chain, this function is the mapReduce implementation of the GROUP BY)
     */
    var mapReduceCountByStage = function(chain){
        return chain.mapReduce(
			function(doc){ 
				return { stage_id : doc.stage_id } ;
			}, 
			function(docs){ 
				var byStage = {}; 
				docs.forEach(function(d){ 
				    if(!byStage[d.stage_id]){ 
				   		byStage[d.stage_id] = 0; 
				    }
					byStage[d.stage_id]++;
				}); 
				return byStage ;
		} ) ;
    } ;
    
    this.countAffectedByStage = function(userId, chosenUserId, projects){
    	if(projects.length === 0){
    		return 0 ;
    	}
    	
    	var searches = [
        		{project_id : { $in : projects}} 
    		] ;
    	if(chosenUserId && chosenUserId!=="ALL"){
    		var searchUser = {user_id :  chosenUserId } ;
    		searches.push(searchUser) ;
    	}
    	
        return mapReduceCountByStage(this.coll().chain().find(
        	{ $and : searches}).where(function(task){
			return _filterRestrict(task, _getMyGroups(userId)) ;
		})) ;
    } ;
    
    this.countFollowed = function(userId,chosenUserId, projects){
		if(projects.length === 0){
    		return 0 ;
    	}
    	var searches = [
    		{project_id : { $in : projects}},
		] ;
		if(chosenUserId && chosenUserId!=="ALL"){
			var user = loki.daos["res.users"].searchFirst({id: chosenUserId}) ;
    		searches.push(
    			{"AetnaFollowers_ids" : {"$contains" : user.partner_id}}
    		) ;
    	}
    	
        return this.coll().chain().find(
        	{ $and : searches }).where(function(task){
        		if(!task.AetnaFollowers_ids || task.AetnaFollowers_ids.length === 0){
        			//for the case when no user is selected
        			return false ;
        		}
				return _filterRestrict(task, _getMyGroups(userId)) ;
		}).data().length ;
    } ;
    
    this.countCreatedBy = function(userId,chosenUserId, projects){
		if(projects.length === 0){
    		return 0 ;
    	}
    	
    	var searches = [
    			{stage_name : { $ne : "DELETED"}},
        		{project_id : { $in : projects}},
    		] ;
    		
    	if(chosenUserId && chosenUserId!=="ALL"){
    		searches.push(
    			{create_uid : chosenUserId}
    		) ;
    	}
    	
        return this.coll().chain().find(
        	{ $and : searches }).where(function(task){
			return _filterRestrict(task, _getMyGroups(userId)) ;
		}).data().length ;
    } ;
    
    this.countAffectOther = function(userId,chosenUserId, projects){
		if(projects.length === 0){
    		return 0 ;
    	}
    	
    	var searches = [
    		{"stage_name" : { $ne : "DELETED"}},
    		{"stage_name" : { $ne : "PUBLISHED"}},
    		{project_id : { $in : projects}},
		] ;
    		
    	if(chosenUserId && chosenUserId!=="ALL"){
    		searches.push(
    			{create_uid : chosenUserId},
    			{user_id :  { $ne : chosenUserId}}
    		) ;
    	}
    	
        return this.coll().chain().find(
        	{ $and : searches }).where(function(task){
        		if(task.create_uid === task.user_id){
        			//for the case when selected "all users", we ust check each row
        			return false ;
        		}
				return _filterRestrict(task, _getMyGroups(userId)) ;
		}).data().length ;
    } ;
    
    this.listInProgress = function(login){
    	var allStatus = loki.daos["project.task.type"].listAll() ;
		var user = loki.daos["res.users"].searchFirst({login: login}) ;

		
		var statusIds = [] ;
		
		allStatus.forEach(function(s){
			if(s.name !== "PUBLISHED" && s.name !== "DELETED"){
				statusIds.push(s.id) ;
			}
		});
		
        var tasks = this.coll().chain().find({
			$or : [
	        	{"AetnaFollowers_ids" : {"$contains" : user.partner_id}},
				{
					$and : [
						{stage_id : {"$in" : statusIds}},
						{user_id : user.id}
						]
				}
			]
		}).where(function(task){
			return _filterRestrict(task, _getMyGroups(user.id)) ;
		}).data() ;

		var taskIds = tasks.map(function(task){ return task.id}) ;
		
		var tasksWithDoc = [] ;
		
		var docs = loki.daos["ir.attachment"].search({res_model : "project.task", res_id : taskIds});
		docs.forEach(function(doc){
			tasks.some(function(task){
				if(task.id === doc.res_id){
					tasksWithDoc.push({task : task, doc : doc}) ;
					return true ;
				}
			}) ;
		}) ;
		return tasksWithDoc ;
    } ;
    
    this.listSync = function(login){
    	var allStatus = loki.daos["project.task.type"].listAll() ;
		var user = loki.daos["res.users"].searchFirst({login: login}) ;

		
		var statusIds = [] ;
		
		allStatus.forEach(function(s){
			if(s.name !== "PUBLISHED" && s.name !== "DELETED"){
				statusIds.push(s.id) ;
			}
		});
		
        var tasks = this.coll().chain().find({
			$or : [
	        	{"AetnaFollowers_ids" : {"$contains" : user.partner_id}},
				{
					$and : [
						{stage_id : {"$in" : statusIds}},
						{user_id : user.id}
						]
				}
			]
		}).where(function(task){
			return _filterRestrict(task, _getMyGroups(user.id)) ;
		}).data() ;

		return tasks ;
    } ;
    
    this.getDoc = function(task){
     	return loki.daos["ir.attachment"].searchFirst({res_model : "project.task", res_id : task.id});
    } ;
    
    this.getActionCycle = function(taskId, action){
    	var task = loki.daos["project.task"].getByPk(taskId) ;
		
		var cycle = loki.daos["project.aetnaworkflowcycle"].searchFirst({
				AetnaStage_id : task.stage_id, 
				AetnaAction : action, 
				AetnaWorkflow_id : task.AetnaWorkflow_id
			}) ;
			
			
		if(!cycle){
			return null;
		}
				
		var notifs = loki.daos["project.aetnatasknotification"].search({AetnaTask_id : taskId});
					
		var notifOfThisNewStatus = null;
		
		notifs.some(function(n){
			if(n.AetnaStage_id === cycle.AetnaStageAfter_id){
				notifOfThisNewStatus = n;
				return true ;
			}
		}) ;
					
		var user_id = task.create_uid ;
		
		if(notifOfThisNewStatus){
			user_id = notifOfThisNewStatus.AetnaUser_id ;
		}
		
		return {
			cycle : cycle,
			user_id : user_id
		} ;
    } ;
} ;

module.exports = LokiProjectTask ;