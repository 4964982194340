var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget") ;
var Autocomplete = require("aetna-views/fields/autocomplete") ;
var Generic = require("aetna-views/fields/generic") ;
var LibsLoader = require("aetna-views/LibsLoader") ;
var libs = require("aetna-views/libs") ;
var BaseView = require("aetna-views/BaseView.js") ;
var async = require("async") ;

LibsLoader.ensureLoaded([
	libs.js.jquery,
	libs.js.bootstrap
], function(){
	LibsLoader.load([
		[
		    "Sortable/Sortable.min.js "
		]
	]) ;	
}) ;

var model = "project.config.settings" ;
var moduleName = "projectSettings" ;

function ProjectSettingsFormView(serverUrl, schemas){
	var _self = this ;
	
	BaseCrudViews.Form.call(this, serverUrl, {
		module : moduleName,
    	model : model
	}) ;
	
	_self.fields.fields["ir.cron~interval_type"].filter = function(item){
		return ["minutes", "hours", "work_days"].indexOf(item) === -1 ;
	} ;
	
	_self.fields.fields["ir.cron~interval_type"].label = t("projectSettings.sequenceResetPeriod") ;
	
	_self.once("initDone", function(){
		_self.refTemplate = new TemplateWidget(_self.EL.refEls) ;
		_self.refTemplate.on("render", _self.renderRef) ;
		
		_self.EL.resetSequence.emit() ;
	}) ;
	
	this.renderRef = function(){
		_self.refTemplate.EL.addRefEl.addEventListener("click", _self.addRefEl) ;

	} ;
	
	var _load = this.load ;
	this.load = function(item){
		_load(item) ;
		
		var ref = item["project.config.settings"].AetnaDocumentReference ;
		
		var refEls = [] ;
		var currentKeyword = {
			word : "",
			type : "static"
		} ;
		for(var i=0; i<ref.length; i++){
			var c = ref[i] ;
			if(c === '{'){
				//start keyword
				if(currentKeyword.word){
					refEls.push(currentKeyword) ;
				}
				currentKeyword = {
					word : "",
					type : "field"
				} ;
			}else if(c === '}'){
				//end keyword
				if(currentKeyword.word){
					refEls.push(currentKeyword) ;
				}
				currentKeyword = {
					word : "",
					type : "static"
				} ;
			}else{
				currentKeyword.word += c ;
			}
		}
		if(currentKeyword.word){
			refEls.push(currentKeyword) ;
		}
		
		_self.styles = ["primary","success","info","warning","danger"] ;
		refEls.forEach(function(el, i){
			if(el.word.indexOf("formatDate") === 0){
				el.format = el.word.substring(el.word.lastIndexOf(' "')+1) ;
				el.format = el.format.replace(/"/g, "") ;
				el.label = t("projectSettings.refEls."+el.word.substring(0, el.word.lastIndexOf(' "'))) ;
				el.label += " ["+t("projectSettings.refEls.formatDate."+el.format)+"]" ;
			}else{
				el.label = el.word ;
				if(el.type === "field"){
					el.label = t("projectSettings.refEls."+el.word) ;
				}
			}
			
			el.style = _self.styles[i%_self.styles.length] ;
		}) ;
		
		_self.refTemplate.render({refEls:refEls}) ;
		
		_self.sortableRef = Sortable.create(_self.EL.refEls, {
		  handle: '.projectSettings_projectSettings__handle',
		  filter: '.projectSettings_projectSettings__delete',
		  onFilter: function (evt) {
		    var el = _self.sortableRef.closest(evt.item); // get dragged item
		    el && el.parentNode.removeChild(el);
		  }
		});
		
		console.log("REF", refEls) ;
	} ;
	
	this.addRefEl = function(){
		var addRefPopup = new ProjectFormAddRefPopup() ;
		addRefPopup.openInPopup() ;
		addRefPopup.on("validate", function(ev){
			var el = {};
			if(ev.data.type === "static"){
				el.word = ev.data.text;
				el.type = "static" ;
				el.label = el.word ;
			}else if(ev.data.type.indexOf("formatDate") === 0){
				el.word = ev.data.type+' "'+ev.data.format+'"' ;
				el.type = "field" ;
				el.label = t("projectSettings.refEls."+ev.data.type) ;
				el.label += " ["+t("projectSettings.refEls.formatDate."+ev.data.format)+"]" ;
			}else{
				el.word = ev.data.type ;
				el.type = "field" ;
				el.label = t("projectSettings.refEls."+ev.data.type) ;
			}
			el.style = _self.styles[_self.EL.refEls.childElementCount%_self.styles.length] ;
			el.word = el.word.replace(/'/g, "") ;
			var $li = $('<li data-value=\''+el.word+'\' data-type="'+el.type+'" class="projectSettings_projectSettings__refItem">'+
						'<div class="btn-group" role="group">'+
						  '<button type="button" class="btn btn-'+el.style+' projectSettings_projectSettings__handle"><i class="fa fa-arrows"></i></button>'+
						  '<button type="button" class="btn btn-'+el.style+'">'+el.label+'</button>'+
						  '<button type="button" class="btn btn-'+el.style+' projectSettings_projectSettings__delete"><i class="fa fa-trash"></i></button>'+
					    '</div>'+
					'</li>') ;
			$li.insertBefore(_self.refTemplate.$EL.liAddRefEl) ;
			addRefPopup.closePopup() ;
		}) ;
	} ;

	var _displayButtons = this.displayButtons ;
	this.displayButtons = function(){
		_displayButtons() ;
		_self.EL.returnToList.style.display = "none" ;
		_self.EL.delete.style.display = "none" ;
		_self.fields.fields["ir.sequence~number_next_actual"].setReadOnly(true) ;
	} ;
	
	this.checkInput = function(){
		/*var values = _self.getValues() ;
		
		var ref = values.allValues["project.config.settings"].AetnaDocumentReference ;
		if(ref.indexOf("timestamp") === -1 || ref.indexOf("employee.otherid") === -1 ||
			ref.indexOf("AetnaRev") === -1 ){
			return t("projectSettings.shouldHaveEmployeeRevAndTimestamp") ;	
		}*/
	} ;
	
	var _getValues = this.getValues ;
	this.getValues = function(){
		var values = _getValues() ;
		
		var ref = "" ;
		_self.$EL.refEls.find("li").each(function(i, li){
			if(li.getAttribute("data-value")){
				if(li.getAttribute("data-type") ==="static"){
					ref += li.getAttribute("data-value") ;
				}else{
					ref += "{{"+li.getAttribute("data-value")+"}}" ;
				}
			}
		}) ;
		
		if(!values.allValues["project.config.settings"]){
			values.allValues["project.config.settings"] = {} ;
		}
		values.allValues["project.config.settings"].id = _self.item["project.config.settings"].id ;
		values.allValues["project.config.settings"].AetnaDocumentReference = ref ;
		
		
		values.allValues["ir.cron"].id = _self.item["ir.cron"].id ;
		return values ;
	} ;
	
}


function ProjectFormAddRefPopup(){
	BaseView.call(this, t, "projectSettings", "addRefPopup") ;
	
	var _self = this ;
	
	this._initElements = function(callback){
		
		// var fields = ["employee.otherid", "customer.ref", "project.AetnaCode", 
		// "formatDate create_date", "AetnaRev", "AetnaSeq", "static"] ;
		
		var fields = ["employee.otherid", "customer.ref", "project.AetnaCode", 
		"formatDate create_date", "AetnaRev", "AetnaSeq", "AetnaSeqProject", "formatDate timestamp", "static"] ;
		
		var items = {} ;
		
		fields.forEach(function(f){
			items[f] = t("projectSettings.refEls."+f) ;
		}) ;
		
		_self.refFieldType = new Autocomplete(t, "refFieldType", items, t("projectSettings.refFieldType")) ;
		_self.refFieldType.setRequired(true) ;
		
		
		
		var formats = ["YYMMDD","YYYYMMDD", "DD/MM/YY", "DD/MM/YYYY", "YYMM", "YYYYMM",  
		 "MM/YY", "MM/YYYY",  "YYYY", "DDDD"] ;
		
		var itemsFormats = {} ;
		
		formats.forEach(function(f){
			itemsFormats[f] = t("projectSettings.refEls.formatDate."+f) ;
		}) ;
		
		_self.refFieldFormat = new Autocomplete(t, "refFieldFormat", itemsFormats, t("projectSettings.refFieldFormat")) ;
		
		_self.refFieldFormat.sort = function(a, b){
			return formats.indexOf(a) - formats.indexOf(b) ;
		} ;
		_self.refFieldFormat.setRequired(true) ;
		
		
		var formatsTimestamp = ["YYMMDDHHmmss","YYYYMMDDHHmmss", "YYMMDD_HHmmss", "YYYYMMDD_HHmmss", "DDDDHHmmss", "DDDD_HHmmss"] ;
		
		var itemsFormatsTimestamp = {} ;
		
		formatsTimestamp.forEach(function(f){
			itemsFormatsTimestamp[f] = t("projectSettings.refEls.formatDate."+f) ;
		}) ;
		
		_self.refFieldFormatTimestamp = new Autocomplete(t, "refFieldFormatTimestamp", itemsFormatsTimestamp, t("projectSettings.refFieldFormat")) ;
		
		_self.refFieldFormatTimestamp.sort = function(a, b){
			return formatsTimestamp.indexOf(a) - formatsTimestamp.indexOf(b) ;
		} ;
		_self.refFieldFormatTimestamp.setRequired(true) ;
		
		_self.refFieldText = new Generic(t, "refFieldText", "text", t("projectSettings.refFieldText")) ;
		_self.refFieldText.setRequired(true) ;
		
		_self.EL.cancel.addEventListener("click", _self.onCancel) ;
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
		
		async.parallel([
		    async.apply(_self.refFieldType.init, _self.EL.refFieldType),
		    async.apply(_self.refFieldText.init, _self.EL.refFieldText),
		    async.apply(_self.refFieldFormat.init, _self.EL.refFieldFormat),
		    async.apply(_self.refFieldFormatTimestamp.init, _self.EL.refFieldFormatTimestamp)
		], function(){
			_self.refFieldType.addEventListener("change", _self.onTypeChange) ;
			callback() ;
		});
	};
	
	this.onTypeChange = function(){
		var type = _self.refFieldType.val() ;
		if(type === "static"){
			_self.EL.refFieldTextDiv.style.display = "block" ;
		}else{
			_self.EL.refFieldTextDiv.style.display = "none" ;
		}
		if(type === "formatDate timestamp"){
			_self.EL.refFieldFormatTimestampDiv.style.display = "block" ;
		}else{
			_self.EL.refFieldFormatTimestampDiv.style.display = "none" ;
			
			if(type.indexOf("formatDate") === 0){
				_self.EL.refFieldFormatDiv.style.display = "block" ;
			}else{
				_self.EL.refFieldFormatDiv.style.display = "none" ;
			}
		}
	} ;
	
	this.onCancel = function(){
		_self.closePopup() ;	
	} ;
	
	this.onValidate = function(){
		_self.refFieldType.clearError() ;
		_self.refFieldText.clearError() ;
		_self.refFieldFormat.clearError() ;
		
		var type = _self.refFieldType.val() ;
		if(!type){
			return _self.refFieldType.error() ;
		}
		if(type === "static"){
			if(_self.refFieldText.isEmpty()){
				return _self.refFieldText.error() ;	
			}
		}
		var formatValue = "" ;
		if(type === "formatDate timestamp"){
			if(_self.refFieldFormatTimestamp.isEmpty()){
				return _self.refFieldFormatTimestamp.error() ;	
			}
			formatValue = _self.refFieldFormatTimestamp.val() ;	
		}else if(type.indexOf("formatDate") === 0){
			if(_self.refFieldFormat.isEmpty()){
				return _self.refFieldFormat.error() ;	
			}
			formatValue = _self.refFieldFormat.val() ;	
		}
		
		_self.emit("validate", {
			type : type,
			text : _self.refFieldText.val(),
			format : formatValue
		}) ;
	} ;
}

module.exports.Form = ProjectSettingsFormView;
