var AccountingVATGrid = require("../views/accountingVAT/accountingVATGrid");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var cli = require("aetna-ajax/Cli").cli ;
var moment = require("moment") ;

function AccountingVATController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "account.invoice.aetna.tvaexport",
		socketChangeEvent : 'accountChanged',
		name : "accountingVAT",
		gridClass : AccountingVATGrid,
		formClass : null
	}) ;
	
	this.onGridLoad = function(){
		_self.viewList.allTaxes = {} ;
		loki.daos["account.tax"].listAll().forEach(function(tax){
			_self.viewList.allTaxes[tax.id] = tax ;	
		}) ;
		_self.viewList.on("changeFilter", _self.refreshList) ;
		_self.viewList.on("doExport", _self.doExport) ;
		_self.refreshCount() ;
	} ;
	
	this.refreshCount = function(){
		var filter = _self.viewList.getFilter() ;
		filter.from = moment(filter.from).startOf('day').toDate() ;
		filter.to = moment(filter.to).endOf('day').toDate() ;
		var counts = loki.daos["account.invoice.aetna.tvaexport"].countByState(filter) ;
		_self.viewList.loadCounts(counts) ;
	} ;
	
	this.listAll = function(){
		var filter = _self.viewList.getFilter() ;
		filter.from = moment(filter.from).startOf('day').toDate() ;
		filter.to = moment(filter.to).endOf('day').toDate() ;
		return loki.daos["account.invoice.aetna.tvaexport"].contractFilters(filter) ;
	} ;
	
	this.refreshList = function(){
		_self.viewList.load(_self.listAll()) ;	
		_self.refreshCount();
	} ;
	
	this.doExport = function(ev){
		var lines = ev.data.lines ;
		
		var ids = lines.map(function(l){ return l.id; }) ;
		
		_self.viewList.startWait() ;
		cli("account.invoice.aetna.tvaexport").doExport(ids, function(err){
			if(err){ return _self.viewList.endWaitError(err); }
		
			_self.doSync(function(){
				
				_self.refreshList() ;
				_self.viewList.endWait() ;
				
				cli().gridToXls(
		            ev.data.columnsToPrint,
		            ev.data.recordsToPrint,
		            null,
		            t("accountingVAT.exportVAT")+".xlsx"
		        ) ;
				
			}) ;
		}) ;
	} ;
	
}

module.exports = new AccountingVATController();
