var loki = require("../loki") ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var moment = require("moment") ;
var cli = require("aetna-ajax/Cli") ;
var BUS = require("aetna-events").EventSource.BUS;
var async = require("async") ;
var uuid = require("uuid") ;
var DistantFile = require("../DistantFile") ;
var execAlone = require("aetna-func/func").execAlone ;
var BaseGui = require("aetna-views/BaseGui") ;

var gui = new BaseGui() ;

function addJournalEntry(action, model, record){
    var journal = storage(context().name).get("offline-synchro") ;
    if(!journal){
        journal = {
            entries : [],
            lokiIdsMap : {},
            oldIdsMap : {}
        } ;
    }
    
    if(!record.write_date){
        record.write_date = new Date() ;
    }
    if(!record.write_uid){
        record.write_uid = storage(context().name).get("user").id ;
    }
    
    
    if(!record.create_date && action === "insert"){
        record.create_date = new Date() ;
    }
    if(!record.create_uid && action === "insert"){
        record.create_uid = storage(context().name).get("user").id ;
    }
    
    journal.entries.push({
        action : action,
        model : model,
        record : record,
        date : moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS")
    }) ;
    if(action === "insert"){
        journal.lokiIdsMap[model+"_"+record.$loki] = record.id ;
    }
    storage(context().name).set("offline-synchro", journal) ;
}

function mapRecord(item){
    var journal = storage(context().name).get("offline-synchro") ;
    var oldIdsMap = {};
    if(journal){
        oldIdsMap = journal.oldIdsMap ;
    }
     Object.keys(item).forEach(function(k){
        var val = item[k] ;
        if(oldIdsMap[val]){
            //this is an temporary id, set the new one instead
            item[k] = oldIdsMap[val] ;
        }else{
            if(/_[u]{0,1}id$/.test(k)){ //example : parent_id
                if(val && val !== "false" && typeof(val) ==="string" && val.length < 10){
                   item[k] = parseInt(val,10) ;
                }
            }
            if(val && val.constructor === Date){
                item[k] = moment.utc(val).format("YYYY-MM-DD HH:mm:ss") ;
            }else if(val && Array.isArray(val)){
                val.forEach(function(v){
                    if(v && typeof(v) === "object"){
                        mapRecord(v) ;
                    }
                }) ;
            }else if(val && typeof(val) === "object"){
                item[k] = mapRecord(val) ;
            }
        }
    }) ;
    return item ;
}

module.exports.insert = function(model, record){
    if(!record.id){
        record.id = uuid.v4() ;
    }
    loki.collections[model].insert(mapRecord(record)) ;
    addJournalEntry("insert", model, record) ;
    return record ;
} ;

module.exports.update = function(model, record){
    record = mapRecord(record) ;
    var existingRecord = loki.collections[model].findOne({id : record.id});
	if (existingRecord) {
	    Object.keys(record).forEach(function(k){
	        existingRecord[k] = record[k] ;
	    }) ;
	    loki.collections[model].update(existingRecord);
	} 
    addJournalEntry("update", model, record) ;
} ;

module.exports.delete = function(model, record){
    var existingRecord = loki.collections[model].findOne({id : record.id});
	if (existingRecord) {
	    loki.collections[model].remove(existingRecord);
	} 
    addJournalEntry("delete", model, record) ;
} ;

//synchro
var synchro = execAlone(function synchro(callback){
    if(cli.getContext() === "offline"){
        return callback();
    }
    
    console.log("Start synchro") ;
    gui.startWait() ;
    BUS.emit("synchroOfflineStart") ;
    
    var journal = storage(context().name).get("offline-synchro") ;
    if(!journal || journal.entries.length === 0){
        gui.endWait() ;
        BUS.emit("synchroOfflineFinished", {nosync : true}) ;
        return callback();
    }
    
    
    
	

    
    var calls = [function(cb){ cb(); }] ;
    var docsToUpload= [] ;
    journal.entries.forEach(function(entry, i){
        calls.push(function(cb){
            
            mapRecord(entry.record) ;
            
            if(entry.action === "update" && entry.record.AetnaRef !== undefined){
                delete entry.record.AetnaRef ; //don't send AetnaRef on update because we will erase the computed ref
            }
            
            cli.cli("synchro").syncRecord(entry, function(err, result){
                if(err){
                    console.log("ERROR SYNCHRO", err) ;
                    gui.endWait() ;
                    BUS.emit("synchroError", {error : err, entry: entry}) ;
                    return cb(err) ;
                }
                
                var newJournal = storage(context().name).get("offline-synchro") ;
                
                if(entry.model !== "ir.attachment" && journal.lokiIdsMap[entry.model+"_"+entry.record.$loki]){
                    //this record add a temporary id, update with server id
                    
                    if(entry.action === "insert"){
                        newJournal.oldIdsMap[journal.lokiIdsMap[entry.model+"_"+entry.record.$loki]] = result.insertedObject.id;
                    }
                    
                    var oldId = journal.lokiIdsMap[entry.model+"_"+entry.record.$loki] ;
                    var newId = newJournal.oldIdsMap[oldId] ;
                    docsToUpload.push({
                        model : entry.model,
                        oldId : oldId,
                        newId : newId
                    }) ;
                    
                    
                    if(entry.action === "insert"){
                        entry.record.id = result.insertedObject.id ;
                        if(result.insertedObject.AetnaRef){ //specific for project.task
                            entry.record.AetnaRef = result.insertedObject.AetnaRef ;
                            console.log("udate ref", result.insertedObject.AetnaRef);
                        }
                        var lokiRecord = loki.collections[entry.model].get(entry.record.$loki);
                        if(lokiRecord){
                            console.log("udate loki ref", entry.record);
                            lokiRecord.id = entry.record.id ;
                            lokiRecord.AetnaRef = entry.record.AetnaRef ;

                            loki.collections[entry.model].update(lokiRecord);
                        }else{
                            console.log("WARN ! loki not found", entry.record);
                        }
                    }

                    newJournal.entries[i].action="insertDone" ;
                }
                storage(context().name).set("offline-synchro", newJournal) ;
                
                BUS.emit("synchroOfflineProgress", {total : calls.length + docsToUpload.length, progress : ++totalDone}) ;
                cb() ;
            }) ;
        }) ;
    }) ;
    
    
    var totalDone = 0 ;
    BUS.emit("synchroOfflineProgress", {total : calls.length + docsToUpload.length, progress : 0}) ;
    
    async.series(calls, function(err){
        if(err){
            console.error("STOP SYNCHRO ON ERROR (db)", err) ;
            gui.endWait() ;
            BUS.emit("synchroError", {error : err}) ;
            return callback(err);
        }
        
        var callsUpload = [function(cbU){ cbU() ;}] ;
        docsToUpload.forEach(function(d){
            callsUpload.push(function(cbU){
                DistantFile.uploadOfflineDoc(d.model, d.oldId, d.newId, function(err){
                    if(err){
                        return cbU(err) ;
                    }
                    BUS.emit("synchroOfflineProgress", {total : calls.length + callsUpload.length, progress : ++totalDone}) ;
                    cbU() ;
                }) ;
            }) ;
            
        }) ;
        
        
        async.series(callsUpload, function(err){
            if(err){
                console.error("STOP SYNCHRO ON ERROR (uploads)", err) ;
                gui.endWait() ;
                BUS.emit("synchroError", {error : err}) ;
                return callback(err);
            }
            
            journal = storage(context().name).get("offline-synchro") ;
            journal.entries = [];
            journal.lokiIdsMap = {} ;
            journal.oldIdsMap = {} ;
            storage(context().name).set("offline-synchro", journal) ;

            gui.endWait() ;
            BUS.emit("synchroOfflineFinished") ;
            
            console.log("Finish synchro") ;
            callback() ;
        }) ;
        
    }) ;
});

BUS.on("online", function(){
    synchro(function(err){
        if(err){
            console.log("synchro failed", err) ;
        }
    }) ;
}) ;


setInterval(function(){
    if(cli.getContext() === "offline"){
        return ;
    }
    
    synchro(function(err){
        if(err){
            console.log("synchro failed", err) ;
        }
    }) ;
}, 5000) ;