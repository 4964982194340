var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function AccountInvoiceAetnaRemindSettings(serverUrl){
	CrudCli.call(this, serverUrl, "account.invoice.aetna.remind.settings");
	
	this.create = function(remindSettings, uploadFile, callback, callbackProgress){
        ajax(serverUrl + "/api/account.invoice.aetna.remind.settings/create", "POST",
		{
		    remindSettings : remindSettings, 
		    uploadFile: uploadFile
		}, callback, callbackProgress, true);
    };
    
    this.update = function(remindSettings, uploadFile, callback, callbackProgress){
        ajax(serverUrl + "/api/account.invoice.aetna.remind.settings/update", "POST",
		{
		    remindSettings : remindSettings, 
		    uploadFile: uploadFile
		}, callback, callbackProgress, true);
    };
    
	
	this.getWebDavDoc = function(id, callback){
        ajax(serverUrl + "/api/account.invoice.aetna.remind.settings/getWebDavDoc", {id : id},callback);
    };
}

module.exports = AccountInvoiceAetnaRemindSettings ;