var LokiDao = require("../LokiDao") ;
var moment = require("moment") ;

var LokiAccountInvoiceAetnaExportVAT = function(loki){
    LokiDao.call(this, loki, "account.invoice.aetna.tvaexport") ;
    
    var _self = this ;
    
    this.contractFilters = function(filter){
    	var searchDate = null;
    	
    	if(filter.to){
    		filter.to = moment(filter.to).endOf("day").toDate() ;
    	}
    	
    	if(filter.from && filter.to){
    		searchDate = {
    			$and : [
    				{date_ready : { $gte : moment(filter.from).format("YYYY-MM-DD HH:mm:ss") } },
    				{date_ready : { $lte : moment(filter.to).format("YYYY-MM-DD HH:mm:ss") } }
				]
    		} ;
    	}else if(filter.from){
    		searchDate = {date_ready : { $gte : moment(filter.from).format("YYYY-MM-DD HH:mm:ss") } } ;
    	}else if(filter.to){
    		searchDate = {date_ready : { $lte : moment(filter.to).format("YYYY-MM-DD HH:mm:ss") } } ;
    	}
    	
        var searchFilters = [] ;
        
		if(filter.todo){
			searchFilters.push({ state : "todo" }) ;
		}
        
		if(filter.done){
			searchFilters.push({ state : "done" }) ;
		}
		
		
		
		var search = {} ;
		if(searchFilters.length>1){
			search = {
				$or : searchFilters	
			};
		}else if(searchFilters.length>0){
			search = searchFilters[0] ;
		}
		if(searchDate){
			search = {
				$and : [
					search,
					searchDate
				]
			} ;
		}
		
		
		
		
		return this.coll().chain().find(search).data() ;
    } ;
    
    
    this.countByState = function(filter){
    	var searchDate = null;
    	
    	if(filter.to){
    		filter.to = moment(filter.to).endOf("day").toDate() ;
    	}
    	
    	if(filter.from && filter.to){
    		searchDate = {
    			$and : [
    				{date_ready : { $gte : moment(filter.from).format("YYYY-MM-DD HH:mm:ss") } },
    				{date_ready : { $lte : moment(filter.to).format("YYYY-MM-DD HH:mm:ss") } }
				]
    		} ;
    	}else if(filter.from){
    		searchDate = {date_ready : { $gte : moment(filter.from).format("YYYY-MM-DD HH:mm:ss") } } ;
    	}else if(filter.to){
    		searchDate = {date_ready : { $lte : moment(filter.to).format("YYYY-MM-DD HH:mm:ss") } } ;
    	}
    	
    	var search = {};
    	
    	if(searchDate){
    		search = searchDate ;
    	}
    	
    	
    	return this.coll().chain().find(search).mapReduce(
			function(contract){ 
				return { state : contract.state } ;
			}, 
			function(contracts){ 
				var byState = {}; 
				contracts.forEach(function(d){ 
				    if(!byState[d.state]){ 
				   		byState[d.state] = 0; 
				    }
					byState[d.state]++;
				}); 
				return byState ;
		} ) ;
    } ;
    
    
} ;

module.exports = LokiAccountInvoiceAetnaExportVAT ;