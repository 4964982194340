var ProjectSettingsViews = require("../views/projectSettings/projectSettingsViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var t = require("aetna-views/locales/tr").t ;
var async = require("async") ;
var moment = require("moment") ;

function ProjectSettingsController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "project.config.settings",
		modelsToSync : ["project.config.settings", "ir.cron", "ir.sequence"],
		socketChangeEvent : 'projectSettingsChanged',
		name : "projectSettings",
		gridClass : null,
		formClass : ProjectSettingsViews.Form,
		
	}) ;
	
	var _initForm = this.initForm ;
	this.initForm = function(callback){
		_initForm(function(){
			_self.viewForm.on("resetSequence", _self.resetSequence) ;
			callback() ;
		}) ;
	} ;
	
	this.openGrid = function(){
		//no grid
		_self.initForm(function(){
			_self.doSync(function(err){
				if(err){ return _self.viewForm.error(err); }

				var record = _self.listAll()[0] ;
				var cron = loki.daos["ir.cron"].searchFirst({
					model : "project.config.settings", 
					"function" : "sequence_reset"}) ;
				var sequence = loki.daos["ir.sequence"].searchFirst({
					code : "project.task.aetna.sequence"}) ;

				_self.loadRecord({
					"project.config.settings" : record,
					"ir.cron" : cron,
					"ir.sequence" : sequence
					}) ;
				
				_self.viewForm.show() ;
			}) ;	
		});
	} ;
    
    this.checkCanDelete = function(data, callback){
    	callback(false) ;
	} ;
	
	this.onSave = function(ev){
		_self.viewForm.startWait();
		
		var projectSettings = ev.data.allValues["project.config.settings"] ;
		var cron = ev.data.allValues["ir.cron"] ;
		
		cron.nextcall = moment().startOf("day").add(1, cron.interval_type).toDate() ;
		
		var calls = [function(cb){cb()}] ;
		
		calls.push(function(cb){
			cli("project.config.settings").save(projectSettings, cb) ;
		}) ;
		
		calls.push(function(cb){
			cli("ir.cron").save(cron, cb) ;
		}) ;
		
		async.parallel(calls, function(err){
			if(err){ return _self.viewForm.endWaitError(err) ; }
			_self.doSync(true, function(){
				_self.viewForm.endWait() ;
				_self.openGrid() ;
			}) ;
		}) ;
	} ;
	
	this.resetSequence = function(){
		_self.viewForm.startWait();
		var sequence = loki.daos["ir.sequence"].searchFirst({
					code : "project.task.aetna.sequence"}) ;
		cli("ir.sequence").save({id : sequence.id, number_next_actual:1}, function(err){
			if(err){ return _self.viewForm.endWaitError(err) ; }
			_self.doSync(true, function(){
				_self.viewForm.endWait() ;
				_self.openGrid() ;
			}) ;
		}) ;
	} ;
}

module.exports = new ProjectSettingsController();
