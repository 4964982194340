var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseCrudViews = require("../BaseCrudViews")  ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var PdfView = require("aetna-views/views/pdf/pdf") ;
var moment = require("moment") ;
var moduleName = "dms" ;

function DmsGridView(model,activities, wkFields, actFields, serverUrl, schemas){
	var _self = this ;
	BaseCrudViews.Grid.call(this, serverUrl, {
		module : moduleName,
    	model : model, 
    	gridOptions :  {
			multiSelect : false
		},
    	colsToShow : wkFields
    		.filter(function(f){ 
    			return !f.table_hide ;
    		})
    		.map(function(f){ 
    			return f.name ; 
	    	}).concat(["state"]),
	}) ;
	
	this.staticHTML = null;
	this.staticCSS = null;
	
	_self.editableFields = {} ;
	_self.currentFieldsDef = wkFields ;
	
	
	_self.fields.fields[model+"~~grid"].colsCustom = [] ;
	wkFields.forEach(function(field, indexField){
		if(field.subfields.length > 0){
			_self.fields.fields[model+"~~grid"].colsCustom.push({
				field : field.name,
				size : field.table_width||"10%",
				renderHTML : function(cell, record, fieldName, column){
					
					var currentFieldDef = _self.currentFieldsDef[indexField] ;
					
					var value = record[field.name] ;
					cell.innerHTML = "" ;
					
					if(!value || value.length === 0){ 
						return ; 
					}
					
					if(currentFieldDef.readonly || !currentFieldDef.table_edit ){
						var str = [] ;
						value.forEach(function(v){
							var strLine = [] ;
							field.subfields.forEach(function(f){
								strLine.push(v[f.name]) ;
							}) ;
							str.push(strLine.join(", ")) ;
						}) ;
						cell.innerHTML = str.join("<br />") ;
						return ; 
					}
					
					cell.className += " hasField" ;
											
					value.forEach(function(v, index){
						var $row = $('<div class="row"></div>') ;
						cell.appendChild($row[0]) ;
						currentFieldDef.subfields.forEach(function(f){
							
							var colLen = Math.floor(12/currentFieldDef.subfields.length) ;
							var $f = $('<div class="col-lg-'+colLen+' col-md-'+colLen+'"></div>') ;
							$f.appendTo($row) ;
							
							_self.createTableField($f[0], f, v, record.recid, index, column) ;
						}) ;
					}) ;
				}
			}) ;
		}else if(field.table_edit && !field.readonly){
			_self.fields.fields[model+"~~grid"].colsCustom.push({
				field : field.name.replace(/_id$/, "_name"),
				size : field.table_width||"10%",
				renderHTML : function(cell, record, fieldName, column){
					var currentFieldDef = _self.currentFieldsDef[indexField] ;
					
					if(currentFieldDef.readonly || !currentFieldDef.table_edit ){
						//do nothing
						return ;
					}
					
					cell.className += " hasField" ;
					_self.createTableField(cell, field, record, record.recid, 0, column) ;
				}
			}) ;
		}else if(field.table_width){
			_self.fields.fields[model+"~~grid"].colsCustom.push({
				field : field.name.replace(/_id$/, "_name"),
				size : field.table_width||"10%"
			}) ;
		}	
	}) ;
	
	_self.fields.fields[model+"~~grid"].colsCustom.push({
		field : "state",
		size : "110px",
		render : function(record){
			var html = t("fields.values.aetna.dms.scan.invoice.state."+record.state) ;
			html += ' <div class="dms_dmsGrid__saveStatus" id="dms_dmsGrid__saveStatus_'+record.recid+'"></div>' ;
			return html ;
		}
	}) ;
	
	this.getInputedRecord = function(recid){
		var record = {} ;
		var row = _self.editableFields[recid] ;
		Object.keys(row).forEach(function(column){
			var col = row[column] ;
			var fieldName = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.columns[parseInt(column, 10)].field ;
			fieldName = fieldName.replace(/_name$/, "_id") ;
			Object.keys(col).forEach(function(subIndex){
				var sub = col[subIndex] ;
				var index = parseInt(subIndex,10);
				sub.forEach(function(f){
					var field = f.field ;
					var def = f.fieldDef ;
					if(def.name === fieldName){
						//simple field
						record[fieldName] = field.val() ;
					}else{
						//subfields
						if(!record[fieldName]){
							record[fieldName] = [] ;
						}
						if(!record[fieldName][index]){
							record[fieldName][index] = {} ;
						}
						record[fieldName][index][def.name] = field.val() ;
					}
				}) ;
			}) ;
		}) ;
		return record ;
	} ;
	
	
	
	this.isEqual = function(val1, val2){
		if(val1 == val2){ return true ; }
		if(
			(typeof(val1) === "object" && val1 && val1.constructor === Date) ||
			(typeof(val2) === "object" && val2 && val2.constructor === Date)
		){
			return moment(val1).isSame(moment(val2), "day") ;
		}
			
		if(val1 && val2 && typeof(val1) === "object" && typeof(val2) === "object"){
			return Object.keys(val2).every(function(k){
				return _self.isEqual(val1[k], val2[k]) ;
			}) ;
		}
		return false;		
	} ;
	
	this.checkDiff = function(recid, oldRecord, newRecord){
		var elIndicator = document.getElementById("dms_dmsGrid__saveStatus_"+recid) ;
		if(!_self.isEqual(oldRecord, newRecord)){
			elIndicator.innerHTML = '<i class="fa fa-floppy-o text-primary"></i>' ;
			_self.emit("addLineToSave", {recid : recid, oldRecord : oldRecord, newRecord : newRecord}) ;
		}
	} ;
	
	this.savingInProgressStatus = function(recid){
		var elIndicator = document.getElementById("dms_dmsGrid__saveStatus_"+recid) ;
		elIndicator.innerHTML = '<i class="fa fa-spinner fa-spin"></i>' ;
	} ;
	
	this.savingErrorStatus = function(recid){
		var elIndicator = document.getElementById("dms_dmsGrid__saveStatus_"+recid) ;
		elIndicator.innerHTML = '<i class="fa fa-exclamation-circle text-danger"></i>' ;
	} ;
	
	this.saveOKStatus = function(recid){
		var elIndicator = document.getElementById("dms_dmsGrid__saveStatus_"+recid) ;
		elIndicator.innerHTML = '<i class="fa fa-check-circle text-success"></i>' ;
		setTimeout(function(){
			elIndicator.innerHTML = '' ;	
		}, 2000) ;
	} ;
	
	this.clearFieldError = function(fields, recid){
		fields.forEach(function(f){
			_self.fieldsByCode[recid][f].forEach(function(field){
				field.clearError() ;
			}) ;
		}) ;
	} ;
	
	this.addFieldError = function(fields, msg,  recid){
		fields.forEach(function(f){
			_self.fieldsByCode[recid][f].forEach(function(field){
				field.error(msg) ;
			}) ;
		}) ;
	} ;
	
	this.changeFocus = function(newField, recid){
		if(_self.currentField){
			_self.currentField.setViewMode(true) ;
			var record = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.get(_self.currentSelectedId) ;
			var inputedRecord = _self.getInputedRecord(_self.currentSelectedId) ;
			inputedRecord.id = recid ;
			_self.emit("fieldChange", {
				activity : record.state,
				code : _self.currentField.code,
				fields : inputedRecord,
				callback : function(err){
					if(err){
						var elIndicator = document.getElementById("dms_dmsGrid__saveStatus_"+recid) ;
						elIndicator.innerHTML = '<i class="fa fa-exclamation-triangle text-danger"></i>' ;
					}else{
						_self.checkDiff(_self.currentSelectedId, record, inputedRecord) ;			
					}
				}
			}) ;
		}
		_self.currentField = newField ;
		if(newField){
			newField.setViewMode(false) ;
			newField.focus() ;
			_self.fields.fields[_self.options.model+"~~grid"].grid.grid.select(recid) ;
		}
	} ;
	
	this.createTableField = function(cell, field, record, recid, subIndex, column){
		if(!_self.editableFields[recid]) {
			_self.editableFields[recid] = {} ;
		}
		if(!_self.fieldsByCode[recid]) {
			_self.fieldsByCode[recid] = {} ;
		}
		
		var thisField = _self.fields.createColumnField(field.model, field.name) ;
		thisField.label = "";
		thisField.setViewMode(true) ;
		
		thisField.init(cell, function(){
			thisField.load(record[field.name]) ;
		}) ;
		
		if(!_self.editableFields[recid][column]){
			_self.editableFields[recid][column] = {};
		}
		
		if(!_self.editableFields[recid][column][subIndex]){
			_self.editableFields[recid][column][subIndex] = [];
		}
		
		if(!_self.fieldsByCode[recid][field.name]) {
			_self.fieldsByCode[recid][field.name] = [] ;
		}
		
		_self.fieldsByCode[recid][field.name].push(thisField) ;
		
		var thisLineIndex = _self.editableFields[recid][column][subIndex].length;
		
		_self.editableFields[recid][column][subIndex].push({
			fieldDef : field,
			field : thisField, 
			cell : cell 
		}) ;
		
		cell.addEventListener("click", function(){
			_self.changeFocus(thisField, recid) ;
		}) ;
		document.addEventListener("click", function(){
			setTimeout(function(){
				if(!thisField.hasFocus() && !thisField.viewMode){
					_self.changeFocus(null, recid) ;
				}
			}, 10) ;
		}) ;
		thisField.addEventListener("keydown", function(e){
			var code = e.keyCode || e.which;
		    if (code == '9') { //TAB
		    	var back = e.shiftKey; 
		    	
		    	var found = false ;
		    	
		    	//first check on the same column and same line (case of multiline cell)
		    	var colLine = _self.editableFields[recid][column][subIndex] ;
		    	if(!back && colLine.length > thisLineIndex + 1){
					//go forward and have a field on the same line, go to next field on the same line
					_self.changeFocus(colLine[thisLineIndex+1].field, recid) ;
					found = true ;
				}else if(back && thisLineIndex > 0){
					//go back and have a field on the same line, go to previous field on the same line
					_self.changeFocus(colLine[thisLineIndex-1].field, recid) ;
					found =true ;
				}
				
				if(found){ return; }

	    		//no eligibe field on the same line, search other line in the same column (still case of multiline cell)
    			var indexes = Object.keys(_self.editableFields[recid][column]).sort() ;
    			var indexOfCurrent = indexes.indexOf(""+subIndex) ;
	    		if(!back && indexOfCurrent<indexes.length-1){
	    			//go forward and have a next line, go to first field of next line
	    			_self.changeFocus(_self.editableFields[recid][column][indexes[indexOfCurrent+1]][0].field, recid) ;
	    			found =true ;
	    		}else if(back && indexOfCurrent > 0){
	    			//go back and have a previous line, go to last field of previous line
	    			var previousLine = _self.editableFields[recid][column][indexes[indexOfCurrent-1]] ;
	    			_self.changeFocus(previousLine[previousLine.length-1].field, recid) ;
	    			found =true ;
	    		}
	    		
	    		if(found){ return; }
	    		
	    		//no eligible field on the same cell
	    		var columns = Object.keys(_self.editableFields[recid]).sort() ;
	    		var indexOfCurrentCol = columns.indexOf(""+column) ;
	    		if(!back && indexOfCurrentCol < columns.length-1){
	    			//go forward and have a next cell, go to first field of next cell
	    			_self.changeFocus(_self.editableFields[recid][columns[indexOfCurrentCol+1]][0][0].field, recid) ;
	    			found =true ;
	    		}else if(back && indexOfCurrentCol>0){
	    			//go back and have a previous cell, go to the last field of previous cell
	    			var previousCell = _self.editableFields[recid][columns[indexOfCurrentCol-1]] ;
	    			var previousCellIndexes = Object.keys(previousCell).sort() ;
	    			var lastLine = previousCell[previousCellIndexes[previousCellIndexes.length-1]] ;
	    			_self.changeFocus(lastLine[lastLine.length-1].field, recid) ;
	    			found =true ;
	    		}
	    		
	    		if(found){ return; }
	    		
	    		//no eligible field on the same row
	    		var rows = Object.keys(_self.editableFields).sort(function(r1, r2){
	    			var index1 = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.get(r1, true) ;
	    			var index2 = _self.fields.fields[_self.options.model+"~~grid"].grid.grid.get(r2, true) ;
	    			return index1 - index2 ;
	    		}) ;
	    		var indexOfCurrentRow = rows.indexOf(""+recid) ;
	    		if(!back && indexOfCurrentRow < rows.length-1){
	    			//go forward and have a next row, go to first field of first cell of next row
	    			columns = Object.keys(_self.editableFields[rows[indexOfCurrentRow+1]]).sort() ;
	    			_self.changeFocus(_self.editableFields[rows[indexOfCurrentRow+1]][columns[0]][0][0].field, rows[indexOfCurrentRow+1]) ;
	    			found =true ;
	    		}else if(back && indexOfCurrentRow > 0){
	    			//go back and have a previous row, go to the last field of the last cell of previous row
	    			var previousRow = _self.editableFields[rows[indexOfCurrentRow-1]] ;
	    			columns = Object.keys(previousRow).sort() ;
	    			var previousRowCell = previousRow[columns[columns.length-1]] ;
	    			var previousRowCellIndexes = Object.keys(previousRowCell).sort() ;
	    			var previousRowCellLine = previousRowCell[previousRowCellIndexes[previousRowCellIndexes.length-1]] ;
	    			_self.changeFocus(previousRowCellLine[previousRowCellLine.length-1].field, rows[indexOfCurrentRow-1]) ;
	    			found =true ;
	    		}
	    		
	    		if(found){ return; }
	    		
	    		//no eligible field, remove focus
	    		_self.changeFocus(null) ;
		    }
		}) ;
	} ;
	
	
	
	var _initElements = this._initElements ;
	this._initElements = function(callback){
		_self.EL.grid.setAttribute("data-field", model+"~~grid") ;
		
		_initElements(callback) ;
	} ;
	
	_self.activities = activities ;
	
	_self.once("initDone", function(){
		_self.pdfView = new PdfView(t) ;
		_self.pdfView.init(_self.EL.pdf) ;
		
		_self.templateFilters = new TemplateWidget(_self.EL.filters);

		var filters = [] ;
		_self.activities.forEach(function(act){
			filters.push({
				state : act.name,
				label : act.AetnaLabel||t("fields.values."+model+".state."+act.name),
				icon : act.AetnaIcon||"fa-cog",
				color : act.AetnaColor||"black",
				activity : act
			}) ;
		}) ;
		
		_self.templateFilters.on("render", _self.renderFilters);
		
		_self.templateFilters.render({filters : filters}) ;

		$(_self.container).find('[data-toggle="tooltip"]').tooltip() ;

		
		_self.EL.detailsFilters.addEventListener("click", _self.toggleDetailsFilter);


		_self.fields.fields[_self.options.model+"~~grid"].addEventListener("unselect", function(ev){
			if(ev.recid){
				ev.preventDefault();
			}
		}) ;

		_self.fields.fields[_self.options.model+"~~grid"].addEventListener("select", _self.onTableSelect) ;
		
	}) ;
	
	this.onTableSelect = function(ev){
		if(ev.recid && ev.recid != _self.currentSelectedId){ // != because compare number and string
			_self.currentSelectedId = ev.recid ;
			_self.pdfView.showPdf(serverUrl+"/api/aetna.dms.scan.base/downloadPdf?model="+model+"&id="+ev.recid) ;
		}
	} ;
	
	this.renderFilters = function(){
		_self.templateFilters.$EL_LISTS.filter.forEach(function($a, i){
			$a.on("click", function(ev){
				if(!ev.ctrlKey && !ev.shiftKey){
					_self.$EL.filters.find("a").each(function(y, link){
						if(link !== $a[0]){
							$(link).removeClass("active") ;			
						}
					}) ;
				}
				$a.toggleClass("active") ;
				_self.emit("changeFilter", {filter : _self.getFilter()}) ;
				ev.preventDefault() ;
			}) ;
		}) ;
	} ;

	this.getFilter = function(){
		var filter = {} ;
		
		_self.$EL.filters.find("a").each(function(y, link){
			var $a = $(link) ;	
			if($a.hasClass("active")){
				filter[link.getAttribute("data-state")] = true ;
			}
		});
		
		return filter ;
	} ;
	
	this.toggleDetailsFilter = function(){
		_self.$EL.filters.toggleClass("list-group-horizontal") ;
		if(_self.$EL.filters.hasClass("list-group-horizontal")){
			_self.EL.detailsFilters.innerHTML = t("refund.viewDetails") ;
		}else{
			_self.EL.detailsFilters.innerHTML = t("refund.reduceDetails") ;
		}
		_self.resizeTable() ;
	} ;
	
	
	_self.on("changeFilter", function(ev){
		var filterCount = 0;
		var selectedStates = [] ;
		Object.keys(ev.data.filter).forEach(function(k){
			if(ev.data.filter[k]){
				selectedStates.push(k) ;
				filterCount++ ;
			} 
		}) ;
		
		_self.currentFieldsDef = wkFields ;
		
		if(filterCount === 1){
			//only 1 state / activity is selected
			if(actFields[selectedStates[0]]){
				_self.currentFieldsDef = actFields[selectedStates[0]] ;
			}
		}

		//update column visibility		
		_self.fields.fields[_self.options.model+"~~grid"].grid.grid.columns.forEach(function(col){
			var hidden = false ;
			_self.currentFieldsDef.some(function(field){
				if(field.name === col.field){
					hidden = !!field.table_hide ;
					return true ;
				}
			}) ;
			col.hidden = hidden ;
		}) ;
	}) ;
	
	
	this.resizeTable = function(){
		
		var headerHeight = 5 ;
		
		if(_self.$EL.rowControls.is(":visible")){
			headerHeight += _self.$EL.rowControls.height() ;
		}
		
		_self.EL.gridRow.style.height = "calc(100% - "+headerHeight+"px)"; 

		_self.fields.fields[model+"~~grid"].render() ;
	} ;
	
	this.loadCounts = function(counts, forceRefresh){
		_self.counts = counts ;
		_self.$EL.filters.find("a").each(function(y, link){
			var thisState = link.getAttribute("data-state") ;
			
			var $span = $(link).find(".badge") ;
			var count = counts[thisState] || 0 ;
			if($span.html() != count){// != because compare string and integer
				$span.html(count) ;
				$span.addClass("animated") ;
				$span.addClass("flash") ;
				
				$span.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(){
					$span.removeClass("animated") ;
					$span.removeClass("flash") ;
				});	
			}
		});
	} ;
	
	var _load = this.load ;
	this.load = function(records){
		_self.editableFields = {} ;
		_self.fieldsByCode = {} ;
		_load(records) ;
		
		var hasEditable = false;
		_self.currentFieldsDef.some(function(f){
			if(f.table_edit){
				hasEditable = true;
				return true ;
			}
		}) ;
		
		if(hasEditable){
			_self.$EL.gridRow.addClass("withPdf") ;
		}else{
			_self.$EL.gridRow.removeClass("withPdf") ;
		}
	} ;
	
}

module.exports =DmsGridView;
