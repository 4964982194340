var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function AccountInvoice(serverUrl){
	CrudCli.call(this, serverUrl, "account.invoice");
	
    
	this.validate = function(id, callback){
         ajax(serverUrl + "/api/account.invoice/validate", {ids : [id]}, callback);
    };
    
    this.validateMulti = function(ids, callback){
         ajax(serverUrl + "/api/account.invoice/validate", {ids : ids}, callback);
    };
    
    this.createRemindMulti = function(ids, callback){
         ajax(serverUrl + "/api/account.invoice/createRemind", {ids : ids}, callback);
    };
	
	this.cancel = function(id, callback){
         ajax(serverUrl + "/api/account.invoice/cancel", {ids : [id]}, callback);
    };
    
    this.cancelMulti = function(ids, callback){
         ajax(serverUrl + "/api/account.invoice/cancel", {ids : ids}, callback);
    };
    
    this.computeTaxExport = function(ids, callback){
         ajax(serverUrl + "/api/account.invoice/computeTaxExport", {invoicesIds : ids}, callback);
    };
   
   
    this.refundInvoice = function(idInvoice, callback){
         ajax(serverUrl + "/api/account.invoice/refundInvoice", {idInvoice : idInvoice}, callback);
    };
    
    
}

module.exports = AccountInvoice ;