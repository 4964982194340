var WorkflowViews = require("../views/workflow/workflowViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var t = require("aetna-views/locales/tr").t ;

function WorkflowController() {
	var _self = this ;
	
	BaseController.call(this, {
		model : "project.project",
		modelsToSync : ["project.project", "project.aetnaworkflowcycle"],
		socketChangeEvent : 'projectChanged',
		name : "workflow",
		gridClass : WorkflowViews.Grid,
		formClass : WorkflowViews.Form,
		forceRefreshOnModif : true
	}) ;
	
	this.listAll = function(){
		return loki.daos["project.project"].search({AetnaIsWorkflow : true}) ;
	};
	
    
    this.checkCanDelete = function(data, callback){
    	var workflow = data.recordToDelete ;
    	
    	var task = loki.daos["project.task"].searchFirst({AetnaWorkflow_id : workflow.id}) ;
    	if(task){
    		_self.viewForm.error(t("workflow.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
	
	
	// this.openRecord = function(ev){
	// 	_self.viewList.startWait() ;
		
	// 	_self.doSync(function(err){
	// 		if(err){ return _self.viewList.endWaitError(err) ; }
			
	// 		var record = loki.daos[_self.options.model].searchFirst({id : ev.data.id}) ;
	// 		var steps = loki.daos["project.aetnaworkflowcycle"].search({AetnaWorkflow_id : ev.data.id}) ;
			
	// 		_self.initForm(function(){
				
	// 			_self.viewForm.load({ workflow : record, steps: steps}) ;
				
	// 			_self.viewForm.show() ;
				
	// 			_self.viewList.endWait() ;	
	// 			_self.viewList.hide() ;
	// 		}) ;
			
	// 	}) ;
	// } ;
	
	this.loadRecord = function(record){
		var steps = loki.daos["project.aetnaworkflowcycle"].search({AetnaWorkflow_id : record.id}) ;
		steps = steps.sort(function(a, b){
			var stageDiff = a.AetnaStage_id - b.AetnaStage_id ;
			if(stageDiff !== 0){
				return stageDiff ;
			}
			return a.id - b.id ;
		}) ;
		_self.viewForm.load({ workflow : record, steps: steps}) ;
	} ;
	
	
	// this.onSave = function(ev){
	// 	_self.viewForm.startWait();
		
	// 	var workflow = ev.data.workflow ;
	// 	var steps = ev.data.steps ;
		
	// 	if(ev.data.isCreate){
	// 		cli(_self.options.model).create(workflow, steps, function(err, savedRecord){
	// 			if(err){ return _self.viewForm.endWaitError(err) ; }
				
	// 			_self.viewForm.load({ workflow : savedRecord, steps: steps}) ;
	// 			_self.viewForm.endWait() ;
	// 		}) ;
	// 	}else{
	// 		cli(_self.options.model).save(workflow, steps, function(err, savedRecord){
	// 			if(err){ return _self.viewForm.endWaitError(err) ; }
				
	// 			_self.viewForm.load({ workflow : savedRecord, steps: steps}) ;
	// 			_self.viewForm.endWait() ;
	// 		}) ;
	// 	}	
	// } ;
}

module.exports = new WorkflowController();
