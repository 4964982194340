var CrudCli = require("aetna-ajax/CrudCli") ;
var ajax = require("aetna-ajax/ajax.js").ajax;

function AccountInvoiceAetnaRemind(serverUrl){
	CrudCli.call(this, serverUrl, "account.invoice.aetna.remind");
	
	    
	this.createRemind = function(remindToDo, callback){
         ajax(serverUrl + "/api/account.invoice.aetna.remind/createRemind", {remindsToDo : [remindToDo]}, callback);
    };
    
    this.createRemindMulti = function(remindsToDo, callback){
         ajax(serverUrl + "/api/account.invoice.aetna.remind/createRemind", {remindsToDo : remindsToDo}, callback);
    };
	
	this.getWebDavDoc = function(id, callback){
        ajax(serverUrl + "/api/account.invoice.aetna.remind/getWebDavDoc", {id : id},callback);
    };
}

module.exports = AccountInvoiceAetnaRemind ;