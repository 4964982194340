var SaleViews = require("../views/sale/saleViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var cli = require("aetna-ajax/Cli").cli ;
var DistantFile = require("../api/DistantFile") ;
var BUS = require("aetna-events").EventSource.BUS;

function SaleController() {
	var _self = this ;

	BaseController.call(this, {
		model : "sale.order",
		modelsToSync : ["sale.order", "sale.order.line", "ir.attachment"],
		name : "sale",
		gridClass : SaleViews.Grid,
		formClass : SaleViews.Form,
		filter : { field : "state" }
	}) ;
	
	BUS.on("loaded", function(){
		_self.socket.on('sale-pdfStart', _self.onPdfStart);
		_self.socket.on('sale-pdfFinished', _self.onPdfFinished);
	}) ;
	
	var _initForm = this.initForm;
	this.initForm = function(callback){
		_initForm(callback) ;
		
		_self.viewForm.on("createPdf", _self.createPdf) ;
		_self.viewForm.on("send", _self.onSend) ;
		_self.viewForm.on("accept", _self.onAccept) ;
		_self.viewForm.on("canBeInvoice", _self.onCanBeInvoice) ;
		_self.viewForm.on("createInvoice", _self.onCreateInvoice) ;
		_self.viewForm.on("cancel", _self.onCancel) ;
		_self.viewForm.on("openContractWord", _self.openContractWord) ;

	} ;
	
	this.loadRecord = function(record){
		_self.currentRecord = record ;
		
		record.order_line = loki.daos["sale.order.line"].search({order_id : record.id}, "id") ;

		var attach = loki.daos["ir.attachment"].searchFirst({res_model : "sale.order", res_id : record.id}) ;

		_self.viewForm.load(record, attach) ;
	} ;
	
	
	this.createPdf = function(ev){
		_self.gui.startWait() ;
		
		
		if(!Array.isArray(ev.data)){
			ev.data = [ev.data] ;
		}
		
		
		var ids = ev.data.map(function(order){ return order.id ;}) ;
		
		
		cli("sale.order").createPdf(ids, function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.doSync(function(){
			
				if(ev.source.constructor === SaleViews.Grid){
					//from list, refresh list
					_self.refreshCount();
					_self.refreshList();	
				}else{
					//from form, refresh the form
					_self.doSync(function(err){
						if(err){ return _self.viewList.endWaitError(err) ; }
						
						var record = loki.daos[_self.options.model].searchFirst({id : ids[0]}) ;
						_self.currentRecord = record ;
					
						_self.loadRecord(record) ;
						
					}) ;
				}
				
				
				_self.gui.endWait() ;
				window.location.href = cli().serverUrl+"/api/sale.order/downloadPdf?id="+ids.join(",") ;
 				
			});
		}) ;
	} ;
	
	this.openContractWord = function(ev){
		_self.gui.startWait() ;
		
		DistantFile.openDistantFile("sale.order", ev.data.id, function(err){
			if(err){ return _self.gui.endWaitError(err) ;}
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.onPdfStart = function(contract){
		if(_self.viewForm){
			_self.viewForm.onPdfStart(contract) ;
		}
	} ;
	
	this.onPdfFinished = function(contract){
		if(_self.viewForm){
			_self.viewForm.onPdfFinished(contract) ;
		}
	} ;
	
	
	var _applyAction = function(items, action, callback){
		if(!Array.isArray(items)){
			items = [items] ;
		}
		
		
		var ids = ev.data.map(function(order){ return order.id ;}) ;
		
		
		cli("sale.order")[action](ids, function(err){
			if(err){ return callback(err); }
			
			_self.doSync(function(){
				if(ev.source.constructor === SaleViews.Grid){
					//from list, refresh list
					_self.refreshCount();
					_self.refreshList();
					callback() ;
				}else{
					//from form, refresh the form
					_self.doSync(function(err){
						if(err){ return callback(err) ; }
						
						var record = loki.daos[_self.options.model].searchFirst({id : ids[0]}) ;
						_self.currentRecord = record ;
					
						_self.loadRecord(record) ;
						callback() ;
					}) ;
				}
			});
		}) ;
	} ;
	
	this.onSend = function(ev){
		_self.gui.startWait() ;
		_applyAction(ev.data, "send", function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.onAccept = function(ev){
		_self.gui.startWait() ;
		_applyAction(ev.data, "accept", function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.onCanBeInvoice = function(ev){
		_self.gui.startWait() ;
		_applyAction(ev.data, "canBeInvoice", function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.onCreateInvoice = function(ev){
		_self.gui.startWait() ;
		_applyAction(ev.data, "createInvoice", function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.gui.endWait() ;
		}) ;
	} ;
	
	this.onCancel = function(ev){
		_self.gui.startWait() ;
		_applyAction(ev.data, "cancel", function(err){
			if(err){ return _self.gui.endWaitError(err); }
			
			_self.gui.endWait() ;
		}) ;
	} ;
}

module.exports = new SaleController();
