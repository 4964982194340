var t = require("aetna-views/locales/tr").t ;
var BUS = require("aetna-events").EventSource.BUS;
var BaseView = require("aetna-views/BaseView")  ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;
var TemplateWidget = require("aetna-views/widgets/TemplateWidget");
var CreateInvoiceView = require("./createInvoice") ;
var numbro = require("numbro");
var numbroLangs = [require("numbro/languages/fr-FR.js")] ;
var FieldsEMA = require("../fields/FieldsEMA") ;
var async = require("async") ;

numbroLangs.forEach(function(l){
	numbro.culture(l.langLocaleCode, l);
}) ;


function GenInvoiceFromSchedulesView(serverUrl, schemas){
	var _self = this ;
	
	BaseView.call(this, t, "contract", "genInvoiceFromSchedules") ;
	
	this.fields = new FieldsEMA(t, serverUrl)  ;

	
	this._initElements = function(callback){
		_self.templateInvoices = new TemplateWidget(_self.EL.invoices);
		
		_self.templateInvoices.on("render", _self.renderInvoices) ;
		
		_self.EL.cancel.emit() ;
		_self.EL.validate.addEventListener("click", _self.onValidate) ;
		
		_self.fields.init(_self, function(){
			_self.fields.fields["account.invoice~amount_untaxed"].setReadOnly(true) ;
			_self.fields.fields["account.invoice~amount_tax"].setReadOnly(true) ;
			_self.fields.fields["account.invoice~amount_total"].setReadOnly(true) ;
			
            callback() ;
		}) ;
	} ;
	
	this.load = function(invoices){
		_self.invoices = invoices ;
		_self.templateInvoices.render({invoices : invoices}) ;
	} ;
	
	this.renderInvoices = function(){
		_self.invoicesViews = [] ;
		if(_self.templateInvoices.LISTS.invoice){
			_self.templateInvoices.LISTS.invoice.forEach(function(invoice){
				var view = new CreateInvoiceView() ;
				_self.invoicesViews.push(view) ;
				view.productsById = _self.productsById ;
				view.taxesById = _self.taxesById ;
				view.init(invoice.el, function(){
					view.load(invoice.data) ;
				}) ;
				view.on("change", _self.computeTotals) ;
			}) ;
		}
	} ;
	
	
	this.computeTotals = function(){
		_self.ensureInit(function(){
			if(_self.invoicesViews.length === 1){
				_self.EL.totals.style.display = "none" ;
			}else{
				_self.EL.totals.style.display = "block" ;
			}
			
			var lang = storage(context().name).get("lang");
			numbro.language(lang);
			
			var totals = {
				amount_untaxed : 0,
				amount_tax : 0,
				amount_total : 0
			} ;
			_self.invoicesViews.forEach(function(view){
				if(!view.initDone){ return ; }
				
				var invoice = view.getValues() ;
				totals.amount_untaxed += invoice.amount_untaxed ;
				totals.amount_tax += invoice.amount_tax ;
				totals.amount_total += invoice.amount_total ;
			}) ;
			
			_self.fields.load(totals) ;
		}) ;
	} ;
	
	this.onValidate = function(){
		var initialInvoices = _self.invoices ;
		var invoices = [] ;
		var warnQty = [] ;
		_self.invoicesViews.forEach(function(view, i){
			var invoice = view.getValues() ;
			invoices.push(invoice) ;
			
			var has0InQty = invoice.invoice_line.some(function(line){
				return line.quantity === 0 ;
			}) ;
			if(has0InQty){
				warnQty.push(t("contract.qty0InInvoice", 
				{customerName : initialInvoices[i].partner_name, invoiceName : invoice.name})) ;
			}
		}) ;
		
		var calls = [function(cb){ cb(null, true) ; }] ;
		
		if(warnQty.length > 0){
			calls.push(function(cb){
				_self.confirm(t("contract.warnAreYouSure", {warns : warnQty.join("\n")}), function(yes){
					cb(null, yes) ;
				})	 ;
			});
		}
		
		async.series(calls, function(err, oks){
			if(err){ return ; }
			if(oks.every(function(ok){ return ok ;})){
				_self.emit("validate", {invoices: invoices, initialInvoices : initialInvoices}) ;
			}
		}) ;
	} ;
}

module.exports = GenInvoiceFromSchedulesView;
