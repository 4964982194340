var LokiDao = require("../LokiDao") ;
var moment = require("moment") ;

var LokiAccountInvoice = function(loki){
    LokiDao.call(this, loki, "account.invoice") ;
    
    var _self = this ;
    
    this.invoiceFilters = function(filter){
        var searchFilters = [] ;
        
		if(filter.draft){
			searchFilters.push({ state : "draft" }) ;
		}
        
		if(filter.paid){
			searchFilters.push({ state : "paid" }) ;
		}
		
		var limitDateInvoice = _self.getLimitDate() ;
		var limitDatesRemind = _self.getLimitRemind() ;
		
		if(filter.type === "out_refund"){
			//refund
			if(filter.waiting){
				//filter open status
				searchFilters.push({ state : "open" }) ;
			} 
		}else{
			//invoice
			if(filter.waiting && filter.toRemind && filter.remindWaiting && filter.notPaid){
				//all open status are selected, no need to check dates
				searchFilters.push({ state : "open" }) ;
			}else{
				if (filter.waiting){
					searchFilters.push({
						$and : [
							{ state : "open"},
							{ date_due : {$gt : moment(limitDateInvoice).format("YYYY-MM-DD")} } //invoice limit not reached
							] 
					}) ;
				}
				
				if (filter.toRemind || filter.remindWaiting){
					searchFilters.push({
						$and : [
							{ state : "open"},
							{ date_due : {$lte : moment(limitDateInvoice).format("YYYY-MM-DD")} } //invoice limit reached
						]
					}) ;
				}
				
				if (filter.notPaid){
					searchFilters.push({
						$and : [
							{ state : "open"},
							{ date_due : {$lte : moment(limitDateInvoice).format("YYYY-MM-DD")} }, //invoice limit reached
							{ AetnaLastRemindNumber : {$gte : limitDatesRemind.length}}, //all remind has been done already
							{ AetnaLastRemindDate : {$lte : moment(limitDatesRemind[limitDatesRemind.length-1]).format("YYYY-MM-DD")} } //last remind limit reached
						]
					}) ;
				}
			} 
		}
		
		
		
		if(filter.cancel){
			searchFilters.push({ state : "cancel" }) ;
		}
		
		var search = null;
		if(searchFilters.length>1){
			search = {
				$or : searchFilters	
			};
		}else if(searchFilters.length>0){
			search = searchFilters[0] ;
		}
		
		if(!search){
			search = { type : filter.type } ;
		}else{
			search = {
				$and : [
					{ type : filter.type },
					search
				]
			} ;	
		}
		
		
		
		var whereFunction = function(invoice){ 
			var hasFilter = Object.keys(filter).some(function(k){
				if(k === "type") { return false }
				return filter[k] ;
			}) ;
			if(!hasFilter){ return true ; }
			
			var state = _self.getExtendedState(invoice) ;
			
			if(filter.draft && state === "draft"){ return true ; }
			
			if(filter.waiting && state === "waiting"){ return true ; }
			
			if(filter.toRemind && state === "toRemind"){ return true ; }
			
			if(filter.remindWaiting && state === "remindWaiting"){ return true ; }
			
			if(filter.notPaid && state === "notPaid"){ return true ; }
			
			if(filter.paid && state === "paid"){ return true ; }
			
			if(filter.cancel && state === "cancel"){ return true ; }
			
			
			return false ;
		
		} ;
		
		
		return this.coll().chain().find(search).where(whereFunction).compoundsort([['create_date', true]]).data() ;
    } ;
    
    this.getLimitDate = function(){
    	var remindSettings = loki.daos["account.invoice.aetna.remind.settings"].listAll("sequence") ;
    	if(remindSettings.length > 0){
    		return moment().subtract(remindSettings[0].delay, 'days').toDate() ; 	
    	}
    } ;
    
    this.getLimitRemind = function(){
    	var remindSettings = loki.daos["account.invoice.aetna.remind.settings"].listAll("sequence") ;
    	var limits = [] ;
    	remindSettings.forEach(function(r, i){
    		if(i>0){
    			limits.push(moment().subtract(r.delay, 'days').toDate()) ;
    		}
    	}) ;
    	return limits ;
    } ;
    
    this.getExtendedState = function(invoice){
    	if(invoice.type === "out_refund"){ 
    		if(invoice.state === "open"){ return "waiting"; }
    		return invoice.state ;
    	}
    	
    	var limitDate = _self.getLimitDate() ;
    	var limitDatesRemind = _self.getLimitRemind() ;
    	if(invoice.state === "open"){
			if(invoice.date_due && moment(invoice.date_due).isBefore(limitDate)){
				if(!invoice.AetnaLastRemindNumber){
					return "toRemind" ; //the invoice limit is reached and no remind has been done yet
				}else{
					//a remind has been done check that the limit is reached
					var limit = limitDatesRemind[invoice.AetnaLastRemindNumber-1] ;
					if(invoice.AetnaLastRemindNumber >= limitDatesRemind.length){
						limit = limitDatesRemind[limitDatesRemind.length -1] ;
					}
					if(invoice.AetnaLastRemindDate <= moment(limit).format("YYYY-MM-DD")){
						//limit reached
						if(invoice.AetnaLastRemindNumber >= limitDatesRemind.length){
							//no more remind to do
							return 'notPaid' ;
						}else{
							//still have remind to do
							return "toRemind" ;
						}
					}else{
						return "remindWaiting" ;
					}
				}
			}else{
				return "waiting" ;
			}
		}
		return invoice.state;
    } ;
    
    
    this.countByState = function(type){
    	
    	return this.coll().chain().find({ type :type }).mapReduce(
			function(invoice){ 
				return { state : _self.getExtendedState(invoice) } ;
			}, 
			function(invoices){ 
				var byState = {}; 
				invoices.forEach(function(d){ 
				    if(!byState[d.state]){ 
				   		byState[d.state] = 0; 
				    }
					byState[d.state]++;
				}); 
				return byState ;
		} ) ;
    } ;
    
    this.listExportableToVAT = function(){
    	return this.coll().chain().find({
    		'$or': [
    			{'AetnaTaxProductPaid' : { '$gte': 0 } },
    			{'AetnaTaxServicePaid' : { '$gte': 0 } }
    		]
    	}).where(
		function(invoice){ 
			if(invoice.AetnaTaxProductPaid){
				//some product VAT is paid
				if(!invoice.AetnaTaxProductExported || invoice.AetnaTaxProductExported<invoice.AetnaTaxProductPaid){
					//not yet exported or only partly exported
					return true ;
				}
			}
			if(invoice.AetnaTaxServicePaid){
				//some service VAT is paid
				if(!invoice.AetnaTaxServiceExported || invoice.AetnaTaxServiceExported<invoice.AetnaTaxServicePaid){
					//not yet exported or only partly exported
					return true ;
				}
			}
			return false ;
		}).data() ;
    } ;
    
    
    
    this.getStatsInvoices = function(filter){
     	var searchDate = null;
    	
    	if(filter.to){
    		filter.to = moment(filter.to).endOf("day").toDate() ;
    	}
    	
    	if(filter.from && filter.to){
    		searchDate = {
    			$and : [
    				{date_invoice : { $gte : moment(filter.from).format("YYYY-MM-DD HH:mm:ss") } },
    				{date_invoice : { $lte : moment(filter.to).format("YYYY-MM-DD HH:mm:ss") } }
				]
    		} ;
    	}else if(filter.from){
    		searchDate = {date_invoice : { $gte : moment(filter.from).format("YYYY-MM-DD HH:mm:ss") } } ;
    	}else if(filter.to){
    		searchDate = {date_invoice : { $lte : moment(filter.to).format("YYYY-MM-DD HH:mm:ss") } } ;
    	}
    	
    	var search = { 
    		$and : [
				{type : { $in : ["out_refund", "out_invoice"]}},
				{state : {$in  : ["open", "paid"]} }
			]
		};
    	
		if(searchDate){
			search = {
				$and : [
					search,
					searchDate
				]
			};
		}
     	
    	
    	return this.coll().chain().find(search).mapReduce(
			function(invoice){ 
				var inv = {
					key : invoice.period_id+"_"+invoice.partner_name,
					period : invoice.period_name,
					partner : invoice.partner_name,
					total_untaxed : invoice.amount_untaxed,
					total_tax : invoice.amount_tax,
					total_amount : invoice.amount_total,
					total_residual : invoice.residual,
					total_paid : invoice.amount_total-invoice.residual,
					type : invoice.type
				} ;
				
				if(filter.period && !filter.partner){
					inv.key = invoice.period_id;
				}
				if(!filter.period && filter.partner){
					inv.key = invoice.partner_name;
				}
				
				if(invoice.type === "out_refund"){
					inv.total_untaxed = -1 * inv.total_untaxed;
					inv.total_tax = -1 * inv.total_tax;
					inv.total_amount = -1 * inv.total_amount;
					inv.total_residual = -1 * inv.total_residual;
					inv.total_paid = -1 * inv.total_paid;
				}
				
				return inv ;
			}, 
			function(invoices){ 
				var byKey = {}; 
				invoices.forEach(function(d){ 
				    if(!byKey[d.key]){ 
				   		byKey[d.key] = {
				   			key : d.key,
   							invoice_count : 0,
							period : d.period,
							partner : d.partner,
							total_untaxed : 0,
							total_tax : 0,
							total_amount : 0,
							total_residual : 0,
							total_paid : 0
				   		}; 
				    }
				    if(d.type === "out_invoice"){
				    	byKey[d.key].invoice_count ++;
				    }
				    byKey[d.key].total_untaxed += d.total_untaxed;
					byKey[d.key].total_tax += d.total_tax;
					byKey[d.key].total_amount += d.total_amount;
					byKey[d.key].total_residual += d.total_residual;
					byKey[d.key].total_paid += d.total_paid;
				}); 
				
				var records = [] ;
				Object.keys(byKey).sort().forEach(function(key){
					records.push(byKey[key]) ;
				}) ;
				
				return records ;
		} ) ;
    } ;
} ;

module.exports = LokiAccountInvoice ;