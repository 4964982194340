var HrDepartmentViews = require("../views/hrDepartment/hrDepartmentViews");
var BaseController = require("./BaseController") ;
var loki = require("../api/loki") ;
var t = require("aetna-views/locales/tr").t ;
var context = require("aetna-views/context").context;
var storage = require("aetna-storage").storage ;

function HrDepartmentController() {
	var _self = this ;


	
	BaseController.call(this, {
		model : "hr.department",
		modelsToSync : ["hr.department", "hr.employee"],
		socketChangeEvent : 'hrDepartmentChanged',
		name : "hrDepartment",
		gridClass : HrDepartmentViews.Grid,
		formClass : HrDepartmentViews.Form,
		uniqueCols : ["name"]
	}) ;
	
    
    this.checkCanDelete = function(data, callback){
    	var hrDepartment = data.recordToDelete ;
    	
    	var task = loki.daos["hr.employee"].searchFirst(
    		{department_id :hrDepartment.id}) ;
    	if(task){
    		_self.viewForm.error(t("hrDepartment.cantDeleteUsed")) ;
			return callback(false) ;
    	}
    	
		return callback(true) ;
	} ;
}

module.exports = new HrDepartmentController();
